import React, { Component, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { BASE_URL, TOKEN_ICON_URL } from "../../config/constant";
import '../../index.css'
import BigNumber from 'bignumber.js';
import { uniqBy } from 'lodash';
import Pagination from 'react-js-pagination';
import moment from 'moment';

function TransactionStatements() {
    const [adminTransactions, setAdminTransactions] = useState([])
    const loading = useRef(true);
    const [tokenList, setTokenList] = useState([])
    const [selectDropdown, setSelectDropdown] = useState('Select Token');
    const [filters, setFilters] = useState([])
    const [pageIndex, setPageIndex] = useState(1);
    const [totalRow, setTotalRow] = useState(0)

    useEffect(() => {
        document.title = "Transactions";
        axios.get(BASE_URL + `ercToken/get/all`)
            .then(res => {
                if (res.data.message == "success") {
                    setTokenList(res.data.data)
                }
            })
    }, [])
    useEffect(() => {
        getTableData()
    }, [filters,pageIndex])
    
    const getTableData = () => {
        let userId = localStorage.getItem('userDetails')
        const filterList = uniqBy(filters, 'name')
        axios.post(BASE_URL + `transactionStatement/get/user`, { userId: userId, pageIndex: pageIndex-1, filters: filterList })
            .then(res => {
                if (res.data && res.data.data) {
                    setAdminTransactions(res.data.data);
                    setTotalRow(res.data.totalRow)
                }
                loading.current = false;
            }).catch((e) => {
                loading.current = false;
            })
    }
    const onSelectCoin = (coin) => {
        const uniq = filters.filter(e => e.name != "coinName")
        setFilters([...uniq, { name: "coinName", value: coin.tokenSymbol }]);

        setSelectDropdown(<><img src={TOKEN_ICON_URL + coin.tokenSymbol + ".png"} width="20px" height="20px" style={{ marginLeft: "10px", marginRight: "20px", marginTop: "-4px" }} /> {coin.tokenSymbol} <span style={{ fontSize: "12px", color: "#888" }}>({coin.tokenName})</span></>)
    }
    const onFilterChange = (filertType,value)=>{
        const uniq = filters.filter(e => e.name != filertType)
        setFilters([...uniq, { name: filertType, value: value }]);
    }
   const handlePageChange = (page)=>{
     setPageIndex(page)
    }
    return (
        <div>
            {/* <div className="row">
                <div className="col-sm-12 col-md-3">
                    <div className="dropdown withdarw_drop">
                        <button id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-search"></i> 
                            {selectDropdown} <span className="caret"></span> </button>
                        <ul className="dropdown-menu" aria-labelledby="dLabel">
                            {tokenList.map((d, xid) => (
                                <li key={xid} onClick={() => { onSelectCoin(d) }}>
                                    <img src={TOKEN_ICON_URL + d.tokenSymbol + ".png"} width="20px" height="20px" style={{ marginLeft: "10px", marginRight: "20px" }} />
                                    {d.tokenSymbol} <span style={{ fontSize: "12px", color: "#888" }}>({d.tokenName})</span>
                                </li>
                            ))}
                        </ul>

                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                <select className='form-control' name="tnxType-filter" id="tnxType-filter" onChange={(e) => { onFilterChange('tnxType',e.target.value) }}>
                        <option value="">All</option>
                        <option value="withdraw">Debit</option>
                        <option value="deposit">Credit</option>
                    </select>
                <div className="dropdown withdarw_drop">
                        <button id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           
                            {filters.find(e=>e.name == 'tnxType')?.name || 'Select Mode'} <span className="caret"></span> </button>
                        <ul className="dropdown-menu" aria-labelledby="dLabel">
                                <li  onClick={() => { onFilterChange('tnxType','withdraw') }}>
                                  Debit
                                </li>
                                <li  onClick={() => { onFilterChange('tnxType','deposit') }}>
                                  Credit
                                </li>
                        </ul>

                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <select className='form-control' name="status-filter" id="status-filter" onChange={(e) => { onFilterChange('status',e.target.value) }}>
                        <option value="">All</option>
                        <option value="Pending">Pending</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Completed">Completed</option>
                    </select>
                <div className="dropdown withdarw_drop">
                        <button id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    
                            {filters.find(e=>e.name == 'status')?.name || 'Select Status'} <span className="caret"></span> </button>
                        <ul className="dropdown-menu" aria-labelledby="dLabel">
                                <li  onClick={() => { onFilterChange('Pending') }}>
                                  Pending
                                </li>
                                <li  onClick={() => { onFilterChange('Rejected') }}>
                                Rejected
                                </li>
                                <li  onClick={() => { onFilterChange('Completed') }}>
                                Completed
                                </li>
                        </ul>

                    </div>
                </div>
                <div className="col-sm-12 col-md-3 text-right">
                    <button className='btn btn-primary' onClick={()=>{setFilters([])}}>Clear</button>
                </div>
            </div> */}
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Coin</th>
                            <th>Amount/Bonus</th>
                            <th>Type</th>
                            {/* <th>Amount</th>
                            <th>Rate</th> */}
                            <th>Wallet Address / Details</th>
                            <th>Date & Time</th>
                            <th>Status</th>

                        </tr>
                    </thead>

                    {loading.current ?
                        <tbody>
                            <tr>
                                <td colSpan="6" height="350px">
                                    <div className="loader">Loading...</div>
                                </td>
                            </tr>
                        </tbody>
                        :
                        <>
                            {adminTransactions && adminTransactions.length ?
                                <tbody>
                                    {adminTransactions.map((d, xid) => (
                                        <tr key={xid}>
                                            <td><img src={TOKEN_ICON_URL +"USDT.png"} width="20px" height="20px" style={{ marginLeft: "0px", marginRight: "10px" }} /> USDT</td>
                                            <td><b>{new BigNumber(d.amount).toFormat(6, 1)}</b></td>
                                            <td>{d.tnxType == 'deposit' ? 'Credit' : d.tnxType == 'withdraw' ? 'Debit': d.tnxType}</td>
                                            {/* <td><b>$ {new BigNumber(d.usdVal || 0).toFormat(6, 1)}</b></td>
                                            <td>$ {new BigNumber(d.usdPrice).toFormat(6, 1)}</td> */}
                                            <td>{d.walletAddress}<div>
                                                {d.extratag != "" && d.coinName == "XRP" ?
                                                    "Destination Tag : " + d.extratag : <></>
                                                }
                                                {d.extratag != "" && d.coinName == "XLM" ?
                                                    "Memo : " + d.extratag : <></>
                                                }

                                                {d.coinName == "INR" &&
                                                    <>
                                                        <b>Bank Name</b> : {d.bankName}<br></br>
                                                        <b>Account Type</b> : {d.accountType}<br></br>
                                                        <b>Account Number</b> : {d.accountNumber}<br></br>
                                                        <b>IFSC</b> : {d.ifsc}<br></br>
                                                        <b>Remark</b> : {d.remark}<br></br>
                                                    </>
                                                }
                                            </div></td>
                                            <td>{d.timestamp ? moment(d.timestamp).format("YYYY-MM-DD hh-mm a") :d.date}</td>
                                            <td>
                                                {d.status == "Pending" && <span className="label label-default">Pending</span>}
                                                {d.status == "Processing" && <span className="label label-primary">Processing</span>}
                                                {d.status == "Rejected" && <span className="label label-danger">Rejected</span>}
                                                {d.status == "Completed" && <><span className="label label-success">Completed</span>
                                                </>}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                                :
                                <tbody>
                                    <tr>
                                        <td colspan="6" align="center" className="no_data"><i className="fa fa-file-o"></i> No record found</td>
                                    </tr>
                                </tbody>
                            }
                        </>
                    }

                </table>
            </div>
            <div style={{ marginTop: '20px', float: 'right' }}>
            <Pagination
                activePage={pageIndex}
                itemsCountPerPage={10}
                totalItemsCount={totalRow}
                pageRangeDisplayed={5}
                onChange={(e)=>handlePageChange(e)}
            />
            </div>
        </div>
    )
}

export default TransactionStatements