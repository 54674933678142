import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";

import BigNumber from 'bignumber.js'
import axios from 'axios';
import {BASE_URL, TOKEN_ICON_URL} from "../../config/constant";
import AdminTransactions from './adminTransactions';
import TransactionStatements from './transactionStatement';
import BuySellTransactionStatements from './buySellOrders';
import Pagination from 'react-js-pagination';

const usdtExplorer={
    ERC20:"https://etherscan.io/tx/",
    BEP20:"https://bscscan.com/tx/",
    TRC20:"https://tronscan.org/#/transaction/"
}
class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
        withdrawalHistory:[],
        depositHistory:[],
        userData: [],
        loadFeeSubs: false,
        FESSstakeamt: 0,
        historyLoading: true,
        deposithistoryLoading: true,
        cardData:{},
        depPageIndex:1,
        withdrowPageIndex:1,
        totalRowDeposit:0,
        totalRowWithdrawal:0,
        slicedWithdrawalHistory:[],
        slicedDepositHistory:[],
    };
  }

  async componentDidMount() {
    if(!localStorage.getItem('userDetails')) {
      this.props.history.push('/login')
    } else {
    
        this.setState({ userId: localStorage.getItem('userDetails') })
    

    let userId = localStorage.getItem('userDetails')
    this.setState({historyLoading : true})
    this.setState({deposithistoryLoading : true})
    let data = {
        "userId": userId,
    }
    axios.post(BASE_URL + `withdrawal/get/user`, data)
        .then(res => {
            // console.log(res.data.data);
            if (res.data.message == "success") {
                this.setState({ withdrawalHistory: res.data.data })
                const data = res.data.data.slice(10 * (this.state.withdrowPageIndex-1), 10*this.state.withdrowPageIndex)
                 this.setState({slicedWithdrawalHistory: data})
            }
            if (res.data.message == "walletDoesNotExist") {
                // console.log("Wallet not found");
            }
            this.setState({historyLoading : false})
        })
    
    axios.post(BASE_URL + `depositehistory/get/user`, data)
        .then(res => {
            // console.log(res.data.data);
            if (res.data.message == "success") {
                this.setState({ depositHistory: res.data.data })
                const data = res.data.data.slice(10 * (this.state.depPageIndex-1), (10*this.state.depPageIndex))
                 this.setState({slicedDepositHistory: data})
            }
            if (res.data.message == "walletDoesNotExist") {
                // console.log("Wallet not found");
            }
            this.setState({deposithistoryLoading : false})
        })
    }



    // await axios.get(BASE_URL + `ercToken/get/all`)
    // .then(res => {
    //     // console.log(res);
    //     // console.log(res.data.data);
    //     if (res.data.message == "success") {

    //         // res.data.data.map(row => {
    //         //     if(row.tokenSymbol == "FESS") {
    //         //         this.setState({FESSstakeamt: row.minstake})
    //         //     }
    //         // })
            
    //     }
    // })
    await axios.post(BASE_URL + `userInformation/get`,{
        "id": this.state.userId
    })
    .then(res => {
        // console.log(res);
        if (res.data.message == "success") {
            this.setState({ 
                userData: res.data.data
            })

        }
    })
    await axios.post(BASE_URL + `userBalance/get`, {
        "userId": this.state.userId
    })
    .then(res => {
        // console.log("user id",this.state.userId)
        // console.log(res);
        if (res.data.message == "success") {
            // if(Number(res.data.data['FESS']) >= Number(this.state.FESSstakeamt)) {
            //     this.setState({loadFeeSubs:true})
            // }
            // if(Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
            //     this.setState({loadFeeSubs:true})
            // }
            
        }
    })
    
    // await axios.post(BASE_URL + `deposite/get/id`,{
    //     tokenSymbol: "TRX",
    //     userId: this.state.userId,
    //     userIDNo: 0
    // })
    // .then(async res => {
    //     // console.log(res);
    //     if (res.data.message == "success") {
            
    //         // console.log("trx",res.data.data.walletAddress)

    //         await axios.post('https://coincdn.bitallx.com:2053/trxadd',{
    //             walletAddress: res.data.data.walletAddress
    //         })
    //         .then()


    //     }
    // })

    // await axios.post('https://coincdn.bitallx.com:2083/xlmadd',{})
    //     .then()


    this.getCardData()

    }
    getCardData = async()=>{
        await axios.post(BASE_URL + `Staking/getDashboardBalance`, {
            "userId": localStorage.getItem('userDetails')
        })
        .then(async (res) => {
            if (res.data.statusCode == 200) {
                this.setState({cardData:res.data.data})
                console.log('res.data.data',res.data.data)
            }
        })
    }
    handleDepositPageChange=(index)=>{
        const data = this.state.depositHistory.slice(10 * (index-1), 10*index)
        this.setState({depPageIndex:index,slicedDepositHistory: data})
    }
    handleWithdrawalPageChange=(index)=>{
        const data = this.state.withdrawalHistory.slice(10 * (index-1), 10*index)
        this.setState({depPageIndex:index,slicedWithdrawalHistory: data})
    }
  render() {
    document.title ="Transactions"; 
    document.body.classList.add('faded_bg'); 

    return (
        <div> 
            <Header loadFees={this.state.loadFeeSubs}/>

            
            <section id="middel_area">
            <img src="images/bg_shape1.png" alt="img" className="shape1" />
                <img src="images/bg_shape2.png" alt="img" className="shape2" />
                <div className="container">
                <div className="row">
                    <div className="col-sm-12 area_left">
                        <Sidebar loadFees={this.state.loadFeeSubs}/>
                    </div>
                    
                    <div className="col-sm-12 area_right">
                                <div className="dashboard_top_info row">
                                    <div className="col-sm-4 col-xs-6 full">
                                            <div className="white_bx clearfix">                                                
                                                <div className="asset_info">
                                                    <h6>Total Balance</h6>
                                                    <p style={{color:'green'}}><span>${this.state.cardData?.totalBalance || 0}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-xs-6 full">
                                            <div className="white_bx clearfix">                                                
                                                <div className="asset_info">
                                                    <h6>Total Transactions</h6>
                                                    <p style={{color:'green'}}><span>{this.state.cardData?.totalTransaction || 0}</span></p>
                                                </div>                                                
                                            </div>
                                        </div>
                                  </div>
                                  <br />
                                  <h4>Latest Transactions</h4>
                        <div  className="white_bx clearfix" style={{minHeight:"500px"}}>          
                            <ul className="nav nav-tabs new_tab" role="tablist">
                            <li className="active"><a href="#deposit_his" aria-controls="deposit_his" role="tab" data-toggle="tab">Deposit History</a></li>
                            <li><a href="#withdraw_his" aria-controls="withdraw_his" role="tab" data-toggle="tab">Withdrawal History</a></li>
                            {/* <li><a href="#adminTnx" aria-controls="adminTnx" role="tab" data-toggle="tab">Other Transactions</a></li> */}
                            <li><a href="#drcr" aria-controls="drcr" role="tab" data-toggle="tab">Staking Statement</a></li>
                            <li><a href="#buySell" aria-controls="buySell" role="tab" data-toggle="tab">Buy/Sell</a></li>

                            </ul>
                            <hr></hr>
                            <div className="tab-content">
                            <div role="tabpanel" className="tab-pane active" id="deposit_his">
                                {/* <div className="side_txt">
                                <h6>Deposit History</h6>
                                </div> */}
                            <div className="table-responsive">
                            <table className="table table-hover">
                            <thead>
                            <tr>
                            <th>Coin</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Wallet Address / Details</th>
                            <th>Date & Time</th>
                            <th>Status</th>
                            </tr>
                            </thead>
                            
                                        {this.state.deposithistoryLoading == true ?
                                            <tbody>
                                                <tr>
                                                    <td colSpan="6" height="350px">
                                                        <div className="loader">Loading...</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        :
                                            <>
                                            {!!this.state.slicedDepositHistory ?
                                                
                                                <tbody>
                                                    {this.state.slicedDepositHistory.map((d,xid) => (
                                                        <tr key={xid}>
                                                            <td><img src={TOKEN_ICON_URL+d.coinName+".png" } width="15px" height="15px" /> {d.coinName}</td>
                                                            <td>{new BigNumber(d.amount).toFormat(8,1)}</td>
                                                            <td style={{textTransform: 'capitalize'}}>{(d.type === "Admin Deposit" || d.type === "Internal Deposit") ? "Deposit" :d.type}</td>
                                                            {d.walletAddress ? <td>{d.walletAddress}<div>
                                                                {d.extratag != "" && d.coinName == "XRP" ? 
                                                                "Destination Tag : "+d.extratag : <></>
                                                                }
                                                                {d.extratag != "" && d.coinName == "XLM" ? 
                                                                "Memo : "+d.extratag : <></>
                                                                }

                                                                {d.coinName == "INR" &&
                                                                <>
                                                                <b>Bank Name</b> : {d.bankname}<br></br>
                                                                <b>Account Number</b> : {d.acno}<br></br>
                                                                <b>IFSC</b> : {d.ifsc}<br></br>
                                                                <b>Reference ID</b> : {d.refid}<br></br> 
                                                                <b>Remark</b> : {d.remark}<br></br> 
                                                                </>
                                                                }

                                                                </div></td>: <td>{d._id}</td>}
                                                            <td>{new Date(d.timestamp).toLocaleDateString()} {new Date(d.timestamp).toLocaleTimeString()}</td>
                                                            <td>
                                                                {d.status == "Pending" && <span className="label label-default">Pending</span>}
                                                                {d.status == "Processing" && <span className="label label-primary">Processing</span>}
                                                                {d.status == "Rejected" && <span className="label label-danger">Rejected</span>}
                                                                
                                                                {d.status == "Completed" && <><span className="label label-success">Completed</span> 
                                                                {d.coinName !== "INR" &&
                                                                <>
                                                                <br></br>
                                                                <a href={
                                                                    d.coinName == "BTC" ?
                                                                    "https://www.blockchain.com/btc/tx/"+d.txid
                                                                    :
                                                                    d.coinName == "XRP" ?
                                                                    "https://bithomp.com/explorer/"+d.txid
                                                                    :
                                                                    d.coinName == "LTC" ?
                                                                    "https://live.blockcypher.com/ltc/tx/"+d.txid
                                                                    :
                                                                    d.coinName == "DASH" ?
                                                                    "https://live.blockcypher.com/dash/tx/"+d.txid
                                                                    :
                                                                    d.coinName == "BCH" ?
                                                                    "https://explorer.bitcoin.com/bch/tx/"+d.txid
                                                                    :
                                                                    d.coinName == "TRX" ?
                                                                    "https://tronscan.org/#/transaction/"+d.txid
                                                                    :
                                                                    d.coinName == "XLM" ?
                                                                    "https://steexp.com/tx/"+d.txid
                                                                    :
                                                                    d.coinName == "ETC" ?
                                                                    "https://blockscout.com/etc/mainnet/tx/"+d.txid
                                                                    :
                                                                    d.coinName == "USDT" ? usdtExplorer[d.network]+d.txid
                                                                    :
                                                                    "https://etherscan.io/tx/"+d.txid

                                                                    } target="_blank" className="label label-default" style={{color:"#fff"}}>View Tx</a>
                                                                    </>
                                                                    }
                                                                </>}
                                                            </td>
                                                        </tr>
                                                    ))}

                                                  {/* <tr className="no_data">
                                                        <td colspan="5" align="center"><i className="fa fa-file-o"></i> No record found</td>
                                                    </tr> */}

                                                </tbody>
                                                :
                                                <tbody>
                                                    <tr className="no_data">
                                                        <td colspan="5" align="center"><i className="fa fa-file-o"></i> No record found</td>
                                                    </tr>
                                                </tbody>
                                            }
                                            </>
                                        }

                            </table>
                            </div>
                            <div style={{ marginTop: '20px', float: 'right' }}>
                            <Pagination
                                activePage={this.state.depPageIndex}
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.depositHistory.length}
                                pageRangeDisplayed={5}
                                onChange={(e)=>this.handleDepositPageChange(e)}
                            />
                            </div>
                            </div>                                
                                                                    
                            <div role="tabpanel" className="tab-pane" id="withdraw_his">
                            {/* <div className="side_txt">
                            <h6>Withdrawal History</h6>
                            </div> */}
                            <div className="table-responsive">
                            <table className="table table-hover">
                            <thead>
                            <tr>
                             <th>TX ID</th>   
                            <th>Coin</th>
                            <th>Amount</th>
                            <th>Fees</th>
                            <th>Type</th>
                            <th>Wallet Address / Details</th>
                            <th>Date & Time</th>
                            <th>Status</th>

                            </tr>
                            </thead>
                            
                                        {this.state.historyLoading == true ?
                                            <tbody>
                                                <tr>
                                                    <td colSpan="6" height="350px">
                                                        <div className="loader">Loading...</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        :
                                            <>
                                            {!!this.state.slicedWithdrawalHistory  ?
                                                <tbody>
                                                    {this.state.slicedWithdrawalHistory.map((d,xid) => (
                                                        <tr key={xid}>
                                                            <td>{d._id}</td>
                                                            <td><img src={TOKEN_ICON_URL+d.coinName+".png" } width="15px" height="15px"/> {d.coinName}</td>
                                                            <td>{new BigNumber(d.amount).toFormat(6,1)}</td>
                                                            <td>{new BigNumber(d.fees).toFormat(6,1)}</td>
                                                            <td style={{textTransform: 'capitalize'}}>{(d.type === "Admin Withdrawal" || d.type === "Internal Withdrawal") ? "Withdrawal" :d.type}</td>
                                                            {d.walletAddress ? <td>{d.walletAddress}<div>
                                                                {d.extratag != "" && d.coinName == "XRP" ? 
                                                                "Destination Tag : "+d.extratag : <></>
                                                                }
                                                                {d.extratag != "" && d.coinName == "XLM" ? 
                                                                "Memo : "+d.extratag : <></>
                                                                }

                                                                {d.coinName == "INR" &&
                                                                <>
                                                                <b>Bank Name</b> : {d.bankName}<br></br>
                                                                <b>Account Type</b> : {d.accountType}<br></br>
                                                                <b>Account Number</b> : {d.accountNumber}<br></br>
                                                                <b>IFSC</b> : {d.ifsc}<br></br>
                                                                <b>Remark</b> : {d.remark}<br></br> 
                                                                </>
                                                                }
                                                                </div>
                                                            </td>: <td>{d._id}</td>}
                                                            <td>{d.date}</td>
                                                            <td>
                                                                {d.status == "Pending" && <span className="label label-default">Pending</span>}
                                                                {d.status == "Processing" && <span className="label label-primary">Processing</span>}
                                                                {d.status == "Rejected" && <span className="label label-danger">Rejected</span>}
                                                                {d.status == "Completed" && <><span className="label label-success">Completed</span>
                                                                {d.coinName !== "INR" &&
                                                                <>
                                                                <br></br>
                                                                 <a href={
                                                                    d.coinName == "BTC" ?
                                                                    "https://www.blockchain.com/btc/tx/"+d.txhash
                                                                    :
                                                                    d.coinName == "XRP" ?
                                                                    "https://bithomp.com/explorer/"+d.txhash
                                                                    :
                                                                    d.coinName == "LTC" ?
                                                                    "https://live.blockcypher.com/ltc/tx/"+d.txhash
                                                                    :
                                                                    d.coinName == "DASH" ?
                                                                    "https://live.blockcypher.com/dash/tx/"+d.txhash
                                                                    :
                                                                    d.coinName == "BCH" ?
                                                                    "https://explorer.bitcoin.com/bch/tx/"+d.txhash
                                                                    :
                                                                    d.coinName == "TRX" ?
                                                                    "https://tronscan.org/#/transaction/"+d.txhash
                                                                    :
                                                                    d.coinName == "XLM" ?
                                                                    "https://steexp.com/tx/"+d.txhash
                                                                    :
                                                                    d.coinName == "ETC" ?
                                                                    "https://blockscout.com/etc/mainnet/tx/"+d.txhash
                                                                    :
                                                                    "https://etherscan.io/tx/"+d.txhash

                                                                    } target="_blank" className="label label-default" style={{color:"#fff"}}>View Tx</a></>}
                                                                    </>}
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                                :
                                                <tbody>
                                                    <tr>
                                                        <td colspan="5" align="center" className="no_data"><i className="fa fa-file-o"></i> No record found</td>
                                                    </tr>
                                                </tbody>
                                            }
                                            </>
                                        }

                            </table>

                            </div>
                            <div style={{ marginTop: '20px', float: 'right' }}>
                            <Pagination
                                activePage={this.state.withdrowPageIndex}
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.withdrawalHistory.length}
                                pageRangeDisplayed={5}
                                onChange={(e)=>this.handleWithdrawalPageChange(e)}
                            />
                            </div>
                            </div>  
                            {/* <div role="tabpanel" className="tab-pane" id="adminTnx">
                               <AdminTransactions />
                                </div>   */}
                                <div role="tabpanel" className="tab-pane" id="drcr">
                               <TransactionStatements />
                                </div>    
                                <div role="tabpanel" className="tab-pane" id="buySell">
                               <BuySellTransactionStatements />
                                </div>                      
                            </div>                                    
                            </div>
                                
                    </div>
                    

                </div>
                </div>
            </section>

            <Footer/>

        </div>
    );
  }
}

export default Transactions;