import React, { useEffect, useState } from "react";

import axios from "axios";
import { BASE_URL, TOKEN_ICON_URL } from "../../../config/constant";
import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
// import Loader from "../../../React-Loader/loader";
import BigNumber from "bignumber.js";
import TransferModal from "./transfer/transfer.modal";
import { useHistory,Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../../Components/Dashboard/sidebar";

const FutureWallet = () => {
  const routeHistory = useHistory();
  const [tokenList, setTokenList] = useState([]);
  const [balances, setBalances] = useState({});
  const [loading, setLoading] = useState(false);
  const [wazirxTickers, setWazirxTickers] = useState([]);
  const [totalUsdt, setTotalUsdt] = useState(0);
  const [btxtUsdtrate, setBTXTUsdtrate] = useState(0);
  const [showTransferModal, setShowTransferModal] = useState(false);
const [clickedCoin, setClickedCoin] = useState("USDT")
const [btcusdtPrice, setBtcusdtPrice]=useState(1)
  const closeTransferModal = () => {
    setShowTransferModal(false);
    const userId = localStorage.getItem("userDetails");
    getBalances(userId);
  };
  const openTransferModal = () => {
    setShowTransferModal(true);
  };
  useEffect(() => {
    const userId = localStorage.getItem("userDetails");
    if (userId) {
      setLoading(true);
      axios
        .get(BASE_URL + `ercToken/get/all`)
        .then((res) => {
          setTokenList(res.data?.data || []);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
      getBtxtPrice();
      getWazirXTickers();
      getBalances(userId);
    } else {
      routeHistory.push("/login");
      localStorage.clear();
    }
    document.title = "Future Trade";
        document.body.classList.add('faded_bg');
  }, []);
  const getWazirXTickers = () => {
    axios.get("https://api.wazirx.com/sapi/v1/tickers/24hr").then((tickers) => {
      if (tickers.status === 200) {
        setWazirxTickers(tickers.data);
      }
    });
  };
  const getBtxtPrice = () => {
    axios.get(`${BASE_URL}coinpair/get/token?token=BTXT`).then((res) => {
      setBTXTUsdtrate(res?.data?.data?.price || 0);
    });
  };

  useEffect(() => {
    if (
      wazirxTickers &&
      wazirxTickers.length &&
      balances?.FutureWalletBalances
    ) {
      let total_usdt = 0;
      Object.keys(balances.FutureWalletBalances).forEach((balKay) => {
        const ticker = wazirxTickers.find(
          (e) => e.baseAsset === balKay.toLowerCase() && e.quoteAsset === "usdt"
        );
        if (ticker) {
          total_usdt +=
            ticker.lastPrice * balances.FutureWalletBalances[balKay];
        }
        if (ticker && ticker.baseAsset === "btc") {
          setBtcusdtPrice(+ticker.lastPrice) 
        }
      });
      setTotalUsdt(total_usdt);
       
    }
  }, [wazirxTickers, balances]);

  const getBalances = (userId) => {
    setLoading(true);
    axios
      .get(BASE_URL + `get/futureAndSpotBalances?userId=${userId}`)
      .then((res) => {
        setBalances(res.data?.data || []);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const getAvailbleBalance = (token) => {
    return balances?.FutureWalletBalances
      ? Number(balances?.FutureWalletBalances[token])
      : 0;
  };

  const getEstimatedUSDT = () => {
    return (
      totalUsdt +
      (balances?.FutureWalletBalances
        ? Number(balances?.FutureWalletBalances["BTXT"] * btxtUsdtrate || 0)
        : 0)
    );
  };

  return (
    <div>
      {loading ? <h6>Loading...</h6> : <></>}
      <Header />
       <TransferModal
        show={showTransferModal}
        handleClose={closeTransferModal}
        coin={clickedCoin}
        sourceEx={"Future Wallet"}
        targetEx={"Spot Wallet"}
      />
      <section id="middel_area">
        <img src="/images/bg_shape1.png" alt="img" className="shape1" />
        <img src="/images/bg_shape2.png" alt="img" className="shape2" />
        <div className="container">
          <div className="row">
            <div class="col-sm-12 area_left">
              <Sidebar loadFees={true} />
            </div>

            <div className="col-sm-12 area_right">
              <div className="content">
                <div className="coin_list">
                  <br />
                  <h4>List of coins</h4>
                  <hr />
                  <div className="d-flex flex-between">
                    <div
                      className="est-val"
                      style={{ display: "inline-block", marginLeft: "20px" }}
                    >
                      <h5
                        style={{
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: "18px",
                        }}
                      >
                        Estimated Value
                      </h5>
                      <p>
                       
                        {new BigNumber(
                          Number(getEstimatedUSDT() / btcusdtPrice)
                        ).toFormat(4, 1)}{" "}
                        <small>BTC</small>
                        ≈{" "}
                        <span>
                          {new BigNumber(getEstimatedUSDT()).toFormat(4, 1)}{" "}
                          <small>USDT</small>
                        </span>{" "}
                      </p>
                    </div>
                    {/* <div>
                      <div
                        className="font-16 txt-white cursor-pointer btn-solid btn-md bg-yellow-5 rounded-sm border-none"
                        onClick={() => openTransferModal()}
                      >
                        Transfer Token{" "}
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    {tokenList.sort(function(x,y){ return x.tokenSymbol == "USDT" ? -1 : y.tokenSymbol == "USDT" ? 1 : 0; }).map(
                      (d, xid) =>
                        d.active === true && (
                          <div
                            key={xid}
                            className="col-md-3 col-sm-6 col-xs-6 full "
                            style={{ padding: 1 }}
                          >
                            <div className="outer_bx">
                              <div className="coin_bx">
                                <img
                                  src={TOKEN_ICON_URL + d.tokenSymbol + ".png"}
                                  alt=""
                                />
                                <div className="coin_info">
                                  <h6 style={{ marginBottom: 0 }}>
                                    {d.tokenSymbol} <small> ({d.tokenName})</small>
                                  </h6>
                                  <h6>
                                    
                                  </h6>
                                  <p>
                                    {/* <strong>Avail.</strong>{" "} */}
                                    <strong>
                                      {Number(
                                        getAvailbleBalance(d.tokenSymbol)
                                      ).toFixed(4) >= 0
                                        ? Number(
                                            getAvailbleBalance(d.tokenSymbol)
                                          )
                                            .toFixed(10)
                                            .slice(0, -6)
                                        : "0.0000"}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                              <hr />
                              <div className="action_btn text-center">
                        <a href="javascript:void" className="outline-btn txt-white m-1"  onClick={() => {setClickedCoin(d.tokenSymbol);openTransferModal()}}>
                          Transfer
                        </a>
                        {d.tActive &&
                            <Link to={"/futures/trade/" + d.tokenSymbol + "/USDT"} className="outline-btn" style={{ padding: '0px 5px' }}>Trade</Link>
                        }
                        {/* <button className="btn bg-red txt-white m-1" onClick={()=>onBuySell('Sell',d.tokenSymbol)}>
                          Sell
                        </button> */}
                      </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default FutureWallet;
