import React, { Component } from "react";
import { Button } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import { BASE_URL, TEST_SITE_KEY } from "../../config/constant";

import { Link, Redirect } from "react-router-dom";

import speakeasy from "@levminer/speakeasy";

import { detect } from "detect-browser";
import Header from "../../Components/Header/header";

const browser = detect();
const publicIp = require("public-ip");
// const TEST_SITE_KEY = "6LeDCJgaAAAAAH_Rgyi0bg_rzJiwzf6qJzhnr3Tz";
const DELAY = 1500;

// ReCAPTCHA
// site key : 6LeDCJgaAAAAAH_Rgyi0bg_rzJiwzf6qJzhnr3Tz
// secret : 6LeDCJgaAAAAACvUL9EFlBmwTowIs2FfBNLGaKc_

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validPhonenumberRegex = RegExp(
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      password: null,
      captcha: null,
      reserror: null,
      ressuccess: null,
      userId: null,
      showTwoFA: false,
      buttonDisable: false,
      userDetails: "",
      userName: "",
      twoFASecret: "",
      twofa: "",
      loader: 0,
      errors: {
        email: "",
        password: "",
        captcha: "",
        twofa: "",
      },
    };
  }
  componentDidMount() {
    if (localStorage.getItem("userDetails")) {
      let userData = localStorage.getItem("userDetails");
      this.setState({ userId: userData });
      this.props.history.push("/dashboard");
    }

    // console.log("Response2",this.state.userId)
  }
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        break;
      case "password":
        errors.password =
          value.length < 8 ? "Password must be 8 characters long!" : "";
        break;
      case "twofa":
        errors.twofa = value.length < 6 ? "Please enter valid 2FA code" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleChange2 = (value) => {
    let errors = this.state.errors;
    console.log("Captcha value:", value);
    // this.state.captcha = value;
    this.setState({ captcha: value });
    // console.log(this.state.captcha);
    if (this.state.captcha != null) {
      errors.captcha = "";
      this.setState({ errors });
    }
  };

  if(browser) {
    // console.log(browser.name);
    // console.log(browser.version);
    // console.log(browser.os);
  }

  addLoginHistory = async (userid) => {
    var ipAddress = await publicIp.v4();
    console.log(ipAddress);
    console.log(browser.os);
    // await axios.get('https://ipapi.co/json/')
    // .then(res => {
    //   console.log("Response: ", res)
    // })

    axios
      .post(BASE_URL + `loginhistory`, {
        browser: browser.os + " (" + browser.name + " " + browser.version + ")",
        userId: userid,
        ipAddress: ipAddress,
      })
      .then((res) => {});
  };

  resendMail = () => {
    const data = {
      emailId: this.state.email,
      formUrl: "https://bitallx.com/",
    };

    this.setState({ reserror: null });

    axios.post(BASE_URL + `registration/resendmail`, data).then((res) => {
      if (res.data.message == "success") {
        this.setState({ ressuccess: "Verification mail resent successfully" });
      } else {
        this.setState({ reserror: "Something went wrong" });
      }
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({
      loader: 1,
    });
    let errors = this.state.errors;

    if (this.state.email == null) {
      errors.email = "Email is not valid!";
    }
    if (this.state.password == null) {
      errors.password = "Password must be 8 characters long!";
    }
    if (!this.state.captcha) {
      errors.captcha = "Please verify Recaptcha";    //uncomment this
      // errors.captcha = "";    //comment this
    } else {
      errors.captcha = "";
    }
    this.setState({ errors });

    if (validateForm(this.state.errors)) {
      this.setState({ buttonDisable: true });
      // console.info('Valid Form')
      //   window.location.assign(`/dashboard`);

      const data = {
        emailId: this.state.email,
        password: this.state.password,
      };
      this.setState({ reserror: null, ressuccess: null });

      axios.post(BASE_URL + `login`, data).then((res) => {
        // console.log(res);
        // console.log(res.data.userData._id);

        if (res.data.message == "emailidDoesNotExists") {
          this.setState({ reserror: "User Not Found!" });
        }
        if (res.data.message == "pleaseVerifyYourEmail") {
          this.setState({
            reserror: (
              <div>
                Please verify your email address!<br></br>Didn't received mail?{" "}
                <a href="#" onClick={this.resendMail}>
                  <b>Resend</b>
                </a>
              </div>
            ),
          });
        }
        if (res.data.message == "loginSuccessful") {

          // if (res.data.userData.two_factor == 0) {
            axios.post(BASE_URL + `userRefferal/Script`,{userId:res.data.userData._id}).then(e=>{})
            this.addLoginHistory(res.data.userData._id);
            localStorage.setItem("userDetails", res.data.userData._id);
            localStorage.setItem("userName", res.data.userData.userName);
            // localStorage.setItem('userObject', res.data.userData) //complete user data
            this.props.history.push("/dashboard");
            this.setState({ reserror: "Please wait..." });
          // } else {
          //   this.setState({
          //     showTwoFA: true,
          //     twoFASecret: res.data.userData.secret,
          //     userDetails: res.data.userData._id,
          //     userName: res.data.userData.userName,
          //   });
          // }
        }
        if (res.data.message == "emailidAndPasswordDoesNotMatch") {
          this.setState({ reserror: "Email Id and password does not match!" });
        }
        this.setState({ buttonDisable: false, loader: 0 });
      });
    } else {
      // console.error('Invalid Form')
    }
  };

  verifyTwoFA = async (event) => {
    event.preventDefault();
    let errors = this.state.errors;

    if (this.state.twofa == null) {
      errors.twofa = "Please enter valid 2FA code";
    }
    if (this.state.twofa.length == 0) {
      errors.twofa = "Please enter valid 2FA code";
    }

    this.setState({ errors });

    if (validateForm(this.state.errors)) {
      var verified = speakeasy.totp.verify({
        secret: this.state.twoFASecret,
        encoding: "base32",
        token: this.state.twofa,
      });

      if (verified == true) {
        this.addLoginHistory(this.state.userDetails);
        localStorage.setItem("userDetails", this.state.userDetails);
        localStorage.setItem("userName", this.state.userName);
        // localStorage.setItem('userObject', res.data.userData) //complete user data
        this.props.history.push("/dashboard");
        this.setState({ reserror: "Please wait..." });
      } else {
        this.setState({ twofa: "" });
        errors.twofa = "Please enter valid 2FA code";
        this.setState({ errors });
      }
    }
    // console.log("verify",verified)
  };

  render() {
    const { errors } = this.state;
    document.title = "Login";
    document.body.classList.add("faded_bg");

    return (
      <>
      <img src="images/bg_shape1.png" alt="img" className="shape1" />
<img src="images/bg_shape2.png" alt="img" className="shape2" />
        <Header blueBg={true} />
        <section id="form_page">
        
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-sm-10 col-lg-offset-3 col-sm-offset-1">
                <div class="white_bx clearfix">
                  <h5>Sign In Account</h5>
                  {/* {this.state.showTwoFA == false ? ( */}
                    <form onSubmit={this.handleSubmit} noValidate>
                      {this.state.reserror != null && (
                        <div className="alert alert-danger text-center">
                          {this.state.reserror}
                        </div>
                      )}

                      {this.state.ressuccess != null && (
                        <div className="alert alert-success text-center">
                          {this.state.ressuccess}
                        </div>
                      )}
                      <div class="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control" name="email" placeholder="Email" onChange={this.handleChange} noValidate/>
                        {errors.email.length > 0 && 
                        <span className='formerror'>{errors.email}</span>}
                      </div>
                      <div class="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" name="password" placeholder="Password" onChange={this.handleChange} noValidate/>
                        {errors.password.length > 0 && 
                        <span className='formerror'>{errors.password}</span>}
                      </div>
                      <ReCAPTCHA
                            style={{ display: "inline-block" }}
                            theme="light"
                            ref={this._reCaptchaRef}
                            sitekey={TEST_SITE_KEY}
                            onChange={this.handleChange2}
                            asyncScriptOnLoad={this.asyncScriptOnLoad}
                        />
                        {errors.captcha.length > 0 && 
                            <div className='formerror' style={{textAlign:"center"}}>{errors.captcha}</div>}

                      <div class="form-group btn_part">
                        {this.state.buttonDisable == true ? 
                        <button type="" className="blue_btn" disabled style={{backgroundColor: '#5a9ce8'}}>Login </button>
                        :
                          <button type="submit" class="blue_btn">Login</button>
                          }
                      </div>
                      <div class="form-group form_link">
                        <p>
                          Don't have account? <a href="/register">Sign Up</a>
                        </p>
                        <a href="/forgotpassword">Forgot Password</a>
                      </div>
                    </form>
                  {/* ) : (
                    <div className="text-center">
                      <hr></hr>
                      <h4 style={{ fontWeight: "300" }}>
                        Two-Factor Authentication (2FA)
                      </h4>

                      <div className="">
                        <input
                          type="number"
                          style={{
                            margin: "20px 0px 0px 0px",
                            textAlign: "center",
                          }}
                          className="form-control"
                          name="twofa"
                          onChange={this.handleChange}
                          value={this.state.twofa}
                          placeholder="Enter Authentication Code"
                          noValidate
                        />
                        {errors.twofa.length > 0 && (
                          <span className="formerror">{errors.twofa}</span>
                        )}
                        <div className="clearfix"></div>
                        <button
                          className="btn btn-success"
                          style={{ margin: "20px 0px", width: "100%" }}
                          onClick={this.verifyTwoFA}
                        >
                          Verify Code
                        </button>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  )} */}
                  {/* <div class="center_part text-center">
                    <span>OR</span>
                  </div>

                  <div class="form_social text-center">
                    <a href="#">
                      <div class="binance_img">
                        {" "}
                        <img src="images/binance_icon.png" alt="img" />
                      </div>{" "}
                      LOGIN VIA BINANCE
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Login;
