import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { BASE_URL , TOKEN_ICON_URL } from "../../config/constant";
import speakeasy from "@levminer/speakeasy";
import QRCode from "qrcode.react";
import moment from "moment";
import Pagination from "react-js-pagination";

var id;
const itemsCountPerPage = 10;

class LockHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lockHistory: [],
            slicedLockHistory: [],
            activePage: 0,
            totalCount:0,
            ercTokens : [],
            liveFunds : [],
            lockedEstmatedValue:0,
            liveEstmatedValue:0,
            loading: false
        };
    }
    async componentDidMount() {
       await this.getErcTokens()
        await this.getLiveFunds()
        if (!localStorage.getItem('userDetails')) {
            this.props.history.push('/login')
        }else{
            id = localStorage.getItem('userDetails');
            this.getLockedBalHistory()

        }
    }
    getLockedBalHistory = async () =>{
      try {
        this.setState({loading: true})
        let url = 'get/lockedBalHistory';
       const res = await axios.post(BASE_URL+url, {
            "userId": id
        })
        this.setState({loading: false})
        if (res.data && res.data.data) {
           this.setState({lockHistory: res.data.data,totalCount:res.data.data.length});
          const slicedItems = res.data.data.slice(0,itemsCountPerPage);
          this.setState({loading: true})
        //  const coinpairdate =  await axios.get(`${BASE_URL}coinpair/getall`)
          await axios({
            method: 'get',
            url: `${BASE_URL}coinpair/getall`
          }).then((coinpairdata)=>{
            let estmatedUsdt = 0;
            slicedItems.forEach((fa)=>{
              //  const tickerPrice = (tickers.data || []).find(e => e.symbol === fa.coinName+'USDT')
                if (fa.coinName === 'USDT') {
                    estmatedUsdt+= fa.amountLocked;
                    fa['usdValue'] = fa.amountLocked;
                }else  {
                  const cd= (coinpairdata.data?.data || []).find(e => e.primaryCoin.toUpperCase() === fa.coinName && e.secondaryCoin.toUpperCase() === "USDT");
                    estmatedUsdt+= (fa.amountLocked * (cd?.price || 1));
                    fa['usdValue'] = fa.amountLocked * (cd?.price || 1);
                }
                // else if(tickerPrice){
                //     estmatedUsdt+= (fa.amountLocked * tickerPrice.price);
                //     fa['usdValue'] = fa.amountLocked * tickerPrice.price;
                // }
                this.setState({loading: false})
            })
            this.setState({ lockedEstmatedValue: estmatedUsdt})
            this.setState({slicedLockHistory: slicedItems})
          }).catch((e)=>{
            this.setState({loading: false})
          })
        }
      } catch (error) {
        this.setState({loading: false})
      }
      }

      getErcTokens = async ()=>{
        await axios.get(BASE_URL + `ercToken/get/all`)
        .then(res => {
            // console.log(res);
            if (res.data.message === "success") {
                this.setState({
                    ercTokens : res.data.data
                })
            }else{
                this.setState({
                    ercTokens : []
                })
            }

        })
      }

      getLiveFunds = async ()=>{
        try {
          this.setState({loading: true})
          await axios.post(BASE_URL + `userBalance/get`, {
              "userId": localStorage.getItem('userDetails')
          })
          .then(async (res) => {
              let finalArray = [];
              if (res.data.message === "success") {
                  let allBalanceData = res.data.data;
                  for(let i=0; i<=this.state.ercTokens.length; i++){
                      for(let j in allBalanceData){
                          
                          // console.log(i ,"  >>>>  "   )
                              if(this.state.ercTokens[i]?.tokenSymbol == j && allBalanceData[j]>0 ){
                                      let data = {
                                          "coinName" : this.state.ercTokens[i].tokenName,
                                          "symbol" : this.state.ercTokens[i].tokenSymbol,
                                          "balance" : allBalanceData[j]
                                      }
                                      finalArray.push(data);
                              }
                      }
                  }
                  // const coinpairdate =  await axios.get(`${BASE_URL}coinpair/getall`)
                  await axios({
                      method: 'get',
                      url: `${BASE_URL}coinpair/getall`
                    }).then((coinpairdata)=>{
                      
                      let estmatedUsdt = 0;
                      finalArray.forEach((fa)=>{
                        //  const tickerPrice = (tickers.data.data || []).find(e => e.symbol === fa.symbol+'USDT')
                          if (fa.symbol === 'USDT') {
                              estmatedUsdt+= fa.balance;
                              fa['usdValue'] = fa.balance;
                          }else {
                              const cd= (coinpairdata.data?.data || []).find(e => (e.primaryCoin.toUpperCase() === fa.symbol )&& (e.secondaryCoin.toUpperCase() === "USDT"));
                              estmatedUsdt+=  cd?.price  ? (fa.balance * (cd?.price )): 0;
                              fa['usdValue'] = cd?.price  ? fa.balance * (cd?.price  ): 0;
                          }
                          // else if(tickerPrice){
                          //     estmatedUsdt+= (fa.balance * tickerPrice.price);
                          //     fa['usdValue'] = fa.balance * tickerPrice.price;
                          // }
                      })
                      this.setState({loading: false})
                      this.setState({ liveEstmatedValue: estmatedUsdt})
                    }).catch((e)=>{this.setState({loading: false})})
  
                      this.setState({
                          liveFunds : finalArray
                      },()=>{
  
                        console.log(this.state.liveFunds,finalArray)
                      })
              }else{
                  this.setState({
                      liveFunds : []
                  })
                  this.setState({loading: false})
              }
          })
        } catch (error) {
          this.setState({loading: false})
        }
      }


    handlePageChange(index){
        const slicedItems = this.state.lockHistory.slice(itemsCountPerPage * (index-1) , index * itemsCountPerPage)
           this.setState({slicedLockHistory: slicedItems})
    }
    render() {
        const { errors } = this.state;
        document.title = "Lock/Live Funds";
        document.body.classList.add('faded_bg');

        return (
          <div>
            <Header loadFees={this.state.loadFeeSubs} />
            <section id="middel_area">
              <img src="images/bg_shape1.png" alt="img" className="shape1" />
              <img src="images/bg_shape2.png" alt="img" className="shape2" />
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 area_left">
                    <Sidebar loadFees={this.state.loadFeeSubs} />
                  </div>

                  <div className="col-sm-12 area_right">
                    <div className="white_bx clearfix">
                      <div className="side_txt">
                        <h6>Lock/Live Funds </h6>
                      </div>

                      <div
                        className="white_bx clearfix"
                        style={{ minHeight: "500px" }}
                      >
                        <ul className="nav nav-tabs new_tab" role="tablist">
                          <li className="active">
                            <a
                              href="#lockFunds"
                              aria-controls="lockFunds"
                              role="tab"
                              data-toggle="tab"
                            >
                              Lock Funds
                            </a>
                          </li>
                          <li>
                            <a
                              href="#liveFunds"
                              aria-controls="liveFunds"
                              role="tab"
                              data-toggle="tab"
                            >
                              Live Funds
                            </a>
                          </li>
                        </ul>
                        <hr></hr>
                        <div className="tab-content">
                          <div
                            role="tabpanel"
                            className="tab-pane active"
                            id="lockFunds"
                          >
                            <div className="table-responsive recent_login">
                            <div style={{width: 250, border: '1px solid red', borderRadius: 10, padding: 16, marginBottom: 10}}>
                                        <strong>Estimated Locked value:</strong> <br /> <span style={{color: "red"}}>{this.state.lockedEstmatedValue.toFixed(4)} <small>USDT</small></span>
                            </div>
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th>SR. No.</th>
                                    <th>TX ID</th>
                                    <th>COIN</th>
                                    <th>Quantity</th>
                                    <th>Est. USDT Value</th>
                                    <th>LOCK DATE</th>
                                    <th>UNLOCK DATE</th>
                                    <th>STATUS</th>
                                  </tr>
                                </thead>
                               {this.state.slicedLockHistory && this.state.slicedLockHistory.length ? <tbody>
                                  {this.state.slicedLockHistory.map(
                                    (row, xid) => (
                                      <tr key={xid}>
                                        <td>{xid + 1}</td>
                                        <td>{row._id}</td>
                                        <td>{row.coinName}</td>
                                        <td>{row.amountLocked}</td>
                                        <td>{row.usdValue.toFixed(4)}</td>
                                        <td>{row.date.split(" ")[0]}</td>
                                        <td>{row.lockedDate}</td>
                                        <td>
                                          <button
                                            className={`lock-status ${row.type}`}
                                          >
                                            {row.type}
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>: 
                                <tbody>
                                  <tr>
                                    <td style={{textAlign: 'center' }} colSpan={7}>
                                      Loading...
                                    </td>
                                  </tr>
                                  </tbody>}
                              </table>
                            </div>
                          </div>

                          <div
                            role="tabpanel"
                            className="tab-pane"
                            id="liveFunds"
                          >
                            <div style={{width: 250, border: '1px solid green', borderRadius: 10, padding: 16, marginBottom: 10}}>
                                        <strong>Estimated Reward value:</strong><br /> <span style={{color: "green"}}> {this.state.liveEstmatedValue.toFixed(4)} <small>USDT</small></span> 
                            </div>
                            <div className="table-responsive recent_login">
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th>SR. No.</th>
                                    <th>Coin Name</th>
                                    <th>Coin Symbol</th>
                                    <th>Live Funds / Available Balance</th>
                                    <th>Est. USDT Value</th>
                                  </tr>
                                </thead>
                               {this.state.liveFunds && this.state.liveFunds.length ? <tbody>
                                  {this.state.liveFunds.map((row, xid) => (
                                    <tr key={xid}>
                                      <td>{xid + 1}</td>
                                      <td>
                                        <img
                                          src={
                                            TOKEN_ICON_URL + row.symbol + ".png"
                                          }
                                          width="20px"
                                          height="20px"
                                          alt=""
                                          style={{marginRight:4}}
                                        />
                                        {row.coinName}
                                      </td>
                                      <td>{row.symbol}</td>
                                      <td>{Number(row.balance.toFixed(4))}</td>
                                      <td style={{color: "green"}}>{ row.usdValue ? "$" + Number(row.usdValue.toFixed(4)) : "loading..."}</td>
                                    </tr>
                                  ))}
                                </tbody>:
                                <tbody>
                                <tr>
                                  <td style={{textAlign: 'center'}} colSpan={5}>
                                    Loading...
                                  </td>
                                </tr>
                                </tbody>}
                              </table>
                            </div>
                            {/* <div className="row">
                              {this.state.liveFunds.map((row, xid) => (
                                <>
                                  <div
                                    className="col-lg-2 col-md-3 col-sm-6"
                                    style={{ padding: 10 }}
                                  >
                                    <div style={{borderRadius:10,padding:16, background:'aliceblue', color:'#2fa6ea', display:'flex', justifyContent:'space-between'}}>
                                     <div className='full' style={{display: 'flex', justifyContent:'center', alignItems:'center'}}> <img
                                        src={
                                          TOKEN_ICON_URL + row.symbol + ".png"
                                        }
                                        width="30px"
                                        height="30px"
                                        alt=""
                                      /></div>
                                      <div style={{width: 2, borderLeft: '1px solid'}}></div>
                                      <div  >
                                        <strong>{row.symbol}</strong>
                                        <p>

                                          <small>{Number(row.balance).toFixed(6)}</small>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                              {
                               !this.state.liveFunds || this.state.liveFunds.length === 0 ?
                               <>
                               <div> No Funds are available</div>
                               </>: null
                              }
                            </div> */}
                          </div>
                        </div>
                      </div>

                      <div style={{ marginTop: "20px", float: "right" }}>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={itemsCountPerPage}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Footer />
          </div>
        );
    }
}

export default LockHistory;