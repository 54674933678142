import React, { Component } from 'react';
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
//import stakingForm from "./stakingForm";
import './index.css'
import Sidebar from '../../Components/Dashboard/sidebar';
import Notifications, { notify } from 'react-notify-toast';
import axios from 'axios';
import { BASE_URL, TOKEN_ICON_URL } from '../../config/constant';
import BigNumber from 'bignumber.js';
class userStaking extends Component {
    constructor(props) {
        super(props);
        this.state={
            selectedPackege:null,
            selectedCoin:null,
            selectedCoinDropdown: null,
            packageList: [],
            coinList:[],
            coinUsdPrice:'',
            amount:'',
            userBalance: null,
            availableBalance:0,
            tnCAccepted:false,
            noPackageFound:false,
        }
        document.title = 'User Staking'
    }
    async componentDidMount() {
        if(!localStorage.getItem('userDetails')) {
          this.props.history.push('/login')
        }
        this.getPackages();
        this.getTokens();
    }
    getPackages = () =>{
        axios.get(BASE_URL+'stakingMaster/get/all').then(res=>{
            if (res.data && res.data.data && res.data.data.length ) {
                this.setState({packageList:res.data.data})
            }
        }).catch(e=>{
            
        })
    }
    getTokens = () =>{
        axios.get(BASE_URL+'ercToken/get/all').then(res=>{
            if (res.data && res.data.data && res.data.data.length ) {
                this.setState({coinList:res.data.data})
                this.setState({selectedCoinDropdown : <><span>Select Token</span></>})
            //     this.setState({selectedCoinDropdown :
            //         <>
            //         {/* {row.tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>{row.tokenName}</span> */}
            //         <img src={TOKEN_ICON_URL+row.tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px",marginTop:"-4px"}}/>
            //         {row.tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>({row.tokenName})</span>
            //         </>})
            }
        }).catch(e=>{
            
        })
    }
    performStaking = ()=>{
        if (this.state.amount <=0 || !this.state.userBalance || (+this.state.amount) > (+this.state.availableBalance)) {
            return;
        }
        // if (this.state.amount > this.state.userBalance[this.state.selectedCoin?.tokenSymbol]) {
        //     notify.show('Amount can not be exceed Available ' +this.state.selectedCoin?.tokenSymbol +' Balance ','warning');
        //     return
        // }
        const data = {
            userId: localStorage.getItem('userDetails'),
            stakingPackageId: this.state.selectedPackege._id,
            coinName: this.state.selectedCoin.tokenSymbol,
            amount: this.state.amount,
            usdPrice: this.state.coinUsdPrice,
            stakingPeriod: this.state.selectedPackege.stakingPeriod
        }
            axios.post(BASE_URL+'userStaking/performStaking',data).then(res=>{
                   if (res.data.statusCode == 200) {
                    notify.show(res.data.message,'success');
                    this.resetFeilds()
                   }else{

                       notify.show(res.data.message,'error');
                   }
            
            }).catch(e=>{
                notify.show('Something went wrong','error')
            })
    }
    onSelectPackege = (data) => {
        this.setState({ selectedPackege: data })
    }
    onSelectToken = async(data) => {
        this.setState({
            selectedCoinDropdown: <><img src={TOKEN_ICON_URL+data.tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px",marginTop:"-4px"}}/> {data.tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>({data.tokenName})</span></>
            
        });
        this.setState({ selectedCoin: data });
        let CoinName = data.tokenSymbol + 'USDT'
        if (data.tokenSymbol.toLowerCase() != 'usdt') {
            await axios.get(`https://api.wazirx.com/sapi/v1/ticker/24hr?symbol=` + CoinName.toLowerCase()).then((res) => {
                if (res && res.data) {
                    this.setState({ coinUsdPrice: res.data.lastPrice })
                }
            })
        } else {
            this.setState({ coinUsdPrice: 1 })
        }
        this.getUserBalance()
    }
    getUserBalance = async () => {
      let  userId = localStorage.getItem('userDetails')
        await axios.post(BASE_URL + `userBalance/get`, {
            "userId": userId
        })
            .then(res => {
                // console.log(res);
                // console.log("userbalance",res.data.data);
                // console.log("userbalance",res.data.data["BTC"]);
                if (res.data.message === "success") {
                    this.setState({ 
                        userBalance: res.data.data,
                        availableBalance: new BigNumber(res.data.data[this.state.selectedCoin?.tokenSymbol]).toFormat(6,1)
                    })
                    // if(Number(this.state.userBalance['FESS']) >= Number(this.state.FESSstakeamt)) {
                    //     this.setState({loadFeeSubs:true})
                    // }
                    // if(Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
                    //     this.setState({loadFeeSubs:true})
                    // }

                }
            })
    }
    handleAmountChange = (value) => {
        this.setState({ amount: value })
        const usdPrice = (+value) * (+this.state.coinUsdPrice);
        const packageData = this.state.packageList.find(e => usdPrice >= e.lowerpriceLimit && usdPrice <= e.upperpriceLimit)
        this.setState({ selectedPackege: packageData });
console.log(packageData)
        if (!packageData || !value) {
            this.setState({noPackageFound:true})
        }else{
            this.setState({noPackageFound:false})
        }
    }
    handleMaxFill = ()=>{
        if(!this.state.selectedCoin) return;

        this.setState({amount:this.state.userBalance[this.state.selectedCoin?.tokenSymbol]},()=>{
            this.handleAmountChange(this.state.userBalance[this.state.selectedCoin?.tokenSymbol])
        })
       
    }
resetFeilds = ()=>{
this.setState({
    selectedCoin:'',
    selectedPackege:'',
    amount:'',
    usdPrice:''
})
}

    render() { 
        return (
            <div>
            <Notifications />
            <Header />

            <section id="middel_area" className="ref_mid">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-12 area_left">
                                <Sidebar  />
                            </div>
                            <div className="col-sm-12 area_right">
                                <div className="row">
                                    <div className="col-md-6 col-md-offset-3">
                                        <div className="white_bx clearfix deposit_part">
                                            <div className="side_txt">
                                                <h6>Stake</h6>
                                            </div>
                                            <div className=''>
                                                <div className='col-12 px-4'>
                                                <div className="dropdown withdarw_drop">
                                                <button id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {/* <i className="fa fa-search"></i>  */}
                                                    {this.state.selectedCoinDropdown} <span className="caret"></span> </button>
                                                <ul className="dropdown-menu" aria-labelledby="dLabel">
                                                    {/* <li><img src="images/currency_img2.png" width="25px" alt="img" /> ETH <span>(Ethereum</span>)</li> */}
                                                    {this.state.coinList.map((d,xid) => (
                                                        d.active === true && d.wActive === true ?
                                                        d.tokenSymbol == "INR" ?
                                                        this.state.userData.country == "India" &&
                                                        <li key={xid} onClick={() => { this.onSelectToken(d) }}>
                                                            <img src={TOKEN_ICON_URL+d.tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px"}}/>
                                                            {d.tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>({d.tokenName})</span>
                                                        </li>
                                                        :
                                                        <>
                                                        <li key={xid} onClick={() => { this.onSelectToken(d) }}>
                                                            <img src={TOKEN_ICON_URL+d.tokenSymbol+".png" } width="20px" height="20px" style={{marginLeft:"10px",marginRight:"20px"}}/>
                                                            {d.tokenSymbol} <span style={{fontSize:"12px",color:"#888"}}>({d.tokenName})</span>
                                                        </li>
                                                        </>
                                                        :
                                                        <></>
                                                    ))}
                                                </ul>

                                            </div>
                                                </div>
                                            
                                            {/* <div className='col-12 col-sm-12 col-md-4'>
                                            <div className='form-group'>
                                            <label htmlFor='coin'>Token</label>
                                                    <select name="coin" id="coin" className='form-control' onChange={(d)=>{this.onSelectToken(JSON.parse(d.target.value))}}>
                                                        <option defaultValue = {null}>Select</option>
                                                        {this.state.coinList.map((d)=>{
                                                            return(
                                                                <option key={d._id} value={JSON.stringify(d)} onClick={()=>{this.onSelectToken(d)}}>{d.tokenSymbol} ({d.tokenName})</option>
                                                            )
                                                        })
                                                    }
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className='col-12 px-4'>
                                                <div className='form-group'  style={{position:"relative"}}>
                                                <label htmlFor='amount'>Amount <span>Available {this.state.selectedCoin?.tokenSymbol}: <strong>{this.state.availableBalance}</strong></span></label>
                                                    <input type="text" id='amount' className='form-control' placeholder='Amount' value={this.state.amount} 
                                                    readOnly={!this.state.selectedCoin} onChange={(e)=>{this.handleAmountChange(e.target.value)}} name="fromAmount" />
                                                    <span style={{fontSize:"12px",color:"rgb(0, 200, 83)",cursor:"pointer",position: "absolute",top: "35px",right: "10px"}} onClick={() =>{this.handleMaxFill()}}>STAKE ALL</span>
                                                <div style={{fontSize:11,display:'flex', justifyContent:'space-between'}}>
                                                    {(+this.state.amount) > (+this.state.availableBalance) ?<div className='text-danger'> Insufficient Fund</div>:<div></div>}
                                                    <div > Amount in USD : {isNaN(this.state.amount * this.state.coinUsdPrice)? '0.00':this.state.amount * this.state.coinUsdPrice}</div>
                                                   
                                                </div>
                                                </div>
                                            </div>
                                            <div className='col-12 px-4'>
                                                <div className='form-group'>
                                                <label htmlFor='packageid'>Package</label>
                                                    {/* <select name="packegeid" id="packageid" className='form-control' 
                                                    onChange={(d)=>{this.onSelectPackege(JSON.parse(d.target.value))}}
                                                    >
                                                        <option defaultValue = {null}>Select</option>
                                                        {this.state.packageList.map((d)=>{
                                                            return(
                                                                <option disabled key={d._id} value={JSON.stringify(d)} >{d.packageName} ({d.stakingPeriod}Days)</option>
                                                            )
                                                        })
                                                    }
                                                        
                                                    </select> */}
                                                      <input readOnly onChange={()=>{}} type="text" id='amount' className='form-control' placeholder='package' value={this.state.selectedPackege ?(this.state.selectedPackege?.packageName + ' ('+this.state.selectedPackege?.stakingPeriod+')month'):'No Package Selected'} name="package" />
                                                   
                                                   {this.state.amount * this.state.coinUsdPrice <50 ? <div style={{fontSize:11}} className='text-danger text-sm'>Minimum Stakable Amount is 50 USD</div> :this.state.noPackageFound ? <div style={{fontSize:11}} className='text-danger text-sm'>Currently we do not have any packege for this amount</div> :null}
                                                </div>
                                            </div>
                                            <div className='col-12 px-4'>
                                                <div className='form-group'>
                                                <label htmlFor='packageid'>Interest Percentage : {this.state.selectedPackege?.interestPercentage || 0.00}%</label>
                                                </div>
                                                </div>
                                    </div>
                                            <div className='text-center btns'>
                                                <input className=''style={{height:15, width:15,cursor:'pointer'}} type={'checkbox'} onChange={(e)=> this.setState({tnCAccepted:e.target.checked})} /> <a className='text-info' href='javascript:void' >Terms and Conditions</a>
                                            </div>
                                        <div className='text-center '>
                                        {/* <button className='btn btn-warning' onClick={this.resetFeilds}>Reset</button> */}
                                        <button type="button" disabled={!this.state.tnCAccepted} className="btn btn-danger" onClick={this.performStaking}>Proceed for Stake</button>
                                            {/* <button className='btn btn-info' onClick={this.performStaking}>Stake</button> */}
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* <div className="col-sm-12 area_right">
                            <div className="white_bx clearfix">
                                <div className="side_txt " style={{display:'flex'}}>
                                    <h6 style={{width:'100%'}}>Stake </h6>
                                
                                </div>
                                <div>
                                    <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-4'>
                                                <div className='form-group'>
                                                <label htmlFor='packageid'>Package</label>
                                                    <select name="packegeid" id="packageid" className='form-control' onChange={(d)=>{this.onSelectPackege(JSON.parse(d.target.value))}}>
                                                        <option defaultValue = {null}>Select</option>
                                                        {this.state.packageList.map((d)=>{
                                                            return(
                                                                <option key={d._id} value={JSON.stringify(d)} >{d.packageName} ({d.stakingPeriod}Days)</option>
                                                            )
                                                        })
                                                    }
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-4'>
                                            <div className='form-group'>
                                            <label htmlFor='coin'>Token</label>
                                                    <select name="coin" id="coin" className='form-control' onChange={(d)=>{this.onSelectToken(JSON.parse(d.target.value))}}>
                                                        <option defaultValue = {null}>Select</option>
                                                        {this.state.coinList.map((d)=>{
                                                            return(
                                                                <option key={d._id} value={JSON.stringify(d)} onClick={()=>{this.onSelectToken(d)}}>{d.tokenSymbol} ({d.tokenName})</option>
                                                            )
                                                        })
                                                    }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-4'>
                                                <div className='form-group'>
                                                <label htmlFor='amount'>Amount</label>
                                                    <input type="text" id='amount' className='form-control' placeholder='Amount' onChange={(e)=>{this.setState({amount:e.target.value})}} name="fromAmount" />
                                                </div>
                                            </div>
                                    </div>
                                        <div className='text-right btns'>
                                        <button className='btn btn-warning' onClick={this.resetFeilds}>Reset</button>
                                            <button className='btn btn-info' onClick={this.performStaking}>Stake</button>
                                        </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </div>
//             <div>
//             <Header />   
//             <div>
//             <div style={{
//                 height: "300px",
//                 backgroundSize: "cover",
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center'
//             }}>
//                 <div className='text-center'>
//                     <h1 style={{ color: 'black' }}>User Staking</h1><br/>
//                 </div>
// <div></div>
//            {/* </div> */}
//             <div className='mt-5 converter'>
//                 <div className='input-grp'>
//                     <input type="text" className='form-control my-form-control convert-input'name="fromAmount"/>
//                     <div className='select-coin' >
//                     <div className="dropdown">
//                                             </div>
//                     </div>
//                 </div>
//                 <div className='input-grp'>
//                     <input type="text" className='form-control my-form-control convert-input' name="toAmount"/>
//                     <div className='select-fromcoin' >
//                     <div className="dropdown">

//                     </div>
//                     </div>
//                 </div>
//                 <div className='text-center btns'>
//                     {/* <button className='btn btn-info me-2' >Convert</button> */}
//                     <button className='btn btn-info'>Stake</button>
//                 </div>
//             </div>
//         </div>
//         </div>
//             <Footer />
//             </div>  
         );
    }
}
 
export default userStaking;