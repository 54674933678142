import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { Button } from 'reactstrap';


class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uniqueId:''
        }
    }

    render() {
        return (
            
                <div className="side_bg clearfix">
                <ul>
                    <li className={document.title === "Dashboard" ? 'active' : ''}><Link to="/dashboard"><i className="fa fa-columns fa-lg"></i><p>Dashboard</p></Link></li>
                    <li className={document.title === "Future Trade" ? 'active' : ''}><Link to="/wallet/futures"><i className="fa fa-list fa-lg"></i><p>Future</p></Link></li>
               
                    <li className={document.title === "Transactions" ? 'active' : ''}><Link to="/transactions"><i className="fa fa-money fa-lg" ></i><p>Transactions</p></Link></li>
                    
                    <li className={document.title === "Referral" ? 'active' : ''}><Link to="/referral"><i className="fa fa-share fa-lg"></i><p>Referral</p></Link></li>
                    
                    <li className={document.title === "Deposit" ? 'active' : ''}><Link to="/deposit"><i className="fa fa-university" ></i><p>Deposit</p></Link></li>
                    
                    <li className={document.title === "Withdraw" ? 'active' : ''}><Link to="/withdraw"><i className="fa fa-usd" ></i><p>Withdraw</p></Link></li>

                    {/* <li className={document.title === "Manage Subscription" ? 'active' : ''}><Link to="/feesubscription"><i className="fa fa-exchange" ></i><p>Trading FEE</p></Link></li> */}
                        
                    <li className={document.title === "Verify KYC" ? 'active' : ''}><Link to="/kyc"><i className="fa fa-address-card-o"></i><p>Verify KYC</p></Link></li>
                    <li className={document.title === "Lock History" ? 'active' : ''}><Link to="/lockHistory"><i className="fa fa-file-text"></i> <p> Funds</p></Link></li>

                    <li className={document.title === "Staking Level" ? 'active' : ''}><Link to="/stakinglevels"><i className="fa fa-address-card-o"></i><p>Team Staking Level</p></Link></li>
                    <li className={document.title === "Stake History" ? 'active' : ''}><Link to="/stakehistory"><i className="fa fa-address-card-o"></i><p>Stake Statement History</p></Link></li>
                    <li className={document.title === "User Staking" ? 'active' : ''}><Link to="/userStaking"><i className="fa fa-address-card-o"></i><p>Stake</p></Link></li>
                    <li className={document.title === "Activity Log" ? 'active' : ''}><Link to="/activitylog"><i className="fa fa-file-text"></i> <p>Activity Logs</p></Link></li>
                    <li className={document.title === "USDT Transfer" ? 'active' : ''}><Link to="/usdttransfer"><i className="fa fa-usd"></i> <p>USDT Transfer</p></Link></li>
                    <li className={document.title === "Bank Transfer" ? 'active' : ''}><Link to="/banktransfer"><i className="fa fa-university" ></i><p>Bank Transfer</p></Link></li>
                    
                    {/* <li className={document.title === "Settings" ? 'active' : ''}><Link to="/setting"><i className="fa fa-cog fa-lg"></i><p>Settings</p></Link></li>
                    
                    <li><a href="/personaldetails"><i className="fa fa-user fa-lg"></i> <p>Personal Details</p></a></li> */}
            
                </ul>
                </div>
            
        );
    }
}

export default Sidebar;