import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { BASE_URL } from "../../config/constant";
import speakeasy from "@levminer/speakeasy";
import QRCode from "qrcode.react";
import moment from "moment";
import Pagination from "react-js-pagination";
const base32Decode = require('base32-decode')
var page = 1;
var id;
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}
class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: [],
            loginHistory: [],
            loadFeeSubs: false,
            FESSstakeamt: 0,
            oldPassword: '',
            newPassword: '',
            mainError: null,
            userInfo: [],
            warningBlock: true,
            loading: true,
            count: 0,
            userDetails: true,
            enableTwoFA: false,
            disableTwoFA: false,
            activePage: 1,
            newTwoFACode: "",
            twofa: "",
            errors: {
                oldpassword: '',
                newpassword: '',
                cnfpassword: '',
                twofa: '',
            }
        };
    }



    getUserInfo = async () => {
        let userId = localStorage.getItem('userDetails')
        await axios.post(BASE_URL + `userInformation/get`, {
            "id": userId
        })
            .then(res => {
                // console.log(res);
                if (res.data.message === "success") {
                    this.setState({
                        userData: res.data.data,
                        newTwoFACode: res.data.data.secret
                    })

                }

                this.setState({ loading: false })

            })
    }

    showTwoFACode = () => {

        this.setState({
            loading:true,
            warningBlock:false
        })

        var secret = speakeasy.generateSecret({length: 10});
        this.setState({newTwoFACode:secret.base32})


        this.setState({loading:false})
    }

    verifyTwoFA = async (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({mainError: null})

        if(this.state.twofa === null) {
            errors.twofa = 'Please enter valid 2FA code';
        }
        if(this.state.twofa.length === 0) {
            errors.twofa = 'Please enter valid 2FA code';
        }

        this.setState({errors});
        console.log('this is verified after clicking verify now', this.state.twofa);
 if(validateForm(this.state.errors)) {
    console.log('this is verified after clicking verify now', this.state.twofa);
   // var verified = false;
console.log(this.state.newTwoFACode);
          var   verified = speakeasy.totp.verify({
                secret: this.state.newTwoFACode,
                encoding: 'base32',
                token: this.state.twofa,
                window:6
            
            });
            console.log('this is verified after clicking verify now', verified);

             
            if(verified === true) {

                let data = {
                    "id": this.state.userId,
                    "secret": this.state.newTwoFACode,
                    "two_factor": 1,
                }
                axios.post(BASE_URL + `userInformation/key/update`, data)
                    .then(res => {

                        this.getUserInfo()
                        
                        this.setState({twofa: ""})
                        this.setState({mainError: "twofa"});
                    })
                
                
                
            } else {
                this.setState({twofa: ""})
                errors.twofa = 'Please enter valid 2FA code';
                this.setState({errors});
            }
            
        }
        // console.log("verify",verified)
    }



    // verifyTwoFA = async (event) => {
    //     event.preventDefault();
    //     const { name, value } = event.target;
    //     let errors = this.state.errors;
    //     this.setState({ mainError: null })

    //     if (this.state.twofa === null) {
    //         errors.twofa = 'Please enter valid 2FA code';
    //     }
    //     if (this.state.twofa.length === 0) {
    //         errors.twofa = 'Please enter valid 2FA code';
    //     }

    //     this.setState({ errors });

    //     if (validateForm(this.state.errors)) {

    //         var verified = speakeasy.totp.verify({
    //             secret: this.state.newTwoFACode,
    //             encoding: 'base32',
    //             token: this.state.twofa
    //         });

    //         if (verified === true) {

    //             let data
    //             if (this.state.userData.two_factor === 0) {
    //                 data = {
    //                     "id": this.state.userId,
    //                     "secret": this.state.newTwoFACode,
    //                     "two_factor": 1,
    //                 }
    //             } else {
    //                 data = {
    //                     "id": this.state.userId,
    //                     "secret": "",
    //                     "two_factor": 0,
    //                 }
    //             }


    //             axios.post(BASE_URL + `userInformation/key/update`, data)
    //                 .then(res => {

    //                     this.getUserInfo()

    //                     this.setState({ twofa: "" })

    //                     if (this.state.userData.two_factor === 0) {
    //                         this.setState({ mainError: "success" });
    //                     } else {
    //                         this.setState({ mainError: "error" });
    //                     }

    //                     this.setState({
    //                         enableTwoFA: false,
    //                         disableTwoFA: false,
    //                         userDetails: true
    //                     })

    //                 })



    //         } else {
    //             this.setState({ twofa: "" })
    //             errors.twofa = 'Please enter valid 2FA code';
    //             this.setState({ errors });
    //         }

    //     }
    //     // console.log("verify",verified)
    // }


    enableTwoFA = async () => {
        this.setState({ loading: true })
        var secret = speakeasy.generateSecret({ length: 10 });
        this.setState({ newTwoFACode: secret.base32 })
        this.setState({
            enableTwoFA: true,
            userDetails: false
        })
        this.setState({ loading: false })
    }

    disableTwoFA = async () => {
        this.setState({
            disableTwoFA: true,
            userDetails: false
        })
    }

    async componentDidMount() {
        if (!localStorage.getItem('userDetails')) {
            this.props.history.push('/login')
        } else {
            this.setState({ userId: localStorage.getItem('userDetails') })

            this.getUserInfo()

            await axios.get(BASE_URL + `ercToken/get/all`)
                .then(res => {
                    // console.log(res);
                    // console.log(res.data.data);
                    if (res.data.message === "success") {

                        res.data.data.map(row => {
                            if (row.tokenSymbol === "FESS") {
                                this.setState({ FESSstakeamt: row.minstake })
                            }
                        })

                    }
                })


            await axios.post(BASE_URL + `loginhistory/getall`, {
                "userId": this.state.userId
            })
                .then(res => {
                    // console.log("user id",this.state.userId)
                    // console.log(res);
                    if (res.data.message === "success") {
                        this.setState({ loginHistory: res.data.data })

                        // console.log(res.data.data)

                    }
                })

            await axios.post(BASE_URL + `userBalance/get`, {
                "userId": this.state.userId
            })
                .then(res => {
                    // console.log("user id",this.state.userId)
                    // console.log(res);
                    if (res.data.message === "success") {
                        if (Number(res.data.data['FESS']) >= Number(this.state.FESSstakeamt)) {
                            this.setState({ loadFeeSubs: true })
                        }

                        if (Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
                            this.setState({ loadFeeSubs: true })
                        }

                    }
                })
            
                await axios.post(BASE_URL + `loginhistory/count`, {
                    "userId": this.state.userId
                })
                    .then(res => {
                        this.setState({count: res.data.data})
                    })
                
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({ error: null })
        this.setState({ errorSuccess: null })
        switch (name) {
            case 'oldpassword':
                errors.oldpassword =
                    value.length < 8
                        ? 'Old Password must be 8 characters long!'
                        : '';
                break;
            case 'newpassword':
                errors.newpassword =
                    value.length < 8
                        ? 'New Password must be 8 characters long!'
                        : '';
                break;
            case 'cnfpassword':
                errors.cnfpassword =
                    value.length < 8
                        ? 'Confirm Password must be 8 characters long!'
                        :
                        this.state.newpassword !== value
                            ? 'New Password & Confirm Password should match!'
                            : '';
                break;
                case 'twofa': 
                errors.twofa = 
                    value.length  < 6
                    ? 'Please enter valid 2FA code'
                    : '';
                break;


            default:
                break;
        }

        this.setState({ errors, [name]: value });
    }


    handleSubmit = async (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        this.setState({ error: null })
        this.setState({ errorSuccess: null })

        if (this.state.oldpassword === null) {
            errors.oldpassword = 'Old Password must be 8 characters long!';
        }
        if (this.state.newpassword === null) {
            errors.newpassword = 'New Password must be 8 characters long!';
        }
        if (this.state.cnfpassword === null) {
            errors.cnfpassword = 'Confirm Password must be 8 characters long!';
        }
        if (this.state.newpassword !== this.state.cnfpassword) {
            errors.cnfpassword = 'New Password & Confirm Password should match!';
        }
        if(this.state.twofa === null) {
            errors.twofa = 'Please enter valid 2FA code';
        }
        if(this.state.twofa.length === 0) {
            errors.twofa = 'Please enter valid 2FA code';
        }

        var verified = speakeasy.totp.verify({
            secret: this.state.userData.secret,
            encoding: 'base32',
            token: this.state.twofa
        });

        verified= true //uncomment this remove this

        if(verified === true) {

        } else {
            this.setState({twofa: ""})
            errors.twofa = 'Please enter valid 2FA code';
            this.setState({errors});
        }


        this.setState({ errors });

        let data = {
            "id": this.state.userId,
            "oldPassword": this.state.oldpassword,
            "newPassword": this.state.newpassword,
        }


        if (validateForm(this.state.errors)) {
            await axios.post(BASE_URL + `changePassword`, data)
                .then(res => {
                    // console.log(res.data);
                    if (res.data.message === "updatedSuccessfully") {
                        this.setState({ error: null })
                        this.setState({ errorSuccess: 'Password updated successfully' })
                        //   alert("Updated!")
                    }
                    if (res.data.message === "passwordDoesNotMatch") {
                        // console.log("Id does not exist!");
                        this.setState({ error: 'Old Password does not match' })
                        //   alert("Password does not match")
                    }
                })
        }

    }

    
   async handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        page = pageNumber
        await axios.post(BASE_URL + `loginhistory/getall`, {
            "userId": this.state.userId,
            "pageNumber" : 1
        })
            .then(res => {
                // console.log("user id",this.state.userId)
                // console.log(res);
                if (res.data.message === "success") {
                    this.setState({ loginHistory: res.data.data })

                    // console.log(res.data.data)

                }
            })
    }

    render() {
        const { errors } = this.state;
        document.title = "Settings";
        document.body.classList.add('faded_bg');
        console.log("User two factor: ", this.state.userData)

        return (
            <div>
                <Header loadFees={this.state.loadFeeSubs} />
                <section id="middel_area">
                <img src="/images/bg_shape1.png" alt="img" className="shape1" />
                <img src="/images/bg_shape2.png" alt="img" className="shape2" />
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 area_left">
                                <Sidebar loadFees={this.state.loadFeeSubs} />
                            </div>

                            <div className="col-sm-12 area_right">
                                <div className="white_bx clearfix">

                                    {this.state.loading === true ?
                                        <div style={{ height: "250px" }}>
                                            <div className="loader">Loading...</div>
                                        </div>

                                        :
                                        <>
                                   
                                        {
                                            // false ?
                                        this.state.userData.two_factor === 0 ? // uncomment this
                                        <div className="col-md-12 text-center setting_info">
                                            <br></br>

                                            <h4 style={{fontWeight:"400"}}>PLEASE ACTIVATE 2FA BEFORE CHANGE PASSWORD</h4> 


                                            
                                            <hr></hr>

                                            {this.state.warningBlock === true ?
                                            <>
                                            <img src="/images/security_img-min.png" width="70%"/>

                                            <div className="alert alert-warning">
                                                <h5 style={{color:"#ff0000"}}>WARNING!!!</h5>
                                                <br></br>
                                                <p>Your account is not secure, hence it can be easily hacked. We highly recommend you to activate Two-Factor Authentication, which will provide maximum protection to your financial assets!</p>
                                            </div>
                                            <br></br>

                                            <div>
                                                <button className="btn btn-success" onClick={this.showTwoFACode} style={{fontSize:"20px"}}>Click here to enable 2FA</button>
                                            </div>
                                            </>
                                            
                                            :
                                            
                                            <>
                                            <div className="text-center">
                                            <h5 style={{fontWeight:"300",fontSize:"15px"}}>Scan below QR code with Google Authenticator</h5>
                                            <br></br>
                                            <QRCode value={"otpauth://totp/"+this.state.userData.emailId+"?secret="+this.state.newTwoFACode+"&issuer=Bitallx"} />
                                            <br></br>
                                            <h4 style={{margin:"10px"}}>{this.state.newTwoFACode}</h4> 
                                            <div className="clearfix"></div>
                                            <span style={{color:"red"}}>Please save this authentication code for future use</span>
                                            
                                            <div className="col-md-6 col-md-offset-3">
                                                <input type="number" style={{margin:"20px 0px 0px 0px",textAlign:"center"}} className="form-control" name="twofa" onChange={this.handleChange} value={this.state.twofa} placeholder="Enter Authentication Code" noValidate/>
                                                {errors.twofa.length > 0 && 
                                                    <span className='formerror'>{errors.twofa}</span>}
                                                    <div className="clearfix"></div>
                                                <button className="btn btn-success" style={{margin:"20px 0px"}} onClick={this.verifyTwoFA} >Verify Code</button>
                                            </div>
                                            
                                            <div className="col-md-12" style={{textAlign:'justify', marginTop: '20px'}}>
                                            <div className="clearfix"></div>
                                                <h6 style={{borderTop: '1px solid #eee', paddingTop: '20px'}}>2FA code don't work? Try below solution</h6>
                                                <p>It may be because the time isn't correctly synced on your Google Authenticator app.</p> <br/>
                                                <p>To set the correct time:</p>
                                                <p> 1. On your android device, go to the main menu of the Google Authenticator app.</p>
                                                <p> 2. <strong>Tap More `{'>'}` Settings `{'>'}` Time correction for codes `{'>'}`  Sync now </strong></p>
                                                <p>On the next screen, the app confirms the time has been synced. You should be able to sign in. The sync will only affect the internal time of your Google Authenticator app, and will not change your device's Date and Time settings.</p>
                                            </div>
                                            <div className="clearfix"></div>
                                            <hr></hr>
                                            </div>
                                           
                                            </>
                                            
                                            }
                                           
                                            <br></br>
                                            <br></br>
                                            <p><strong>Two-Factor Authentication (2FA)</strong> is the most advanced security system, which adds an extra protection to your account, preventing unauthorized access.</p>

                                            <br></br>
                                            <br></br>


                                            <div class="security_steps text-center row">
                                                <div class="col-md-4">
                                                    <img src="/images/security1.png" alt="img" />
                                                    <p>Enter your Bitallx username and password as usual.</p>
                                                </div>
                                                <div class="col-md-4">
                                                    <img src="/images/security2.png" alt="img" />
                                                    <p>Then, you'll be asked for a code that will be visible in your Google Authenticator App.</p>
                                                </div>
                                                <div class="col-md-4">
                                                    <img src="/images/security3.png" alt="img" />
                                                    <p>Congratulations! You are successfully logged in to your account.</p>
                                                </div>
                                            </div>
                                            <br></br>


                                        </div>

                                        :
                                            <div className="clearfix change_pass">
                                                <h6>Change Password</h6>
                                                <form onSubmit={this.handleSubmit} noValidate>
                                                    <div className="row">
                                                        <div className="form-group col-sm-4">
                                                            <label style={{ color: "#aaa" }}>Old Password</label>
                                                            <input type="password" name="oldpassword" placeholder="" className="form-control" onChange={this.handleChange} />
                                                            {errors.oldpassword.length > 0 &&
                                                                <span className='formerror'>{errors.oldpassword}</span>}
                                                            {this.state.error !== null &&
                                                                <span className='formerror'>{this.state.error}</span>}
                                                            {this.state.errorSuccess !== null &&
                                                                <span className='formerror' style={{ color: "green" }}>{this.state.errorSuccess}</span>}
                                                        </div>
                                                        <div className="form-group col-sm-4">
                                                            <label style={{ color: "#aaa" }}>New Password</label>
                                                            <input type="password" name="newpassword" placeholder="" className="form-control" onChange={this.handleChange} />
                                                            {errors.newpassword.length > 0 &&
                                                                <span className='formerror'>{errors.newpassword}</span>}
                                                        </div>
                                                        <div className="form-group col-sm-4">
                                                            <label style={{ color: "#aaa" }}>Confirm Passowrd</label>
                                                            <input type="password" name="cnfpassword" placeholder="" className="form-control" onChange={this.handleChange} />
                                                            {errors.cnfpassword.length > 0 &&
                                                                <span className='formerror'>{errors.cnfpassword}</span>}
                                                        </div>

                                                        <div className="form-group col-sm-4">
                                                            <label style={{ color: "#aaa" }}>Two Factor Code</label>
                                                            <input name="twofa" placeholder="" className="form-control" onChange={this.handleChange} />
                                                            {errors.twofa.length > 0 &&
                                                                <span className='formerror'>{errors.twofa}</span>}
                                                        </div>

                                                        <div className="col-md-12 text-right">
                                                            <button type="submit" className="blue_btn">Change Password</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        }
                                        </>
                                    }
                                </div>
                              
                            </div>


                        </div>
                    </div>
                </section>

                <Footer />

            </div>
        );
    }
}

export default Setting;