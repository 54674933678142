import React from 'react'
import BigNumber from 'bignumber.js'
// import { decimalData } from './decimalDataForAllCoins';
import Axios from 'axios';
import { BASE_URL } from '../../config/constant';
var decimalData = "";

const TradeHistory = (props) => {
    if(props.coinName){
        if(decimalData == ""){
            Axios.get(BASE_URL+"coinpair/getall").then((res)=>{
                if (res.data && res.data.statusCode === 200) {
                        let a  = res.data.data.find((data)=>  data.primaryCoin == props.coinName )
                        decimalData = Number(a.priceDecimal)
                        sessionStorage.setItem('currentCoinDecimalValue' ,decimalData )
                }
            })
        }        
}
    if(props.trades) {
    let currentp = new BigNumber(0);
    let rows = [];
    let numRows = props.trades.length;
    for (var i = 0; i < numRows; i++) {
        let newp = new BigNumber(props.trades[i].p);
        let newq = new BigNumber(props.trades[i].q);
        let mode
        if(newp >= currentp) {
            mode = "Buy"
        } else {
            mode = "Sell"
        }
        rows.unshift(
            
            <tr key={`${i}:${props.trades[i].p}:${props.trades[i].q}:${props.trades[i].T}`} onClick={() => props.handelTradeHistory(Number(newp),Number(newq),mode)} className="coinhover" style={{cursor:"pointer"}}>
            { props.trades[i].Mode ?
                <td ><span className={props.trades[i].Mode == "Buy" ? 'green_clr' : 'red_clr'}>{new BigNumber(props.trades[i].p).toFormat(decimalData,1)}</span></td>      
              :
                <td ><span className={newp.gte(currentp) ? 'green_clr' : 'red_clr'}>{newp.toFormat(decimalData,1)}</span></td>     
            } 
            <td>{new BigNumber(props.trades[i].q).toFormat(4,1)}</td>
            {props.trades[i].E && <td>{new Date(props.trades[i].E).toLocaleTimeString()}</td>}
            {/* {(props.trades[i].T) && <td>{new Date(props.trades[i].T).toLocaleTimeString()}</td>} */}
        </tr>
       
   
        )
        currentp = new BigNumber(props.trades[i].p);
    }
    return (
        <>
        {rows}
        </>
    )


} else {
    return (
        <div></div>
    )
}

}

export default TradeHistory;