import React, { Component } from "react";
import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";

import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
import BigNumber from "bignumber.js";
import axios from "axios";
import OrderBookBid from "./OrderBookBid";
import OrderBookAsk from "./OrderBookAsk";
import TradeHistory from "./TradeHistory";
import { BASE_URL } from "../../../config/constant";
import { Offline, Online } from "react-detect-offline";
import $ from "jquery";
import Notifications, { notify } from "react-notify-toast";

// import { SocketProvider } from "socket.io-react";
import io from "socket.io-client";
// import Binance from "binance-api-react-native";
// import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
// import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
// import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
// import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
// import ToggleButton from "@material-ui/lab/ToggleButton";
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import { decimalData } from "../decimalDataForAllCoins";
import { TVChartContainer } from "../tradeChart/trading-chart";
import UserOrderList from "./userOrderList";
import BuySell from "./buySell";
import { BsStar, BsStarFill } from "react-icons/bs";
// const client = Binance();

// const socket = io.connect("wss://bithind.com:2087");
const socket = io.connect("");

const validNumber = RegExp(/^[0-9.]*$/);
var priceValid = new RegExp(/^[0-9.]{1,11}(\.\d{1,8})?$/);

class FutureTrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      LodingOrder: false,
      exchangeType: "binance",
      // ticker: 0,
      // allticker: [],
      trades: [],
      tradehistory: [],
      depth: [],
      userData: [],
      tokenData: [],
      // tickerPrice: [],
      // coinCode: null,
      // pairCode: null,
      coinCode: "BTC",
      pairCode: "USDT",
      isSellModalOpen: false,
      loadFeeSubs: false,
      isBuyModalOpen: false,
      userBalanceCoin: 0,
      userBalancePair: 0,
      userBalanceFESS: 0,
      FESSstakeamt: 0,
      tradingPercent: "0.2",
      userId: 0,
      errors: "",
      themeMode: true,
      theme: "dark_mode",
      openOrderrows: [],
      cancelOrderrows: [],
      historyOrderrows: [],
      buySellType: "LIMIT",
      socketConnect: false,
      minAmt: 0,
      minTotal: 0,
      priceDecimal: 0,
      amtDecimal: 0,
      myfavcoin: [],
      myorderPrice: [],
      doctitle: "Bitallx",
      feesPacks: [],
      manageFee: false,

      allCoinPairsData: [],
      currentCoinPairDecimalData: {},

      buyerFess: 0,
      sellerFess: 0,
      showPairlist: false,
    };
    let rows = [];
    this.tradesCount = 100;
    this.streams = ["@depth20", "@trade"];
    // this.streams = ['@ticker','@depth20','@trade'];
  }

  _connectTickerStreams(streams) {
    streams = streams.join("/");
    let connection = btoa(streams);
    // let connectionnew = btoa(streams);

    this[connection] = new WebSocket(
      `wss://stream.binance.com:9443/stream?streams=${streams}`
    );
    // this[connectionnew] = io.connect('wss://stream.binance.com:9443/stream?streams='+streams);

    // client.ws.depth('ETHBTC', depth => {
    //   console.log(depth)
    // })

    // console.log("stream",streams[0])
    var lastsockettimeNew = 0;
    var currenttimestampNew;

    this[connection].onmessage = (evt) => {
      currenttimestampNew = Math.floor(Date.now() / 1000);

      // lastsockettimeNew = currenttimestampNew + 0;

      let eventData = JSON.parse(evt.data);

      if (eventData.stream.endsWith("@ticker")) {
        // let decimals
        // if(eventData.data.s.endsWith('BTC')) {
        //   decimals = 8
        // }
        // if(eventData.data.s.endsWith('ETH')) {
        //   decimals = 6
        // }
        // if(eventData.data.s.endsWith('USDT')) {
        //   decimals = 4
        // }
        // console.log("tokendata",eventData.data)
        var lastPrice = $("." + eventData.data.s + "_price").html();
        if (lastPrice != undefined) {
          lastPrice = lastPrice.replace(/,/g, "");
        }

        // console.log('I think main data eventData',eventData,lastPrice);
        // $(document).prop('title', eventData.data.s + ' | Bithind');

        if (Number(lastPrice) > eventData.data.c) {
          $("." + eventData.data.s + "_priceColor").removeClass("red_clr");
          $("." + eventData.data.s + "_priceColor").removeClass("green_clr");
          $("." + eventData.data.s + "_priceColor").addClass("red_clr");

          $("#" + eventData.data.s + "_priceArrow").removeClass("fa-arrow-up");
          $("#" + eventData.data.s + "_priceArrow").removeClass(
            "fa-arrow-down"
          );
          $("#" + eventData.data.s + "_priceArrow").addClass("fa-arrow-down");
          // this.setState({ [eventData.data.s+'_priceColor']: "red_clr"})
        } else if (Number(lastPrice) < eventData.data.c) {
          $("." + eventData.data.s + "_priceColor").removeClass("red_clr");
          $("." + eventData.data.s + "_priceColor").removeClass("green_clr");
          $("." + eventData.data.s + "_priceColor").addClass("green_clr");

          $("#" + eventData.data.s + "_priceArrow").removeClass("fa-arrow-up");
          $("#" + eventData.data.s + "_priceArrow").removeClass(
            "fa-arrow-down"
          );
          $("#" + eventData.data.s + "_priceArrow").addClass("fa-arrow-up");
          // this.setState({ [eventData.data.s+'_priceColor']: "green_clr" })
        } else {
          $("." + eventData.data.s + "_priceColor").removeClass("red_clr");
          $("." + eventData.data.s + "_priceColor").removeClass("green_clr");
          // this.setState({ [eventData.data.s+'_priceColor']: "" })
        }

        if (eventData.data.P > 0) {
          $("." + eventData.data.s + "_priceChangePercentColor").removeClass(
            "red_clr"
          );
          $("." + eventData.data.s + "_priceChangePercentColor").removeClass(
            "green_clr"
          );
          $("." + eventData.data.s + "_priceChangePercentColor").addClass(
            "green_clr"
          );
        } else {
          $("." + eventData.data.s + "_priceChangePercentColor").removeClass(
            "red_clr"
          );
          $("." + eventData.data.s + "_priceChangePercentColor").removeClass(
            "green_clr"
          );
          $("." + eventData.data.s + "_priceChangePercentColor").addClass(
            "red_clr"
          );
        }

        if (this.state.allCoinPairsData.length == 0) {
          var BTC_DECIMAL = this.state.allCoinPairsData.find(
            (data) => data.primaryCoin == "BTC"
          );
          var ETH_DECIMAL = this.state.allCoinPairsData.find(
            (data) => data.primaryCoin == "ETH"
          );
          var USDT_DECIMAL = this.state.allCoinPairsData.find(
            (data) => data.primaryCoin == "USDT"
          );
        }

        let decimalForCoin = 0;
        if (eventData.data.s.substr(eventData.data.s.length - 4) == "USDT") {
          decimalForCoin = USDT_DECIMAL;
        } else if (
          eventData.data.s.substr(eventData.data.s.length - 3) == "BTC"
        ) {
          decimalForCoin = BTC_DECIMAL;
        } else if (
          eventData.data.s.substr(eventData.data.s.length - 3) == "ETH"
        ) {
          decimalForCoin = ETH_DECIMAL;
        }

        // this.setState({ [eventData.data.s+'_price']: eventData.data.c })
        $("." + eventData.data.s + "_price").html(
          new BigNumber(eventData.data.c).toFormat(decimalForCoin, 1)
        );
        // this.setState({ [eventData.data.s+'_priceChangePercent']: eventData.data.P })
        $("." + eventData.data.s + "_priceChangePercent").html(
          new BigNumber(eventData.data.P).toFormat(2, 1)
        );

        if (
          this.props.match.params.coinCode + this.props.match.params.pairCode ==
          eventData.data.s
        ) {
          // document.title = eventData.data.s + ' | Bithind'
          this.setState({
            doctitle:
              new BigNumber(eventData.data.c).toFormat(null, 1) +
              " | " +
              eventData.data.s +
              " | Bitallx",
          });
          $("." + eventData.data.s + "_priceChange").html(
            new BigNumber(eventData.data.p).toFormat(null, 1)
          );
          $("." + eventData.data.s + "_priceHigh").html(
            new BigNumber(eventData.data.h).toFormat(null, 1)
          );
          $("." + eventData.data.s + "_priceLow").html(
            new BigNumber(eventData.data.l).toFormat(null, 1)
          );
          $("." + eventData.data.s + "_priceVolume").html(
            new BigNumber(eventData.data.q).toFormat(2, 1)
          );
        }

        //       eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0
        //       this.setState({ticker: eventData.data});
      }

      // let ticker = this._getTickerBySymbol(JSON.parse(evt.data).data)
      // this.props.dispatch({
      //     type: 'UPDATE_MARKET_PAIRS',
      //     data: ticker
      // })

      // this.setState({allticker: ticker});
      // console.log(ticker)

      // if(this.state.tokenData) {
      //   this.state.tokenData.forEach(item => {

      //     data = '<tr style={{borderBottom:"1px solid rgba(255,255,255,0.1)"}}>
      //     <td style={{padding:"10px 10px"}}><i className="fa fa-star"></i></td>
      //     <td style={{padding:"10px 0px"}}><a href="#">{d.tokenSymbol}/USDT</a></td>
      //     <td style={{padding:"10px 0px"}}>

      //       {/* {this.state.allticker.ETHBTC.lastPrice} */}

      //     </td>
      //     <td style={{padding:"10px 0px"}} className="red_clr">-0.21%</td>
      // </tr>'
      // if(this.state.allticker[''+item.tokenSymbol+'USDT']) {

      //   if(this.state[item.tokenSymbol+'USDT_price'] > this.state.allticker[''+item.tokenSymbol+'USDT'].lastPrice) {
      //     this.setState({ [item.tokenSymbol+'USDT_priceColor']: "red_clr"})
      //   } else if(this.state[item.tokenSymbol+'USDT_price'] < this.state.allticker[''+item.tokenSymbol+'USDT'].lastPrice) {
      //     this.setState({ [item.tokenSymbol+'USDT_priceColor']: "green_clr" })
      //   } else {
      //     this.setState({ [item.tokenSymbol+'USDT_priceColor']: "" })
      //   }

      //   this.setState({ [item.tokenSymbol+'USDT_price']: this.state.allticker[''+item.tokenSymbol+'USDT'].lastPrice })
      //   this.setState({ [item.tokenSymbol+'USDT_priceChangePercent']: this.state.allticker[''+item.tokenSymbol+'USDT'].priceChangePercent })

      // }

      // if(item.tokenSymbol == 'BTC' || item.tokenSymbol == 'ETH') {

      // } else {
      //   if(this.state.allticker[''+item.tokenSymbol+'ETH']) {

      //     if(this.state[item.tokenSymbol+'ETH_price'] > this.state.allticker[''+item.tokenSymbol+'ETH'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'ETH_priceColor']: "red_clr"})
      //     } else if(this.state[item.tokenSymbol+'ETH_price'] < this.state.allticker[''+item.tokenSymbol+'ETH'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'ETH_priceColor']: "green_clr" })
      //     } else {
      //       this.setState({ [item.tokenSymbol+'ETH_priceColor']: "" })
      //     }

      //   this.setState({ [item.tokenSymbol+'ETH_price']: this.state.allticker[''+item.tokenSymbol+'ETH'].lastPrice })
      //   this.setState({ [item.tokenSymbol+'ETH_priceChangePercent']: this.state.allticker[''+item.tokenSymbol+'ETH'].priceChangePercent })

      //   }
      // }

      // if(item.tokenSymbol == 'BTC') {

      // } else {
      //   if(this.state.allticker[''+item.tokenSymbol+'BTC']) {

      //     if(this.state[item.tokenSymbol+'BTC_price'] > this.state.allticker[''+item.tokenSymbol+'BTC'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'BTC_priceColor']: "red_clr"})
      //     } else if(this.state[item.tokenSymbol+'BTC_price'] < this.state.allticker[''+item.tokenSymbol+'BTC'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'BTC_priceColor']: "green_clr" })
      //     } else {
      //       this.setState({ [item.tokenSymbol+'BTC_priceColor']: "" })
      //     }

      //   this.setState({ [item.tokenSymbol+'BTC_price']: this.state.allticker[''+item.tokenSymbol+'BTC'].lastPrice })
      //   this.setState({ [item.tokenSymbol+'BTC_priceChangePercent']: this.state.allticker[''+item.tokenSymbol+'BTC'].priceChangePercent })

      //   }
      // }

      // console.log(this.state.ETHUSDT_price)
      // console.log(item.tokenSymbol)
      //   })
      // }

      // !this.props.active_market.market && this._handleTabClick('BTC')
      // this.setState({
      //   isLoaded: true
      // })
    };

    this[connection].onerror = (evt) => {
      console.error(evt);
    };
  }

  _connectSocketStreams(streams) {
    streams = streams.join("/");
    let connection = btoa(streams);

    this[connection] = new WebSocket(
      `wss://stream.binance.com:9443/stream?streams=${streams}`
    );

    var lastsockettime = 0;
    var currenttimestamp;

    this[connection].onmessage = (evt) => {
      currenttimestamp = Math.floor(Date.now() / 1000);

      if (currenttimestamp > lastsockettime) {
        lastsockettime = currenttimestamp + 1;

        // console.log("lastsockettime",Date.now())
        let eventData = JSON.parse(evt.data);

        // if(eventData.stream.endsWith('@ticker')){
        //     eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0
        //     this.setState({ticker: eventData.data});
        //     // console.log("ticket",this.state.ticker.s.length)
        //     // this.props.dispatch({
        //     //     type: 'SET_TICKER',
        //     //     data: eventData.data
        //     // })
        //     this.setState({
        //         loadedTicker: true
        //     })
        // }

        if (eventData.stream.endsWith("@trade")) {
          // if(this.state.trades && Object.keys(this.state.trades).length > 0){

          // $('#tradehistorytable').prepend('<tr class="coinhover tradehistoryclick" onclick="this.tradehistoryclick"style="cursor:pointer"><td>'+new BigNumber(eventData.data.p).toFormat(null,1)+'</td><td>'+new BigNumber(eventData.data.q).toFormat(null,1)+'</td><td>'+new Date(eventData.data.T).toLocaleTimeString()+'</td></tr>');

          // $('#tradehistorytable').html(<><tr className="coinhover tradehistoryclick" ><td>sadaadsa</td></tr></>);

          // $('#tradehistorytable tr:last').remove();

          // console.log(eventData.data)
          // E: 1590227905352
          // M: true
          // T: 1590227905350
          // a: 2277982279
          // b: 2277982282
          // e: "trade"
          // m: false
          // p: "9130.48000000"
          // q: "0.29765100"
          // s: "BTCUSDT"
          // t: 326511267
          // this.setState({trades: eventData.data});
          // let trades = this.state.trades;

          // alert("hi")
          this.setState({ tradehistory: "" });

          var trades = this.state.trades.slice();
          trades.push(eventData.data);
          this.setState({ trades: trades });

          // trades.push(eventData.data);

          trades = trades.slice(-1 * this.tradesCount);
          this.setState({ trades: trades });

          // trades = trades.slice(-1*this.tradesCount);
          // console.log("trades",this.state.trades[2].q)

          // this.props.dispatch({
          //     type: 'SET_TRADES',
          //     data: trades
          // })
          // this.setState({
          //     loadedTrades: true
          // })
          // }
        }
        if (eventData.stream.endsWith("@depth20")) {
          // this.props.dispatch({
          //     type: 'SET_DEPTH',
          //     data: eventData.data
          // })
          console.log("I think main data eventData stream", eventData.stream);
          this.setState({ depth: eventData.data });

          // console.log(this.state.depth.bids.length)
          // this.setState({
          //     loadedDepth: true
          // })
        }
      }

      // this.setState({
      //     isLoaded: true
      // })
    };

    this[connection].onerror = (evt) => {
      console.error(evt);
    };
  }

  handleBuySellModal() {
    // $("#buy_modal").modal("hide");
    $("#buy_trade_click_active").removeClass("active");
    $("#sell_trade_click_active").removeClass("active");
    $("#buy_trade_click_active").addClass("active");
    $("#buy_trade_click").trigger("click");
    // $("#sell_modal").modal("hide");
    // $("#buy_modal").modal("show");
  }

  // handleSellModal() {
  //   // $("#sell_modal").modal("hide");
  //   $("#buy_trade_click_active").removeClass("active");
  //   $("#sell_trade_click_active").removeClass("active");
  //   $("#sell_trade_click_active").addClass("active");
  //   $("#sell_trade_click").trigger("click");
  //   // $("#buy_modal").modal("hide");
  //   // $("#sell_modal").modal("show");
  // }

  handleCloseModal() {
    $("#buy_modal").modal("hide");
    $("#sell_modal").modal("hide");
  }

  getmyfavcoin() {
    axios
      .post(BASE_URL + `future/favcoin/get`, {
        userId: this.state.userId,
      })
      .then((res) => {
        // console.log("favcoin",res);
        if (res.data.message == "success") {
          this.setState({
            myfavcoin: res.data.data,
          });
            this.setState({ myFavCoins: res.data.data });
        }
      });
  }

  async getBithindDetails() {
    if (this.state.exchangeType == "Bitallx") {
      await axios
        .post(BASE_URL + `trade/history/get`, {
          coinName: this.props.match.params.coinCode,
          pair: this.props.match.params.pairCode,
        })
        .then((res) => {
          // console.log("tradehistory",res.data.data)
          if (res !== null) {
            var tradesStates = res.data.data
              .map((row) => {
                var dataRow = {
                  p: Number(row.price).toFixed(6),
                  q: Number(row.amount).toFixed(6),
                  T: Number(row.timestamp + "001"),
                  E: Number(row.timestamp + "001"),
                  Mode: row.orderType,
                };
                return dataRow;
              })
              .reverse();
            // console.log("tradehistoryrow",tradesStates)
            this.setState({ trades: tradesStates });
          }
        });

      await axios
        .post(BASE_URL + `order/open`, {
          coinName: this.props.match.params.coinCode,
          pair: this.props.match.params.pairCode,
        })
        .then((res) => {
          // console.log("tradehistoryCCCC",res)
          if (res.data.message !== "noRecordsFound") {
            var sellOrders = res.data.data.sell
              .map((row) => {
                var dataRow = [
                  Number(row.price).toFixed(6),
                  Number(row.amount).toFixed(6),
                ];
                // var dataRow = [Number(row.price).toFixed(6),Number(row.amount).toFixed(6)]

                return dataRow;
              })
              .reverse();

            var buyOrders = res.data.data.buy
              .map((row) => {
                var dataRow = [
                  Number(row.price).toFixed(6),
                  Number(row.amount).toFixed(6),
                ];
                // var dataRow = [Number(row.price).toFixed(6),Number(row.amount).toFixed(6)]

                return dataRow;
              })
              .reverse();

            var openOrderBookData = {
              asks: sellOrders,
              bids: buyOrders,
            };
            this.setState({ depth: openOrderBookData });
          } else {
            var openOrderBookData = {
              asks: [],
              bids: [],
            };
            this.setState({ depth: openOrderBookData });
          }
        });
    }
  }

  async getBithindPrice() {
    // axios({
    //   method: 'get',
    //   // url: `https://cors-anywhere.herokuapp.com/https://www.binance.com/api/v1/aggTrades?limit=${this.tradesCount}&symbol=${symbol}`
    //   url: `https://bithind.com/dataApi/getlastprice`
    // })
    //   .then(response => {
    //     // console.log("tradedata",response.data)
    //     {
    //       this.state.tokenData.map((d, idx) => {
    //         if (d.tActive == true) {
    //           if (d.BTC == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "BTC"])) {
    //               if (response.data["exch" + d.tokenSymbol + "BTC"].length > 0) {
    //                 $("." + d.tokenSymbol + "BTC_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].close).toFormat(2, 1))
    //                 if (Number(response.data["exch" + d.tokenSymbol + "BTC"][0].close) < Number(response.data["exch" + d.tokenSymbol + "BTC"][0].open)) {
    //                   $("." + d.tokenSymbol + 'BTC_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "BTC_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "BTC"][0].close) > Number(response.data["exch" + d.tokenSymbol + "BTC"][0].open)) {
    //                   $("." + d.tokenSymbol + 'BTC_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "BTC_priceArrow").addClass("fa-arrow-up")
    //                 }
    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "BTC"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "BTC"][0].close - response.data["exch" + d.tokenSymbol + "BTC"][0].open) / response.data["exch" + d.tokenSymbol + "BTC"][0].open) * 100).toFormat(2, 1)
    //                 }
    //                 $("." + d.tokenSymbol + "BTC_priceChangePercent").html(percentage)
    //                 $("." + d.tokenSymbol + "BTC_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "BTC"][0].close) - Number(response.data["exch" + d.tokenSymbol + "BTC"][0].open)).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "BTC_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "BTC_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "BTC_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].high).toFormat(2, 1))
    //               }
    //             }
    //           }
    //           if (d.ETH == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "ETH"])) {
    //               if (response.data["exch" + d.tokenSymbol + "ETH"].length > 0) {
    //                 $("." + d.tokenSymbol + "ETH_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].close).toFormat(2, 1))
    //                 if (Number(response.data["exch" + d.tokenSymbol + "ETH"][0].close) < Number(response.data["exch" + d.tokenSymbol + "ETH"][0].open)) {
    //                   $("." + d.tokenSymbol + 'ETH_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "ETH_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "ETH"][0].close) > Number(response.data["exch" + d.tokenSymbol + "ETH"][0].open)) {
    //                   $("." + d.tokenSymbol + 'ETH_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "ETH_priceArrow").addClass("fa-arrow-up")
    //                 }
    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "ETH"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "ETH"][0].close - response.data["exch" + d.tokenSymbol + "ETH"][0].open) / response.data["exch" + d.tokenSymbol + "ETH"][0].open) * 100).toFormat(2, 1)
    //                 }
    //                 $("." + d.tokenSymbol + "ETH_priceChangePercent").html(percentage)
    //                 $("." + d.tokenSymbol + "ETH_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "ETH"][0].close) - Number(response.data["exch" + d.tokenSymbol + "ETH"][0].open)).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "ETH_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "ETH_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "ETH_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].high).toFormat(2, 1))
    //               }
    //             }
    //           }
    //           if (d.USDT == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "USDT"])) {
    //               if (response.data["exch" + d.tokenSymbol + "USDT"].length > 0) {
    //                 $("." + d.tokenSymbol + "USDT_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "USDT"][0].close).toFormat(2, 1))
    //                 if (Number(response.data["exch" + d.tokenSymbol + "USDT"][0].close) < Number(response.data["exch" + d.tokenSymbol + "USDT"][0].open)) {
    //                   $("." + d.tokenSymbol + 'USDT_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "USDT_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "USDT"][0].close) > Number(response.data["exch" + d.tokenSymbol + "USDT"][0].open)) {
    //                   $("." + d.tokenSymbol + 'USDT_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "USDT_priceArrow").addClass("fa-arrow-up")
    //                 }
    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "USDT"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "USDT"][0].close - response.data["exch" + d.tokenSymbol + "USDT"][0].open) / response.data["exch" + d.tokenSymbol + "USDT"][0].open) * 100).toFormat(2, 1)
    //                 }
    //                 $("." + d.tokenSymbol + "USDT_priceChangePercent").html(percentage)
    //                 $("." + d.tokenSymbol + "USDT_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "USDT"][0].close) - Number(response.data["exch" + d.tokenSymbol + "USDT"][0].open)).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "USDT_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "USDT"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "USDT_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "USDT"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "USDT_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].high).toFormat(2, 1))
    //               }
    //             }
    //           }
    //           if (d.INR == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "INR"])) {
    //               if (response.data["exch" + d.tokenSymbol + "INR"].length > 0) {
    //                 $("." + d.tokenSymbol + "INR_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].close).toFormat(2, 1))
    //                 if (Number(response.data["exch" + d.tokenSymbol + "INR"][0].close) < Number(response.data["exch" + d.tokenSymbol + "INR"][0].open)) {
    //                   $("." + d.tokenSymbol + 'INR_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "INR_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "INR"][0].close) > Number(response.data["exch" + d.tokenSymbol + "INR"][0].open)) {
    //                   $("." + d.tokenSymbol + 'INR_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "INR_priceArrow").addClass("fa-arrow-up")
    //                 }
    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "INR"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "INR"][0].close - response.data["exch" + d.tokenSymbol + "INR"][0].open) / response.data["exch" + d.tokenSymbol + "INR"][0].open) * 100).toFormat(2, 1)
    //                 }
    //                 $("." + d.tokenSymbol + "INR_priceChangePercent").html(percentage)
    //                 $("." + d.tokenSymbol + "INR_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "INR"][0].close) - Number(response.data["exch" + d.tokenSymbol + "INR"][0].open)).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "INR_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "INR_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "INR_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].high).toFormat(2, 1))
    //               }
    //             }
    //           }
    //         }
    //       })
    //     }
    //     // this.setState({
    //     //     trades:response.data,
    //     //     isLoaded: true,
    //     //     loadedTrades: true
    //     // })
    //   })
  }

  async getmyOrders() {
    await axios
      .post(BASE_URL + `order/get/id`, {
        pair: this.props.match.params.pairCode,
        coinName: this.props.match.params.coinCode,
        status: 0,
        userId: this.state.userId,
      })
      .then((res) => {
        this.setState({ myorderPrice: [] });
        // console.log("userbalance",res.data.data);
        if (res.data.message == "success") {
          res.data.data.map((row) => {
            this.setState({
              myorderPrice: this.state.myorderPrice.concat(row.price),
            });
          });
          // console.log("myorderPrice",this.state.myorderPrice)
          this.setState({ openOrderrows: res.data.data });
        }
      });

    await axios
      .post(BASE_URL + `order/get/id`, {
        pair: this.props.match.params.pairCode,
        coinName: this.props.match.params.coinCode,
        status: 2,
        userId: this.state.userId,
      })
      .then((res) => {
        // console.log("userbalance",res.data.data);
        if (res.data.message == "success") {
          this.setState({ cancelOrderrows: res.data.data });
        }
      });

    await axios
      .post(BASE_URL + `order/get/id`, {
        pair: this.props.match.params.pairCode,
        coinName: this.props.match.params.coinCode,
        status: 1,
        userId: this.state.userId,
      })
      .then((res) => {
        // console.log("userbalance",res.data.data);
        if (res.data.message == "success") {
          this.setState({ historyOrderrows: res.data.data });
        }
      });
  }

  async getmyBalance() {
    await axios
      .get(BASE_URL + `get/futureAndSpotBalances?userId=${ this.state.userId}`)
      .then(async (res) => {
        // console.log("user id",this.state.userId)

        // console.log("userbalance",res.data.data[this.props.match.params.coinCode]);
        if (res.data.data ) {
          const balance =  res.data?.data?.FutureWalletBalances
          this.setState({
            userBalanceCoin: balance[this.props.match.params.coinCode],
            userBalancePair: balance[this.props.match.params.pairCode],
            userBalanceFESS: balance["FESS"],
          });

          await axios.get(BASE_URL + `feespack/get/all`).then(async (res) => {
            // console.log(res);
            // console.log(res.data.data);
            if (res.data.message === "success") {
              this.setState({
                feesPacks: res.data.data,
              });

              this.state.feesPacks.map((row) => {
                if (row.title == "General FESS") {
                  this.setState({ tradingPercent: row.feePercent });
                }
              });

              if (this.state.userBalanceFESS >= this.state.FESSstakeamt) {
                this.setState({ manageFee: true });
              }

              if (
                Math.floor(Date.now() / 1000) <
                Number(
                  Number(this.state.userData.feesValidId) +
                    Number(this.state.userData.feesSubsTime)
                )
              ) {
                await axios
                  .post(BASE_URL + `feespack/get/historyrev`, {
                    userId: this.state.userId,
                  })
                  .then((res) => {
                    // console.log(res);
                    // console.log(res.data.data);
                    if (res.data.message === "success") {
                      {
                        res.data.data
                          .map((rowMain, xid) => {
                            //  console.log("myhistory",xid)
                            if (xid == 0) {
                              this.setState({
                                tradingPercent: rowMain.feePercent,
                              });
                            }
                            //  console.log("myhistory",xid)
                            //  console.log("myhistory",rowMain.title)
                          })
                          .reverse();
                      }
                    }
                  });
                this.setState({ loadFeeSubs: true });
              }
            }
          });

          // if (this.state.userBalanceFESS >= this.state.FESSstakeamt || Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
          //   if (Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
          //     this.setState({ tradingPercent: "0" })
          //   } else {
          //     this.setState({ tradingPercent: "0.1" })
          //   }
          //   this.setState({ loadFeeSubs: true })
          // } else {
          //   this.setState({ tradingPercent: "0.2" })
          // }
        }
      });
  }

  getFormatedTime(time) {
    let T = time.split(" ");
    let T1 = T[1];
    let T3 = T1.replaceAll("-", ":");
    let T4 = new Date(T[0] + " " + T3).getTime() + 19800000;
    return T4;
  }

  updateOrders() {
    const usrID = localStorage.getItem("userDetails");
    if (usrID && this.props.match.params.coinCode != "BTXT") {
      let sym =
        this.props.match.params.coinCode + this.props.match.params.pairCode;
      axios
        .get(
          BASE_URL +
            `order/updateOrderStatus?symbol=${sym?.toUpperCase()}&userId=${usrID}`
        )
        .then();
    }
  }

  async componentDidMount() {
    this.updateOrders();
    const usrID = localStorage.getItem("userDetails");
    // if (usrID && this.props.match.params.coinCode != 'BTXT') {
    //   let sym = this.props.match.params.coinCode + this.props.match.params.pairCode;
    //   axios.get(BASE_URL + `order/updateOrderStatus?symbol=${sym?.toUpperCase()}&userId=${usrID}`).then()
    // }

    if (usrID) {
      if (this.props.match.params.coinCode == "BTXT") {
        setInterval(() => {
          // this.getBithindDetails();
          // this.getmyOrders();
          this.getmyBalance();
        }, 20000);
      } else {
        setInterval(() => {
          // this.getmyOrders();
          // this.getmyBalance();
          // this.updateOrders();
        }, 10000);
      }
    }

    if (localStorage.getItem("tradeMode")) {
      if (localStorage.getItem("tradeMode") == "light_mode") {
        this.setState({
          alignment: "left",
          theme: "light_mode",
          backgroundcolor1: "#FDB813",
          backgroundcolor2: "#fff",
        });
      } else {
        this.setState({
          alignment: "right",
          theme: "dark_mode",
          backgroundcolor1: "#fff",
          backgroundcolor2: "#FDB813",
        });
      }
    }

    socket.on("connect", (msg) => {
      this.setState({ socketConnect: true });
    });
    socket.on("disconnect", (msg) => {
      this.setState({ socketConnect: false });
    });

    socket.on("orderUpdate", (msg) => {
      // this.getBithindDetails();
      // this.getBithindPrice()
      this.getmyOrders();
      this.getmyBalance();
    });

    socket.on("orderDetails", (msg) => {
      if (msg.executionType == "TRADE") {
        // console.log("mssg",msg)
        // console.log("mssg",this.state.userBalanceCoin)

        this.state.openOrderrows.map((row, xid) => {
          if (row.binanceorderId == msg.orderId) {
            // console.log("mssg","ORDER FOUND")

            this.setState({ myorderPrice: [] });
            const item = {
              coinName: row.coinName,
              pair: row.pair,
              orderType: row.orderType,
              orderMode: row.orderMode,
              price: row.price,
              amount: row.amount,
              total: row.total,
              dateTime: row.dateTime,
              tredingFee: row.tredingFee,
              binanceorderId: row.binanceorderId,
              _id: row._id,
            };

            this.setState({
              historyOrderrows: [...this.state.historyOrderrows, item],
            });

            if (row.orderType == "Sell") {
              this.setState({
                userBalancePair: Number(
                  Number(this.state.userBalancePair) +
                    Number(row.amount * row.price)
                )
                  .toFixed(10)
                  .slice(0, -4),
              });
            } else {
              this.setState({
                userBalanceCoin: Number(
                  Number(this.state.userBalanceCoin) + Number(row.amount)
                )
                  .toFixed(10)
                  .slice(0, -4),
              });
            }

            const openOrderrows = [...this.state.openOrderrows];
            openOrderrows.splice(xid, 1);
            this.setState({ openOrderrows });

            this.state.openOrderrows.map((row) => {
              // console.log("myorderPrice",row)
              this.setState({
                myorderPrice: this.state.myorderPrice.concat(row.price),
              });
            });
          }
        });
      }
    });

    // const clean = await client.ws.user(msg => {
    //   console.log(msg)
    // })

    if (this.props.match.params.coinCode && this.props.match.params.pairCode) {
      this.setState({
        coinCode: this.props.match.params.coinCode,
        pairCode: this.props.match.params.pairCode,
      });

      let symbol = (
        this.props.match.params.coinCode + this.props.match.params.pairCode
      ).toLowerCase();
      // let symbol = 'BTCUSDT'.toLowerCase();
      // console.log(this.state.coinCode);
      // console.log(symbol);

      // PRICE SOCKET TO START
      if (this.state.exchangeType == "binance") {
        this._connectSocketStreams(this.streams.map((i) => `${symbol}${i}`));
      }

      await axios.get(BASE_URL + `ercToken/get/all`).then((res) => {
        // console.log('erc api res  ',res);
        if (res.data.message == "success") {
          this.setState({ tokenData: res.data.data });
          // console.log("tokendata",this.state.tokenData)

          {
            this.state.tokenData.map((d, idx) => {
              if (d.tokenSymbol == "FESS") {
                this.setState({ FESSstakeamt: d.minstake });
              }
              if (d.tokenSymbol == this.props.match.params.coinCode) {
                if (this.props.match.params.pairCode == "BTC") {
                  this.setState({ minAmt: d.minAmtBTC });
                  this.setState({ minTotal: d.minTotalBTC });
                  this.setState({ priceDecimal: d.priceDecimalBTC });
                  this.setState({ amtDecimal: d.amtDecimalBTC });
                }
                if (this.props.match.params.pairCode == "ETH") {
                  this.setState({ minAmt: d.minAmtETH });
                  this.setState({ minTotal: d.minTotalETH });
                  this.setState({ priceDecimal: d.priceDecimalETH });
                  this.setState({ amtDecimal: d.amtDecimalETH });
                }
                if (this.props.match.params.pairCode == "USDT") {
                  this.setState({ minAmt: d.minAmtUSDT });
                  this.setState({ minTotal: d.minTotalUSDT });
                  this.setState({ priceDecimal: d.priceDecimalUSDT });
                  this.setState({ amtDecimal: d.amtDecimalUSDT });
                }
                if (this.props.match.params.pairCode == "INR") {
                  this.setState({ minAmt: d.minAmtINR });
                  this.setState({ minTotal: d.minTotalINR });
                  this.setState({ priceDecimal: d.priceDecimalINR });
                  this.setState({ amtDecimal: d.amtDecimalINR });
                }
              }

              if (this.props.match.params.coinCode == d.tokenSymbol) {
                // if (d.exchange == "Bitallx") {
                //   this.setState({ exchangeType: "Bitallx" });
                // }
              }

              if (d.tActive == true) {
                if (d.BTC == "1") {
                  this.setState({ [d.tokenSymbol + "BTC_favcoin"]: false });
                }
                if (d.ETH == "1") {
                  this.setState({ [d.tokenSymbol + "ETH_favcoin"]: false });
                }
                if (d.USDT == "1") {
                  this.setState({ [d.tokenSymbol + "USDT_favcoin"]: false });
                }
                if (d.INR == "1") {
                  this.setState({ [d.tokenSymbol + "INR_favcoin"]: false });
                }
              }
            });
          }
          // userBalanceCoin: res.data.data[this.props.match.params.coinCode],
          // userBalancePair: res.data.data[this.props.match.params.pairCode],
          if (
            this.props.match.params.pairCode == "INR" ||
            this.props.match.params.pairCode == "BTXT" ||
            this.props.match.params.coinCode == "BTXT"
          ) {
            // console.log('first')
            this.setState({ exchangeType: "Bitallx" });
            this.getBithindDetails();
          }

          let usdtpair = this.state.tokenData.map(
            (i) => `${i.tokenSymbol.toLowerCase() + "usdt@ticker"}`
          );
          let btcpair = this.state.tokenData.map(
            (i) => `${i.tokenSymbol.toLowerCase() + "btc@ticker"}`
          );
          let ethpair = this.state.tokenData.map(
            (i) => `${i.tokenSymbol.toLowerCase() + "eth@ticker"}`
          );

          // PRICE SOCKET TO START
          // this._connectTickerStreams([btcpair.join('/')])
          this._connectTickerStreams([
            usdtpair.join("/") +
              "/" +
              btcpair.join("/") +
              "/" +
              ethpair.join("/"),
          ]);
          // this._connectTickerStreams([usdtpair])

          // NEED TO START

          // axios({
          //   method: 'get',
          //   // url: `https://cors-anywhere.herokuapp.com/https://www.binance.com/api/v1/aggTrades?limit=${this.tradesCount}&symbol=${symbol}`
          //   url: `https://www.binance.com/api/v3/ticker/24hr`
          // })
          // .then(response => {
          //   response.data.map(row => {
          //     // console.log(row)
          //     this.state.tokenData.map(i => {
          //         if(i.tokenSymbol+"USDT" == row.symbol || i.tokenSymbol+"BTC" == row.symbol || i.tokenSymbol+"ETH" == row.symbol) {

          //             this.setState({ [row.symbol+'_price']: row.lastPrice })
          //             this.setState({ [row.symbol+'_priceChangePercent']: row.priceChangePercent })
          //             this.setState({ [row.symbol+'_priceHigh']: row.highPrice })
          //             this.setState({ [row.symbol+'_priceLow']: row.lowPrice })
          //             this.setState({ [row.symbol+'_priceVolume']: row.volume })

          //         }
          //     })
          //   })
          // })
        }
      });

      // await this.getBitallxDetails()
      // await this.getBitallxPrice()
      // this._connectTickerStreams(['!ticker@arr'])
      // this._connectTickerStreams(['btcusdt@ticker','ethusdt@ticker'])

      symbol = (
        this.props.match.params.coinCode + this.props.match.params.pairCode
      ).toUpperCase();
   

      if (localStorage.getItem("userDetails")) {
        await this.setState({ userId: localStorage.getItem("userDetails") });

        await this.getmyfavcoin();

        await axios
          .post(BASE_URL + `userInformation/get`, {
            id: this.state.userId,
          })
          .then((res) => {
            if (res.data.message == "success") {
              this.setState({
                userData: res.data.data,
              });
            }
          });

        await this.getmyBalance();
        await this.getmyOrders();
      }
    } else {
      window.location.href = "/futures/trade/BTC/USDT";
      const usrID = localStorage.getItem("userDetails");
      this.updateOrders();
      // if (usrID && this.props.match.params.coinCode != 'BTXT') {
      //   let sym = this.props.match.params.coinCode + this.props.match.params.pairCode;
      //   let a = await axios.get(BASE_URL + `order/updateOrderStatus?symbol=${sym?.toUpperCase()}&userId=${usrID}`)
      // }
    }
    console.log("getall 0");
    axios.get(`${BASE_URL}futurecoinpair/getall`).then((res) => { 
      if (res.data && res.data.statusCode === 200) {
        let a = res.data.data.find(
          (data) => data.primaryCoin == this.state.coinCode
        );
        this.setState({
          allCoinPairsData: res.data.data,
          currentCoinPairDecimalData: a,
        });

        // if (
        //   this.props.match.params.coinCode === "BTXT" ||
        //   this.props.match.params.pairCode === "BTXT"
        // ) {
          const currentPair = res.data.data.find(
            (e) =>
              e.primaryCoin.toUpperCase() ===
                this.props.match.params.coinCode &&
              e.secondaryCoin.toUpperCase() === this.props.match.params.pairCode
          );

          this.setState({ tokenPairData: res.data.data });
          this.setState({
            buySellPrice: (+(currentPair?.price) || 0).toFixed(3),
            currentPair: currentPair,
            change24H: currentPair?.change24Hr,
            changeHigh: currentPair?.high24Hr,
            changeLow: currentPair?.low24Hr,
            volume: currentPair?.volume,
          });
        // }

      }
    });
  }

  getCoinPairAll(value) {
    console.log("getall 1");
    axios.get(`${BASE_URL}futurecoinpair/getall`).then((res) => {
      if (res.data && res.data.statusCode === 200) {
        //   if(this.props.match.params.coinCode === value || this.props.match.params.pairCode === value){
        //     const currentPair = res.data.data.find(e=> e.primaryCoin.toUpperCase() === this.props.match.params.coinCode && e.secondaryCoin.toUpperCase() === this.props.match.params.pairCode)

        this.setState({ allCoinPairsData: res.data.data })
        //   // this.setState({
        //   //   buySellPrice: currentPair?.price || 0,
        //   //   currentPair: currentPair,
        //   //   change24H : currentPair?.change24Hr,
        //   //   changeHigh : currentPair?.high24Hr,
        //   //   changeLow : currentPair?.low24Hr,
        //   //   volume : currentPair?.volume
        //   // })
        // }
        for (const pairValues of (res?.data?.data || [])) {
          let btxt_decimal = pairValues?.priceDecimal;
          // let other_decimal =
  
          $("."+pairValues.primaryCoin + value + "_priceColor").removeClass("red_clr");
          $("."+pairValues.primaryCoin + value + "_priceColor").removeClass("green_clr");
          $("."+pairValues.primaryCoin + value + "_priceColor").addClass("green_clr");
          $("."+pairValues.primaryCoin + value + "_price").html(
            new BigNumber(pairValues?.price).toFixed(Number(btxt_decimal))
          );
  
          // $(".BTXT" + value + "_price").html(new BigNumber(pairValues?.price).toFixed(pairValues.primaryCoin == 'BTXT' ? decimalData[currentPair1?.primaryCoin+currentPair1?.secondaryCoin]['D'] : decimalData[currentPair1?.primaryCoin]['D']))
          $("."+pairValues.primaryCoin + value + "_priceChangePercent").html(
            new BigNumber(pairValues?.change24Hr).toFormat(2, 1)
          );
        }
      }
    });
  }


  buySellBalancePer(percent) {
    try {
      var lastPrice = $(
        "." +
          this.props.match.params.coinCode +
          this.props.match.params.pairCode +
          "_price"
      ).html();
      if (lastPrice != undefined) {
        lastPrice = lastPrice.replace(/,/g, "");
      }
  
      let calculatedBalance = (this.state.userBalancePair / 100) * percent;
      let calculatedBalanceString = calculatedBalance.toString();
      let D = Number(this.state.currentCoinPairDecimalData.amountDecimal) + 1;
      let num = calculatedBalanceString.slice(
        0,
        calculatedBalanceString.indexOf(".") + D
      );
  
      if (this.state.buySellType != "MARKET") {
        let numCal = num / Number(this.state.buySellPrice);
        let numString = numCal.toString();
        let D = Number(this.state.currentCoinPairDecimalData.amountDecimal) + 1;
  
        let calculatedvolume = numString.slice(0, numString.indexOf(".") + D);
        this.setState({
          buySellVolume: calculatedvolume,
          buySellTotal: num,
        });
        // this.setState({ buySellTotal: (calculatedBalance).toFixed(10).slice(0, -4) })
      } else {
        this.setState({
          buySellVolume: num / lastPrice,
          buySellTotal: num,
        });
        // this.setState({ buySellTotal: (calculatedBalance).toFixed(10).slice(0, -4) })
      }
    } catch (error) {
      
      console.log("calculatedBalance",error)
    }
  }

  handelTradeHistory = (price, amount, mode) => {
    // console.log("coin","coinTradePage",price,amount,mode)

      this.setState({
        buySellType: "LIMIT",
        buySellPrice: Number(price),
        buySellVolume: Number(amount),
        buySellTotal: Number(price * amount).toFixed(4),
      });
      this.handleBuySellModal();
   
  };

  handleSubmitBuy = (event, odrType) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let orderProcess = true;
    let tradingFess = Number(this.state.tradingPercent);
    let fs;
    this.setState({ LodingOrder: true });
    if (this.state.buySellType == "MARKET") {
      var lastPrice = $(
        "." +
          this.props.match.params.coinCode +
          this.props.match.params.pairCode +
          "_price"
      ).html();
      // console.log(lastPrice)
      if (lastPrice != undefined) {
        lastPrice = lastPrice.replace(/,/g, "");
      }
      let buySellTotal = Number(Number(lastPrice) * Number(this.state.buySellVolume));
      // console.log(buySellTotal)
      // console.log(Number(this.state.minTotal))
      if (Number(buySellTotal) <= Number(this.state.minTotal)) {
        orderProcess = false;

        notify.show(
          "Order total should be more than " +
            this.state.minTotal +
            " " +
            this.props.match.params.pairCode,
          "error"
        );
      }
      if (Number(buySellTotal) > Number(this.state.userBalancePair)) {
        orderProcess = false;

        notify.show("Not enough balance to execute this order", "error");
      }
      // console.log(Number(Number(lastPrice) * Number(this.state.buySellVolume)))
    }
    if (this.state.buySellPrice == null || this.state.buySellPrice == "") {
      orderProcess = false;

      notify.show("Please enter a valid price", "error");
    } else if (this.state.buySellVolume == null || this.state.buySellVolume == "") {
      orderProcess = false;

      notify.show("Please enter a valid amount", "error");
    } else if (this.state.buySellTotal == null || this.state.buySellTotal == "") {
      orderProcess = false;

      notify.show("Please enter a valid total", "error");
    } else if (
      Number(this.state.buySellTotal) > Number(this.state.userBalancePair)
    ) {
      orderProcess = false;

      notify.show("Not enough balance to execute this order", "error");
    }
    if (this.state.buySellType != "MARKET") {
      if (Number(this.state.buySellTotal) <= Number(this.state.minTotal)) {
        orderProcess = false;

        notify.show(
          "Order total should be more than " +
            this.state.minTotal +
            " " +
            this.props.match.params.pairCode,
          "error"
        );
      }
    }
    if (Number(this.state.buySellVolume) <= Number(this.state.minAmt)) {
      orderProcess = false;

      notify.show(
        "Order amount should be more than " +
          this.state.minAmt +
          " " +
          this.props.match.params.coinCode,
        "error"
      );
    }

    if (orderProcess == true) {
      let tradingFessAmt = (Number(this.state.buySellVolume) / 100) * tradingFess;

      let pricesubmit = 0;
      let totalsubmit = 0;
      let statusubmit = 0;
      if (this.state.buySellType == "MARKET") {
        pricesubmit = 0;
        totalsubmit = 0;
        statusubmit = 1;
      } else {
        pricesubmit = Number(this.state.buySellPrice).toFixed(12).slice(0, -4);
        totalsubmit = Number(this.state.buySellTotal).toFixed(6).slice(0, -4);
        statusubmit = 0;
      }

      
        let decimalValue = this.state.allCoinPairsData.find(
          (data) => data.primaryCoin == this.props.match.params.coinCode
        );
       let newDecimalprice = Number(pricesubmit).toFixed(
          decimalValue.priceDecimal
        );
        let newDecimalAmount = Number(this.state.buySellVolume).toFixed(
          decimalValue.amountDecimal
        );
       let decimalVal = decimalValue.amountDecimal;

      let payload = {
        side: odrType,
        userId: this.state.userId,
        symbol:
          this.props.match.params.coinCode + this.props.match.params.pairCode,
        type: this.state.buySellType,

        total: totalsubmit,
        priceDecimal: this.state.priceDecimal,
        decimal: decimalVal,
        dateTime: Date.now(),
        coinName: this.props.match.params.coinCode,
        pair: this.props.match.params.pairCode,
        status: statusubmit,
        reduceOnly: false,
        closeOrder: false,
        forceHold: false,
        iceberg: false,
        leverage: 0,
        
        stop: "",
        stopPrice: 0,
        stopPriceType: "",
      };
      if(this.state.buySellType == "MARKET"){
        payload.price = newDecimalprice;
        payload.size = newDecimalAmount;
      }
      if(this.state.buySellType == "LIMIT"){
        payload.price = newDecimalprice;
        payload.size = newDecimalAmount;
        payload.hidden = false
      }
      console.log(payload);
      axios.post(BASE_URL + "future/addOrder", payload).then((res) => {
        if (res.data.message == "addedSuccessfully") {
          let resdata = res.data.data[0];
           if (resdata.orderMode == "LIMIT") {
            if (resdata.status == 0) {
              this.setState({
                userBalancePair: Number(
                  Number(this.state.userBalancePair) - Number(resdata.total)
                )
                  .toFixed(10)
                  .slice(0, -4),
              });
            } else {
              this.setState({
                userBalanceCoin: Number(
                  Number(this.state.userBalanceCoin) + Number(resdata.amount)
                )
                  .toFixed(10)
                  .slice(0, -4),
              });
              this.setState({
                userBalancePair: Number(
                  Number(this.state.userBalancePair) - Number(resdata.total)
                )
                  .toFixed(10)
                  .slice(0, -4),
              });
            }
          } else {
            this.setState({
              userBalanceCoin: Number(
                Number(this.state.userBalanceCoin) + Number(resdata.amount)
              )
                .toFixed(10)
                .slice(0, -4),
            });
            this.setState({
              userBalancePair: Number(
                Number(this.state.userBalancePair) - Number(resdata.total)
              )
                .toFixed(10)
                .slice(0, -4),
            });
          }

          if (resdata.orderMode == "LIMIT") {
            this.setState({ myorderPrice: [] });
            const item = {
              coinName: resdata.coinName,
              pair: resdata.pair,
              orderType: resdata.orderType,
              orderMode: resdata.orderMode,
              price: resdata.price,
              amount: resdata.amount,
              total: resdata.total,
              dateTime: resdata.dateTime,
              tredingFee: resdata.tredingFee,
              binanceorderId: resdata.binanceorderId,
              _id: resdata._id,
            };

            if (resdata.status == 0) {
              this.setState({
                openOrderrows: [...this.state.openOrderrows, item],
              });

              this.state.openOrderrows.map((row) => {
                // console.log("myorderPrice",row)
                this.setState({
                  myorderPrice: this.state.myorderPrice.concat(row.price),
                });
              });
            } else {
              this.setState({
                historyOrderrows: [...this.state.historyOrderrows, item],
              });
            }
          } else {
            const item = {
              coinName: resdata.coinName,
              pair: resdata.pair,
              orderType: resdata.orderType,
              orderMode: resdata.orderMode,
              price: resdata.price,
              amount: resdata.amount,
              total: resdata.total,
              dateTime: resdata.dateTime,
              tredingFee: resdata.tredingFee,
              binanceorderId: resdata.binanceorderId,
              _id: resdata._id,
            };
            this.setState({
              historyOrderrows: [...this.state.historyOrderrows, item],
            });
          }

          //                   amount: "0.998000"
          // coinName: "BTC"
          // dateTime: "2020-05-08 10-15-44"
          // isDeleted: false
          // orderType: "Sell"
          // pair: "USDT"
          // price: "1.000000"
          // status: 0
          // timestamp: 1588947269
          // total: "1.000000"
          // tredingFee: "0.002000"
          // userId: "5eb38e25848fe7050cf99ddf"
          // _id: "5eb569444ad12637f1510b8f"

          if (resdata.status == 0) {
            notify.show("Buy order submitted successfully", "warning");
          } else {
            notify.show("Buy order executed successfully", "success");
          }
          this.setState({
            // buySellPrice: "",
            buySellVolume: "",
            buySellTotal: "",
            buySellType: "LIMIT",
          });
          this.setState({ LodingOrder: false });
          // this.setState({ userBalanceCoin: res.data.data[this.props.match.params.coinCode], userBalancePair: res.data.data[this.props.match.params.pairCode] })
          this.getBithindDetails();
          this.getBithindPrice();
          this.getmyOrders();
          this.getmyBalance();
        } else {
          notify.show("Something went wrong...", "error");
          this.setState({ LodingOrder: false });
        }
      });
    } else {
      this.setState({ LodingOrder: false });
    }
  };

 
  addFavCoin = (coin, pair, status) => {
    axios
      .post(BASE_URL + `future/favcoin/update`, {
        coin: coin,
        pair: pair,
        userId: this.state.userId,
        active: status
      })
      .then((res) => {
        if (res.data.message == "updated") {
          this.getmyfavcoin();
         
        }
      });
  };

 
  handleRemoveRowOpenOrder = (idx) => () => {
    // console.log("cancelorder", this.state.openOrderrows[idx])

    let cancelUrl = "order/update/status";
    if (this.state.exchangeType == "binance") {
      cancelUrl = "order/update/status";
    }
    if (this.state.exchangeType == "Bitallx") {
      cancelUrl = "order/update/status";
    }

    axios
      .post(BASE_URL + cancelUrl, {
        pair: this.state.openOrderrows[idx].pair,
        coinName: this.state.openOrderrows[idx].coinName,
        orderType: this.state.openOrderrows[idx].orderType,
        status: 2,
        binanceorderId: this.state.openOrderrows[idx].binanceorderId,
        id: this.state.openOrderrows[idx]._id,
      })
      .then((res) => {
        // console.log("cancelorder", res.data.message)
        if (res.data.message == "updatedSuccessfully") {
          if (this.state.openOrderrows[idx].orderType == "Sell") {
            this.setState({
              userBalanceCoin: Number(
                Number(this.state.userBalanceCoin) +
                  Number(
                    Number(this.state.openOrderrows[idx].amount) +
                      Number(this.state.openOrderrows[idx].tredingFee)
                  )
              )
                .toFixed(10)
                .slice(0, -4),
            });
          } else {
            this.setState({
              userBalancePair: Number(
                Number(this.state.userBalancePair) +
                  Number(this.state.openOrderrows[idx].total)
              )
                .toFixed(10)
                .slice(0, -4),
            });
          }

          this.setState({ myorderPrice: [] });
          const item = {
            coinName: this.state.openOrderrows[idx].coinName,
            pair: this.state.openOrderrows[idx].pair,
            orderType: this.state.openOrderrows[idx].orderType,
            orderMode: this.state.openOrderrows[idx].orderMode,
            price: this.state.openOrderrows[idx].price,
            amount: this.state.openOrderrows[idx].amount,
            total: this.state.openOrderrows[idx].total,
            dateTime: this.state.openOrderrows[idx].dateTime,
            tredingFee: this.state.openOrderrows[idx].tredingFee,
            binanceorderId: this.state.openOrderrows[idx].binanceorderId,
            _id: this.state.openOrderrows[idx]._id,
          };
          this.setState({
            cancelOrderrows: [...this.state.cancelOrderrows, item],
          });

          const openOrderrows = [...this.state.openOrderrows];
          openOrderrows.splice(idx, 1);
          this.setState({ openOrderrows });

          notify.show("Order cancelled successfully", "success");
          this.getmyOrders();
          this.getBithindDetails();

          this.state.openOrderrows.map((row) => {
            // console.log("myorderPrice",row)
            this.setState({
              myorderPrice: this.state.myorderPrice.concat(row.price),
            });
          });
        } else {
          notify.show("Something went wrong...", "error");
        }
      });
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
       case "buySellType":
        if (value === "MARKET") {
          // this.setState({buySellTotal: "0" })
          this.setState({ buySellPrice: value });
        } else {
          this.setState({ buySellPrice: "" });
        }
        this.setState({ buySellType: value });

        break;

      

      case "buySellPrice":
        if (value.length == 0) {
          this.setState({ buySellPrice: "" });
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            // console.log("priceValid",priceValid.test(value))
            if (priceValid.test(value)) {
              this.setState({ buySellPrice: value });
            }
            this.setState({
              buySellTotal: (value * this.state.buySellVolume).toFixed(4),
            });
          }
        }
        break;

      case "buySellVolume":
        if (value.length == 0) {
          this.setState({ buySellVolume: "" });
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            this.setState({ buySellVolume: value });

            if (this.state.buySellType == "MARKET") {
              var lastPrice = $(
                "." +
                  this.props.match.params.coinCode +
                  this.props.match.params.pairCode +
                  "_price"
              ).html();
              if (lastPrice != undefined) {
                lastPrice = lastPrice.replace(/,/g, "");
              }
              this.setState({ buySellTotal: (value * lastPrice).toFixed(4) });
            } else {
              this.setState({
                buySellTotal: (value * this.state.buySellPrice).toFixed(4),
              });
            }
          }
        }
        break;

       case "buySellTotal":
        if (value.length == 0) {
          this.setState({ buySellTotal: "" });
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            this.setState({ buySellTotal: value });

            if (this.state.buySellType == "MARKET") {
              var lastPrice = $(
                "." +
                  this.props.match.params.coinCode +
                  this.props.match.params.pairCode +
                  "_price"
              ).html();
              if (lastPrice != undefined) {
                lastPrice = lastPrice.replace(/,/g, "");
              }
              this.setState({ buySellVolume: (value / lastPrice).toFixed(4) });
            } else {
              this.setState({
                buySellVolume: (value / this.state.buySellPrice).toFixed(4),
              });
            }
          }
        }
        break;

      default:
        break;
    }

    // this.setState({[name]: value});
  };

  // ******* DAY NIGHT MODE ***********
  handleAlignment = (event, newAlignment) => {
    // console.log(newAlignment)
    if (newAlignment === "left") {
      this.setState({
        alignment: newAlignment,
        theme: "light_mode",
        backgroundcolor1: "#FDB813",
        backgroundcolor2: "#fff",
      });
      localStorage.setItem("tradeMode", "light_mode");
    } else if (newAlignment === "right") {
      this.setState({
        alignment: newAlignment,
        theme: "dark_mode",
        backgroundcolor1: "#fff",
        backgroundcolor2: "#FDB813",
      });
      localStorage.setItem("tradeMode", "dark_mode");
    }
  };

  render() {
    // if (!this.props.match.params.coinCode) {
    //   return <Redirect to='/trade/BTC/USDT' />
    // }
    let openFavTab = false;
    // let openEthTab = false
    let openUsdtTab = true
    // let openInrTab = false
    // this.state.myfavcoin.map((d, idx) => {
    //   if (d.coin + d.pair == this.state.coinCode + this.state.pairCode) {
    //     openFavTab = true;
    //   }
    // });
    if (openFavTab == true) {
      
    }

    document.title = this.state.doctitle;
    // document.body.classList.add('faded_bg');
    if (this.state.theme == "dark_mode") {
      document.body.classList.remove("lightbackground");
      document.body.classList.add("darkbackground");
    } else {
      document.body.classList.remove("darkbackground");
      document.body.classList.add("lightbackground");
    }
    // console.log('All the props in trade.js', this.props);
    // console.log('All the state in trade.js', this.state);
    return (
      <div className={this.state.theme}>
        <Notifications />
        <Header />
        <section id="future_trade">
          <div className="container-fluid">
            <div className="row">
              <div className="trade_info clearfix">
                <div className="basic_top_bg clearfix">
                  <div className="row">
                    <div className="col-md-2 col-sm-2 col-xs-2 full">
                      <div className="trade_left">
                        <div className="dropdown">
                          {" "}
                          <a
                            href="#"
                            // className="dropdown-toggle"
                            // data-toggle="dropdown"
                            role="button"
                            // aria-haspopup="true"
                            // aria-expanded="false"
                             onClick={()=> this.setState({showPairlist: !this.state.showPairlist})}
                          >
                            <div style={{borderRight: '1px solid gray'}}>
                            <h6>
                              {this.state.coinCode}/{this.state.pairCode}{" "}
                            </h6>
                            <span className="caret"></span>
                            <div>Perpetual</div>
                            </div>
                            
                          </a>
                          {this.state.showPairlist && <ul
                            className="coinpair-list-popup"
                          >
                            <li className="" style={{ minWidth: 350 }}>
                              <div className="row">
                                <div className="trade_side_br col-md-12 col-sm-12 col-xs-12 full">
                                  <div
                                    role="tabpanel"
                                    className="tab-pane active"
                                    id="token"
                                  >
                                    <ul className="nav nav-tabs" role="tablist">
                                      <li
                                        className={openFavTab ? "active" : ""}
                                        style={{
                                          width: "22%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <a
                                          href="#Fab"
                                          onClick={() =>
                                            this.getCoinPairAll("USDT")
                                          }
                                          aria-controls="new"
                                          role="tab"
                                          data-toggle="tab"
                                          aria-expanded={true}
                                        >
                                          Favorites
                                        </a>
                                      </li>
                                      <li
                                        className={openUsdtTab ? "active" : ""}
                                        style={{
                                          width: "22%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <a
                                          href="#USDT"
                                          onClick={() =>
                                            this.getCoinPairAll("USDT")
                                          }
                                          aria-controls="ent"
                                          role="tab"
                                          data-toggle="tab"
                                          aria-expanded={false}
                                        >
                                          USDT
                                        </a>
                                      </li>
                                    </ul>
                                    {/* <div className="trade_search clearfix">
                        <input type="text" placeholder="Search" />
                      </div> */}
                                    <div className="tab-content scroller">
                                      {/* <!-- tab1 start --> */}
                                      <div
                                        role="tabpanel"
                                        className={
                                          openUsdtTab
                                            ? "tab-pane active"
                                            : "tab-pane"
                                        }
                                        id="USDT"
                                      >
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                              <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Fav
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Pair
                                                </th>
                                                <th
                                                  style={{ textAlign: "right" }}
                                                >
                                                  Price
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Chg
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.allCoinPairsData.map(
                                                (d, idx) =>
                                                  // d.active == true &&
                                                  // d.tActive == true &&
                                                   (
                                                    <tr
                                                      key={idx}
                                                      style={{
                                                        borderBottom:
                                                          "1px solid rgba(255,255,255,0.1)",
                                                        cursor: "pointer",
                                                      }}
                                                      className="coinhover p-2"
                                                    >
                                                      <td>
                                                     
                                                     {this.state.myfavcoin && this.state.myfavcoin[d.primaryCoin+ d.secondaryCoin] ?
                                                      <img onClick={()=>{this.addFavCoin(d.primaryCoin, d.secondaryCoin, false)}} src="/images/star-filled.png" alt="" width="15px"/>
                                                     :
                                                      <img onClick={()=>{
                                                        this.addFavCoin(d.primaryCoin, d.secondaryCoin, true)
                                                        }} src="/images/star.png" alt="" width="15px"/>
                                                     }
                                                      </td>
                                                      <td
                                                        style={{
                                                          padding: "10px 5px",
                                                          fontSize: "11px",
                                                        }}
                                                        onClick={(e) => {
                                                          window.location.href =
                                                            "/futures/trade/" +
                                                            d.primaryCoin +
                                                            "/USDT";
                                                        }}
                                                      >
                                                        {d.primaryCoin}/USDT
                                                      </td>
                                                      <td
                                                        style={{
                                                          padding: "10px 5px",
                                                          fontSize: "11px",
                                                          textAlign: "right",
                                                          fontWeight: "500",
                                                        }}
                                                        className={
                                                          d.primaryCoin +
                                                          "USDT_priceColor"
                                                        }
                                                        onClick={(e) => {
                                                          window.location.href =
                                                            "/futures/trade/" +
                                                            d.primaryCoin +
                                                            "/USDT";
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            d.primaryCoin +
                                                            "USDT_price"
                                                          }
                                                        >
                                                          0.000000{" "}
                                                        </span>
                                                      </td>
                                                      <td
                                                        style={{
                                                          padding: "10px 5px",
                                                          fontSize: "11px",
                                                        }}
                                                        className={
                                                          d.primaryCoin +
                                                          "USDT_priceChangePercentColor"
                                                        }
                                                        onClick={(e) => {
                                                          window.location.href =
                                                            "/futures/trade/" +
                                                            d.primaryCoin +
                                                            "/USDT";
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            d.primaryCoin +
                                                            "USDT_priceChangePercent"
                                                          }
                                                        >
                                                          0.00
                                                        </span>
                                                        %
                                                      </td>
                                                    </tr>
                                                  )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      {/* <!-- tab1 end -->  */}

                                      {/* <!-- tab2 start --> */}

                                      <div
                                        role="tabpanel"
                                        className={
                                          openFavTab == true
                                            ? "tab-pane active"
                                            : "tab-pane"
                                        }
                                        id="Fab"
                                      >
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Pair
                                                </th>
                                                <th
                                                  style={{ textAlign: "right" }}
                                                >
                                                  Price
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Chg
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.allCoinPairsData.filter(f=> this.state.myfavcoin && this.state.myfavcoin[f.primaryCoin+ f.secondaryCoin]).map(
                                                (d, idx) =>
                                                  // d.active == true &&
                                                  // d.tActive == true &&
                                                   (
                                                    <>
                                                   {/* {this.state.myfavcoin && this.state.myfavcoin[d.primaryCoin+ d.secondaryCoin] &&  */}
                                                   <tr
                                                      key={idx}
                                                      style={{
                                                        borderBottom:
                                                          "1px solid rgba(255,255,255,0.1)",
                                                        cursor: "pointer",
                                                      }}
                                                      className="coinhover p-2"
                                                    >
                                                      <img onClick={()=>this.addFavCoin(d.primaryCoin, d.secondaryCoin, false)} src="/images/star-filled.png" alt="" width="15px"/>
                                                      <td
                                                        style={{
                                                          padding: "10px 5px",
                                                          fontSize: "11px",
                                                        }}
                                                        onClick={(e) => {
                                                          window.location.href =
                                                            "/futures/trade/" +
                                                            d.primaryCoin +
                                                            "/USDT";
                                                        }}
                                                      >
                                                        {d.primaryCoin}/USDT
                                                      </td>
                                                      <td
                                                        style={{
                                                          padding: "10px 5px",
                                                          fontSize: "11px",
                                                          textAlign: "right",
                                                          fontWeight: "500",
                                                        }}
                                                        className={
                                                          d.primaryCoin +
                                                          "USDT_priceColor"
                                                        }
                                                        onClick={(e) => {
                                                          window.location.href =
                                                            "/futures/trade/" +
                                                            d.primaryCoin +
                                                            "/USDT";
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            d.primaryCoin +
                                                            "USDT_price"
                                                          }
                                                        >
                                                          0.000000{" "}
                                                        </span>
                                                      </td>
                                                      <td
                                                        style={{
                                                          padding: "10px 5px",
                                                          fontSize: "11px",
                                                        }}
                                                        className={
                                                          d.primaryCoin +
                                                          "USDT_priceChangePercentColor"
                                                        }
                                                        onClick={(e) => {
                                                          window.location.href =
                                                            "/futures/trade/" +
                                                            d.primaryCoin +
                                                            "/USDT";
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            d.primaryCoin +
                                                            "USDT_priceChangePercent"
                                                          }
                                                        >
                                                          0.00
                                                        </span>
                                                        %
                                                      </td>
                                                    </tr>
                                                    </>
                                                  )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      {/* <!-- tab2 end -->  */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>}
                        </div>
                        
                      </div>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1 full">
                          <span>
                              <small
                                style={{ marginLeft: "0px", fontSize: "18px" }}
                                className={
                                  this.state.coinCode +
                                  this.state.pairCode +
                                  "_price"
                                }
                              >
                                0.000000
                              </small>
                            </span>
                        </div>
                    <div className="col-md-9 col-sm-9 col-xs-9 full">
                      <div className="trade_right text-right">
                        <ul>
                          <li style={{ flexDirection: "column" }}>
                            <span>Mark Price</span>
                            <span
                              className={
                                this.state.coinCode +
                                this.state.pairCode +
                                "_priceChangePercentColor"
                              }
                            >
                              {" "}
                              <span
                                style={{ display: "inline" }}
                                className={`${
                                  this.state.coinCode +
                                  this.state.pairCode +
                                  "_priceChangePercent"
                                } ${
                                  this.state.coinCode +
                                  this.state.pairCode +
                                  "_priceChangePercentColor"
                                }`}
                              >
                                {this.state.change24H || 0.0}
                              </span>
                              %{" "}
                            </span>
                          </li>
                          <li style={{ flexDirection: "column" }}>
                            <span>Index Price</span>
                            <span
                              className={
                                this.state.coinCode +
                                this.state.pairCode +
                                "_priceChangePercentColor"
                              }
                            >
                              {" "}
                              <span
                                style={{ display: "inline" }}
                                className={`${
                                  this.state.coinCode +
                                  this.state.pairCode +
                                  "_priceChangePercent"
                                } ${
                                  this.state.coinCode +
                                  this.state.pairCode +
                                  "_priceChangePercentColor"
                                }`}
                              >
                                {this.state.change24H || 0.0}
                              </span>
                              %{" "}
                            </span>
                          </li>
                          <li style={{ flexDirection: "column" }}>
                            <span>Funding/Countdown</span>
                            <span
                              className={
                                this.state.coinCode +
                                this.state.pairCode +
                                "_priceChangePercentColor"
                              }
                            >
                              {" "}
                              <span
                                style={{ display: "inline" }}
                                className={`${
                                  this.state.coinCode +
                                  this.state.pairCode +
                                  "_priceChangePercent"
                                } ${
                                  this.state.coinCode +
                                  this.state.pairCode +
                                  "_priceChangePercentColor"
                                }`}
                              >
                                {this.state.change24H || 0.0}
                              </span>
                              %{" "}
                            </span>
                          </li>
                          <li style={{ flexDirection: "column" }}>
                            <span>24H Change</span>
                            {/* <div className="red_clr">Change -0.3%</div> */}
                            <span
                              className={
                                this.state.coinCode +
                                this.state.pairCode +
                                "_priceChangePercentColor"
                              }
                            >
                              {" "}
                              <span
                                style={{ display: "inline" }}
                                className={`${
                                  this.state.coinCode +
                                  this.state.pairCode +
                                  "_priceChangePercent"
                                } ${
                                  this.state.coinCode +
                                  this.state.pairCode +
                                  "_priceChangePercentColor"
                                }`}
                              >
                                {this.state.change24H || 0.0}
                              </span>
                              %{" "}
                            </span>
                          </li>
                          <li style={{ flexDirection: "column" }}>
                            <span>24H High</span>
                            {/* 0.00001580{" "} */}
                            <div
                              className={
                                this.state.coinCode +
                                this.state.pairCode +
                                "_priceHigh"
                              }
                            >
                              {this.state.changeHigh || 0}
                            </div>
                          </li>
                          <li style={{ flexDirection: "column" }}>
                            <span>24H Low</span>
                            {/* 0.00001580{" "} */}
                            <div
                              className={
                                this.state.coinCode +
                                this.state.pairCode +
                                "_priceLow"
                              }
                            >
                              {this.state.changeLow || 0}
                            </div>
                          </li>
                          <li style={{ flexDirection: "column" }}>
                            <span>24H Vol({this.state.coinCode})</span>
                            {/* 28,636.4 ETH */}
                            <div>
                              <span
                                style={{ display: "inline" }}
                                className={
                                  this.state.coinCode +
                                  this.state.pairCode +
                                  "_priceVolume"
                                }
                              >
                                {this.state.volume
                                  ? (+this.state.volume).toFixed(5)
                                  : "0.00"}
                              </span>
                            </div>
                          </li>
                          <li style={{ flexDirection: "column" }}>
                            <span>24H Turnover({this.state.pairCode})</span>
                            {/* 28,636.4 ETH */}
                            <div>
                              <span
                                style={{ display: "inline" }}
                                className={
                                  this.state.coinCode +
                                  this.state.pairCode +
                                  "_priceVolume"
                                }
                              >
                                {this.state.volume
                                  ? (+this.state.volume).toFixed(5)
                                  : "0.00"}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-9">
                    <div className="row">
                      {/* Chart div */}
                      <div className="col-md-8 col-sm-12 col-xs-12 pl-2 pr-1 ">
                        <div
                          className="dark_map map_part col-lg-12 full"
                          style={{ height: "88vh" }}
                        >
                          {this.state.exchangeType == "binance" && (
                            <>
                              {/* {console.log('this.state.exchangeType1',this.state.exchangeType)} */}
                              {this.state.theme === "dark_mode" ? (
                                <>
                                  {/* <TradingViewWidget
      symbol={"BINANCE:" + this.state.coinCode + "" + this.state.pairCode}
      theme={Themes.LIGHT}
      // theme={Themes.LIGHT}
      style="1"
      locale="en"
      enable_publishing="false"
      allow_symbol_change=""
      hide_legend="true"
      toolbar_bg="#262D33"
      autosize
    /> */}
                                  <TVChartContainer
                                    symbol={
                                      "Binance:" +
                                      this.props.match.params.coinCode +
                                      "/" +
                                      this.props.match.params.pairCode
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  {/* <TradingViewWidget
      symbol={"BINANCE:" + this.state.coinCode + "" + this.state.pairCode}
      theme={Themes.LIGHT}
      style="1"
      locale="en"
      enable_publishing="false"
      allow_symbol_change=""
      hide_legend="true"
      toolbar_bg="#262D33"
      autosize
    />  */}
                                  <TVChartContainer
                                    symbol={
                                      "Binance:" +
                                      this.props.match.params.coinCode +
                                      "/" +
                                      this.props.match.params.pairCode
                                    }
                                  />
                                </>
                              )}
                            </>
                          )}
                          {this.state.exchangeType == "Bitallx" && (
                            <>
                              {this.state.theme === "dark_mode" ? (
                                <>
                                  <TVChartContainer
                                    symbol={
                                      "Bitallx:" +
                                      this.props.match.params.coinCode +
                                      "/" +
                                      this.props.match.params.pairCode
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <TVChartContainer
                                    symbol={
                                      "Bitallx:" +
                                      this.props.match.params.coinCode +
                                      "/" +
                                      this.props.match.params.pairCode
                                    }
                                  />
                                </>
                              )}
                            </>
                          )}
                          {/* </Helmet> */}
                          {/* <!-- TradingView Chart END -->  */}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-12 col-xs-12 px-1">
                    <div className="order_list">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="active">
                        <a href="#orderBook_id" role="tab" data-toggle="tab">
                          Order Book
                        </a>
                      </li>
                      <li>
                        <a href="#tradeHistory_id" role="tab" data-toggle="tab">
                         Market Orders
                        </a>
                      </li>
                    </ul>
                      <div className="tab-content">
                      <div role="tabpanel" className="active tab-pane" id="orderBook_id">
                          <div className="table-responsive ">
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th>Price (USDT)</th>
                                  <th>Size (lot(s))</th>
                                  <th>Total (lot(s))</th>
                                </tr>
                              </thead>
                              <tbody>
                                <td colSpan="3">
                                  <table style={{ width: "100%" }}>
                                    <OrderBookBid
                                      bids={this.state.depth.bids}
                                      coinName={this.state.coinCode}
                                      myorders={this.state.myorderPrice}
                                      handelTradeHistory={
                                        this.handelTradeHistory
                                      }
                                    />

                                    <tr>
                                      <td >
                                        <div
                                          style={{ fontSize: 20, padding: 2 }}
                                        >
                                          <span
                                            className={
                                              this.state.coinCode +
                                              this.state.pairCode +
                                              "_priceChangePercentColor"
                                            }
                                          >
                                            {" "}
                                            <span
                                              style={{ display: "inline" }}
                                              className={`${
                                                this.state.coinCode +
                                                this.state.pairCode +
                                                "_priceChangePercent"
                                              } ${
                                                this.state.coinCode +
                                                this.state.pairCode +
                                                "_priceChangePercentColor"
                                              }`}
                                            >
                                              {this.state.change24H || 0.0}
                                            </span>
                                            %{" "}
                                          </span>
                                        </div>
                                          
                                      </td>
                                      <td><span>Mark: 40,943.58</span></td>
                                    </tr>
                                    <OrderBookAsk
                                      asks={this.state.depth.asks}
                                      coinName={this.state.coinCode}
                                      myorders={this.state.myorderPrice}
                                      handelTradeHistory={
                                        this.handelTradeHistory
                                      }
                                    />
                                  </table>
                                </td>
                              </tbody>
                            </table>
                          </div>
                      </div>
                      <div role="tabpanel" className="tab-pane" id="tradeHistory_id">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>Price</th>
                              <th>Volume</th>
                              <th>Time</th>
                            </tr>
                          </thead>

                          <tbody id="tradehistorytable">
                            <TradeHistory
                              trades={this.state.trades}
                              coinName={this.state.coinCode}
                              handelTradeHistory={this.handelTradeHistory}
                            />
                          </tbody>
                        </table>
                      </div>
                      </div>
                      </div>
                    </div>
                     </div>
                     <div className="row ico_detail">
                    <div className="col-md-12 col-sm-12 col-xs-12 order_his">
                     <UserOrderList 
                     openOrderrows={this.state.openOrderrows}
                     currentCoinPairDecimalData={this.state.currentCoinPairDecimalData}
                     historyOrderrows={this.state.historyOrderrows}
                    //  getFormatedTime={this.getFormatedTime}
                     handleRemoveRowOpenOrder={this.handleRemoveRowOpenOrder}
                      />
                    </div>
                </div>

                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12 pl-1 pr2">
                <BuySell 
                state={this.state}
                handleChange={this.handleChange}
                buySellBalancePer={this.buySellBalancePer}
                // sellBalancePer={this.sellBalancePer}
                handleSubmitBuy={this.handleSubmitBuy}
                /> 
                  </div>
                </div>

               

                
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default FutureTrade;
