import React from 'react'
import BigNumber from 'bignumber.js'
import Axios from 'axios';
import { BASE_URL } from '../../../config/constant';
// import { decimalData } from './decimalDataForAllCoins'
var decimalData = "";

const OrderRow = (props) => (
    // background: linear-gradient(to right, #ff0004 0%,#ff0004 32%,#000000 32%);
    // {{width: `${new BigNumber(props.diff).minus(props.ba[0]).div(props.max).multipliedBy(100).toFormat(2)}%`}}
    <tr style={{cursor:"pointer",background: `linear-gradient(to right, rgba(255, 34, 126, 0.2) 0%, rgba(255, 34, 126, 0.2) ${new BigNumber(props.diff).minus(props.ba[0]).div(props.max).multipliedBy(100).toFormat(2) }%, transparent ${new BigNumber(props.diff).minus(props.ba[0]).div(props.max).multipliedBy(100).toFormat(2) }%)` }} onClick={() => props.handelTradeHistory(900,10,10)}>
        <td className="red_clr">{new BigNumber(props.ba[0]).toFormat(2,1)}</td> 
        <td>{new BigNumber(props.ba[1]).toFormat(6,1)}</td>
        <td style={{textAlign:"right"}}>{new BigNumber(props.ba[0]*props.ba[1]).toFormat(2,1)}</td>
    </tr>
)

const OrderBook = (props) => {
    if(props.coinName){
        if(decimalData == ""){
            // Axios.get(BASE_URL+"coinpair/getall").then((res)=>{
            //     if (res.data && res.data.statusCode === 200) {
            //             let a  = res.data.data.find((data)=>  data.primaryCoin == props.coinName )
            //             decimalData = Number(a.priceDecimal)
            //     }
            // })
            if(sessionStorage.getItem('currentCoinDecimalValue')){
                decimalData = Number(sessionStorage.getItem('currentCoinDecimalValue'))
            }else{
                decimalData = ""
            }
        }        
}

    if(props.asks) {
        // console.log(props.bids)
    
        // let bids = [];
        let asks = [];
        // let numRowsBid = Math.min(20, props.bids.length);
        let numRowsAsk = Math.min(10, props.asks.length);
        // let maxBid = BigNumber.maximum(props.bids.map(bid => bid[0])).toFormat()
        let minAsk = Math.min.apply(0, props.asks.map(ask => Number(ask[0]) ))
        // let minAsk = BigNumber.minimum(props.asks.map(ask => ask[0])).toFormat()
        // let minBid = new BigNumber(maxBid).minus(BigNumber.minimum(props.bids.map(bid => bid[0]))).toFormat()

        let maxAsk = new BigNumber(minAsk).minus(Math.max.apply(0, props.asks.map(ask => Number(ask[0]) ))).toFormat()
        // let maxAsk = Math.max.apply(0, props.asks.map(ask => Number(ask[0]) ))
        // for (var b = 0; b < numRowsBid; b++) { 
        //     bids.push(
        //         <OrderRow i={b} ba={props.bids[b]} diff={maxBid} max={minBid} class="bg-success" />         
        //     )
        // // }
        // console.log("BGPERss", minAsk , maxAsk, props.asks[0][0] )
        // console.log("BGPERss", `${new BigNumber(minAsk).minus(props.asks[0][0]).div(maxAsk).multipliedBy(100).toFormat(2)}` )
        
  
        for (var a = 0; a < numRowsAsk; a++) {
            let price =props.asks[a][0]
            let amount =props.asks[a][1]
            let mypriceicon
            {props.myorders.map(rowmy => {
                if(Number(rowmy) == Number(price)) {
                    mypriceicon = <span style={{color:"gold",position:"absolute",left:"-3px"}}>&#9658;</span>
                }
                 
            })}
            asks.push( 
                // <OrderRow i={a} ba={props.asks[a]} diff={minAsk} max={maxAsk}  class="bg-danger" />  
                
                <tr key={`${a}:${props.asks[a][0]}:${props.asks[a][1]}`} style={{cursor:"pointer",background: `linear-gradient(to right, rgba(255, 34, 126, 0.2) 0%, rgba(255, 34, 126, 0.2) ${new BigNumber(minAsk).minus(props.asks[a][0]).div(maxAsk).multipliedBy(100).toFormat(2) }%, transparent ${new BigNumber(minAsk).minus(props.asks[a][0]).div(maxAsk).multipliedBy(100).toFormat(2) }%)` }} onClick={() => props.handelTradeHistory(price,amount,"Buy")}>
                    <td className="red_clr" style={{position:"relative",width:"50%",padding:"0px 8px"}}>
                        {mypriceicon}
                         
                        {new BigNumber(props.asks[a][0]).toFormat(decimalData,1)}</td> 
                    <td style={{position:"relative",width:"50%",padding:"0px 8px"}}>{new BigNumber(props.asks[a][1]).toFormat(4,1)}</td>
                    {/* <td style={{textAlign:"right"}}>{new BigNumber(props.asks[a][0]*props.asks[a][1]).toFormat(2,1)}</td> */}
                </tr>
 
            )

            // console.log("BGPERss",remainingPer)
            
        // console.log("BGPERss", `${new BigNumber(minAsk).minus(props.asks[a][0]).div(maxAsk).multipliedBy(100).toFormat(2)}%` )

        }
    // console.log(items);
        return (
                    <>
                    {asks} 
                    {/* {bids} */}
                    </>
               
        )
    } else {
        return (
            <tbody><tr><td style={{textAlign:'center'}} colSpan={3}>No Order data</td></tr></tbody>
        )
    }
} 


export default OrderBook;