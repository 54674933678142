import axios from 'axios';
import { BASE_URL } from "../../../../config/constant";
let request = 0;

// Make requests to CryptoCompare API
export async function makeApiRequest(path) {
	try {
		if(!path.coinName){
			const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
			return response.json();
		}else{
			if(request == 0){
				request = 1;
				const response = await axios.post(BASE_URL+'get/OHLC',{
					coinName: path.coinName,
					pair: path.pair,
					limit:1000
				})
				return {Data:response.data.data}
			}
		}
		
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}
