
export const BASE_URL = "https://api.bitallx.com/";  //current
// export const BASE_URL = 'http://localhost:2087/';

/**
 * This Url is Bitallx-ADMIN_BACKEND Url, using only for fetcing Tokens Icon
 */
export const TOKEN_ICON_URL = 'https://adminapi.bitallx.com/coinicon/'; 

export const TEST_SITE_KEY = "6LeDCJgaAAAAAH_Rgyi0bg_rzJiwzf6qJzhnr3Tz"; //Prod
// export const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"; //Dev