import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { BASE_URL, TOKEN_ICON_URL } from "../../config/constant";
import Notifications, {notify} from 'react-notify-toast';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import BigNumber from 'bignumber.js'
import TransferModal from '../Trade/FutureTrade/transfer/transfer.modal';
var id;
var tableFields = [];
var fessAmt = 0;
var fessUsdValue = 0;
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            tokenData: [],
            userData: [],
            inorderdata: [],
            withdrawPending: [],
            loadFeeSubs: false,
            coinBalance: {},
            FESSstakeamt: 0,
            btcusdtPrice: "0",
            totalBalanceValueInBTC : 0,
            totalWithdrawValueInBTC : 0,
            totalBalanceValueInUSDT: 0,
            totalWithdrawArray : [],
            balanceLoading: true,
            fessData: [],
            usdFessAmt: 0,
            stakeDetails:{},
            BTXT_USDT_price:0,
            showTransferModal: false,
            clickedCoin: null,
        };
    }

    async componentDidMount() {
        if(!localStorage.getItem('userDetails')) {
          this.props.history.push('/login')
        } else {
            const userId=localStorage.getItem('userDetails');
            this.setState({ userId: localStorage.getItem('userDetails') })
            // axios.post(BASE_URL + `userRefferal/Script`,{userId:userId}).then(e=>{})
            // await axios.get(BASE_URL + `/FESS/price`)
            // .then(res => {
            //     let dataByDigi = JSON.parse(res.data.data)
            //     // console.log("Data: ", dataByDigi.data.ticker.usdt_fess.last)
            //     let val = Number(dataByDigi.data.ticker.usdt_fess.last.toFixed(6))
            //         this.setState({ fessData: dataByDigi.data.ticker.usdt_fess.last })
            // })
        }
        
       

        // console.log("Response2",this.state.userId) 
    }

    async componentWillMount() {
        id = localStorage.getItem('userDetails');
        console.log("User id: ", id);
        await this.getBTXT_USDTPrice()
        this.tableData()
        this.getStakeDetails();
    }
    async getBTXT_USDTPrice(){
        axios.get(`${BASE_URL}coinpair/getall`).then((res)=>{
            if (res.data && res.data.statusCode === 200) {
                const currentPair = res.data.data.find(e=> e.primaryCoin.toUpperCase() === "BTXT" && e.secondaryCoin.toUpperCase() === "USDT")
                const BTXT_USDT = currentPair?.price
                this.setState({BTXT_USDT_price: BTXT_USDT})
      }
      })
    }
    tableData = async () => {
        await axios.get(BASE_URL + `ercToken/get/all`)
        .then(res => {
            // console.log(res);
            if (res.data.message === "success") {
                let popElement = res.data.data[10]
                // console.log("In render: ", popElement);
                res.data.data.unshift(popElement)
                res.data.data.splice(11,1) 
                this.setState({ tokenData: res.data.data })

                {this.state.tokenData.map((d,idx) => {
                    if(d.tokenSymbol === "FESS") {
                        this.setState({FESSstakeamt: d.minstake})
                    }
                })}
                this.getInOrderValues();
            }
        })



        await axios.post(BASE_URL + `userInformation/get`,{
            "id": this.state.userId
        })
        .then(res => {
            // console.log(res);
            if (res.data.message === "success") {
                this.setState({ 
                    userData: res.data.data
                })

            }
        })


        await axios.post(BASE_URL + `withdrawal/gettotal`, {
            "userId": this.state.userId
        })
        .then(res => {
            // console.log("user id",this.state.userId)
            // console.log(res);
            if (res.data.message === "success") {
                this.setState({ totalWithdrawArray: res.data.data })
                // console.log("withdraw",res.data)
                
            }
        })

        await axios.post(BASE_URL + `userBalance/get`, {
            "userId": this.state.userId
        })
        .then(res => {
            // console.log("user id",this.state.userId)
            // console.log(res);
            if (res.data.message === "success") {
                this.setState({ coinBalance: res.data.data }, function(){
                    this.state.tokenData.map((d) => {
                        if(!(this.state.coinBalance.hasOwnProperty([d.tokenSymbol + "_lock"]))){
                            var name = d.tokenSymbol + "_lock"
                          res.data.data[name] = 0
                        }
                    })
                })
                console.log(this.state.coinBalance)
                
                fessAmt = new BigNumber(this.state.coinBalance['FESS']  * this.state.fessData)
                if(Number(this.state.coinBalance['FESS']) >= Number(this.state.FESSstakeamt)) {
                 
                    this.setState({loadFeeSubs:true, usdFessAmt: fessAmt})
                }
                if(Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
                    this.setState({loadFeeSubs:true})
                }
                
            }
        })

        await axios({
            method: 'get',
            url: `${BASE_URL}coinpair/getall`
          })
          .then(response => { 
            // console.log("coinbalance",response)
            let totalBtc=0;
            let totalUsdt = 0;
            this.state.tokenData.forEach(tokenDetails => {
                //    const btcRow = response.data.data.find(cp => (tokenDetails.tokenSymbol === cp.primaryCoin.toUpperCase()) && cp.secondaryCoin.toUpperCase() === "BTC")
                //    const btc = Number(this.state.coinBalance[tokenDetails.tokenSymbol]);
                //     const btcAmount = btc && btcRow.price ? btc * btcRow.price :0
                //     totalBtc+=btcAmount

                    const usdtRow = response.data.data.find(cp => (tokenDetails.tokenSymbol === cp.primaryCoin.toUpperCase()) && (cp.secondaryCoin.toUpperCase() === "USDT"))
                    const usdt = Number(this.state.coinBalance[tokenDetails.tokenSymbol])+ (this.state.coinBalance[tokenDetails.tokenSymbol + "_lock"]);
                     const usdtAmount = usdt && usdtRow?.price ? usdt * usdtRow?.price :0

                     totalUsdt+=usdtAmount
                   if(usdtRow?.primaryCoin === "BTC"){
                    this.setState({btcusdtPrice : Number(usdtRow.price)})
                   }
                   if(tokenDetails.tokenSymbol === "USDT"){
                    totalUsdt+=Number(this.state.coinBalance["USDT"])
                   }
                // let currentSymbol = row.primaryCoin.toUpperCase()+row.secondaryCoin.toUpperCase()
            //     if(currentSymbol === "BTCUSDT") {
            //         this.setState({btcusdtPrice : Number(row.price)})
            //         const bal_btc =  Number(Number(this.state.totalBalanceValueInBTC)+Number(this.state.coinBalance["BTC"])).toFixed(8);
            //         this.setState({totalBalanceValueInBTC : bal_btc});
            //         const lock_btc = Number(Number(this.state.totalBalanceValueInBTC)+Number(this.state.coinBalance["BTC_lock"])).toFixed(8)
            //         this.setState({totalBalanceValueInBTC : lock_btc})
            //         const bal_usdt =  Number(Number(this.state.totalBalanceValueInBTC)+Number(this.state.coinBalance["USDT"] / row.price)).toFixed(8);
            //         this.setState({totalBalanceValueInBTC : bal_usdt})
            //         const lock_usdt = Number(Number(this.state.totalBalanceValueInBTC)+Number(this.state.coinBalance["USDT_lock"] / row.price)).toFixed(8);
            //         this.setState({totalBalanceValueInBTC : lock_usdt})
            //         // console.log('BTCUSDT',bal_btc,lock_btc,bal_usdt,lock_usdt);
                   
            //         const totalBTXTinUSDT = this.state.BTXT_USDT_price * Number(this.state.coinBalance["BTXT"] || 0 )
            //         const btxtInBTC = totalBTXTinUSDT / row.price
            //         this.setState({totalBalanceValueInBTC : (+this.state.totalBalanceValueInBTC) + btxtInBTC})

            //         this.state.totalWithdrawArray.map(rowW => {
            //             if(rowW._id === "BTC") {
            //                 const btc = Number(Number(this.state.totalWithdrawValueInBTC)+Number(rowW.amountSum)).toFixed(8)
            //                 this.setState({totalWithdrawValueInBTC : btc})
            //                 // console.log("btc",btc)
            //             }
            //         })
            //     }
            // //  console.log("coinbalanceBTCTOTAL",this.state.tokenData)

            //     this.state.tokenData.map(tokenrow => {
            //         if(tokenrow.tokenSymbol === 'BTT' || tokenrow.tokenSymbol === 'SHIB'){
            //             if(tokenrow.tokenSymbol+"USDT" === currentSymbol){
            //                 const coinBal = Number(this.state.coinBalance[tokenrow.tokenSymbol]);
                                                
            //                 const bal = coinBal?  Number(Number(this.state.totalBalanceValueInBTC)+  Number(Number(this.state.coinBalance[tokenrow.tokenSymbol] * row.price)).toFixed(8)/this.state.btcusdtPrice.toFixed(6)) :this.state.totalBalanceValueInBTC;
            //                 // console.log(bal)
            //             this.setState({totalBalanceValueInBTC : bal})
                        
            //             if(this.state.coinBalance[tokenrow.tokenSymbol + "_lock"] > 0 ){
            //                 const bal1 = Number(Number(this.state.totalBalanceValueInBTC)+ Number(Number(this.state.coinBalance[tokenrow.tokenSymbol+"_lock"] * row.price)).toFixed(8)/this.state.btcusdtPrice.toFixed(6));
            //                 // console.log(bal)
            //                 this.setState({totalBalanceValueInBTC : bal1})
            //                 }
            //             }
            //         }
            //         else if(tokenrow.tokenSymbol+"BTC" === currentSymbol) {
            //         //    console.log(tokenrow.tokenSymbol+"BTC"  + " sachin here" + currentSymbol,row.price);
            //             // console.log(this.state.coinBalance);
            //             const coinBal = Number(this.state.coinBalance[tokenrow.tokenSymbol])
            //             const b1 = coinBal ? Number(Number(this.state.totalBalanceValueInBTC)+(coinBal * row.price)).toFixed(8) : this.state.totalBalanceValueInBTC;
            //             // console.log(b1)
            //             this.setState({totalBalanceValueInBTC : +b1})
            //             if(this.state.coinBalance[tokenrow.tokenSymbol + "_lock"] > 0 ){
            //                 const b2 = Number(Number(this.state.totalBalanceValueInBTC)+Number(this.state.coinBalance[tokenrow.tokenSymbol + "_lock"] * row.price)).toFixed(8)
            //                 // console.log(b2)
            //                 this.setState({totalBalanceValueInBTC : +b2})
            //                 }

            //         }

            //         // {this.state[d.tokenSymbol+"BTC_price"]}
                    
            //     })



            //     this.state.totalWithdrawArray.map(rowW => {
            //         if(rowW._id+"BTC" === currentSymbol) {
            //             this.setState({totalWithdrawValueInBTC : Number(Number(this.state.totalWithdrawValueInBTC)+Number(rowW.amountSum * row.price)).toFixed(6)})

            //             // console.log("withdraw",row.price)
            //             // console.log("withdraw",rowW.amountSum)
            //         }
            //     })


                
            })
            this.setState({totalBalanceValueInBTC: totalBtc, totalBalanceValueInUSDT: totalUsdt})
            this.setState({balanceLoading : false})
          })

          await axios.post(BASE_URL + `deposite/get/id`,{
            tokenSymbol: "TRX",
            userId: this.state.userId,
            userIDNo: 0
        })
        .then(async res => {
            // console.log(res);
            if (res.data.message === "success") {
                
                console.log("trx",res.data.data.walletAddress)

                await axios.post('https://coincdn.bitallx.com/trxadd',{
                // await axios.post('https://adminapi.bitallx.com:2053/trxadd',{
                    walletAddress: res.data.data.walletAddress,
                    userId: this.state.userId,
                })
                .then((e)=>{});

            }
        })

       

        // await axios.post('https://coincdn.bitallx.com:2083/xlmadd',{})
        // .then()

            // console.log("coinbalance",this.state.coinBalance["BTC"])

        // await this.state.tokenData.map((d) => {
        //     for (var key of Object.keys(this.state.coinBalance)) {
        //         if (d.tokenSymbol === key) {
        //             d["availableBalance"] = this.state.coinBalance[key]
        //         }
        //     }
        // })
        
        // console.log("Data: ", this.state.tokenData);
        
    }

    getInOrderValues = async() => {
        var withdrawData  = []
        await axios.post(BASE_URL + `inorder/withdraw`, {
            "userId": this.state.userId
        })
        .then(async (res) => {
            // if (res.data.message === "success") {
                withdrawData = res.data.data
                // console.log("Data: ", withdrawData)
                // this.setState({
                //     inorderdata : res.data.data
                // })
                // this.state.tokenData.push(res.data.data)
                // console.log(this.state.tokenData)
            // }
   

        await axios.post(BASE_URL + `inorder/open/order`, {
            "userId": this.state.userId
        })
        .then(res => {
            // if (res.data.message === "success") {
                console.log("Data2: ", res.data.data)
                let tableData = res.data.data

                Promise.all(this.state.tokenData.map((d) => {
                    // console.log("Data2: ", d)
                    if(tableData.hasOwnProperty([d.tokenSymbol+"BTC"])){
                        // console.log("Data2: ", d.tokenSymbol)
                        if(tableData[d.tokenSymbol+"BTC"].length > 0) {
                            tableData[d.tokenSymbol+"BTC"].map((e) => {
                                if(e._id === "Buy"){
                                    withdrawData["BTC"] += e.totalSum + withdrawData[d.tokenSymbol]
                                }
                                if(e._id === "Sell"){
                                    withdrawData[d.tokenSymbol] += e.amountSum + withdrawData[d.tokenSymbol]
                                }
                            })
                        }
                    } 

                    if(tableData.hasOwnProperty([d.tokenSymbol+"ETH"])){
                        // console.log("Data2: ", d.tokenSymbol)
                        if(tableData[d.tokenSymbol+"ETH"].length > 0) {
                            tableData[d.tokenSymbol+"ETH"].map((e) => {
                                if(e._id === "Buy"){
                                    withdrawData["ETH"] += e.totalSum + withdrawData[d.tokenSymbol]
                                }
                                if(e._id === "Sell"){
                                    withdrawData[d.tokenSymbol] += e.amountSum + withdrawData[d.tokenSymbol]
                                }
                            })
                        }
                    }

                    if(tableData.hasOwnProperty([d.tokenSymbol+"INR"])){
                        // console.log("Data2: ", d.tokenSymbol)
                        if(tableData[d.tokenSymbol+"INR"].length > 0) {
                            tableData[d.tokenSymbol+"INR"].map((e) => {
                                if(e._id === "Buy"){
                                    withdrawData["INR"] += e.totalSum + withdrawData[d.tokenSymbol]
                                }
                                if(e._id === "Sell"){
                                    withdrawData[d.tokenSymbol] += e.amountSum + withdrawData[d.tokenSymbol]
                                }
                            })
                        }
                    }


                    if(tableData.hasOwnProperty([d.tokenSymbol+"USDT"])){
                        // console.log("Data2: ", d.tokenSymbol)
                        if(tableData[d.tokenSymbol+"USDT"].length > 0) {
                            tableData[d.tokenSymbol+"USDT"].map((e) => {
                        console.log("Data2: ", e)

                                if(e._id === "Buy"){
                                    withdrawData["USDT"] += e.totalSum + withdrawData[d.tokenSymbol]
                                }
                                if(e._id === "Sell"){
                                    withdrawData[d.tokenSymbol] += e.amountSum + withdrawData[d.tokenSymbol]
                                }
                            })
                        }
                    }

                        // console.log("Data2: ", this.state.inorderdata)


                }))


                this.setState({
                    inorderdata : withdrawData
                })
                // this.state.tokenData.map((d) => {
                //     if(tableData.hasOwnProperty([d.tokenSymbol+"BTC"])){
                //     if(tableData[d.tokenSymbol+"BTC"].length > 0) {
                //     tableData[d.tokenSymbol+"BTC"].map((e) => {
                //         if(d.tokenSymbol === e._id){
                //             withdrawData[d.tokenSymbol] = e.amountSum + withdrawData[d.tokenSymbol]
                //         }
                //     })
                //      }
                // }

                // if(tableData.hasOwnProperty([d.tokenSymbol+"ETH"])){
                //     if(tableData[d.tokenSymbol+"ETH"].length > 0) {
                //     tableData[d.tokenSymbol+"ETH"].map((e) => {
                //         if(d.tokenSymbol === e._id){
                //             withdrawData[d.tokenSymbol] = e.amountSum + withdrawData[d.tokenSymbol]
                //         }
                //     })
                //      }
                // }

                // if(tableData.hasOwnProperty([d.tokenSymbol+"USDT"])){
                //     if(tableData[d.tokenSymbol+"USDT"].length > 0) {
                //     tableData[d.tokenSymbol+"USDT"].map((e) => {
                //         if(d.tokenSymbol === e._id){
                //             withdrawData[d.tokenSymbol] = e.amountSum + withdrawData[d.tokenSymbol]
                //         }
                //     })
                //      }
                // }

                // if(tableData.hasOwnProperty([d.tokenSymbol+"INR"])){
                //     if(tableData[d.tokenSymbol+"INR"].length > 0) {
                //     tableData[d.tokenSymbol+"INR"].map((e) => {
                //         if(d.tokenSymbol === e._id){
                //             withdrawData[d.tokenSymbol] = e.amountSum + withdrawData[d.tokenSymbol]
                //         }
                //     })
                //      }
                // }
                // })
                
        })
    })
    }
getStakeDetails = async()=>{
    await axios.post(BASE_URL + `Staking/getUserDashboard2`, {
        "userId": localStorage.getItem('userDetails')
    })
    .then(async (res) => {
        if (res.data.statusCode == 200) {
            this.setState({stakeDetails:res.data.data})
            console.log('res.data.data',res.data.data)
        }
    })
}
closeTransferModal = () => {
    this.setState({showTransferModal: false})
    this.tableData()
  };
  openTransferModal = () => {
    this.setState({showTransferModal: true})
  };
    render() {
        // console.log("In order render: ", this.state.inorderdata)
    //  var fessUsdValue = new BigNumber(Number(Number(this.state.totalBalanceValueInBTC) * this.state.btcusdtPrice) + Number(fessAmt))
        document.title = "Dashboard";
        document.body.classList.add('faded_bg');
        // fessUsdValue = Number(Number(fessAmt / this.state.btcusdtPrice).toFixed(6))
        // fessUsdValue = Number(Number(fessUsdValue).toFixed(6)) + Number(this.state.totalBalanceValueInBTC)
        // console.log("In render: ", Number((fessUsdValue).toFixed(6)) + Number(this.state.totalBalanceValueInBTC));

        return (
            <div>
                <Notifications />
                <Header  loadFees={this.state.loadFeeSubs}/>
                <TransferModal
                    show={this.state.showTransferModal}
                    handleClose={this.closeTransferModal}
                    coin={this.state.clickedCoin}
                    sourceEx={"Spot Wallet"}
                    targetEx={"Future Wallet"}
                />
                <section id="middel_area">
                <img src="images/bg_shape1.png" alt="img" className="shape1" />
                <img src="images/bg_shape2.png" alt="img" className="shape2" />
                    <div className="container">
                        <div className="row">
                        <div class="col-sm-12 area_left">

                                <Sidebar  loadFees={this.state.loadFeeSubs}/>
                            </div>

                            <div className="col-sm-12 area_right">
                                <div className="clearfix assets_part">
                                    <div className="dashboard_top_info row">
                                    <div className="col-md-4 col-sm-6 col-xs-6 full">
                                            <div className="white_bx clearfix">                                                
                                                <div className="asset_info">
                                                    <h6>Estimated Value</h6>
                                                    <p style={{whiteSpace:'nowrap', color: "green"}}><span>{new BigNumber(this.state.totalBalanceValueInUSDT / this.state.btcusdtPrice).toFormat(6,1)} BTC</span> ≈ {new BigNumber(this.state.totalBalanceValueInUSDT).toFormat(2,1) } USDT</p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xs-6 full">
                                            <div className="white_bx clearfix">                                                
                                                <div className="asset_info">
                                                    <h6>Team Staking Business</h6>
                                                    <p><span>{isNaN(this.state.stakeDetails?.totalBusiness)  ?'0.00':(+this.state.stakeDetails?.totalBusiness).toFixed(4)}</span></p>
                                                </div>
                                                <div className="view_btn">
                                                    <a href="#"><i class="fa fa-eye"></i> View</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xs-6 full">
                                            <div className="white_bx clearfix">                                                
                                                <div className="asset_info">
                                                    <h6>My Staking Business</h6>
                                                    <p><span>{isNaN(this.state.stakeDetails?.totalStakingOfUser) ? '0.00':(+this.state.stakeDetails?.totalStakingOfUser).toFixed(4)}</span></p>
                                                </div>
                                                <div className="view_btn">
                                                    <a href="#"><i class="fa fa-eye"></i> View</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xs-6 full">
                                            <div className="white_bx clearfix">                                                
                                                <div className="asset_info">
                                                    <h6>My Staking Bonus</h6>
                                                    <p><span>{isNaN(this.state.stakeDetails?.myStakingBonus) ? '0.00' :(+this.state.stakeDetails?.myStakingBonus).toFixed(4) }</span></p>
                                                </div>
                                                <div className="view_btn">
                                                    <a href="#"><i class="fa fa-eye"></i> View</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xs-6 full">
                                            <div className="white_bx clearfix">                                                
                                                <div className="asset_info">
                                                    <h6>Team Staking Bonus</h6>
                                                    <p><span>{isNaN(this.state.stakeDetails?.teamStakingBonus)  ? '0.00' :(+this.state.stakeDetails?.teamStakingBonus).toFixed(4)}</span></p>
                                                </div>
                                                <div className="view_btn">
                                                    <a href="#"><i class="fa fa-eye"></i> View</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-xs-6 full">
                                            <div className="white_bx clearfix">                                                
                                                <div className="asset_info">
                                                    <h6>Refferal Bonus</h6>
                                                    <p><span>{isNaN(this.state.stakeDetails?.referralBonus) ? '0.00': (+this.state.stakeDetails?.referralBonus).toFixed(4)}</span></p>
                                                </div>
                                                <div className="view_btn">
                                                    <a href="#"><i class="fa fa-eye"></i> View</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="clearfix assets_part">
                                    <div className="dashboard_top_info row">
                                        <div className="col-md-6">
                                            <div className="light_bg clearfix">
                                                <div className="dash_icon"><i className="fa fa-user"></i></div>
                                                <div className="asset_info" style={{width:"90%"}}>
                                                    <h5 style={{fontWeight:"300",fontSize:"13px",lineHeight:"18px"}}>Your Referral Link</h5>
                                                    <p>
                                                    <input type="text" className="form-control" style={{background:"#fff"}} name="extratag"  value={"https://bitallx.com/invite/"+this.state.userData.refCode} readOnly noValidate/>    
                                                    <CopyToClipboard
                                                        style={{marginTop:"10px"}}
                                                        className="btn btn-success"
                                                        text={"https://bitallx.com/invite/"+this.state.userData.refCode}
                                                        onCopy={() => { notify.hide(); notify.show("Referral Link Copied!", "success")}}>
                                                        <a href="#" className="btn btn-success"><i className="fa fa-files-o"></i> Copy Invite Link</a>
                                                    </CopyToClipboard>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="light_bg clearfix">
                                                <div className="dash_icon"><i className="fa fa-user"></i></div>
                                                <div className="asset_info" style={{width:"90%"}}>
                                                    <h5 style={{fontWeight:"300",fontSize:"13px",lineHeight:"18px"}}>Your Referral Code</h5>
                                                    <p>
                                                    <input type="text" className="form-control" style={{background:"#fff"}} name="extratag"  value={this.state.userData.refCode} readOnly noValidate/>    
                                                    <CopyToClipboard
                                                        style={{marginTop:"10px"}}
                                                        className="btn btn-success"
                                                        text={this.state.userData.refCode}
                                                        onCopy={() => { notify.hide(); notify.show("Referral Code Copied!", "success")}}>
                                                        <a href="#" className="btn btn-success"><i className="fa fa-files-o"></i> Copy Invite Code</a>
                                                    </CopyToClipboard>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}

                                <div className="coin_list">
                                    <br />
                                    <h4>List of coins</h4>
                                <div className="row">
                                   {this.state.tokenData.sort(function(x,y){ return x.tokenSymbol == "USDT" ? -1 : y.tokenSymbol == "USDT" ? 1 : 0; }).map((d, xid) => (

d.active === true && <div className="col-md-3 col-sm-6 col-xs-6 full " style={{padding: 1}}>
                                        <div className="outer_bx">
                                            <div className="coin_bx">
                                        <img src={TOKEN_ICON_URL + d.tokenSymbol + ".png"} width="60px" height="60px" alt="" />
                                            <div className="coin_info">
                                                <h6>{d.tokenSymbol} ({d.tokenName})</h6>
                                                <p style={{color:"green"}}><strong>Live Bal.</strong> <span>{Number(this.state.coinBalance[d.tokenSymbol]).toFixed(4) >= 0 ? Number(this.state.coinBalance[d.tokenSymbol]).toFixed(10).slice(0, -6) : 0.000000}</span></p>
                                                <p style={{color:"red"}}><strong>Locked Bal.</strong> <span>{Number( Number(this.state.coinBalance[d.tokenSymbol + "_lock"]) + Number(this.state.inorderdata[d.tokenSymbol])).toFixed(4) >= 0 ? Number( Number(this.state.coinBalance[d.tokenSymbol + "_lock"]) + Number(this.state.inorderdata[d.tokenSymbol])).toFixed(10).slice(0, -6) : 0.000000}</span></p>
                                                <p><strong>Total</strong> <span>{Number(Number(this.state.coinBalance[d.tokenSymbol]) + Number(this.state.coinBalance[d.tokenSymbol + "_lock"]) + Number(this.state.inorderdata[d.tokenSymbol])).toFixed(10).slice(0, -6)}</span></p>
                                            </div>
                                            </div>
                                           <hr />
                                            <div className="action_btn text-center">
                                                
                                            {d.dActive === true ?
                                                                            <a href={'/deposit/' + d.tokenSymbol} className="outline-btn" style={{ padding: '0px 5px' }}>Deposit</a>
                                                                            :
                                                                            <a href="#" onClick={() => { notify.hide(); notify.show("Deposit is temporarily disabled for this coin", "warning")}} className="outline-btn disabled" style={{ padding: '0px 5px' }}>Deposit</a>
                                                                        }
                                                                                                      {d.wActive === true ?
                                                                            <a href={'/withdraw/' + d.tokenSymbol} className="outline-btn" style={{ padding: '0px 5px' }}>Withdraw</a>
                                                                            :
                                                                            <a href="#" onClick={() => {notify.hide(); notify.show("Withdraw is temporarily disabled for this coin", "warning")}} className="outline-btn disabled" style={{ padding: '0px 5px' }}>Withdraw</a>
                                                                        }
                                                                        {/* {d.tActive === true && */}
                                                                         <a href={'/userStaking'} className="outline-btn" style={{ padding: '0px 5px' }}>Stake</a>
                                                                         {/* } */}
                                                                        {d.tActive === true &&
                                                                            <a href={"/trade/" + d.tokenSymbol + "/USDT"} className="outline-btn" style={{ padding: '0px 5px' }}>Trade</a>
                                                                        }
                                                                        
                                                </div>
                                                <div className="action_btn text-center">
                                                <a href="javascript:void" className="outline-btn txt-white m-1"  onClick={() => {this.setState({clickedCoin:d.tokenSymbol},()=>this.openTransferModal())}}>
                                                                            Transfer
                                                                            </a>
                                                </div>
                                        </div>
                                    </div>))}
                                                                      
                                    
                                </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />

            </div>
        );
    }
}

export default Dashboard;