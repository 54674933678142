import React from "react";

import { Route, Switch } from 'react-router-dom';

import Homepage from "./Pages/Homepage/homepage";
import Support from "./Pages/Support";
import News from './Pages/News'
import NewsDetails from './Pages/NewsDetails'
import Login from "./Pages/Login/login";
import Forgotpassword from "./Pages/Login/forgotpassword";
import Resetpassword from "./Pages/Login/resetpassword";
import Register from "./Pages/Register/register";
import Activations from "./Pages/Register/activations"; 
import Dashboard from "./Pages/Dashboard/dashboard";
import Referral from "./Pages/Dashboard/referral";
import Transactions from "./Pages/Dashboard/transactions";
import Deposit from "./Pages/Dashboard/deposit";
import Withdraw from "./Pages/Dashboard/withdraw";
import ActivityLog from "./Pages/Dashboard/activitylog";
import Setting from "./Pages/Dashboard/setting";
import kyc from "./Pages/Dashboard/kyc";
import Feesubscription from "./Pages/Dashboard/feesubscription"; 
import Trade from "./Pages/Trade/trade";
import Logout from "./Pages/Dashboard/logout";
import announcement from "./Pages/Homepage/announcement";
import announcementDetail from "./Pages/Homepage/announcementDetail";
import terms from "./Pages/Homepage/terms";
import privacy from "./Pages/Homepage/privacy";
import PersonalDetails from "./Pages/Dashboard/personalDetails";
import ReferalLevels from "./Pages/Dashboard/referalLevels";

import userStaking from "./Pages/Staking/userStaking";
import StakeHistory from "./Pages/Dashboard/stakeHistory";
import Rewards from "./Pages/Staking/rewards";
import StakingLevels from "./Pages/Staking/stakingLevels";
import UsdtTransfer from "./Pages/Dashboard/internalWithdrawl";
import LockHistory from "./Pages/Dashboard/LockHistory";
import BankTransfer from "./Pages/Dashboard/BankTransfer";
import FutureTrade from "./Pages/Trade/FutureTrade/futureTrade";
import FutureWallet from "./Pages/Trade/FutureTrade/futureWallets";
// import PickASymbol from "./Pages/PickASymbol/buySell";
// import SelectGame from "./Pages/SelectGame/selectGame";
// import ConfirmRegistration  from "./Pages/Register/Confirm";
// import LoginWIthToken from "./Pages/LoginWIthToken/loginWithToken";

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/support" component={Support} />
            <Route exact path="/news" component={News} />
            <Route exact path="/newsdetails/:id" component={NewsDetails} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/resetpassword" component={Resetpassword} /> 
            <Route exact path="/forgotpassword" component={Forgotpassword} />
            <Route exact path="/invite/:refcode" component={Register} /> 
            <Route exact path="/register" component={Register} /> 
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/referral" component={Referral} />
            <Route exact path="/transactions" component={Transactions} />
            <Route exact path="/deposit/:coinCode" component={Deposit} />
            <Route exact path="/deposit" component={Deposit} />
            <Route exact path="/withdraw/:coinCode" component={Withdraw} />
            <Route exact path="/withdraw" component={Withdraw} />
            <Route exact path="/activitylog" component={ActivityLog} />
            <Route exact path="/setting" component={Setting} />
            <Route exact path="/feesubscription" component={Feesubscription} />
            <Route exact path="/trade/:coinCode/:pairCode" component={Trade} />
            <Route exact path="/trade" component={Trade} />
            <Route exact path="/futures/trade/:coinCode/:pairCode" component={FutureTrade} />
            <Route exact path="/activations" component={Activations} />  
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/announcement/:blogid" component={announcementDetail} />
            <Route exact path="/announcement" component={announcement} />
            <Route exact path="/kyc" component={kyc} />
            {/* <Route exact path="/terms" component={terms} />  */}
            <Route exact path="/privacy" component={privacy} /> 
            <Route exact path="/personaldetails" component={PersonalDetails} /> 
            <Route exact path="/userStaking" component={userStaking} /> 
            {/* <Route exact path="/pickasymbol" component={PickASymbol} />
            <Route exact path="/selectgame" component={SelectGame} />
            <Route exact path="/confirmRegistration" component={ConfirmRegistration} />
            <Route exact path="/LoginWithToken?token=:token&redirectToFirstPlay=true" component={LoginWIthToken} /> */}
            {/* <Route exact path="/stakinglevels" component={ReferalLevels} />  */}
            <Route exact path="/stakinglevels" component={StakingLevels} /> 
            <Route exact path="/stakehistory" component={StakeHistory} /> 
            <Route exact path="/rewards" component={Rewards} /> 
            <Route exact path="/usdttransfer" component={UsdtTransfer} /> 
            <Route exact path="/lockHistory" component={LockHistory} /> 
            <Route exact path="/banktransfer" component={BankTransfer} /> 
            <Route exact path="/banktransfer/:coinCode" component={BankTransfer} /> 

            <Route exact path="/wallet/futures" component={FutureWallet} /> 


        </Switch>
    );
};

export default Routes;
