import React from 'react'
import moment from "moment";

const UserOrderList = ({openOrderrows,currentCoinPairDecimalData,historyOrderrows,handleRemoveRowOpenOrder}) => {
  return (
    <div>
         <ul className="nav nav-tabs" role="tablist">
                          <li className="active">
                          <a href="#positions_odr" role="tab" data-toggle="tab">
                            Positions
                          </a>
                        </li>
                        <li >
                          <a href="#open_odr" role="tab" data-toggle="tab">
                            Open Orders
                          </a>
                        </li>
                        <li>
                          <a href="#complete_odr" role="tab" data-toggle="tab">
                            Order History
                          </a>
                        </li>
                      
                        <li>
                          <a href="#tradeHistory_odr" role="tab" data-toggle="tab">
                            Trade History
                          </a>
                        </li>
                        <li>
                          <a href="#closedPositions_odr" role="tab" data-toggle="tab">
                            Closed Positions PNL
                          </a>
                        </li>
                        <li>
                          <a href="#tranRecord_odr" role="tab" data-toggle="tab">
                            Transaction Record
                          </a>
                        </li>
                        <li>
                          <a href="#positionHistory_odr" role="tab" data-toggle="tab">
                            Position History
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content order_table">
                        {/* <!-- tab 1 start--> */}
                        <div
                          role="tabpanel"
                          className="tab-pane active"
                          id="positions_odr"
                        >
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Coin</th>
                                  <th>Price</th>
                                  <th>Amount</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {openOrderrows
                                  .map((row, xid) => (
                                    <tr
                                      key={xid}
                                      style={{ borderBottom: "1px solid #ddd" }}
                                    >
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {row.coinName + row.pair}
                                        <br></br>
                                        {row.orderType} ({row.orderMode})<br></br>
                                        <small>
                                          {moment(
                                            row.dateTime
                                          ).format("DD MM YYYY hh:mm:ss a")}
                                        </small>
                                        <br></br>
                                        <a
                                          href="#"
                                          className="label label-danger stroke_btn_main"
                                          onClick={handleRemoveRowOpenOrder(
                                            xid
                                          )}
                                        >
                                          Cancel Order
                                        </a>
                                      </td>
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {Number(row.price).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.priceDecimal
                                          )
                                        )}{" "}
                                        {row.pair}
                                      </td>
                                      <td>
                                        {Number(row.amount).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.amountDecimal
                                          )
                                        )}{" "}
                                        {row.coinName}
                                        <br></br>
                                        <small>
                                          Fee: {Number(row.tredingFee).toFixed(6)}{" "}
                                          {row.orderType == "Buy"
                                            ? row.coinName
                                            : row.pair}
                                        </small>
                                      </td>
                                      <td>
                                        {row.total} {row.pair}
                                      </td>
                                    </tr>
                                  ))
                                  .reverse()}
                              </tbody>

                              {/* <tbody>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                          </tbody> */}
                            </table>
                          </div>
                        </div>
                        {/* <!-- tab 1 ends--> */}

                        {/* <!-- tab 2 start--> */}
                        <div
                          role="tabpanel"
                          className="tab-pane"
                          id="open_odr"
                        >
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Coin</th>
                                  <th>Price</th>
                                  <th>Amount</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {openOrderrows
                                  .map((row, xid) => (
                                    <tr
                                      key={xid}
                                      style={{ borderBottom: "1px solid #ddd" }}
                                    >
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {row.coinName + row.pair}
                                        <br></br>
                                        {row.orderType} ({row.orderMode})<br></br>
                                        <small>
                                          {moment(
                                            row.dateTime
                                          ).format("DD MM YYYY hh:mm:ss a")}
                                        </small>
                                        <br></br>
                                        <a
                                          href="#"
                                          className="label label-danger stroke_btn_main"
                                          onClick={handleRemoveRowOpenOrder(
                                            xid
                                          )}
                                        >
                                          Cancel Order
                                        </a>
                                      </td>
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {Number(row.price).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.priceDecimal
                                          )
                                        )}{" "}
                                        {row.pair}
                                      </td>
                                      <td>
                                        {Number(row.amount).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.amountDecimal
                                          )
                                        )}{" "}
                                        {row.coinName}
                                        <br></br>
                                        <small>
                                          Fee: {Number(row.tredingFee).toFixed(6)}{" "}
                                          {row.orderType == "Buy"
                                            ? row.coinName
                                            : row.pair}
                                        </small>
                                      </td>
                                      <td>
                                        {row.total} {row.pair}
                                      </td>
                                    </tr>
                                  ))
                                  .reverse()}
                              </tbody>

                              {/* <tbody>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                            <tr>
                              <td className="green_clr">0.5214 </td>
                              <td>34,56,606</td>
                              <td className="red_clr">34,56,610</td>
                              <td>0.0013</td>
                            </tr>
                          </tbody> */}
                            </table>
                          </div>
                        </div>
                        {/* <!-- tab 2 ends--> */}

                        {/* <!-- tab 3 start--> */}
                        <div
                          role="tabpanel"
                          className="tab-pane"
                          id="complete_odr"
                        >
                          <div className="table-responsive inner_table">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Coin</th>
                                  <th>Price</th>
                                  <th>Amount</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {historyOrderrows
                                  .map((row, xid) => (
                                    <tr
                                      key={xid}
                                      style={{ borderBottom: "1px solid #ddd" }}
                                    >
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {row.coinName + row.pair}
                                        <br></br>
                                        {row.orderType} ({row.orderMode})<br></br>
                                        <small>
                                          {moment(
                                            row.dateTime
                                          ).format("DD MM YYYY hh:mm:ss a")}
                                        </small>
                                      </td>
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {Number(row.price).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.priceDecimal
                                          )
                                        )}{" "}
                                        {row.pair}
                                      </td>
                                      <td>
                                        {Number(row.amount).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.amountDecimal
                                          )
                                        )}{" "}
                                        {row.coinName}
                                        <br></br>
                                        <small>
                                          Fee: {Number(row.tredingFee).toFixed(6)}{" "}
                                          {row.orderType == "Buy"
                                            ? row.coinName
                                            : row.pair}
                                        </small>
                                      </td>
                                      <td>
                                        {row.total} {row.pair}
                                      </td>
                                    </tr>
                                  ))
                                  .reverse()}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <!-- tab 3 ends-->  */}

                        {/* <!-- tab 4 start--> */}
                         <div
                          role="tabpanel"
                          className="tab-pane"
                          id="tradeHistory_odr"
                        >
                          <div className="table-responsive inner_table">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Coin</th>
                                  <th>Price</th>
                                  <th>Amount</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {historyOrderrows
                                  .map((row, xid) => (
                                    <tr
                                      key={xid}
                                      style={{ borderBottom: "1px solid #ddd" }}
                                    >
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {row.coinName + row.pair}
                                        <br></br>
                                        {row.orderType} ({row.orderMode})<br></br>
                                        <small>
                                          {moment(
                                            row.dateTime
                                          ).format("DD MM YYYY hh:mm:ss a")}
                                        </small>
                                      </td>
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {Number(row.price).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.priceDecimal
                                          )
                                        )}{" "}
                                        {row.pair}
                                      </td>
                                      <td>
                                        {Number(row.amount).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.amountDecimal
                                          )
                                        )}{" "}
                                        {row.coinName}
                                        <br></br>
                                        <small>
                                          Fee: {Number(row.tredingFee).toFixed(6)}{" "}
                                          {row.orderType == "Buy"
                                            ? row.coinName
                                            : row.pair}
                                        </small>
                                      </td>
                                      <td>
                                        {row.total} {row.pair}
                                      </td>
                                    </tr>
                                  ))
                                  .reverse()}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <!-- tab 4 ends-->  */}

                        {/* <!-- tab 5 start--> */}
                        <div
                          role="tabpanel"
                          className="tab-pane"
                          id="closedPositions_odr"
                        >
                          <div className="table-responsive inner_table">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Coin</th>
                                  <th>Price</th>
                                  <th>Amount</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {historyOrderrows
                                  .map((row, xid) => (
                                    <tr
                                      key={xid}
                                      style={{ borderBottom: "1px solid #ddd" }}
                                    >
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {row.coinName + row.pair}
                                        <br></br>
                                        {row.orderType} ({row.orderMode})<br></br>
                                        <small>
                                          {moment(
                                            row.dateTime
                                          ).format("DD MM YYYY hh:mm:ss a")}
                                        </small>
                                      </td>
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {Number(row.price).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.priceDecimal
                                          )
                                        )}{" "}
                                        {row.pair}
                                      </td>
                                      <td>
                                        {Number(row.amount).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.amountDecimal
                                          )
                                        )}{" "}
                                        {row.coinName}
                                        <br></br>
                                        <small>
                                          Fee: {Number(row.tredingFee).toFixed(6)}{" "}
                                          {row.orderType == "Buy"
                                            ? row.coinName
                                            : row.pair}
                                        </small>
                                      </td>
                                      <td>
                                        {row.total} {row.pair}
                                      </td>
                                    </tr>
                                  ))
                                  .reverse()}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <!-- tab 5 ends-->  */}

                         {/* <!-- tab 6 start--> */}
                         <div
                          role="tabpanel"
                          className="tab-pane"
                          id="tranRecord_odr"
                        >
                          <div className="table-responsive inner_table">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Coin</th>
                                  <th>Price</th>
                                  <th>Amount</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {historyOrderrows
                                  .map((row, xid) => (
                                    <tr
                                      key={xid}
                                      style={{ borderBottom: "1px solid #ddd" }}
                                    >
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {row.coinName + row.pair}
                                        <br></br>
                                        {row.orderType} ({row.orderMode})<br></br>
                                        <small>
                                          {moment(
                                            row.dateTime
                                          ).format("DD MM YYYY hh:mm:ss a")}
                                        </small>
                                      </td>
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {Number(row.price).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.priceDecimal
                                          )
                                        )}{" "}
                                        {row.pair}
                                      </td>
                                      <td>
                                        {Number(row.amount).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.amountDecimal
                                          )
                                        )}{" "}
                                        {row.coinName}
                                        <br></br>
                                        <small>
                                          Fee: {Number(row.tredingFee).toFixed(6)}{" "}
                                          {row.orderType == "Buy"
                                            ? row.coinName
                                            : row.pair}
                                        </small>
                                      </td>
                                      <td>
                                        {row.total} {row.pair}
                                      </td>
                                    </tr>
                                  ))
                                  .reverse()}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <!-- tab 6 ends-->  */}

                        {/* <!-- tab 7 start--> */}
                        <div
                          role="tabpanel"
                          className="tab-pane"
                          id="positionHistory_odr"
                        >
                          <div className="table-responsive inner_table">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Coin</th>
                                  <th>Price</th>
                                  <th>Amount</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {historyOrderrows
                                  .map((row, xid) => (
                                    <tr
                                      key={xid}
                                      style={{ borderBottom: "1px solid #ddd" }}
                                    >
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {row.coinName + row.pair}
                                        <br></br>
                                        {row.orderType} ({row.orderMode})<br></br>
                                        <small>
                                          {moment(
                                            row.dateTime
                                          ).format("DD MM YYYY hh:mm:ss a")}
                                        </small>
                                      </td>
                                      <td
                                        className={
                                          row.orderType == "Buy"
                                            ? "green_clr"
                                            : "red_clr"
                                        }
                                      >
                                        {Number(row.price).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.priceDecimal
                                          )
                                        )}{" "}
                                        {row.pair}
                                      </td>
                                      <td>
                                        {Number(row.amount).toFixed(
                                          Number(
                                            currentCoinPairDecimalData
                                              ?.amountDecimal
                                          )
                                        )}{" "}
                                        {row.coinName}
                                        <br></br>
                                        <small>
                                          Fee: {Number(row.tredingFee).toFixed(6)}{" "}
                                          {row.orderType == "Buy"
                                            ? row.coinName
                                            : row.pair}
                                        </small>
                                      </td>
                                      <td>
                                        {row.total} {row.pair}
                                      </td>
                                    </tr>
                                  ))
                                  .reverse()}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <!-- tab 7 ends-->  */}
                      </div>
    </div>
  )
}

export default UserOrderList