import React, { Component } from "react";

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers'; 
import Routes from './routes';
// import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import WorkInProgress from "./Components/workInProgress/WorkInProgress";
import { Route, Switch } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.css';

const createStoreWithMiddleware = applyMiddleware(promiseMiddleware, ReduxThunk)(createStore)
class App extends Component {
  render() {
    return (
      <Provider store={createStoreWithMiddleware(reducers)}>
        <BrowserRouter>
          <Routes />
          {/* <Switch>
            <Route exact path="/" component={WorkInProgress} />
            </Switch> */}
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
