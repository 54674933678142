import React from "react";

const BlogMain = () => {
  return (
    <section id="blog_page">
      <img src="/images/bg_shape1.png" alt="img" className="shape1" />
       <img src="/images/bg_shape2.png" alt="img" className="shape2" />
      <div class="container">
        <div class="row">
          <div class="col-12 blog_nav text-center">
            <ul>
              <li>
                <a href="#">Announcements</a>
              </li>
              <li class="active">
                <a href="#">Contests</a>
              </li>
              <li>
                <a href="#">Events</a>
              </li>
              <li>
                <a href="#">Bitcoin</a>
              </li>
              <li>
                <a href="#">Blockchain</a>
              </li>
              <li>
                <a href="#">Cryptocurrencies</a>
              </li>
              <li>
                <a href="#">News</a>
              </li>
              <li>
                <a href="#">Opinion</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="blog_singel">
              <div class="blog-img">
                <a href="#">
                  <img src="images/blog_mg.jpg" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="blog-title">
                <span>21 January 2021</span>
                <h3>
                  <a href="#">
                    Crypto is Back Bull Mode, What Can We Learn From This?
                  </a>
                </h3>
                <div class="blog-meta">
                  <span class="ago">1 Hour Ago</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="blog_singel">
              <div class="blog-img">
                <a href="#">
                  <img src="images/blog1.jpg" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="blog-title">
                <span>21 January 2021</span>
                <h3>
                  <a href="#">NASDAQ CEO Open Crypto NASDAQ Collaborating..</a>
                </h3>
                <div class="blog-meta">
                  <span class="ago">1 Hour Ago</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="blog_singel">
              <div class="blog-img">
                <a href="#">
                  <img src="images/blog2.jpg" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="blog-title">
                <span>21 January 2021</span>
                <h3>
                  <a href="#">
                    Crypto is Back Bull Mode, What Can We Learn From This?
                  </a>
                </h3>
                <div class="blog-meta">
                  <span class="ago">1 Hour Ago</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="blog_singel">
              <div class="blog-img">
                <a href="#">
                  <img src="images/post.jpg" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="blog-title">
                <span>21 January 2021</span>
                <h3>
                  <a href="#">NASDAQ CEO Open Crypto NASDAQ Collaborating..</a>
                </h3>
                <div class="blog-meta">
                  <span class="ago">1 Hour Ago</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="blog_singel">
              <div class="blog-img">
                <a href="#">
                  <img src="images/post-thumb2.jpg" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="blog-title">
                <span>21 January 2021</span>
                <h3>
                  <a href="#">
                    Crypto is Back Bull Mode, What Can We Learn From This?
                  </a>
                </h3>
                <div class="blog-meta">
                  <span class="ago">1 Hour Ago</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="blog_singel">
              <div class="blog-img">
                <a href="#">
                  <img src="images/blog_mg.jpg" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="blog-title">
                <span>21 January 2021</span>
                <h3>
                  <a href="#">
                    Crypto is Back Bull Mode, What Can We Learn From This?
                  </a>
                </h3>
                <div class="blog-meta">
                  <span class="ago">1 Hour Ago</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="blog_singel">
              <div class="blog-img">
                <a href="#">
                  <img src="images/blog1.jpg" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="blog-title">
                <span>21 January 2021</span>
                <h3>
                  <a href="#">NASDAQ CEO Open Crypto NASDAQ Collaborating..</a>
                </h3>
                <div class="blog-meta">
                  <span class="ago">1 Hour Ago</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="blog_singel">
              <div class="blog-img">
                <a href="#">
                  <img src="images/blog2.jpg" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="blog-title">
                <span>21 January 2021</span>
                <h3>
                  <a href="#">
                    Crypto is Back Bull Mode, What Can We Learn From This?
                  </a>
                </h3>
                <div class="blog-meta">
                  <span class="ago">1 Hour Ago</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="blog_singel">
              <div class="blog-img">
                <a href="#">
                  <img src="images/post.jpg" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="blog-title">
                <span>21 January 2021</span>
                <h3>
                  <a href="#">NASDAQ CEO Open Crypto NASDAQ Collaborating..</a>
                </h3>
                <div class="blog-meta">
                  <span class="ago">1 Hour Ago</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="blog_singel">
              <div class="blog-img">
                <a href="#">
                  <img src="images/post-thumb2.jpg" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="blog-title">
                <span>21 January 2021</span>
                <h3>
                  <a href="#">
                    Crypto is Back Bull Mode, What Can We Learn From This?
                  </a>
                </h3>
                <div class="blog-meta">
                  <span class="ago">1 Hour Ago</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <ul class="pagination justify-content-end">
              <li class="page-item">
                <a class="page-link" href="#">
                  Previous
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  1
                </a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="#">
                  2
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  3
                </a>
              </li>
              <li class="page-item">
                <a class="page-link" href="#">
                  Next
                </a>
              </li>
            </ul>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogMain;
