import React from 'react';

const downloadAppSection=()=>{
    return(
        <section id="download_app">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 text-center">
        <div className="stripe_info">
        <h2>Download Our App</h2>
        <a href="#"><img src="images/google_play.png"/></a>
		{/* <a href="#"><img src="images/google_play.png"/></a>
		<a href="#"><img src="images/google_play.png"/></a>
		<a href="#"><img src="images/google_play.png"/></a> */}
      </div>
      </div>
    </div>
  </div>
</section>
    )
}

export default downloadAppSection