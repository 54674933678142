import React, { Component, useEffect, useState,useRef } from 'react';
import Sidebar from '../../Components/Dashboard/sidebar';
import Header from '../../Components/Header/header';
import axios from 'axios';
import { BASE_URL } from "../../config/constant";
import Notifications, { notify } from 'react-notify-toast';
import '../../index.css';
import moment from 'moment';

function Rewards() {
    const [refList, setRefList] = useState([]);
    // const refFullList = useRef([]);
    useEffect(() => {
        document.title = 'Rewards'
        const userId = localStorage.getItem('userDetails')
        axios.post(BASE_URL+'Staking/getStockReward',{userId:userId}).then(res=>{
          if (res.data && res.data.data && res.data.data.length ) {
              setRefList(res.data.data);
            //   refFullList.current = res.data.data
          }
      }).catch(e=>{
          
      })
    
     
    }, [])
    
    // const onFilter =(d)=>{
    //     if (d) {
    //         const l = refFullList.current.filter(e=>e.status == d)
    //         setRefList(l)
    //     }else{
    //         setRefList(refFullList.current)
    //     }
    // }
    
   
    return (
        <div>
            <Notifications />
            <Header />

            <section id="middel_area" className="ref_mid">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-12 area_left">
                                <Sidebar  />
                            </div>
                        <div className="col-sm-12 area_right">
                            <div className="white_bx clearfix">
                                <div className="side_txt " style={{display:'flex'}}>
                                    <h6 style={{width:'100%'}}>Rewards </h6>
                                {/* <div className='ms-auto'>
                                    <select name="filter" id="filterstake" className='form-control' onChange={(e)=>{onFilter(e.target.value)}}>
                                        <option value="">All</option>
                                        <option value="1">Active</option>
                                        <option value="2">Completed</option>
                                    </select>
                                </div> */}
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>UserId</th>
                                                <th>Staking Package Id</th>
                                                <th>CoinName</th>
                                                <th>Amount</th>
                                                <th>Expiry Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {refList.map((d,i) => (
                                                <>
                                                <tr key={'parent'+i}>
                                                    <td>{d.userId}</td>
                                                    <td>{d.stakingPackageId}</td>
                                                    <td>{d.coinName}</td>
                                                    <td>{d.amount}</td>
                                                    <td>{moment(d.expiryDate).format('DD MMM YYYY, HH:SS ')}</td>
                                                    <td style={{color:d.status==1?'#ff7a00':'#10a52b'}}>{d.status == 1 ?'Ongoing':'Completed'}</td>
                                                </tr>
                                                </>
                                            ))}
                                            {!refList || refList.length == 0 ?<tr>
                                            <td colSpan={6} align="center" className="no_data"><i className="fa fa-file-o" /> No record found</td>
                                            </tr>:null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Rewards