import React from 'react'
const HelpSection= ()=>{
return(
    <section id="help_part">
        <img src="/images/bg_shape1.png" alt="img" className="shape1" />
<div class="container">
<div class="row">
<div class="col-xs-12 heading_part">
<h2>Categories</h2>
<p>Our customer service is online 24/7 to help with your needs.</p>
</div>
</div>
<div class="row">
<div class="col-sm-6 help_points">
<div class="outline_part">
<div class="row">
<div class="help_part_info col-xs-9">
<h4>FAQ</h4>
<p>Got a question? We've probably got the answer for you here.</p>
<a href="#">View More <i class="fa fa-angle-double-right"></i></a>
</div>
<div class="help_part_img col-xs-3 text-right">
<img src="images/help_part_icon1.png" alt="img"/>
</div>
</div>
</div>
</div>
<div class="col-sm-6 help_points">
<div class="outline_part">
<div class="row">
<div class="help_part_info col-xs-9">
<h4>Submit a request</h4>
<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
<a href="#">Contact Support <i class="fa fa-angle-double-right"></i></a>
</div>
<div class="help_part_img col-xs-3 text-right">
<img src="images/help_part_icon2.png" alt="img"/>
</div>
</div>
</div>
</div>
<div class="col-sm-6 help_points">
<div class="outline_part">
<div class="row">
<div class="help_part_info col-xs-9">
<h4>Beginners' Guide</h4>
<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
<a href="#">View More <i class="fa fa-angle-double-right"></i></a>
</div>
<div class="help_part_img col-xs-3 text-right">
<img src="images/help_part_icon3.png" alt="img"/>
</div>
</div>
</div>
</div>
<div class="col-sm-6 help_points">
<div class="outline_part">
<div class="row">
<div class="help_part_info col-xs-9">
<h4>Download The App</h4>
<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
<a href="#">View More <i class="fa fa-angle-double-right"></i></a>
</div>
<div class="help_part_img col-xs-3 text-right">
<img src="images/help_part_icon4.png" alt="img"/>
</div>
</div>
</div>
</div>
<div class="col-sm-6 help_points">
<div class="outline_part">
<div class="row">
<div class="help_part_info col-xs-9">
<h4>API Documentation</h4>
<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem.</p>
<a href="#">View More <i class="fa fa-angle-double-right"></i></a>
</div>
<div class="help_part_img col-xs-3 text-right">
<img src="images/help_part_icon5.png" alt="img" />
</div>
</div>
</div>
</div>
<div class="col-sm-6 help_points">
<div class="outline_part">
<div class="row">
<div class="help_part_info col-xs-9">
<h4>Join the Community</h4>
<p>Got a question? We've probably got the answer for you here.</p>
<a href="#">View More <i class="fa fa-angle-double-right"></i></a>
</div>
<div class="help_part_img col-xs-3 text-right">
<img src="images/help_part_icon6.png" alt="img"/>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
)
}
export default HelpSection