import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Modal } from "react-bootstrap";

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import BigNumber from 'bignumber.js'
import axios from 'axios';
import OrderBookBid from './OrderBookBid'
import OrderBookAsk from './OrderBookAsk'
import TradeHistory from './TradeHistory'    
import { BASE_URL } from "../../config/constant";
import { Link, Redirect } from "react-router-dom";
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import { Offline, Online } from "react-detect-offline";
import $ from "jquery"; 
import Notifications, { notify } from 'react-notify-toast';

import { SocketProvider } from 'socket.io-react';
import io from 'socket.io-client';
import Binance from 'binance-api-react-native'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { decimalData } from './decimalDataForAllCoins'
import { TVChartContainer } from './tradeChart/trading-chart';
import moment from 'moment';
const client = Binance()
   

// const socket = io.connect("wss://bithind.com:2087");
const socket = io.connect("");


const validNumber = RegExp(/^[0-9.]*$/);
var priceValid = new RegExp(/^[0-9.]{1,11}(\.\d{1,8})?$/);
// var priceValid = new RegExp(/^[0-9.]+(\.[0-9]{1,2})?$/);
// const validNumber = RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
// /(\d{0,2})[^.]*((?:\.\d{0,4})?)/g

class Trade extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      LodingOrder: false,
      exchangeType: "binance",
      // ticker: 0,
      // allticker: [],
      trades: [],
      tradehistory: [],
      depth: [],
      userData: [],
      tokenData: [],
      // tickerPrice: [],
      // coinCode: null,
      // pairCode: null,
      coinCode: 'BTC',
      pairCode: 'USDT',
      isSellModalOpen: false,
      loadFeeSubs: false,
      isBuyModalOpen: false,
      userBalanceCoin: 0,
      userBalancePair: 0,
      userBalanceFESS: 0,
      FESSstakeamt: 0,
      tradingPercent: "0.2",
      userId: 0,
      errors: "",
      themeMode: true,
      theme : "dark_mode",
      openOrderrows: [],
      cancelOrderrows: [],
      historyOrderrows: [],
      buyType: "LIMIT",
      sellType: "LIMIT",
      socketConnect: false,
      minAmt: 0,
      minTotal: 0,
      priceDecimal: 0,
      amtDecimal: 0,
      myfavcoin: [],
      myorderPrice: [],
      doctitle: "Bitallx",  
      feesPacks: [],
      manageFee: false,

      allCoinPairsData : [],
      currentCoinPairDecimalData : {},

      buyerFess : 0,
      sellerFess : 0,
    };
    let rows = [];
    this.tradesCount = 100;
    this.streams = ['@depth20', '@trade'];
    // this.streams = ['@ticker','@depth20','@trade'];
  }

  // _getTickerBySymbol(data) {
  //     let ticker = {}
  //     data.forEach(item => {
  //         let symbol = item.symbol || item.s;
  //         ticker[symbol] = {
  //             symbol: symbol,
  //             lastPrice: item.lastPrice || item.c,
  //             priceChange: item.priceChange || item.p,
  //             priceChangePercent: item.priceChangePercent || item.P,
  //             highPrice: item.highPrice || item.h,
  //             lowPrice: item.lowPrice || item.l,
  //             quoteVolume: item.quoteVolume || item.q,
  //         }
  //     }) 
  //     return ticker;
  // }


  _connectTickerStreams(streams) {
    streams = streams.join('/');
    let connection = btoa(streams);
    // let connectionnew = btoa(streams);  

    this[connection] = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${streams}`);
    // this[connectionnew] = io.connect('wss://stream.binance.com:9443/stream?streams='+streams); 

    // client.ws.depth('ETHBTC', depth => { 
    //   console.log(depth)
    // }) 

    // console.log("stream",streams[0])
    var lastsockettimeNew = 0
    var currenttimestampNew
  
    this[connection].onmessage = evt => {
      currenttimestampNew = Math.floor(Date.now() / 1000)

      // lastsockettimeNew = currenttimestampNew + 0;

      let eventData = JSON.parse(evt.data);

      if (eventData.stream.endsWith('@ticker')) {
        // let decimals
        // if(eventData.data.s.endsWith('BTC')) {
        //   decimals = 8
        // }
        // if(eventData.data.s.endsWith('ETH')) {
        //   decimals = 6
        // }
        // if(eventData.data.s.endsWith('USDT')) {
        //   decimals = 4
        // } 
        // console.log("tokendata",eventData.data)
        var lastPrice = $("." + eventData.data.s + "_price").html();
        if(lastPrice!=undefined){
          lastPrice =  lastPrice.replace(/,/g, "");
        }
        
        // console.log('I think main data eventData',eventData,lastPrice);
        // $(document).prop('title', eventData.data.s + ' | Bithind'); 

        if (Number(lastPrice) > eventData.data.c) {
          $("." + eventData.data.s + '_priceColor').removeClass("red_clr")
          $("." + eventData.data.s + '_priceColor').removeClass("green_clr")
          $("." + eventData.data.s + '_priceColor').addClass("red_clr")

          $("#" + eventData.data.s + "_priceArrow").removeClass("fa-arrow-up")
          $("#" + eventData.data.s + "_priceArrow").removeClass("fa-arrow-down")
          $("#" + eventData.data.s + "_priceArrow").addClass("fa-arrow-down")
          // this.setState({ [eventData.data.s+'_priceColor']: "red_clr"})
        } else if (Number(lastPrice) < eventData.data.c) {
          $("." + eventData.data.s + '_priceColor').removeClass("red_clr")
          $("." + eventData.data.s + '_priceColor').removeClass("green_clr")
          $("." + eventData.data.s + '_priceColor').addClass("green_clr")

          $("#" + eventData.data.s + "_priceArrow").removeClass("fa-arrow-up")
          $("#" + eventData.data.s + "_priceArrow").removeClass("fa-arrow-down")
          $("#" + eventData.data.s + "_priceArrow").addClass("fa-arrow-up")
          // this.setState({ [eventData.data.s+'_priceColor']: "green_clr" }) 
        } else {

          $("." + eventData.data.s + '_priceColor').removeClass("red_clr")
          $("." + eventData.data.s + '_priceColor').removeClass("green_clr")
          // this.setState({ [eventData.data.s+'_priceColor']: "" }) 
        }

        if (eventData.data.P > 0) {

          $("." + eventData.data.s + '_priceChangePercentColor').removeClass("red_clr")
          $("." + eventData.data.s + '_priceChangePercentColor').removeClass("green_clr")
          $("." + eventData.data.s + '_priceChangePercentColor').addClass("green_clr")
        } else {

          $("." + eventData.data.s + '_priceChangePercentColor').removeClass("red_clr")
          $("." + eventData.data.s + '_priceChangePercentColor').removeClass("green_clr")
          $("." + eventData.data.s + '_priceChangePercentColor').addClass("red_clr")
        }

        if(this.state.allCoinPairsData.length == 0){
           var BTC_DECIMAL = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'BTC')
           var ETH_DECIMAL = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'ETH')
           var USDT_DECIMAL = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'USDT')
        }

        let decimalForCoin = 0;
        if(eventData.data.s.substr(eventData.data.s.length - 4)  == "USDT"){
          decimalForCoin = USDT_DECIMAL;
        }else if(eventData.data.s.substr(eventData.data.s.length - 3)  == "BTC"){
          decimalForCoin = BTC_DECIMAL;
        }else if(eventData.data.s.substr(eventData.data.s.length - 3)  == "ETH"){
          decimalForCoin = ETH_DECIMAL;
        }

        // this.setState({ [eventData.data.s+'_price']: eventData.data.c })
        $("." + eventData.data.s + "_price").html(new BigNumber(eventData.data.c).toFormat(decimalForCoin, 1))
        // this.setState({ [eventData.data.s+'_priceChangePercent']: eventData.data.P })
        $("." + eventData.data.s + "_priceChangePercent").html(new BigNumber(eventData.data.P).toFormat(2, 1))

        if ((this.props.match.params.coinCode + this.props.match.params.pairCode) == eventData.data.s) {


          // document.title = eventData.data.s + ' | Bithind'
          this.setState({ doctitle: new BigNumber(eventData.data.c).toFormat(null, 1) + ' | ' + eventData.data.s + ' | Bitallx' })
          $("." + eventData.data.s + "_priceChange").html(new BigNumber(eventData.data.p).toFormat(null, 1))
          $("." + eventData.data.s + "_priceHigh").html(new BigNumber(eventData.data.h).toFormat(null, 1))
          $("." + eventData.data.s + "_priceLow").html(new BigNumber(eventData.data.l).toFormat(null, 1))
          $("." + eventData.data.s + "_priceVolume").html(new BigNumber(eventData.data.q).toFormat(2, 1))
        }


        //       eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0
        //       this.setState({ticker: eventData.data});


      }


      // let ticker = this._getTickerBySymbol(JSON.parse(evt.data).data)
      // this.props.dispatch({
      //     type: 'UPDATE_MARKET_PAIRS',
      //     data: ticker
      // })

      // this.setState({allticker: ticker});
      // console.log(ticker)

      // if(this.state.tokenData) {
      //   this.state.tokenData.forEach(item => {





      //     data = '<tr style={{borderBottom:"1px solid rgba(255,255,255,0.1)"}}>
      //     <td style={{padding:"10px 10px"}}><i className="fa fa-star"></i></td>
      //     <td style={{padding:"10px 0px"}}><a href="#">{d.tokenSymbol}/USDT</a></td>
      //     <td style={{padding:"10px 0px"}}>

      //       {/* {this.state.allticker.ETHBTC.lastPrice} */}

      //     </td> 
      //     <td style={{padding:"10px 0px"}} className="red_clr">-0.21%</td> 
      // </tr>'
      // if(this.state.allticker[''+item.tokenSymbol+'USDT']) {

      //   if(this.state[item.tokenSymbol+'USDT_price'] > this.state.allticker[''+item.tokenSymbol+'USDT'].lastPrice) {
      //     this.setState({ [item.tokenSymbol+'USDT_priceColor']: "red_clr"})
      //   } else if(this.state[item.tokenSymbol+'USDT_price'] < this.state.allticker[''+item.tokenSymbol+'USDT'].lastPrice) {
      //     this.setState({ [item.tokenSymbol+'USDT_priceColor']: "green_clr" })
      //   } else {
      //     this.setState({ [item.tokenSymbol+'USDT_priceColor']: "" })
      //   }

      //   this.setState({ [item.tokenSymbol+'USDT_price']: this.state.allticker[''+item.tokenSymbol+'USDT'].lastPrice })
      //   this.setState({ [item.tokenSymbol+'USDT_priceChangePercent']: this.state.allticker[''+item.tokenSymbol+'USDT'].priceChangePercent })


      // }


      // if(item.tokenSymbol == 'BTC' || item.tokenSymbol == 'ETH') {

      // } else {
      //   if(this.state.allticker[''+item.tokenSymbol+'ETH']) {

      //     if(this.state[item.tokenSymbol+'ETH_price'] > this.state.allticker[''+item.tokenSymbol+'ETH'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'ETH_priceColor']: "red_clr"})
      //     } else if(this.state[item.tokenSymbol+'ETH_price'] < this.state.allticker[''+item.tokenSymbol+'ETH'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'ETH_priceColor']: "green_clr" })
      //     } else {
      //       this.setState({ [item.tokenSymbol+'ETH_priceColor']: "" })
      //     }

      //   this.setState({ [item.tokenSymbol+'ETH_price']: this.state.allticker[''+item.tokenSymbol+'ETH'].lastPrice })
      //   this.setState({ [item.tokenSymbol+'ETH_priceChangePercent']: this.state.allticker[''+item.tokenSymbol+'ETH'].priceChangePercent })

      //   }
      // }

      // if(item.tokenSymbol == 'BTC') {

      // } else {
      //   if(this.state.allticker[''+item.tokenSymbol+'BTC']) {

      //     if(this.state[item.tokenSymbol+'BTC_price'] > this.state.allticker[''+item.tokenSymbol+'BTC'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'BTC_priceColor']: "red_clr"})
      //     } else if(this.state[item.tokenSymbol+'BTC_price'] < this.state.allticker[''+item.tokenSymbol+'BTC'].lastPrice) {
      //       this.setState({ [item.tokenSymbol+'BTC_priceColor']: "green_clr" })
      //     } else {
      //       this.setState({ [item.tokenSymbol+'BTC_priceColor']: "" })
      //     }

      //   this.setState({ [item.tokenSymbol+'BTC_price']: this.state.allticker[''+item.tokenSymbol+'BTC'].lastPrice })
      //   this.setState({ [item.tokenSymbol+'BTC_priceChangePercent']: this.state.allticker[''+item.tokenSymbol+'BTC'].priceChangePercent })

      //   }
      // }

      // console.log(this.state.ETHUSDT_price)
      // console.log(item.tokenSymbol)
      //   })
      // }




      // !this.props.active_market.market && this._handleTabClick('BTC')
      // this.setState({
      //   isLoaded: true
      // })

    
    }
 

    this[connection].onerror = evt => {
      console.error(evt);
    }
  }


  _connectSocketStreams(streams) {

    streams = streams.join('/');
    let connection = btoa(streams);

    this[connection] = new WebSocket(`wss://stream.binance.com:9443/stream?streams=${streams}`);

    var lastsockettime = 0;
    var currenttimestamp
   
    this[connection].onmessage = evt => {
      currenttimestamp = Math.floor(Date.now() / 1000)

    if(currenttimestamp > lastsockettime) {
      lastsockettime = currenttimestamp + 1;

      // console.log("lastsockettime",Date.now())
      let eventData = JSON.parse(evt.data);

      // if(eventData.stream.endsWith('@ticker')){
      //     eventData.data.lastc = this.state.ticker ? this.state.ticker.c : 0
      //     this.setState({ticker: eventData.data});
      //     // console.log("ticket",this.state.ticker.s.length)
      //     // this.props.dispatch({
      //     //     type: 'SET_TICKER',
      //     //     data: eventData.data
      //     // })     
      //     this.setState({
      //         loadedTicker: true
      //     })   
      // }


      if (eventData.stream.endsWith('@trade')) {

        // if(this.state.trades && Object.keys(this.state.trades).length > 0){


        // $('#tradehistorytable').prepend('<tr class="coinhover tradehistoryclick" onclick="this.tradehistoryclick"style="cursor:pointer"><td>'+new BigNumber(eventData.data.p).toFormat(null,1)+'</td><td>'+new BigNumber(eventData.data.q).toFormat(null,1)+'</td><td>'+new Date(eventData.data.T).toLocaleTimeString()+'</td></tr>'); 

        // $('#tradehistorytable').html(<><tr className="coinhover tradehistoryclick" ><td>sadaadsa</td></tr></>);  

        // $('#tradehistorytable tr:last').remove();   

        // console.log(eventData.data) 
        // E: 1590227905352 
        // M: true
        // T: 1590227905350
        // a: 2277982279
        // b: 2277982282
        // e: "trade"
        // m: false
        // p: "9130.48000000"
        // q: "0.29765100"
        // s: "BTCUSDT"
        // t: 326511267
        // this.setState({trades: eventData.data});
        // let trades = this.state.trades;

        
          // alert("hi")
        this.setState({ tradehistory: "" });

        var trades = this.state.trades.slice();
        trades.push(eventData.data);
        this.setState({ trades: trades });

        // trades.push(eventData.data);

        trades = trades.slice(-1 * this.tradesCount);
        this.setState({ trades: trades });
        
        // trades = trades.slice(-1*this.tradesCount);
        // console.log("trades",this.state.trades[2].q)


        // this.props.dispatch({ 
        //     type: 'SET_TRADES',
        //     data: trades
        // }) 
        // this.setState({
        //     loadedTrades: true
        // }) 
        // }              
      }
      if (eventData.stream.endsWith('@depth20')) {
        // this.props.dispatch({
        //     type: 'SET_DEPTH',
        //     data: eventData.data
        // }) 
        console.log('I think main data eventData stream',eventData.stream);
        this.setState({ depth: eventData.data });

        // console.log(this.state.depth.bids.length)
        // this.setState({
        //     loadedDepth: true
        // })  
      }

    }

      // this.setState({
      //     isLoaded: true
      // })  
    };
 
    this[connection].onerror = evt => {
      console.error(evt);
    }
  }


  handleBuyModal() {
    // $("#buy_modal").modal("hide");
    $('#buy_trade_click_active').removeClass('active');
    $('#sell_trade_click_active').removeClass('active');
    $('#buy_trade_click_active').addClass('active');
    $('#buy_trade_click').trigger('click');
    // $("#sell_modal").modal("hide");
    // $("#buy_modal").modal("show");
  }

  handleSellModal() {
    // $("#sell_modal").modal("hide");
    $('#buy_trade_click_active').removeClass('active');
    $('#sell_trade_click_active').removeClass('active');
    $('#sell_trade_click_active').addClass('active');
    $('#sell_trade_click').trigger('click');
    // $("#buy_modal").modal("hide");
    // $("#sell_modal").modal("show");
  }

  handleCloseModal() {
    $("#buy_modal").modal("hide");
    $("#sell_modal").modal("hide");
  }

  getmyfavcoin() {
    axios.post(BASE_URL + `favcoin/get`, {
      "userId": this.state.userId
    })
      .then(res => {
        // console.log("favcoin",res);
        if (res.data.message == "success") {
          this.setState({
            myfavcoin: res.data.data,
          })
          res.data.data.map(row => {
            this.setState({ [row.coin + row.pair + '_favcoin']: true })
          })

        }


      })
  }

  async getBithindDetails() {
    if (this.state.exchangeType == "Bitallx") {
      await axios.post(BASE_URL + `trade/history/get`, {
        "coinName": this.props.match.params.coinCode,
        "pair": this.props.match.params.pairCode,
      })
        .then(res => {
          // console.log("tradehistory",res.data.data) 
          if (res !== null) {
            var tradesStates = res.data.data.map(row => {
              var dataRow = {
                "p": Number(row.price).toFixed(6),
                "q": Number(row.amount).toFixed(6),
                "T": Number(row.timestamp + "001"),
                "E" : Number(row.timestamp + "001"),
                "Mode" : row.orderType
              }
              return dataRow
            }).reverse()
            // console.log("tradehistoryrow",tradesStates) 
            this.setState({ trades: tradesStates });
          }
        })


      await axios.post(BASE_URL + `order/open`, {
        "coinName": this.props.match.params.coinCode,
        "pair": this.props.match.params.pairCode,
      })
        .then(res => {
          // console.log("tradehistoryCCCC",res)
          if (res.data.message !== "noRecordsFound") {

            var sellOrders = res.data.data.sell.map(row => {
              var dataRow = [
                Number(row.price).toFixed(6),
                Number(row.amount).toFixed(6)
              ]
              // var dataRow = [Number(row.price).toFixed(6),Number(row.amount).toFixed(6)]

              return dataRow
            }).reverse()

            var buyOrders = res.data.data.buy.map(row => {
              var dataRow = [
                Number(row.price).toFixed(6),
                Number(row.amount).toFixed(6)
              ]
              // var dataRow = [Number(row.price).toFixed(6),Number(row.amount).toFixed(6)]

              return dataRow
            }).reverse()

            var openOrderBookData = {
              "asks": sellOrders,
              "bids": buyOrders,
            }
            this.setState({ depth: openOrderBookData });
            
          }else{
            var openOrderBookData = {
              "asks": [],
              "bids": [],
            }
            this.setState({ depth: openOrderBookData });
          }
        })


    }
  }

  async getBithindPrice() {
    // axios({
    //   method: 'get',
    //   // url: `https://cors-anywhere.herokuapp.com/https://www.binance.com/api/v1/aggTrades?limit=${this.tradesCount}&symbol=${symbol}`
    //   url: `https://bithind.com/dataApi/getlastprice`
    // })
    //   .then(response => {

    //     // console.log("tradedata",response.data)

    //     {
    //       this.state.tokenData.map((d, idx) => {
    //         if (d.tActive == true) {
    //           if (d.BTC == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "BTC"])) {

    //               if (response.data["exch" + d.tokenSymbol + "BTC"].length > 0) {
    //                 $("." + d.tokenSymbol + "BTC_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].close).toFormat(2, 1))

    //                 if (Number(response.data["exch" + d.tokenSymbol + "BTC"][0].close) < Number(response.data["exch" + d.tokenSymbol + "BTC"][0].open)) {
    //                   $("." + d.tokenSymbol + 'BTC_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "BTC_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "BTC"][0].close) > Number(response.data["exch" + d.tokenSymbol + "BTC"][0].open)) {
    //                   $("." + d.tokenSymbol + 'BTC_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "BTC_priceArrow").addClass("fa-arrow-up")
    //                 }


    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "BTC"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "BTC"][0].close - response.data["exch" + d.tokenSymbol + "BTC"][0].open) / response.data["exch" + d.tokenSymbol + "BTC"][0].open) * 100).toFormat(2, 1)
    //                 }
    //                 $("." + d.tokenSymbol + "BTC_priceChangePercent").html(percentage)

    //                 $("." + d.tokenSymbol + "BTC_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "BTC"][0].close) - Number(response.data["exch" + d.tokenSymbol + "BTC"][0].open)).toFormat(2, 1))

    //                 $("." + d.tokenSymbol + "BTC_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "BTC_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "BTC_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "BTC"][0].high).toFormat(2, 1))

    //               }

    //             }
    //           }
    //           if (d.ETH == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "ETH"])) {

    //               if (response.data["exch" + d.tokenSymbol + "ETH"].length > 0) {
    //                 $("." + d.tokenSymbol + "ETH_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].close).toFormat(2, 1))

    //                 if (Number(response.data["exch" + d.tokenSymbol + "ETH"][0].close) < Number(response.data["exch" + d.tokenSymbol + "ETH"][0].open)) {
    //                   $("." + d.tokenSymbol + 'ETH_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "ETH_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "ETH"][0].close) > Number(response.data["exch" + d.tokenSymbol + "ETH"][0].open)) {
    //                   $("." + d.tokenSymbol + 'ETH_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "ETH_priceArrow").addClass("fa-arrow-up")
    //                 }

    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "ETH"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "ETH"][0].close - response.data["exch" + d.tokenSymbol + "ETH"][0].open) / response.data["exch" + d.tokenSymbol + "ETH"][0].open) * 100).toFormat(2, 1)
    //                 }

    //                 $("." + d.tokenSymbol + "ETH_priceChangePercent").html(percentage)

    //                 $("." + d.tokenSymbol + "ETH_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "ETH"][0].close) - Number(response.data["exch" + d.tokenSymbol + "ETH"][0].open)).toFormat(2, 1))

    //                 $("." + d.tokenSymbol + "ETH_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "ETH_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "ETH_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "ETH"][0].high).toFormat(2, 1))

    //               }

    //             }
    //           }
    //           if (d.USDT == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "USDT"])) {

    //               if (response.data["exch" + d.tokenSymbol + "USDT"].length > 0) {
    //                 $("." + d.tokenSymbol + "USDT_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "USDT"][0].close).toFormat(2, 1))

    //                 if (Number(response.data["exch" + d.tokenSymbol + "USDT"][0].close) < Number(response.data["exch" + d.tokenSymbol + "USDT"][0].open)) {
    //                   $("." + d.tokenSymbol + 'USDT_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "USDT_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "USDT"][0].close) > Number(response.data["exch" + d.tokenSymbol + "USDT"][0].open)) {
    //                   $("." + d.tokenSymbol + 'USDT_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "USDT_priceArrow").addClass("fa-arrow-up")
    //                 }

    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "USDT"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "USDT"][0].close - response.data["exch" + d.tokenSymbol + "USDT"][0].open) / response.data["exch" + d.tokenSymbol + "USDT"][0].open) * 100).toFormat(2, 1)
    //                 }

    //                 $("." + d.tokenSymbol + "USDT_priceChangePercent").html(percentage)

    //                 $("." + d.tokenSymbol + "USDT_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "USDT"][0].close) - Number(response.data["exch" + d.tokenSymbol + "USDT"][0].open)).toFormat(2, 1))

    //                 $("." + d.tokenSymbol + "USDT_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "USDT"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "USDT_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "USDT"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "USDT_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].high).toFormat(2, 1))

    //               }

    //             }
    //           }
    //           if (d.INR == "1") {
    //             if (Array.isArray(response.data["exch" + d.tokenSymbol + "INR"])) {
    //               if (response.data["exch" + d.tokenSymbol + "INR"].length > 0) {
    //                 $("." + d.tokenSymbol + "INR_price").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].close).toFormat(2, 1))

    //                 if (Number(response.data["exch" + d.tokenSymbol + "INR"][0].close) < Number(response.data["exch" + d.tokenSymbol + "INR"][0].open)) {
    //                   $("." + d.tokenSymbol + 'INR_priceColor').addClass("red_clr")
    //                   $("#" + d.tokenSymbol + "INR_priceArrow").addClass("fa-arrow-down")
    //                 } else if (Number(response.data["exch" + d.tokenSymbol + "INR"][0].close) > Number(response.data["exch" + d.tokenSymbol + "INR"][0].open)) {
    //                   $("." + d.tokenSymbol + 'INR_priceColor').addClass("green_clr")
    //                   $("#" + d.tokenSymbol + "INR_priceArrow").addClass("fa-arrow-up")
    //                 }

    //                 let percentage = new BigNumber(0).toFormat(2, 1);
    //                 if (response.data["exch" + d.tokenSymbol + "INR"][0].open !== 0) {
    //                   percentage = new BigNumber(((response.data["exch" + d.tokenSymbol + "INR"][0].close - response.data["exch" + d.tokenSymbol + "INR"][0].open) / response.data["exch" + d.tokenSymbol + "INR"][0].open) * 100).toFormat(2, 1)
    //                 }

    //                 $("." + d.tokenSymbol + "INR_priceChangePercent").html(percentage)

    //                 $("." + d.tokenSymbol + "INR_priceChange").html(new BigNumber(Number(response.data["exch" + d.tokenSymbol + "INR"][0].close) - Number(response.data["exch" + d.tokenSymbol + "INR"][0].open)).toFormat(2, 1))

    //                 $("." + d.tokenSymbol + "INR_priceHigh").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].high).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "INR_priceLow").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].low).toFormat(2, 1))
    //                 $("." + d.tokenSymbol + "INR_priceVolume").html(new BigNumber(response.data["exch" + d.tokenSymbol + "INR"][0].high).toFormat(2, 1))

    //               }

    //             }
    //           }
    //         }
    //       })
    //     }

    //     // this.setState({
    //     //     trades:response.data,
    //     //     isLoaded: true,
    //     //     loadedTrades: true
    //     // }) 
    //   })
  }

  async getmyOrders() {
    await axios.post(BASE_URL + `order/get/id`, {
      "pair": this.props.match.params.pairCode,
      "coinName": this.props.match.params.coinCode,
      "status": 0,
      "userId": this.state.userId,
    })
      .then(res => {
        this.setState({ myorderPrice: [] })
        // console.log("userbalance",res.data.data);
        if (res.data.message == "success") {

          res.data.data.map(row => {
            this.setState({
              myorderPrice: this.state.myorderPrice.concat(row.price)
            })
          })
          // console.log("myorderPrice",this.state.myorderPrice)
          this.setState({ openOrderrows: res.data.data })
        }
      })

    await axios.post(BASE_URL + `order/get/id`, {
      "pair": this.props.match.params.pairCode,
      "coinName": this.props.match.params.coinCode,
      "status": 2,
      "userId": this.state.userId,
    })
      .then(res => {

        // console.log("userbalance",res.data.data);
        if (res.data.message == "success") {


          this.setState({ cancelOrderrows: res.data.data })
        }
      })

    await axios.post(BASE_URL + `order/get/id`, {
      "pair": this.props.match.params.pairCode,
      "coinName": this.props.match.params.coinCode,
      "status": 1,
      "userId": this.state.userId,
    })
      .then(res => {

        // console.log("userbalance",res.data.data);
        if (res.data.message == "success") {


          this.setState({ historyOrderrows: res.data.data })
        }
      })
  }

  async getmyBalance() {
    await axios.post(BASE_URL + `userBalance/get`, {
      "userId": this.state.userId
    })
      .then(async res => {
        // console.log("user id",this.state.userId)

        // console.log("userbalance",res.data.data[this.props.match.params.coinCode]);
        if (res.data.message == "success") {
          this.setState({
            userBalanceCoin: res.data.data[this.props.match.params.coinCode],
            userBalancePair: res.data.data[this.props.match.params.pairCode],
            userBalanceFESS: res.data.data['FESS']
          })

          await axios.get(BASE_URL + `feespack/get/all`)
            .then(async res => {
                // console.log(res);
                // console.log(res.data.data);
                if (res.data.message === "success") {

                    this.setState({
                        feesPacks: res.data.data
                    }) 

                    this.state.feesPacks.map(row => {
                      if(row.title == "General FESS") {
                        this.setState({ tradingPercent: row.feePercent })
                      } 
                    })

                    if (this.state.userBalanceFESS >= this.state.FESSstakeamt) {
                      this.setState({ manageFee: true })
                    }

                    if (Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
                        await axios.post(BASE_URL + `feespack/get/historyrev`, {
                          "userId": this.state.userId 
                        })
                        .then(res => {
                            // console.log(res);
                            // console.log(res.data.data);
                            if (res.data.message === "success") { 
            
                              {res.data.data.map((rowMain,xid) => {
                                //  console.log("myhistory",xid)
                                if(xid == 0) {
                                  this.setState({ tradingPercent: rowMain.feePercent })
                                }
                                //  console.log("myhistory",xid)
                                //  console.log("myhistory",rowMain.title)
                              }).reverse()}
                                
                            }
                        })
                      this.setState({ loadFeeSubs: true })
                    } 
                    
                }
          })
 
          // if (this.state.userBalanceFESS >= this.state.FESSstakeamt || Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
          //   if (Math.floor(Date.now() / 1000) < Number(Number(this.state.userData.feesValidId) + Number(this.state.userData.feesSubsTime))) {
          //     this.setState({ tradingPercent: "0" })
          //   } else {
          //     this.setState({ tradingPercent: "0.1" })
          //   }
          //   this.setState({ loadFeeSubs: true })
          // } else {
          //   this.setState({ tradingPercent: "0.2" })
          // }


        }
      })
  }


 getFormatedTime(time){
        let T = time.split(" ")
        let T1 = T[1];
        let T3 = T1.replaceAll("-",":")
        let T4 = new Date(T[0] + " " + T3 ).getTime()+(19800000)
        return T4;
  }

  updateOrders(){
    const usrID  = localStorage.getItem('userDetails');
    if (usrID && this.props.match.params.coinCode != 'BTXT') {
      let sym = this.props.match.params.coinCode + this.props.match.params.pairCode;
      axios.get(BASE_URL + `order/updateOrderStatus?symbol=${sym?.toUpperCase()}&userId=${usrID}`).then()
    }
  }

  async componentDidMount() {
    this.updateOrders()
    const usrID  = localStorage.getItem('userDetails');
    // if (usrID && this.props.match.params.coinCode != 'BTXT') {
    //   let sym = this.props.match.params.coinCode + this.props.match.params.pairCode;
    //   axios.get(BASE_URL + `order/updateOrderStatus?symbol=${sym?.toUpperCase()}&userId=${usrID}`).then()
    // }

    if(usrID){
      if(this.props.match.params.coinCode == 'BTXT'){
        setInterval(()=>{
          this.getBithindDetails();
          this.getmyOrders();
          this.getmyBalance();
        },20000)
      }else{
        setInterval(()=>{
          this.getmyOrders();
          this.getmyBalance();
          this.updateOrders()
        },10000)
      }
    }


    if (localStorage.getItem('tradeMode')) {
      if(localStorage.getItem('tradeMode') == "light_mode") {
        this.setState({
          alignment : 'left',
          theme: 'light_mode',
          backgroundcolor1: '#FDB813',
          backgroundcolor2: '#fff'
        })
      } else {
        this.setState({
          alignment : 'right',
          theme: 'dark_mode',
          backgroundcolor1: '#fff',
          backgroundcolor2: '#FDB813'
        })
      }
  
    }

    socket.on('connect', msg => {
      this.setState({ socketConnect: true })
    })
    socket.on('disconnect', msg => {
      this.setState({ socketConnect: false })
    })

    socket.on('orderUpdate', msg => {
      this.getBithindDetails()
      // this.getBithindPrice()
      this.getmyOrders()
      this.getmyBalance()
    })

    socket.on('orderDetails', msg => {

      if (msg.executionType == "TRADE") {


        // console.log("mssg",msg)
        // console.log("mssg",this.state.userBalanceCoin)

        this.state.openOrderrows.map((row, xid) => {

          if (row.binanceorderId == msg.orderId) {
            // console.log("mssg","ORDER FOUND")

            this.setState({ myorderPrice: [] })
            const item = {
              coinName: row.coinName,
              pair: row.pair,
              orderType: row.orderType,
              orderMode: row.orderMode,
              price: row.price,
              amount: row.amount,
              total: row.total,
              dateTime: row.dateTime,
              tredingFee: row.tredingFee,
              binanceorderId: row.binanceorderId,
              _id: row._id
            };

            this.setState({
              historyOrderrows: [...this.state.historyOrderrows, item]
            });

            if (row.orderType == "Sell") {
              this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) + Number(row.amount * row.price)).toFixed(10).slice(0, -4) })
            } else {
              this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) + Number(row.amount)).toFixed(10).slice(0, -4) })
            }

            const openOrderrows = [...this.state.openOrderrows]
            openOrderrows.splice(xid, 1)
            this.setState({ openOrderrows })



            this.state.openOrderrows.map(row => {
              // console.log("myorderPrice",row)
              this.setState({
                myorderPrice: this.state.myorderPrice.concat(row.price)
              })
            })



          }

        })

      }

    });

    // const clean = await client.ws.user(msg => {
    //   console.log(msg)
    // })


    if (this.props.match.params.coinCode && this.props.match.params.pairCode) {
      this.setState({
        coinCode: this.props.match.params.coinCode,
        pairCode: this.props.match.params.pairCode
      })


      let symbol = (this.props.match.params.coinCode + this.props.match.params.pairCode).toLowerCase();
      // let symbol = 'BTCUSDT'.toLowerCase(); 
      // console.log(this.state.coinCode);
      // console.log(symbol);

      // PRICE SOCKET TO START
      if (this.state.exchangeType == "binance") { 
        this._connectSocketStreams(this.streams.map(i => `${symbol}${i}`));
      }



      await axios.get(BASE_URL + `ercToken/get/all`)
        .then(res => {
          // console.log('erc api res  ',res);
          if (res.data.message == "success") {
            this.setState({ tokenData: res.data.data })
            // console.log("tokendata",this.state.tokenData)

            {
              this.state.tokenData.map((d, idx) => {
                if (d.tokenSymbol == "FESS") {
                  this.setState({ FESSstakeamt: d.minstake })
                }
                if (d.tokenSymbol == this.props.match.params.coinCode) {
                  if (this.props.match.params.pairCode == "BTC") {
                    this.setState({ minAmt: d.minAmtBTC })
                    this.setState({ minTotal: d.minTotalBTC })
                    this.setState({ priceDecimal: d.priceDecimalBTC })
                    this.setState({ amtDecimal: d.amtDecimalBTC })
                  }
                  if (this.props.match.params.pairCode == "ETH") {
                    this.setState({ minAmt: d.minAmtETH })
                    this.setState({ minTotal: d.minTotalETH })
                    this.setState({ priceDecimal: d.priceDecimalETH })
                    this.setState({ amtDecimal: d.amtDecimalETH })
                  }
                  if (this.props.match.params.pairCode == "USDT") {
                    this.setState({ minAmt: d.minAmtUSDT })
                    this.setState({ minTotal: d.minTotalUSDT })
                    this.setState({ priceDecimal: d.priceDecimalUSDT })
                    this.setState({ amtDecimal: d.amtDecimalUSDT })
                  }
                  if (this.props.match.params.pairCode == "INR") {
                    this.setState({ minAmt: d.minAmtINR })
                    this.setState({ minTotal: d.minTotalINR })
                    this.setState({ priceDecimal: d.priceDecimalINR })
                    this.setState({ amtDecimal: d.amtDecimalINR })
                  }
                }

                if (this.props.match.params.coinCode == d.tokenSymbol) {
                  // if (d.exchange == "Bitallx") {
                  //   this.setState({ exchangeType: "Bitallx" });
                  // }
                }

                if (d.tActive == true) {
                  if (d.BTC == "1") {
                    this.setState({ [d.tokenSymbol + 'BTC_favcoin']: false })
                  }
                  if (d.ETH == "1") {
                    this.setState({ [d.tokenSymbol + 'ETH_favcoin']: false })
                  }
                  if (d.USDT == "1") {
                    this.setState({ [d.tokenSymbol + 'USDT_favcoin']: false })
                  }
                  if (d.INR == "1") {
                    this.setState({ [d.tokenSymbol + 'INR_favcoin']: false })
                  }
                }
              })
            }
            // userBalanceCoin: res.data.data[this.props.match.params.coinCode], 
            // userBalancePair: res.data.data[this.props.match.params.pairCode],
            if (this.props.match.params.pairCode == "INR" || this.props.match.params.pairCode == "BTXT" || this.props.match.params.coinCode == "BTXT" ) {
              // console.log('first')
              this.setState({ exchangeType: "Bitallx" });
              this.getBithindDetails()
            }

            let usdtpair = this.state.tokenData.map(i => `${i.tokenSymbol.toLowerCase() + "usdt@ticker"}`)
            let btcpair = this.state.tokenData.map(i => `${i.tokenSymbol.toLowerCase() + "btc@ticker"}`)
            let ethpair = this.state.tokenData.map(i => `${i.tokenSymbol.toLowerCase() + "eth@ticker"}`)


            // PRICE SOCKET TO START  
            // this._connectTickerStreams([btcpair.join('/')]) 
            this._connectTickerStreams([usdtpair.join('/') + "/" + btcpair.join('/') + "/" + ethpair.join('/')]) 
            // this._connectTickerStreams([usdtpair]) 

            // NEED TO START 

            // axios({
            //   method: 'get',
            //   // url: `https://cors-anywhere.herokuapp.com/https://www.binance.com/api/v1/aggTrades?limit=${this.tradesCount}&symbol=${symbol}`
            //   url: `https://www.binance.com/api/v3/ticker/24hr`
            // })
            // .then(response => {
            //   response.data.map(row => {
            //     // console.log(row)
            //     this.state.tokenData.map(i => {
            //         if(i.tokenSymbol+"USDT" == row.symbol || i.tokenSymbol+"BTC" == row.symbol || i.tokenSymbol+"ETH" == row.symbol) {

            //             this.setState({ [row.symbol+'_price']: row.lastPrice })
            //             this.setState({ [row.symbol+'_priceChangePercent']: row.priceChangePercent })
            //             this.setState({ [row.symbol+'_priceHigh']: row.highPrice })
            //             this.setState({ [row.symbol+'_priceLow']: row.lowPrice })
            //             this.setState({ [row.symbol+'_priceVolume']: row.volume })

            //         }
            //     })
            //   })
            // })

          }
        })


      // await this.getBitallxDetails()
      // await this.getBitallxPrice()
      // this._connectTickerStreams(['!ticker@arr'])            
      // this._connectTickerStreams(['btcusdt@ticker','ethusdt@ticker'])            


      symbol = (this.props.match.params.coinCode + this.props.match.params.pairCode).toUpperCase();
      axios({
        method: 'get',
        // url: `https://cors-anywhere.herokuapp.com/https://www.binance.com/api/v1/aggTrades?limit=${this.tradesCount}&symbol=${symbol}`
        url: `https://www.binance.com/api/v1/aggTrades?limit=${this.tradesCount}&symbol=${symbol}`
      })
        .then(response => {
          // console.log("tradedata",response.data)
          this.setState({
            trades: response.data,
            isLoaded: true,
            loadedTrades: true
          })

          
          let a = this.state.trades[this.state.trades.length - 1];
          var decimalPrice ="";

          


          if (this.state.coinCode == "BTC") {
            decimalPrice = Number(a?.p).toFixed(6)
          } else if (this.state.coinCode == "ETH") {
            decimalPrice = Number(a?.p).toFixed(4)
          } else if(this.state.coinCode == "BNB"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "TRX"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "XRP"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "XLM"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "DOGE"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "BTT"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "VET"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "RSR"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "VTHO"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "ENJ"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "LINK"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "MATIC"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "SHIB"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else if(this.state.coinCode == "USDT"){
            decimalPrice = Number(a?.p).toFixed(3)
          }else {
            decimalPrice = Number(a.p)
          }
          
          this.setState({
            buyPrice: decimalPrice,
            sellPrice : decimalPrice
          })
          // console.log('>>>>>>>>>>>>>>>>>>>>>>   ',decimalPrice)



        })






      if (localStorage.getItem('userDetails')) {

        await this.setState({ userId: localStorage.getItem('userDetails') })

        await this.getmyfavcoin()

        await axios.post(BASE_URL + `userInformation/get`, {
          "id": this.state.userId
        })
          .then(res => {
            if (res.data.message == "success") {
              this.setState({
                userData: res.data.data
              })

            }


          })


        await this.getmyBalance()
        await this.getmyOrders()



      }



    } else {
      window.location.href = "/trade/BTC/USDT";
      const usrID  = localStorage.getItem('userDetails');
      this.updateOrders()
      // if (usrID && this.props.match.params.coinCode != 'BTXT') {
      //   let sym = this.props.match.params.coinCode + this.props.match.params.pairCode;
      //   let a = await axios.get(BASE_URL + `order/updateOrderStatus?symbol=${sym?.toUpperCase()}&userId=${usrID}`)
      // }      
    }

    axios.get(`${BASE_URL}coinpair/getall`).then((res)=>{
      if (res.data && res.data.statusCode === 200) {

        let a = res.data.data.find((data)=>  data.primaryCoin == this.state.coinCode)
        this.setState({ allCoinPairsData: res.data.data , currentCoinPairDecimalData : a })
 
        if(this.props.match.params.coinCode === 'BTXT' || this.props.match.params.pairCode === 'BTXT'){
          const currentPair = res.data.data.find(e=> e.primaryCoin.toUpperCase() === this.props.match.params.coinCode && e.secondaryCoin.toUpperCase() === this.props.match.params.pairCode)
          
        this.setState({ tokenPairData: res.data.data })
        this.setState({
          buyPrice: (currentPair?.price || 0).toFixed(3),
          sellPrice : (currentPair?.price || 0).toFixed(3),
          currentPair: currentPair,
          change24H : currentPair?.change24Hr,
          changeHigh : currentPair?.high24Hr,
          changeLow : currentPair?.low24Hr,
          volume : currentPair?.volume
        })
      }

          const currentPair1 = res.data.data.find(e=> e.primaryCoin.toUpperCase() === "BTXT" && e.secondaryCoin.toUpperCase() === this.props.match.params.pairCode)
          let btxt_decimal = currentPair1?.priceDecimal;

        $(".BTXT" + this.props.match.params.pairCode + '_priceColor').removeClass("red_clr")
        $(".BTXT" + this.props.match.params.pairCode + '_priceColor').removeClass("green_clr")
        $(".BTXT" + this.props.match.params.pairCode + '_priceColor').addClass("green_clr")

        $(".BTXT" + this.props.match.params.pairCode + "_price").html(new BigNumber(currentPair1?.price).toFixed(Number(btxt_decimal)))

        // $(".BTXT" + this.props.match.params.pairCode + "_price").html(new BigNumber(currentPair1?.price).toFixed(currentPair1.primaryCoin == 'BTXT' ? decimalData[currentPair1?.primaryCoin+currentPair1?.secondaryCoin]['D'] : decimalData[currentPair1?.primaryCoin]['D']))
        $(".BTXT" + this.props.match.params.pairCode + "_priceChangePercent").html(new BigNumber(currentPair1?.change24Hr).toFormat(2, 1))

      }
      
    })



  }


  getCoinPairAll(value){
    axios.get(`${BASE_URL}coinpair/getall`).then((res)=>{
      if (res.data && res.data.statusCode === 200) {
      //   if(this.props.match.params.coinCode === value || this.props.match.params.pairCode === value){
      //     const currentPair = res.data.data.find(e=> e.primaryCoin.toUpperCase() === this.props.match.params.coinCode && e.secondaryCoin.toUpperCase() === this.props.match.params.pairCode)
          
        // this.setState({ allCoinPairsData: res.data.data })
      //   // this.setState({
      //   //   buyPrice: currentPair?.price || 0,
      //   //   sellPrice : currentPair?.price || 0,
      //   //   currentPair: currentPair,
      //   //   change24H : currentPair?.change24Hr,
      //   //   changeHigh : currentPair?.high24Hr,
      //   //   changeLow : currentPair?.low24Hr,
      //   //   volume : currentPair?.volume
      //   // })
      // }

          const currentPair1 = res.data.data.find(e=> e.primaryCoin.toUpperCase() === "BTXT" && e.secondaryCoin.toUpperCase() === value)
            let btxt_decimal = currentPair1?.priceDecimal;
            // let other_decimal = 


        $(".BTXT" + value + '_priceColor').removeClass("red_clr")
        $(".BTXT" + value + '_priceColor').removeClass("green_clr")
        $(".BTXT" + value + '_priceColor').addClass("green_clr")
        $(".BTXT" + value + "_price").html(new BigNumber(currentPair1?.price).toFixed(Number(btxt_decimal)))

        // $(".BTXT" + value + "_price").html(new BigNumber(currentPair1?.price).toFixed(currentPair1.primaryCoin == 'BTXT' ? decimalData[currentPair1?.primaryCoin+currentPair1?.secondaryCoin]['D'] : decimalData[currentPair1?.primaryCoin]['D']))
        $(".BTXT" + value + "_priceChangePercent").html(new BigNumber(currentPair1?.change24Hr).toFormat(2, 1))

      }
      
    })
  }



  sellBalancePer(percent) {
    let calculatedBalance = (this.state.userBalanceCoin / 100) * percent;
    let calculatedBalanceString = calculatedBalance.toString()
    let D = Number(this.state.currentCoinPairDecimalData.amountDecimal)+1
    let num = calculatedBalanceString.slice(0, (calculatedBalanceString.indexOf("."))+D);


    this.setState({ sellVolume: num })
    if (this.state.sellType != "MARKET") {
      this.setState({ sellTotal: (num * this.state.sellPrice).toFixed(10).slice(0, -4) })
    } else {
      var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
      // console.log(lastPrice) 
      if(lastPrice!=undefined){
        lastPrice =  lastPrice.replace(/,/g, "");
      }
      
      

      this.setState({ sellTotal: (Number(num) * Number(lastPrice)).toFixed(10).slice(0, -4) })
    }
    // console.log(calculatedBalance)
  }

  buyBalancePer(percent) {

    var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
    if(lastPrice!=undefined){
      lastPrice =  lastPrice.replace(/,/g, "");
    }
    

    let calculatedBalance = (this.state.userBalancePair / 100) * percent;
    let calculatedBalanceString = calculatedBalance.toString()
    let D = Number(this.state.currentCoinPairDecimalData.amountDecimal)+1
    let num = calculatedBalanceString.slice(0, (calculatedBalanceString.indexOf("."))+D);

    
    if (this.state.buyType != "MARKET") {
      let numCal = (num) / Number(this.state.buyPrice) ;
      let numString = numCal.toString();
      let D = Number(this.state.currentCoinPairDecimalData.amountDecimal)+1

      let calculatedvolume = numString.slice(0, (numString.indexOf("."))+D);
      this.setState({ 
        buyVolume: calculatedvolume, 
        buyTotal : num  })
      // this.setState({ buyTotal: (calculatedBalance).toFixed(10).slice(0, -4) })
    } else {
      this.setState({ 
        buyVolume: num / lastPrice, 
        buyTotal : num 
      })
      // this.setState({ buyTotal: (calculatedBalance).toFixed(10).slice(0, -4) })
    }
    // console.log(calculatedBalance)
  }

  handelTradeHistory = (price, amount, mode) => {
    // console.log("coin","coinTradePage",price,amount,mode)
    if (mode == "Buy") {
      this.setState({
        buyType: "LIMIT",
        buyPrice: Number(price),
        buyVolume: Number(amount),
        buyTotal: Number(price * amount).toFixed(4),
      })
      this.handleBuyModal()
    } else {
      this.setState({
        sellType: "LIMIT",
        sellPrice: Number(price),
        sellVolume: Number(amount),
        sellTotal: Number(price * amount).toFixed(4),
      })
      this.handleSellModal()
    }
  }

  handleSubmitBuy = (event) => {

    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let orderProcess = true;
    let tradingFess = Number(this.state.tradingPercent);
    let fs
    this.setState({ LodingOrder: true })
    // if(tradingFess == 0.2) {
    //   fs = 0
    // } else if(tradingFess == 0.1) {
    //   fs = 1
    // } else {
    //   fs = 2
    // }
    if (this.state.buyType == "MARKET") {
      var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
      // console.log(lastPrice) 
      if(lastPrice!=undefined){
        lastPrice =  lastPrice.replace(/,/g, "");
      }
      let buyTotal = Number(Number(lastPrice) * Number(this.state.buyVolume))
      // console.log(buyTotal) 
      // console.log(Number(this.state.minTotal))
      if (Number(buyTotal) <= Number(this.state.minTotal)) {
        orderProcess = false;

        notify.show("Order total should be more than " + this.state.minTotal + " " + this.props.match.params.pairCode, "error");
      }
      if (Number(buyTotal) > Number(this.state.userBalancePair)) {
        orderProcess = false;

        notify.show("Not enough balance to execute this order", "error");
      }
      // console.log(Number(Number(lastPrice) * Number(this.state.sellVolume))) 
    }
    if (this.state.buyPrice == null || this.state.buyPrice == "") {
      orderProcess = false;

      notify.show("Please enter a valid price", "error");
    } else
      if (this.state.buyVolume == null || this.state.buyVolume == "") {
        orderProcess = false;

        notify.show("Please enter a valid amount", "error");
      } else
        if (this.state.buyTotal == null || this.state.buyTotal == "") {
          orderProcess = false;

          notify.show("Please enter a valid total", "error");
        } else
          if (Number(this.state.buyTotal) > Number(this.state.userBalancePair)) {
            orderProcess = false;

            notify.show("Not enough balance to execute this order", "error");
          }
    if (this.state.buyType != "MARKET") {
      if (Number(this.state.buyTotal) <= Number(this.state.minTotal)) {
        orderProcess = false;

        notify.show("Order total should be more than " + this.state.minTotal + " " + this.props.match.params.pairCode, "error");
      }
    }
    if (Number(this.state.buyVolume) <= Number(this.state.minAmt)) {
      orderProcess = false;

      notify.show("Order amount should be more than " + this.state.minAmt + " " + this.props.match.params.coinCode, "error");
    }


    if (orderProcess == true) {

      let tradingFessAmt = (Number(this.state.buyVolume) / 100) * tradingFess

      let pricesubmit = 0
      let totalsubmit = 0
      let statusubmit = 0
      if (this.state.buyType == "MARKET") {
        pricesubmit = 0
        totalsubmit = 0
        statusubmit = 1
      } else {
        pricesubmit = Number(this.state.buyPrice).toFixed(12).slice(0, -4)
        totalsubmit = Number(this.state.buyTotal).toFixed(6).slice(0, -4)
        statusubmit = 0
      }


      var newDecimalprice;
      var newDecimalAmount;
      var decimalVal ;
      if (this.props.match.params.coinCode == "BTC") {
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'BTC')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal)
        decimalVal = decimalValue.amountDecimal;
      } else if (this.props.match.params.coinCode == "ETH") {
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'ETH')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      } else if(this.props.match.params.coinCode == "BNB"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'BNB')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "TRX"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'TRX')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "XRP"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'XRP')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal
      }else if(this.props.match.params.coinCode == "XLM"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'XLM')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "DOGE"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'DOGE')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "BTT"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'BTT')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "VET"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'VET')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "RSR"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'RSR')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "VTHO"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'VTHO')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal)
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "ENJ"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'ENJ')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "LINK"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'LINK')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "MATIC"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'MATIC')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "SHIB"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'SHIB')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.buyVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }
      else {
        newDecimalprice = Number(pricesubmit)
        newDecimalAmount = Number(this.state.buyVolume)
        decimalVal = 0;
      }



      let buyUrl = 'order/add2';
      // if (this.state.exchangeType == "binance") {
      //   buyUrl = 'order/add2';
      // }
      // if (this.state.exchangeType == "Bitallx") {
      //   buyUrl = 'tradenew/order/add';
      // }

      let sendKey = {
        "orderType": "Buy",
        "userId": this.state.userId,
        "price": newDecimalprice,
        "amount": newDecimalAmount,
        "orderMode": this.state.buyType,
        "tradingFeePercent": this.state.tradingPercent,
        "tredingFee": Number(tradingFessAmt).toFixed(10).slice(0, -4),
        "total": totalsubmit,
        "priceDecimal": this.state.priceDecimal,
        "decimal": decimalVal,
        "date": 0,
        "time": 0,
        "coinName": this.props.match.params.coinCode,
        "pair": this.props.match.params.pairCode,
        "status": statusubmit
      }
console.log(sendKey)
      axios.post(BASE_URL + buyUrl, sendKey)
        .then(res => {

          if (res.data.message == "addedSuccessfully") {
            let resdata = res.data.data[0]
            // this.setState((prevState, props) => {
            //   const row = {coin: resdata.coinName, pair: resdata.pair, type: resdata.orderType, price: resdata.price,  amount: resdata.amount, total: resdata.total, datetime: resdata.dateTime, fees: resdata.tredingFee};
            //   return { openOrderrows: [...prevState.openOrderrows, row] };
            // });

            if (resdata.orderMode == "LIMIT") {
              if (resdata.status == 0) {
                this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) - Number(resdata.total)).toFixed(10).slice(0, -4) })
              } else {
                this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) + Number(resdata.amount)).toFixed(10).slice(0, -4) })
                this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) - Number(resdata.total)).toFixed(10).slice(0, -4) })
              }
            } else {
              this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) + Number(resdata.amount)).toFixed(10).slice(0, -4) })
              this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) - Number(resdata.total)).toFixed(10).slice(0, -4) })
            }


            if (resdata.orderMode == "LIMIT") {

              this.setState({ myorderPrice: [] })
              const item = {
                coinName: resdata.coinName,
                pair: resdata.pair,
                orderType: resdata.orderType,
                orderMode: resdata.orderMode,
                price: resdata.price,
                amount: resdata.amount,
                total: resdata.total,
                dateTime: resdata.dateTime,
                tredingFee: resdata.tredingFee,
                binanceorderId: resdata.binanceorderId,
                _id: resdata._id
              };

              if (resdata.status == 0) {

                this.setState({
                  openOrderrows: [...this.state.openOrderrows, item]
                });

                this.state.openOrderrows.map(row => {
                  // console.log("myorderPrice",row)
                  this.setState({
                    myorderPrice: this.state.myorderPrice.concat(row.price)
                  })
                })
              } else {
                this.setState({
                  historyOrderrows: [...this.state.historyOrderrows, item]
                });
              }

            } else {
              const item = {
                coinName: resdata.coinName,
                pair: resdata.pair,
                orderType: resdata.orderType,
                orderMode: resdata.orderMode,
                price: resdata.price,
                amount: resdata.amount,
                total: resdata.total,
                dateTime: resdata.dateTime,
                tredingFee: resdata.tredingFee,
                binanceorderId: resdata.binanceorderId,
                _id: resdata._id
              };
              this.setState({
                historyOrderrows: [...this.state.historyOrderrows, item]
              });
            }

            //                   amount: "0.998000"
            // coinName: "BTC"
            // dateTime: "2020-05-08 10-15-44"
            // isDeleted: false
            // orderType: "Sell"
            // pair: "USDT"
            // price: "1.000000"
            // status: 0
            // timestamp: 1588947269
            // total: "1.000000"
            // tredingFee: "0.002000"
            // userId: "5eb38e25848fe7050cf99ddf"
            // _id: "5eb569444ad12637f1510b8f"


            if (resdata.status == 0) {

              notify.show("Buy order submitted successfully", "warning");
            } else {

              notify.show("Buy order executed successfully", "success");
            }
            this.setState({
              // buyPrice: "",
              buyVolume: "",
              buyTotal: "",
              buyType: "LIMIT",
            })
            this.setState({ LodingOrder: false  })
            // this.setState({ userBalanceCoin: res.data.data[this.props.match.params.coinCode], userBalancePair: res.data.data[this.props.match.params.pairCode] })
            this.getBithindDetails()
            this.getBithindPrice()
            this.getmyOrders()
            this.getmyBalance()
          } else {

            notify.show("Something went wrong...", "error");
            this.setState({ LodingOrder: false })
          }


        })
    } else {

      this.setState({ LodingOrder: false })
    }
    // console.log(orderProcess);
    // console.log(this.state.sellVolume);

  }


  handleSubmitSell = (event) => {

    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    let orderProcess = true;
    let tradingFess = Number(this.state.tradingPercent);
    this.setState({ LodingOrder: true })
   
    if (this.state.sellType == "MARKET") {
      var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
      // console.log(lastPrice) 
      if(lastPrice!=undefined){
        lastPrice =  lastPrice.replace(/,/g, "");
      }
      let sellTotal = Number(Number(lastPrice) * Number(this.state.sellVolume))
      if (Number(sellTotal) <= Number(this.state.minTotal)) {
        orderProcess = false;

        notify.show("Order total should be more than " + this.state.minTotal + " " + this.props.match.params.pairCode, "error");
      }
      // console.log(Number(Number(lastPrice) * Number(this.state.sellVolume))) 
    }
    if (this.state.sellPrice == null || this.state.sellPrice == "") {
      orderProcess = false;

      notify.show("Please enter a valid price", "error");
    } else
      if (this.state.sellVolume == null || this.state.sellVolume == "") {
        orderProcess = false;

        notify.show("Please enter a valid amount", "error");
      } else
        if (this.state.sellTotal == null || this.state.sellTotal == "") {
          orderProcess = false;

          notify.show("Please enter a valid total", "error");
        } else
          if (Number(this.state.sellVolume) > Number(this.state.userBalanceCoin)) {
            orderProcess = false;

            notify.show("Not enough balance to execute this order", "error");
          }
    if (this.state.sellType != "MARKET") {
      if (Number(this.state.sellTotal) <= Number(this.state.minTotal)) {
        orderProcess = false;

        notify.show("Order total should be more than " + this.state.minTotal + " " + this.props.match.params.pairCode, "error");
      }
    }
    if (Number(this.state.sellVolume) <= Number(this.state.minAmt)) {
      orderProcess = false;

      notify.show("Order amount should be more than " + this.state.minAmt + " " + this.props.match.params.coinCode, "error");
    }

    if (orderProcess == true) {

      let tradingFessAmt = (Number(this.state.sellVolume) / 100) * tradingFess

      let pricesubmit = 0
      let totalsubmit = 0
      let statusubmit = 0
      if (this.state.sellType == "MARKET") {
        pricesubmit = 0
        totalsubmit = 0
        statusubmit = 1
      } else {
        pricesubmit = Number(this.state.sellPrice).toFixed(12).slice(0, -4)
        totalsubmit = Number(this.state.sellTotal).toFixed(12).slice(0, -4)
        statusubmit = 0
      }
  


      var newDecimalprice;
      var newDecimalAmount;
      var decimalVal ;
      if (this.props.match.params.coinCode == "BTC") {
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'BTC')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal)
        decimalVal = decimalValue.amountDecimal;
      } else if (this.props.match.params.coinCode == "ETH") {
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'ETH')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      } else if(this.props.match.params.coinCode == "BNB"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'BNB')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "TRX"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'TRX')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "XRP"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'XRP')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal
      }else if(this.props.match.params.coinCode == "XLM"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'XLM')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "DOGE"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'DOGE')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "BTT"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'BTT')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "VET"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'VET')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "RSR"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'RSR')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "VTHO"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'VTHO')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal)
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "ENJ"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'ENJ')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "LINK"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'LINK')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal);
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "MATIC"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'MATIC')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "SHIB"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'SHIB')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }else if(this.props.match.params.coinCode == "BTXT"){
        let decimalValue = this.state.allCoinPairsData.find((data)=> data.primaryCoin == 'BTXT')
        newDecimalprice = Number(pricesubmit).toFixed(decimalValue.priceDecimal)
        newDecimalAmount = Number(this.state.sellVolume).toFixed(decimalValue.amountDecimal);
        decimalVal = decimalValue.amountDecimal;
      }
      else {
        newDecimalprice = Number(pricesubmit)
        newDecimalAmount = Number(this.state.sellVolume)
        decimalVal = 0;
      }


      let sellUrl = 'order/add2';
      // if (this.state.exchangeType == "binance") {
      //   sellUrl = 'order/add2';
      // }
      // if (this.state.exchangeType == "Bitallx") {
      //   sellUrl = 'tradenew/order/add';
      // }
      let sendKey = {
        "orderType": "Sell",
        "userId": this.state.userId,
        "price": newDecimalprice,
        "amount": newDecimalAmount,
        "orderMode": this.state.sellType,
        "tradingFeePercent": this.state.tradingPercent,
        "tredingFee": Number(tradingFessAmt).toFixed(10).slice(0, -4),
        "total": totalsubmit,
        "priceDecimal": this.state.priceDecimal,
        "decimal":decimalVal,
        "date": 0,
        "time": 0,
        "coinName": this.props.match.params.coinCode,
        "pair": this.props.match.params.pairCode,
        "status": statusubmit
      }
      console.log("sell order " , sendKey )
      axios.post(BASE_URL + sellUrl,sendKey )
        .then(res => {

          if (res.data.message == "addedSuccessfully") {
            let resdata = res.data.data[0]
            let amountwithoutfees
         

            if (resdata.orderMode == "LIMIT") {
              if (resdata.status == 0) {
                this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) - Number(this.state.sellVolume)).toFixed(10).slice(0, -4) })
              } else {
                this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) - Number(this.state.sellVolume)).toFixed(10).slice(0, -4) })
                this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) + Number(resdata.amount * resdata.price)).toFixed(10).slice(0, -4) })
              }
            } else {
              this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) - Number(this.state.sellVolume)).toFixed(10).slice(0, -4) })
              this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) + Number(resdata.amount * resdata.price)).toFixed(10).slice(0, -4) })
            }


            if (resdata.orderMode == "LIMIT") {

              this.setState({ myorderPrice: [] })
              const item = {
                coinName: resdata.coinName,
                pair: resdata.pair,
                orderType: resdata.orderType,
                orderMode: resdata.orderMode,
                price: resdata.price,
                amount: resdata.amount,
                total: resdata.total,
                dateTime: resdata.dateTime,
                tredingFee: resdata.tredingFee,
                binanceorderId: resdata.binanceorderId,
                _id: resdata._id
              };

              if (resdata.status == 0) {
                this.setState({
                  openOrderrows: [...this.state.openOrderrows, item]
                });

                this.state.openOrderrows.map(row => {
                  this.setState({
                    myorderPrice: this.state.myorderPrice.concat(row.price)
                  })
                })
              } else {
                this.setState({
                  historyOrderrows: [...this.state.historyOrderrows, item]
                });
              }

            } else {
              const item = {
                coinName: resdata.coinName,
                pair: resdata.pair,
                orderType: resdata.orderType,
                orderMode: resdata.orderMode,
                price: resdata.price,
                amount: resdata.amount,
                total: resdata.total,
                dateTime: resdata.dateTime,
                tredingFee: resdata.tredingFee,
                binanceorderId: resdata.binanceorderId,
                _id: resdata._id
              };
              this.setState({
                historyOrderrows: [...this.state.historyOrderrows, item]
              });
            }

            if (resdata.status == 0) {

              notify.show("Sell order submitted successfully", "warning");
            } else {

              notify.show("Sell order executed successfully", "success");
            }
            this.setState({
              sellVolume: "",
              sellTotal: "",
              sellType: "LIMIT",
            })
            this.getBithindDetails()
            this.getBithindPrice()
            this.getmyBalance()
            this.setState({ LodingOrder: false })
          } else {

            notify.show("Something went wrong...", "error");
            this.setState({ LodingOrder: false })
          }


        })
    } else {

      this.setState({ LodingOrder: false })
    }
    
  }

  addFavCoin = (coin, pair) => () => {
    axios.post(BASE_URL + `favcoin/add`, {
      "coin": coin,
      "pair": pair,
      "userId": this.state.userId,
    })
      .then(res => {
        if (res.data.message == "added") {
          this.getmyfavcoin()
          this.setState({ [coin + pair + '_favcoin']: true })
        }
      })
  }

  removeFavCoin = (coin, pair) => () => {
    axios.post(BASE_URL + `favcoin/remove`, {
      "coin": coin,
      "pair": pair,
      "userId": this.state.userId,
    })
      .then(res => {
        if (res.data.message == "removed") {
          this.getmyfavcoin()
          this.setState({ [coin + pair + '_favcoin']: false })
        }
      })
  }


  handleRemoveRowOpenOrder = (idx) => () => {
    // console.log("cancelorder", this.state.openOrderrows[idx])

    let cancelUrl = 'order/update/status';
    if (this.state.exchangeType == "binance") {
      cancelUrl = 'order/update/status';
    }
    if (this.state.exchangeType == "Bitallx") {
      cancelUrl = 'order/update/status';
    }

    axios.post(BASE_URL + cancelUrl, {
      "pair": this.state.openOrderrows[idx].pair,
      "coinName": this.state.openOrderrows[idx].coinName,
      "orderType": this.state.openOrderrows[idx].orderType,
      "status": 2,
      "binanceorderId": this.state.openOrderrows[idx].binanceorderId,
      "id": this.state.openOrderrows[idx]._id
    })
      .then(res => {
        // console.log("cancelorder", res.data.message)
        if (res.data.message == "updatedSuccessfully") {

          if (this.state.openOrderrows[idx].orderType == "Sell") {
            this.setState({ userBalanceCoin: Number(Number(this.state.userBalanceCoin) + Number(Number(this.state.openOrderrows[idx].amount) + Number(this.state.openOrderrows[idx].tredingFee))).toFixed(10).slice(0, -4) })
          } else {
            this.setState({ userBalancePair: Number(Number(this.state.userBalancePair) + Number(this.state.openOrderrows[idx].total)).toFixed(10).slice(0, -4) })
          }

          this.setState({ myorderPrice: [] })
          const item = {

            coinName: this.state.openOrderrows[idx].coinName,
            pair: this.state.openOrderrows[idx].pair,
            orderType: this.state.openOrderrows[idx].orderType,
            orderMode: this.state.openOrderrows[idx].orderMode,
            price: this.state.openOrderrows[idx].price,
            amount: this.state.openOrderrows[idx].amount,
            total: this.state.openOrderrows[idx].total,
            dateTime: this.state.openOrderrows[idx].dateTime,
            tredingFee: this.state.openOrderrows[idx].tredingFee,
            binanceorderId: this.state.openOrderrows[idx].binanceorderId,
            _id: this.state.openOrderrows[idx]._id
          };
          this.setState({
            cancelOrderrows: [...this.state.cancelOrderrows, item]
          });


          const openOrderrows = [...this.state.openOrderrows]
          openOrderrows.splice(idx, 1)
          this.setState({ openOrderrows })

          notify.show("Order cancelled successfully", "success");
          this.getmyOrders();
          this.getBithindDetails();


          this.state.openOrderrows.map(row => {
            // console.log("myorderPrice",row)
            this.setState({
              myorderPrice: this.state.myorderPrice.concat(row.price)
            })
          })
          

        } else {

          notify.show("Something went wrong...", "error");
        }

      })



  };


  handleChange = (event) => {


    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;



    switch (name) {
      case 'sellType':

        if (value === "MARKET") {
          // this.setState({sellTotal: "0" })
          this.setState({ sellPrice: value })
        } else {
          this.setState({ sellPrice: '' })
        }
        this.setState({ sellType: value })

        break;
      case 'buyType':

        if (value === "MARKET") {
          // this.setState({buyTotal: "0" })
          this.setState({ buyPrice: value })
        } else {
          this.setState({ buyPrice: '' })
        }
        this.setState({ buyType: value })

        break;

      case 'sellPrice':
        if (value.length == 0) {
          this.setState({ sellPrice: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            if(priceValid.test(value)) {
              this.setState({ sellPrice:  value})
              }
            // this.setState({ sellPrice: value })
            this.setState({ sellTotal: (value * this.state.sellVolume).toFixed(4) })
          }

        }
        break;

      case 'buyPrice':
        if (value.length == 0) {
          this.setState({ buyPrice: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            // console.log("priceValid",priceValid.test(value))
            if(priceValid.test(value)) {
            this.setState({ buyPrice:  value})
            }
            this.setState({ buyTotal: (value * this.state.buyVolume).toFixed(4) })
          }

        }
        break;

      case 'sellVolume':
        if (value.length == 0) {
          this.setState({ sellVolume: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            this.setState({ sellVolume: value })

            if (this.state.sellType == "MARKET") {
              var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
              if(lastPrice!=undefined){
                lastPrice =  lastPrice.replace(/,/g, "");
              }
              this.setState({ sellTotal: (value * lastPrice).toFixed(4) })
            } else {
              this.setState({ sellTotal: (value * this.state.sellPrice).toFixed(4) })
            }

          }

        }
        break;
      case 'buyVolume':
        if (value.length == 0) {
          this.setState({ buyVolume: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            this.setState({ buyVolume: value })

            if (this.state.buyType == "MARKET") {
              var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
              if(lastPrice!=undefined){
                lastPrice =  lastPrice.replace(/,/g, "");
              }
              this.setState({ buyTotal: (value * lastPrice).toFixed(4) })
            } else {
              this.setState({ buyTotal: (value * this.state.buyPrice).toFixed(4) })
            }

          }

        }
        break;

      case 'sellTotal':
        if (value.length == 0) {
          this.setState({ sellTotal: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            this.setState({ sellTotal: value })

            if (this.state.sellType == "MARKET") {
              var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
              if(lastPrice!=undefined){
                lastPrice =  lastPrice.replace(/,/g, "");
              }
              this.setState({ sellVolume: (value / lastPrice).toFixed(4) })
            } else {
              this.setState({ sellVolume: (value / this.state.sellPrice).toFixed(4) })
            }

          }

        }
        break;
      case 'buyTotal':
        if (value.length == 0) {
          this.setState({ buyTotal: "" })
        } else {
          if (!validNumber.test(value)) {
            return;
          } else {
            this.setState({ buyTotal: value })

            if (this.state.buyType == "MARKET") {
              var lastPrice = $("." + this.props.match.params.coinCode + this.props.match.params.pairCode + "_price").html();
              if(lastPrice!=undefined){
                lastPrice =  lastPrice.replace(/,/g, "");
              }
              this.setState({ buyVolume: (value / lastPrice).toFixed(4) })
            } else {
              this.setState({ buyVolume: (value / this.state.buyPrice).toFixed(4) })
            }

          }

        }
        break;

      default:
        break;
    }

    // this.setState({[name]: value});
  }


  // ******* DAY NIGHT MODE ***********
  handleAlignment = (event, newAlignment) => {
    // console.log(newAlignment)
    if(newAlignment === 'left'){
      this.setState({
        alignment : newAlignment,
        theme: 'light_mode',
        backgroundcolor1: '#FDB813',
        backgroundcolor2: '#fff'
      })
      localStorage.setItem('tradeMode','light_mode')
    } else if(newAlignment === 'right'){
      this.setState({
        alignment : newAlignment,
        theme: 'dark_mode',
        backgroundcolor1: '#fff',
        backgroundcolor2: '#FDB813' 
      })
      localStorage.setItem('tradeMode','dark_mode')

    }
  }

  render() {
    // if (!this.props.match.params.coinCode) {
    //   return <Redirect to='/trade/BTC/USDT' />
    // }
    let openFavTab = false
    let openBtcTab = false
    let openEthTab = false
    let openUsdtTab = false
    let openInrTab = false
    this.state.myfavcoin.map((d, idx) => {
      if (d.coin + d.pair == this.state.coinCode + this.state.pairCode) {
        openFavTab = true
      }
    })
    if (openFavTab == true) {

    } else if (this.state.pairCode == "BTC") {
      openBtcTab = true
    } else if (this.state.pairCode == "ETH") {
      openEthTab = true
    } else if (this.state.pairCode == "USDT") {
      openUsdtTab = true
    } else if (this.state.pairCode == "INR") {
      openInrTab = true
    }



    document.title = this.state.doctitle;
    // document.body.classList.add('faded_bg');
    if(this.state.theme == 'dark_mode') {
      document.body.classList.remove('lightbackground'); 
      document.body.classList.add('darkbackground'); 
    } else {
      document.body.classList.remove('darkbackground');  
      document.body.classList.add('lightbackground'); 
    }
    // console.log('All the props in trade.js', this.props);
    // console.log('All the state in trade.js', this.state);
    return (
      <div
       className={this.state.theme}
      >
        <Notifications />
        <Header />
        <section id="basic_trade">
          <div className="container-fluid">
          <div className="row">
          <div className="trade_info clearfix">
                  <div className="basic_top_bg clearfix">
                    <div className="row">
                      <div className="col-md-3 col-sm-3 col-xs-3 full">
                        <div className="trade_left">
                          <h6>{this.state.coinCode}/{this.state.pairCode} </h6>
                          <span>
                          
                            <small style={{marginLeft:'0px', fontSize:'18px'}} className={this.state.coinCode + this.state.pairCode + '_price'}>0.000000</small>
                           
                          </span>
                        </div>
                      </div>
                      <div className="col-md-9 col-sm-9 col-xs-9 full">
                        <div className="trade_right text-right">
                          <ul>
                            <li>
                              <span>24H Change</span>
                              {/* <div className="red_clr">Change -0.3%</div> */}
                              <span className={this.state.coinCode + this.state.pairCode + '_priceChangePercentColor'}> <span style={{display: 'inline'}} className={`${this.state.coinCode + this.state.pairCode + '_priceChangePercent'} ${this.state.coinCode + this.state.pairCode + '_priceChangePercentColor'}`}>{this.state.change24H || 0.00}</span>% </span>
                            </li>
                            <li>
                              <span>High</span>
                               {/* 0.00001580{" "} */}
                               <div className={this.state.coinCode + this.state.pairCode + '_priceHigh'}>{this.state.changeHigh || 0}</div>
                            </li>
                            <li>
                              <span>Low</span>
                               {/* 0.00001580{" "} */}
                               <div className={this.state.coinCode + this.state.pairCode + '_priceLow'}>{this.state.changeLow || 0}</div>
                            </li>
                            <li>
                              <span>Vol</span>
                              {/* 28,636.4 ETH */}
                              <div><span style={{display: 'inline'}} className={this.state.coinCode + this.state.pairCode + '_priceVolume'}>{this.state.volume ? (+this.state.volume).toFixed(5) : '0.00'}</span> {this.state.pairCode}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
           </div>

      <div className="row">        
        <div className="col-md-3 right_space">
                <div className="row">
                  <div className="trade_side_br col-md-12 col-sm-12 col-xs-12 full">
                    <div role="tabpanel" className="tab-pane active" id="token">
                      <ul className="nav nav-tabs" role="tablist">
                          <li className={openUsdtTab? 'active' : ''} style={{ width: "22%", textAlign: "center" }}><a href="#USDT" onClick={()=>this.getCoinPairAll("USDT")} aria-controls="ent" role="tab" data-toggle="tab" aria-expanded={false}>USDT</a></li>
                          <li className={openBtcTab ? 'active' : ""} style={{ width: "22%", textAlign: "center" }}><a href="#BTC"  onClick={()=>this.getCoinPairAll("BTC")}  aria-controls="new" role="tab" data-toggle="tab" aria-expanded={true}>BTC</a></li>
                          <li className={openEthTab ? 'active' : ""} style={{ width: "22%", textAlign: "center" }}><a href="#ETH"  onClick={()=>this.getCoinPairAll("ETH")}  aria-controls="new" role="tab" data-toggle="tab" aria-expanded={true}>ETH</a></li>
                          {/* <li className={openInrTab == true && 'active'} style={{ width: "22%", textAlign: "center" }}><a href="#INR" aria-controls="new" role="tab" data-toggle="tab" aria-expanded="true">INR</a></li> */}
                      </ul>
                      {/* <div className="trade_search clearfix">
                        <input type="text" placeholder="Search" />
                      </div> */}
                      <div className="tab-content scroller">
                        {/* <!-- tab1 start --> */}
                        <div role="tabpanel" className={openUsdtTab ? 'tab-pane active' : 'tab-pane'} id="USDT">


              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      
                      <th style={{ textAlign: "left" }}>Pair</th>
                      <th style={{ textAlign: "right" }}>Price</th>
                      <th style={{ textAlign: "left" }}>Chg</th>
                    </tr>
                  </thead>
                  <tbody>

                    
                    {this.state.tokenData.map((d, idx) => (
                      d.active == true && d.tActive == true && d.USDT == 1 &&
                      <tr key={idx} style={{ borderBottom: "1px solid rgba(255,255,255,0.1)", cursor: "pointer" }} className="coinhover">
                        {/* <td><img src="images/currency_img1.png" alt="" width="15px"/></td> */}
                        <td style={{ padding: "10px 5px", fontSize: "11px" }} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/USDT' }}>{d.tokenSymbol}/USDT</td>
                        <td style={{ padding: "10px 5px", fontSize: "11px", textAlign: "right", fontWeight: "500" }} className={d.tokenSymbol + 'USDT_priceColor'} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/USDT' }}>
                          <span className={d.tokenSymbol + "USDT_price"}>0.000000 </span>
                          
                          

                        </td>
                        <td style={{ padding: "10px 5px", fontSize: "11px" }} className={d.tokenSymbol + 'USDT_priceChangePercentColor'} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/USDT' }}>
                          <span className={d.tokenSymbol + "USDT_priceChangePercent"}>0.00</span>%
                  
                        </td>

                      </tr>


                    ))}
  
                  </tbody>
                </table>
              </div>

            </div>
                        {/* <!-- tab1 end -->  */}

                        {/* <!-- tab2 start --> */}
                        
            <div role="tabpanel" className={openBtcTab == true ? 'tab-pane active' : 'tab-pane'} id="BTC">


<div className="table-responsive">
  <table className="table">
    <thead>
      <tr>
        
        <th style={{ textAlign: "left" }}>Pair</th>
        <th style={{ textAlign: "right" }}>Price</th>
        <th style={{ textAlign: "left" }}>Chg</th>
      </tr>
    </thead>
    <tbody>

      
      {this.state.tokenData.map((d, idx) => (

        d.active == true && d.tActive == true && d.BTC == 1 &&
        <tr key={idx} style={{ borderBottom: "1px solid rgba(255,255,255,0.1)", cursor: "pointer" }} className="coinhover">

          {/* <td><img src="images/currency_img1.png" alt="" width="15px"/></td> */}
          <td style={{ padding: "10px 5px", fontSize: "11px" }} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/BTC' }}>{d.tokenSymbol}/BTC</td>
          <td style={{ padding: "10px 5px", fontSize: "11px", textAlign: "right", fontWeight: "500" }} className={d.tokenSymbol + 'BTC_priceColor'} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/BTC' }}>
            <span className={d.tokenSymbol + "BTC_price"}>0.000000 </span>
            
            

          </td>
          <td style={{ padding: "10px 5px", fontSize: "11px" }} className={d.tokenSymbol + 'BTC_priceChangePercentColor'} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/BTC' }}>
            <span className={d.tokenSymbol + "BTC_priceChangePercent"}>0.00</span>%

          </td>

        </tr>



      ))}

    </tbody>
  </table>
</div>

</div>
                        {/* <!-- tab2 end -->  */}

                        {/* <!-- tab3 start --> */}
                          
            <div role="tabpanel" className={openEthTab == true ? 'tab-pane active' : 'tab-pane'} id="ETH">


<div className="table-responsive">
  <table className="table">
    <thead>
      <tr>
        
        <th style={{ textAlign: "left" }}>Pair</th>
        <th style={{ textAlign: "right" }}>Price</th>
        <th style={{ textAlign: "left" }}>Chg</th>
      </tr>
    </thead>
    <tbody>

      
      {this.state.tokenData.map((d, idx) => (
        d.active == true && d.tActive == true && d.ETH == 1 &&
        <tr key={idx} style={{ borderBottom: "1px solid rgba(255,255,255,0.1)", cursor: "pointer" }} className="coinhover">

          {/* <td><img src="images/currency_img1.png" alt="" width="15px"/></td> */}
          <td style={{ padding: "10px 5px", fontSize: "11px" }} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/ETH' }}>{d.tokenSymbol}/ETH</td>
          <td style={{ padding: "10px 5px", fontSize: "11px", textAlign: "right", fontWeight: "500" }} className={d.tokenSymbol + 'ETH_priceColor'} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/ETH' }}>
            <span className={d.tokenSymbol + "ETH_price"}>0.000000 </span>
            
            

          </td>
          <td style={{ padding: "10px 5px", fontSize: "11px" }} className={d.tokenSymbol + 'ETH_priceChangePercentColor'} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/ETH' }}>
            <span className={d.tokenSymbol + "ETH_priceChangePercent"}>0.00</span>%
        
          </td>

        </tr>

      ))}

    </tbody>
  </table>
</div>

</div>

                        {/* <!-- tab4 start --> */}
                            
            <div role="tabpanel" className={openInrTab == true ? 'tab-pane active' : 'tab-pane'} id="INR">


<div className="table-responsive">
  <table className="table">
    <thead>
      <tr>
        
        <th style={{ textAlign: "left" }}>Pair</th>
        <th style={{ textAlign: "right" }}>Price</th>
        <th style={{ textAlign: "left" }}>Chg</th>
      </tr>
    </thead>
    <tbody>

      
      {this.state.tokenData.map((d, idx) => (
        d.active == true && d.tActive == true && d.INR == 1 &&
        <tr key={idx} style={{ borderBottom: "1px solid rgba(255,255,255,0.1)", cursor: "pointer" }} className="coinhover">
          {/* <td><img src="images/currency_img1.png" alt="" width="15px"/></td> */}
          <td style={{ padding: "10px 5px", fontSize: "11px" }} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/INR' }}>{d.tokenSymbol}/INR</td>
          <td style={{ padding: "10px 5px", fontSize: "11px", textAlign: "right", fontWeight: "500" }} className={d.tokenSymbol + 'INR_priceColor'} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/INR' }}>
            <span className={d.tokenSymbol + "INR_price"}>0.000000 </span>
            
            

          </td>
          <td style={{ padding: "10px 5px", fontSize: "11px" }} className={d.tokenSymbol + 'INR_priceChangePercentColor'} onClick={(e) => { window.location.href = '/trade/' + d.tokenSymbol + '/INR' }}>
            <span className={d.tokenSymbol + "INR_priceChangePercent"}>0.00</span>%
           
          </td>

        </tr>


      ))}

    </tbody>
  </table>
</div>

</div>

                      </div>
                    
                    </div>
                  
                  </div>
                </div>
       
        </div>
        <div className="col-md-9">
        <div className="row">              
              <div className="col-md-8 col-sm-12 col-xs-12">                
                 <div className="dark_map map_part col-lg-12 full" style={{ height: "48vh" }}>
                 {this.state.exchangeType == "binance" &&

<>
{/* {console.log('this.state.exchangeType1',this.state.exchangeType)} */}
{this.state.theme === "dark_mode" ?
<>
{/* <TradingViewWidget
  symbol={"BINANCE:" + this.state.coinCode + "" + this.state.pairCode}
  theme={Themes.LIGHT}
  // theme={Themes.LIGHT}
  style="1"
  locale="en"
  enable_publishing="false"
  allow_symbol_change=""
  hide_legend="true"
  toolbar_bg="#262D33"
  autosize
/> */}
<TVChartContainer symbol={"Binance:" + this.props.match.params.coinCode + "/" + this.props.match.params.pairCode} />

</>
:
<>
{/* <TradingViewWidget
  symbol={"BINANCE:" + this.state.coinCode + "" + this.state.pairCode}
  theme={Themes.LIGHT}
  style="1"
  locale="en"
  enable_publishing="false"
  allow_symbol_change=""
  hide_legend="true"
  toolbar_bg="#262D33"
  autosize
/>  */}
<TVChartContainer symbol={"Binance:" + this.props.match.params.coinCode + "/" + this.props.match.params.pairCode} />
</>
}
</>
}
    {this.state.exchangeType == "Bitallx" &&
      <>
        {this.state.theme === "dark_mode" ?
          <>
            <TVChartContainer symbol={"Bitallx:" + this.props.match.params.coinCode + "/" + this.props.match.params.pairCode} />
          </>
          :
          <>
            <TVChartContainer symbol={"Bitallx:" + this.props.match.params.coinCode + "/" + this.props.match.params.pairCode} />
          </>}
      </>

    }
                  {/* </Helmet> */}
                  {/* <!-- TradingView Chart END -->  */}
                </div>
                </div>
              <div className="col-md-4 col-sm-12 col-xs-12 left_space">
              <div className="order_list clearfix">
                      <ul className="nav nav-tabs" role="tablist">
                        <h6>Buy Orders</h6>                      
                      </ul>
                      <div className="tab-content">
                        {/* <!-- tab 1 start--> */}
                        <div
                          role="tabpanel"
                          className="tab-pane active"
                          id="market_dep"
                        >
                          <div className="table-responsive inner_table">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Volume</th>
                                  <th>Buy Price</th>                                  
                                </tr>
                              </thead>
                              <tbody>
                              <td colSpan="2">
                                <table style={{width:"100%"}}><OrderBookBid bids={this.state.depth.bids} coinName={this.state.coinCode }  myorders={this.state.myorderPrice} handelTradeHistory={this.handelTradeHistory} /></table></td>
                            </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <!-- tab 1 ends --> */}
                      </div>
                    </div>
               </div>
               </div>

            <div className="row">           

                <div className="col-md-4 col-sm-6 col-xs-12 right_space">
                <div className="trade_form clearfix">                 
                  <div className="trade_form_bg clearfix">
                  <h6>Buy</h6>
                      {/* <!-- tab 1 start--> */}
                      <div id="buy_trade">
                        <div className="trade_buy_form">
                          <form>
                      <div className="form-group clearfix">
                        <label>Available<br></br>{this.state.pairCode}</label>
                        <input type="number" placeholder="0"className="form-control" readOnly value={(Number(this.state.userBalancePair).toFixed(10).slice(0, -4))} />
                      </div>


                            <div className="form-group clearfix">
                              <label> Price </label>
                              <input type="text" className="form-control" placeholder="0" 
                              name="buyPrice" autoComplete="off" value={this.state.buyPrice} 
                              onChange={this.handleChange}
                              {...this.state.buyPrice === "MARKET" ? { 'disabled': 'disabled' } : {}}
                              />
                            </div>
                            <div className="form-group clearfix">
                              <label>Amount</label>
                              <input
                                type="text" className="form-control"
                                placeholder="0"
                                name="buyVolume" value={this.state.buyVolume}  onChange={this.handleChange}
                              />
                              <div className="amount_opt">
                                <ul>
                                  <li onClick={() => this.buyBalancePer(25)}>25%</li>
                                  <li onClick={() => this.buyBalancePer(50)}>50%</li>
                                  <li onClick={() => this.buyBalancePer(75)}>75%</li>
                                  <li onClick={() => this.buyBalancePer(100)}>100%</li>
                                </ul>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Total</label>
                              <input type="text" className="form-control" placeholder="0" name="buyTotal" value={this.state.buyTotal}/>
                            </div>
                            <div className="" onClick={this.handleSubmitBuy}>
                              {" "}
                              <a href="#"  className="green_btn">
                                Place Buy Order
                              </a>{" "}
                              <small>
                                Fee: Maker fee: 0.2% | Taker fee: 0.2%
                              </small>{" "}
                            </div>
                          </form>
                        </div>
                      </div> 
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="trade_form clearfix">                 
                  <div className="trade_form_bg clearfix">
                    <h6>Sell</h6>
                    <div id="sell_trade">
                        <div className="trade_buy_form">
                          <form>
                          <div className="form-group clearfix">
                        <label>Available<br></br>{this.state.coinCode}</label>
                        <input type="number" placeholder="0"className="form-control" readOnly value={(Number(this.state.userBalanceCoin).toFixed(10).slice(0, -4))} />
                      </div>

                            <div className="form-group clearfix">
                              <label> Price </label>
                              <input type="text" className="form-control" placeholder="0" 
                              name="sellPrice" autoComplete="off" value={this.state.sellPrice}
                              onChange={this.handleChange}
                              {...this.state.sellPrice === "MARKET" ? { 'disabled': 'disabled' } : {}} 
                              />
                            </div>
                            <div className="form-group clearfix">
                              <label>Amount</label>
                              <input
                                type="text" className="form-control"
                                placeholder="0"
                                name="sellVolume" value={this.state.sellVolume}  onChange={this.handleChange}
                              />
                              <div className="amount_opt">
                                <ul>
                                  <li onClick={() => this.sellBalancePer(25)}>25%</li>
                                  <li onClick={() => this.sellBalancePer(50)}>50%</li>
                                  <li onClick={() => this.sellBalancePer(75)}>75%</li>
                                  <li onClick={() => this.sellBalancePer(100)}>100%</li>
                                </ul>
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Total</label>
                              <input type="text" className="form-control" placeholder="0" name="sellTotal" value={this.state.sellTotal}/>
                            </div>
                            <div className="" onClick={this.handleSubmitSell}>
                              {" "}
                              <a href="#" className="blue_btn">
                                Place Sell Order
                              </a>{" "}
                              <small>
                                Fee: Maker fee: 0.2% | Taker fee: 0.2%
                              </small>{" "}
                            </div>
                          </form>
                        </div>
                   </div>
                   </div>
                </div>
              </div>

                <div className="col-md-4 col-sm-12 col-xs-12 left_space">
                  <div className="order_list clearfix">
                      <ul className="nav nav-tabs" role="tablist">
                        <h6>Sell Orders</h6>                      
                      </ul>
                      <div className="tab-content">
                        {/* <!-- tab 1 start--> */}
                        <div
                          role="tabpanel"
                          className="tab-pane active"
                          id="market_dep"
                        >
                          <div className="table-responsive inner_table">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Sell Price</th>                                  
                                  <th>Volume</th>
                                </tr>
                              </thead>
                              <tbody>                              
                              <td colSpan="2"><table style={{width:"100%"}}><OrderBookAsk asks={this.state.depth.asks}  coinName={this.state.coinCode}   myorders={this.state.myorderPrice} handelTradeHistory={this.handelTradeHistory} /></table></td>
                            </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <!-- tab 1 ends --> */}
                      </div>
                    </div>
                </div>
            </div>

               <div className="row ico_detail">               
              <div className="col-md-8 col-sm-12 col-xs-12 order_his">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="active">
                    <a href="#open_odr" role="tab" data-toggle="tab">
                      open order
                    </a>
                  </li>
                  <li>
                    <a href="#complete_odr" role="tab" data-toggle="tab">
                      complete order
                    </a>
                  </li>
                </ul>
                <div className="tab-content order_table">
                  {/* <!-- tab 2 start--> */}
                  <div
                    role="tabpanel"
                    className="tab-pane active"
                    id="open_odr"
                  >
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Coin</th>
                            <th>Price</th>
                            <th>Amount</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>

                        {this.state.openOrderrows.map((row, xid) => (
                                <tr key={xid} style={{borderBottom:"1px solid #ddd"}}>
                                  <td className={row.orderType == "Buy" ? "green_clr" : "red_clr"}>
                                    {row.coinName + row.pair}<br></br>{row.orderType} ({row.orderMode})
                                    <br></br>
                                    <small>{ moment(this.getFormatedTime(row.dateTime)).format('DD MM YYYY hh:mm:ss a')}</small>
                                    <br></br>
                                    <a href="#" className="label label-danger stroke_btn_main" onClick={this.handleRemoveRowOpenOrder(xid)}>Cancel Order</a>
                                    </td>
                                  <td className={row.orderType == "Buy" ? "green_clr" : "red_clr"}>{Number(row.price).toFixed(Number(this.state.currentCoinPairDecimalData?.priceDecimal))} {row.pair}</td>
                                  <td>{ Number(row.amount).toFixed(Number(this.state.currentCoinPairDecimalData?.amountDecimal))} {row.coinName}<br></br><small>Fee: {Number(row.tredingFee).toFixed(6)} {row.orderType == 'Buy' ? row.coinName  :  row.pair}</small></td>
                                  <td>{row.total} {row.pair}</td>
                                </tr>
                              )).reverse()}


                        </tbody>

                        {/* <tbody>
                          <tr>
                            <td className="green_clr">0.5214 </td>
                            <td>34,56,606</td>
                            <td className="red_clr">34,56,610</td>
                            <td>0.0013</td>
                          </tr>
                          <tr>
                            <td className="green_clr">0.5214 </td>
                            <td>34,56,606</td>
                            <td className="red_clr">34,56,610</td>
                            <td>0.0013</td>
                          </tr>
                          <tr>
                            <td className="green_clr">0.5214 </td>
                            <td>34,56,606</td>
                            <td className="red_clr">34,56,610</td>
                            <td>0.0013</td>
                          </tr>
                          <tr>
                            <td className="green_clr">0.5214 </td>
                            <td>34,56,606</td>
                            <td className="red_clr">34,56,610</td>
                            <td>0.0013</td>
                          </tr>
                          <tr>
                            <td className="green_clr">0.5214 </td>
                            <td>34,56,606</td>
                            <td className="red_clr">34,56,610</td>
                            <td>0.0013</td>
                          </tr>
                          <tr>
                            <td className="green_clr">0.5214 </td>
                            <td>34,56,606</td>
                            <td className="red_clr">34,56,610</td>
                            <td>0.0013</td>
                          </tr>
                        </tbody> */}
                      </table>
                    </div>
                  </div>
                  {/* <!-- tab 2 ends--> */}

                  {/* <!-- tab 2 start--> */}
                  <div role="tabpanel" className="tab-pane" id="complete_odr">
                    <div className="table-responsive inner_table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Coin</th>
                            <th>Price</th>
                            <th>Amount</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.historyOrderrows.map((row, xid) => (
                                <tr key={xid} style={{borderBottom:"1px solid #ddd"}}>
                                  <td className={row.orderType == "Buy" ? "green_clr" : "red_clr"}>
                                    {row.coinName + row.pair}<br></br>{row.orderType} ({row.orderMode})
                                    <br></br>
                                    <small>{ moment(this.getFormatedTime(row.dateTime)).format('DD MM YYYY hh:mm:ss a')}</small>
                                    </td>
                                  <td className={row.orderType == "Buy" ? "green_clr" : "red_clr"}>{Number(row.price).toFixed(Number(this.state.currentCoinPairDecimalData?.priceDecimal))} {row.pair}</td>
                                  <td>{Number(row.amount).toFixed(Number(this.state.currentCoinPairDecimalData?.amountDecimal))} {row.coinName}<br></br><small>Fee: {Number(row.tredingFee).toFixed(6)} {row.orderType == 'Buy' ? row.coinName  :  row.pair}</small></td>
                                  <td>{row.total} {row.pair}</td>
                                </tr>
                              )).reverse()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <!-- tab 2 ends-->  */}
                </div>

                
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12 trade_his left_space">
                    <div className="bg_clr">
                      <h6>Trade History</h6>
                      <div className="table-responsive">
                        <table className="table table-hover">
                        <thead>
                    <tr>
                      <th>Price</th>                    
                      <th>Volume</th>
				          	  <th>Time</th>
                    </tr>
                  </thead>

                        <tbody id="tradehistorytable">
                              <TradeHistory trades={this.state.trades}  coinName={this.state.coinCode}   handelTradeHistory={this.handelTradeHistory} />
                            </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
      </div>

        
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Trade;
