import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";

import {Helmet} from "react-helmet";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() { 
        return ( 
            <footer>
  <div className="container">
  <div className="row">
  <div className="col-sm-4 footer_logo">
   <a href="/"><img src="/images/logo.png" alt="Company Logo" /> </a>
  </div>
  <div className="col-sm-8 text-right download_btns">
  <a href="https://play.google.com/store/apps/details?id=com.bitallx.mobileapp" target='_blank'><img src="/images/google_play_icon.svg" style={{height:"39px"}} alt="img" /></a> 
  {/* <a href="#"><img src="/images/ios_store.png" style={{height:"39px",marginLeft:"10px"}} alt="img" /></a>  */}
  </div>
  </div>
    <div className="row">     
      <div className="col-md-3 col-sm-3 col-xs-6 links">
        <h6>Legal</h6>
        <ul>
          {/* <li><a href="#">About us</a></li> */}
          {/* <li><a href="#">Security strategy</a></li> */}
          {/* <li><a href="/terms">Terms of use</a></li> */}
          <li><a href="/privacy">Privacy Policy</a></li>
          {/* <li><a href="#">Disclaimer Statement</a></li> */}
          {/* <li><a href="#">Risk Reminder</a></li> */}
          {/* <li><a href="#">Fees</a></li> */}
        </ul>
      </div>
      {/* <div className="col-md-3 col-sm-3 col-xs-6 links">
        <h6>Support</h6>
        <ul>
          <li><a href="#">About us</a></li>
          <li><a href="#">Security strategy</a></li>
          <li><a href="/terms">Terms of use</a></li>
          <li><a href="/privacy">Privacy Policy</a></li>
          <li><a href="#">Disclaimer Statement</a></li>
          <li><a href="#">Risk Reminder</a></li>
          <li><a href="#">Fees</a></li>
        </ul>
      </div> */}
	    {/* <div className="col-md-3 col-sm-3 col-xs-6 links">
        <h6>Legal </h6>
        <ul>
          <li><a href="#">About us</a></li>
          <li><a href="#">Security strategy</a></li>
          <li><a href="/terms">Terms of use</a></li>
          <li><a href="/privacy">Privacy Policy</a></li>
          <li><a href="#">Disclaimer Statement</a></li>
          <li><a href="#">Risk Reminder</a></li>
          <li><a href="#">Fees</a></li>
        </ul>
      </div> */}
      {/* <div className="col-md-3 col-sm-3 col-xs-6 connect_info">
	  <h6>Socials </h6>
        <ul>
          <li><a href="#"><i className="fa fa-paper-plane-o"></i> Telegram</a></li>
          <li><a href="#"><i className="fa fa-twitter"></i> Twitter</a> </li>
          <li><a href="#"><i className="fa fa-facebook"></i> Facebook</a></li>
		      <li><a href="#"><i className="fa fa-paper-plane-o"></i> Telegram</a></li>
          <li><a href="#"><i className="fa fa-twitter"></i> Twitter</a> </li>
        </ul>
       
        
      </div> */}
    </div>
  </div>
  <div className="copyright clearfix">
    <div className="container">
      <div className="row">
	    <hr />
      {/* <div className="col-xs-6 text-left"> 
    
                  <Link to="/privacy">Privacy Policy</Link>
               
       </div> */}
        <div className="col-xs-12 text-center"> <small>@Copyright All right reserved {new Date().getFullYear()}</small> </div>
      </div>
    </div>
  </div>
</footer>
         );
    }
}
 
export default Footer;