import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { BASE_URL } from '../../config/constant';
import axios from 'axios';
import '../../index.css';
import moment from 'moment';
function ReferalDetails({ data, show, hideModal }) {
  const [referalDetailList, setReferalDetailList] = useState([]);

  const handleClose = () => {
    setReferalDetailList([])
      hideModal(false)};

  useEffect(() => {
    document.title = 'Referral'
    if (show) {

    //   const userId = localStorage.getItem('userDetails')
      axios.post(BASE_URL + 'referral/userbyid/get', { userId: data.userId }).then(res => {
        if (res.data && res.data.data) {
            setReferalDetailList(res.data.data);

        }
      }).catch(e => {

      })
    }


  }, [show])
  return (
    <>
      
          <Modal animation={false} show={show} onHide={handleClose} size='lg'>
            
              {/* <div className='forcasting-heading'> Reward Tree</div>
          <hr /> */}
           <section id="middel_area" className="ref_mid" style={{padding:0,position:'initial'}}>
        <img src="images/bg_shape1.png" alt="img" className="shape1" />
        <img src="images/bg_shape2.png" alt="img" className="shape2" />
        {/* <div className="container"> */}
        <div className='white_bx 'style={{padding:0,margin:0}}>
        <Modal.Header closeButton>
              <Modal.Title><h3>Referral details</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="table-responsive" style={{ height: 400, overflowY:'scroll' }}>
                <table className="table table-hover">
                  <thead className='sticky-on-top' style={{ background: '#fef5f8' }}>
                    <tr >
                      {/* <td>Level</td> */}
                      <td>Sr. No.</td>
                      <td>User Name</td>
                      <td>Email Id</td>
                      <td>Created Date</td>
                      <td>Personal Reffered</td>
                      <td>Total Team</td>
                      <td>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {referalDetailList?.map((d, i) => (
                      <>
                        <tr key={'parents' + i}>
                          <td>{i+1}</td>
                          <td>{d.userName}</td>
                          <td>{d.emailId}</td>
                          <td>{d.createdOn}</td>
                          <td>{d.PersonalReffered}</td>
                          <td>{d.PersonalTotalStaking}</td>
                          <td >{d.activationStatus == 1 ? <div className='text-info'>Active</div>:<div className='text-danger'>Inactive</div> }</td>
                          {/* <ReferalDetails data={d} show={true} hideModal={handleClose}/> */}
                        </tr>
                      </>
                    ))}
                    {!referalDetailList || referalDetailList?.length == 0 ? <tr>
                      <td colSpan={7} align="center" className="no_data"><i className="fa fa-file-o" /> No record found for rewards</td>
                    </tr> : null}
                  </tbody>
                </table>
              </div>

            </Modal.Body>
              {/* </div> */}
        </div>
      </section>
            {/* <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer> */}
          </Modal>
        
    </>
  );
}

export default ReferalDetails