import React from 'react'
import BigNumber from 'bignumber.js'
import Axios from 'axios';
import { BASE_URL } from '../../config/constant';
// import { decimalData } from './decimalDataForAllCoins'

var decimalData = "";


const OrderRow = (props) => (

    <tr style={{cursor:"pointer",background: `linear-gradient(to right, rgba(6, 225, 239, 0.2) 0%, rgba(6, 225, 239, 0.2) ${new BigNumber(props.diff).minus(props.ba[0]).div(props.max).multipliedBy(100).toFormat(2) }%, transparent ${new BigNumber(props.diff).minus(props.ba[0]).div(props.max).multipliedBy(100).toFormat(2) }%)` }} onClick={() => props.handelTradeHistory(Number(props.ba[0]),Number(props.ba[1]),"Sell")} className="coinhover">
        <td className="green_clr">{new BigNumber(props.ba[0]).toFormat(2,1)}</td>
        <td>{new BigNumber(props.ba[1]).toFormat(6,1)}</td>
        <td style={{textAlign:"right"}}>{new BigNumber(props.ba[0]*props.ba[1]).toFormat(2,1)}</td>
    </tr>
)

const OrderBook = (props) => {
    if(props.coinName){
            if(decimalData == ""){
                // Axios.get(BASE_URL+"coinpair/getall").then((res)=>{
                //     if (res.data && res.data.statusCode === 200) {
                //             let a  = res.data.data.find((data)=>  data.primaryCoin == props.coinName )
                //             decimalData = Number(a.priceDecimal)
                //     }
                // })

                if(sessionStorage.getItem('currentCoinDecimalValue')){
                    decimalData = Number(sessionStorage.getItem('currentCoinDecimalValue'))
                }else{
                    decimalData = ""
                }

            }        
    }


    if(props.bids) {
        // console.log(props.bids)
    
        let bids = [];
        // let asks = [];
        let numRowsBid = Math.min(20, props.bids.length);
        // let numRowsAsk = Math.min(20, props.asks.length);
        // let maxBid = BigNumber.maximum(props.bids.map(bid => bid[0])).toFormat()
        let maxBid = Math.max.apply(0, props.bids.map(bid => Number(bid[0]) ))
        // let minAsk = BigNumber.minimum(props.asks.map(ask => ask[0])).toFormat()
        // let minBid = new BigNumber(maxBid).minus(BigNumber.minimum(props.bids.map(bid => bid[0]))).toFormat()
        let minBid = new BigNumber(maxBid).minus(Math.min.apply(0, props.bids.map(bid => Number(bid[0]) ))).toFormat() 
        // let maxAsk = new BigNumber(minAsk).minus(BigNumber.maximum(props.asks.map(ask => ask[0]))).toFormat()
        for (var b = 0; b < numRowsBid; b++) {
            let price =props.bids[b][0]
            let amount =props.bids[b][1]
            let mypriceicon
            {props.myorders.map(rowmy => {
                if(Number(rowmy) == Number(price)) {
                    mypriceicon = <span style={{color:"gold",position:"absolute",left:"-3px"}}>&#9658;</span>
                }
                 
            })}
            bids.push(
                // <OrderRow i={b} ba={props.bids[b]} diff={maxBid} max={minBid} class="bg-success" />      
                 
                <tr key={`${b}:${props.bids[b][0]}:${props.bids[b][1]}`} style={{cursor:"pointer",width:"100%",background: `linear-gradient(to left, rgba(6, 225, 239, 0.2) 0%, rgba(6, 225, 239, 0.2) ${new BigNumber(maxBid).minus(props.bids[b][0]).div(minBid).multipliedBy(100).toFormat(2) }%, transparent ${new BigNumber(maxBid).minus(props.bids[b][0]).div(minBid).multipliedBy(100).toFormat(2) }%)` }} onClick={() => props.handelTradeHistory(price,amount,"Sell")}>
                <td style={{position:"relative",width:"50%",padding:"0px 15px"}}>{new BigNumber(props.bids[b][1]).toFormat(4,1)}</td>
                <td className="green_clr" style={{position:"relative",width:"50%",padding:"0px 15px"}}> {mypriceicon}
                    
                        {new BigNumber(props.bids[b][0]).toFormat(decimalData,1)}</td>
                    {/* <td style={{textAlign:"right"}}>{new BigNumber(props.bids[b][0]*props.bids[b][1]).toFormat(2,1)}</td> */}
                </tr>

            )
        }
        // for (var a = 0; a < numRowsAsk; a++) {
        //     asks.unshift(
        //         <OrderRow i={a} ba={props.asks[a]} diff={minAsk} max={maxAsk} class="bg-danger" />         
        //     )
        // }
    // console.log(items);
        return (
                    <>
                    {/* {asks} */} 
                    {bids}
                    </>
               
        )
    } else {
        return (
            <tbody><tr><td style={{textAlign:'center'}} colSpan={3}>No Order data</td></tr></tbody>
        )
    }
} 


export default OrderBook;