import React, { Component } from 'react';
import Footer from '../../Components/Footer/footer';
import Sidebar from '../../Components/Dashboard/sidebar';
import Header from '../../Components/Header/header';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import { BASE_URL } from "../../config/constant";
import Notifications, {notify} from 'react-notify-toast';
import ReferalDetails from './referalDetails';
import TeamStakingLevels from '../Staking/teamStakingLevels';
import Pagination from 'react-js-pagination';
import { orderBy } from 'lodash';
import '../../App.css'
var id;
var responce= [];
let currentPage=1;

export default class Referral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            refHistory: [],
            userData: [],
            kycBonus: '',
            showModal:false,
            forecastingDetails:{},
            totalRows:0,
            activePage:1,
            loading:true,

        };
    }

    async componentWillMount() {
        id = localStorage.getItem('userDetails');
        // console.log("User id: ", id);
        this.tableData()
    }

    tableData = async() => {
        
        var bonus= '';
        await axios.post(BASE_URL + `userInformation/get`,{
            "id": id
        })
        .then(res => {
            // console.log(res.data.data);
            if (res.data.message === "success") {
                responce = res.data.data
                this.setState({ 
                    userData: res.data.data
                })
                this.getRefData();
            }
        })

        // await axios.post(BASE_URL + `ercToken/symbol/get`,{
        //     "tokenSymbol": "FESS"
        // })
        // .then(res => {
        //     // console.log(res.data.data);
        //     if (res.data.message === "success") {
        //         bonus = res.data.data[0]
        //         this.setState({ 
        //             kycBonus:   Number(bonus.refBonus) 
        //         }, function() {
        //             this.getRefData()
        //         })
        //     }
        // })
    }

    getRefData = async() => {
        // console.log(this.state.userData.refCode)
        ////old-code

        // await axios.post(BASE_URL + `referral/history/get`,{
        //     "refCode": this.state.userData.refCode
        // })
        // .then(res => {
        //     // console.log("ref: ", res);
        //     if (res.data.message === "success") {
        //         let refer = res.data.data
        //         refer.map((d) => {
        //             if(d.activationStatus === 1 || d.activationStatus === "1"){
        //                 d["status"] = "Active"
        //                 d["amount"] = this.state.kycBonus
        //             } else {
        //                 d["status"] = "Inactive"
        //                 d["amount"] = ''
        //             }
        //         })
        //         this.setState({ 
        //             refHistory: res.data.data
        //         })
        //     }
        // })

        await axios.post(BASE_URL + `userRefferal/ReferralLevelTreeCount`, {
            "userId": id,
            "pageSize": this.state.activePage
        })
        .then(res => {
            // console.log("ref: ", res);
            if (res.data && res.data.data && res.data.data.data && res.data.data.data.length ) {
                // let refer = res.data.data
                // refer.map((d) => {
                //     if(d.activationStatus === 1 || d.activationStatus === "1"){
                //         d["status"] = "Active"
                //         d["amount"] = this.state.kycBonus
                //     } else {
                //         d["status"] = "Inactive"
                //         d["amount"] = ''
                //     }
                // })
                this.setState({totalRows : res.data.data.totalRows})
                // const d = orderBy(res.data.data.data,'Level')
                this.setState({ 
                    refHistory: res.data.data.data
                })
            }
            this.setState({loading:false})
        })
    }
    handlePageChange = (page)=>{
        // currentPage = page;
        this.setState({loading:true})
        this.setState({activePage:page},()=>{
            this.getRefData()
        })
    }
    onView = (data)=>{
        this.setState({showModal:true,forecastingDetails:data})
    }
    hideModal = ()=>{
        this.setState({showModal:false})
    }
    ordinal_suffix_of(i) {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    }
    render() {
        return (
            <div>
                 <Notifications />
                <Header />
                {/* end header part */}
                
                {/* middel content area start */}
               
                <section id="middel_area" className="ref_mid">
                <img src="images/bg_shape1.png" alt="img" className="shape1" />
                <img src="images/bg_shape2.png" alt="img" className="shape2" />
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 area_left">
                                <Sidebar />
                            </div>
                            <div className="col-sm-12 area_right">
                                {/* <div className="row"> */}
                                    <div className="clearfix assets_part">
                                        <div className="dashboard_top_info row">
                                            <div className="col-md-6">
                                                <div className="white_bx clearfix">
                                                    <div className="dash_icon"><i className="fa fa-user"></i></div>
                                                    <div className="asset_info" style={{display: "inline-block",marginLeft: "20px",width: "80%"}}>
                                                        <h5 style={{ fontWeight: "300", fontSize: "13px", lineHeight: "18px" }}>Your Referral Link</h5>
                                                        <p>
                                                            <input type="text" className="form-control" style={{ background: "#fff",width:"100%" }} name="extratag" value={"https://bitallx.com/invite/" + this.state.userData.refCode} readOnly noValidate />
                                                            <CopyToClipboard
                                                                style={{ marginTop: "10px" }}
                                                                className="btn btn-success"
                                                                text={"https://bitallx.com/invite/" + this.state.userData.refCode}
                                                                onCopy={() => { notify.hide(); notify.show("Referral Link Copied!", "success") }}>
                                                                <a href="#" className="btn btn-success"><i className="fa fa-files-o"></i> Copy Invite Link</a>
                                                            </CopyToClipboard>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="white_bx clearfix">
                                                    <div className="dash_icon"><i className="fa fa-user"></i></div>
                                                    <div className="asset_info" style={{display: "inline-block",marginLeft: "20px",width: "80%"}}>
                                                        <h5 style={{ fontWeight: "300", fontSize: "13px", lineHeight: "18px" }}>Your Referral Code</h5>
                                                        <p>
                                                            <input type="text" className="form-control" style={{ background: "#fff" }} name="extratag" value={this.state.userData.refCode} readOnly noValidate />
                                                            <CopyToClipboard
                                                                style={{ marginTop: "10px" }}
                                                                className="btn btn-success"
                                                                text={this.state.userData.refCode}
                                                                onCopy={() => { notify.hide(); notify.show("Referral Code Copied!", "success") }}>
                                                                <a href="#" className="btn btn-success"><i className="fa fa-files-o"></i> Copy Invite Code</a>
                                                            </CopyToClipboard>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                {/* </div> */}
                                
                              
                                <div className="white_bx clearfix">
                                    <div className="side_txt">
                                        <h6>Referral History </h6>
                                    </div>
                                    {/* <TeamStakingLevels /> */}

                                    {/* Old Code */}

                                    <div className="table-responsive" style={{position:'relative'}}>
                                    {this.state.loading && <div className='loader-overlay'>
                                    <div className='loader-text'>Loading... </div>
                                    </div>}
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sr. No</th>
                                                    <th>Name</th>
                                                    <th>EmailId</th>
                                                    <th>Number of Direct Referrals</th>
                                                    <th>Team (Total)</th>
                                                    <th>Level</th>
                                                    <th>Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.refHistory.map((d,i) => (                                                   
                                                <tr>
                                                    <td className='p5-10'>{((this.state.activePage -1) *10)+i+1}</td>
                                                    <td className='p5-10'>{d.userName}</td>
                                                    <td className='p5-10'>{d.emailId}</td>
                                                    <td className='p5-10'>{d.directTeam}</td>
                                                    <td className='p5-10'>{d.totalteam}</td>
                                                    <td className='p5-10'>{this.ordinal_suffix_of(d.Level)}</td>
                                                    <td className='p5-10'>
                                                        <button className='btn btn-info btn-sm' style={{margin:0}} onClick={()=>{this.onView(d)}}>View</button>
                                                    </td>
                                                 </tr>
                                                ))}
                                            { !this.state.refHistory || this.state.refHistory.length==0? <tr className="no_data">
                                                        <td colspan="8" align="center"><i className="fa fa-file-o"></i> No record found</td>
                                                    </tr>:null}
                                            </tbody>
                                           
                                        </table>
                                    </div>
                                    <div style={{ marginTop: '20px', float: 'right' }}>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={this.state.totalRows}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>
                                    <ReferalDetails data={this.state.forecastingDetails} show={this.state.showModal} hideModal={this.hideModal}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* middel content area end */}
                {/* start footer */}
                <Footer />
                {/* end footer */}
                {/* qr code popup start */}
                <div className="modal fade kyc_modal" id="authenticator" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header modal-header-success">
                                <h5 style={{ display: 'inline-block' }}>Scan this QR Code</h5>  <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
                            </div>
                            <div className="modal-body text-center">
                                <img src="images/qr.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* qr code popup end */}
                {/* all scripts */}
            </div>

        );
    }
}
