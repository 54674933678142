import React from 'react';

const ourQuoteSection=()=>{
    return(
        <section id="our_quote">
  <div class="container">
    <div class="row">
      <div class="col-md-7 col-sm-6">
	 
        <h2 class="heading">We Are FOR EVERYONE</h2>
		 <blockquote>
		<span class="sub_head"><p>We’re building an exchange that will bring anyone who believes in crypto to join the digital cryptocurrency revolution. The world is moving on to this revolution at an unprecedented pace.</p>
		</span>
		</blockquote>
		<p>Now is your time. With Bitallx you can buy, sell & trade digital currencies with amazing ease, confidence and trust. Whether you’re a first time investor or a professional trader - Bitallx has got you both covered!</p>
		
      </div>
	 
      <img src="images/exchange.png"/>
    </div>
  </div>
</section>
    )
}

export default ourQuoteSection