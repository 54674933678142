import React, { useState } from 'react'

const BuySell = ({state,handleChange,buySellBalancePer,handleSubmitBuy}) => {
  return (
    <div>
          <div className="trade_form clearfix">
                      <div className="trade_form_bg clearfix">
                      <ul className="nav nav-tabs" role="tablist" style={{paddingBottom:16}}>
                          <li className="active">
                          <a href="#limit_tab" role="tab" data-toggle="tab">
                            Limit
                          </a>
                        </li>
                        <li >
                          <a href="#market_tab" role="tab" data-toggle="tab">
                            Market
                          </a>
                        </li> 
                        <li >
                          <a href="#stop_tab" role="tab" data-toggle="tab">
                            Stop
                          </a>
                        </li>                        
                      </ul>
                      <div className="tab-content order_table">
                        {/* <!-- limit tab start--> */}
                        <div
                          role="tabpanel"
                          className="tab-pane active"
                          id="limit_tab"
                        >
                         <LimitTab
                         state={state}
                         handleChange={handleChange}
                         buySellBalancePer={buySellBalancePer}
                         handleSubmitBuy={handleSubmitBuy}
                         />
                          </div>
                          <div
                          role="tabpanel"
                          className="tab-pane"
                          id="market_tab"
                        >
                          <MarketTab
                         state={state}
                         handleChange={handleChange}
                         buySellBalancePer={buySellBalancePer}
                         handleSubmitBuy={handleSubmitBuy}
                         />
                        </div>
                        <div
                          role="tabpanel"
                          className="tab-pane"
                          id="stop_tab"
                        >
                          <StopTab
                        state={state}
                        handleChange={handleChange}
                        buySellBalancePer={buySellBalancePer}
                        handleSubmitBuy={handleSubmitBuy}
                        /></div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="trade_form clearfix">
                      <div className="trade_form_bg clearfix">
                        <h6>Sell</h6>
                        <div id="sell_trade">
                          <div className="trade_buy_form">
                            <form>
                              <div className="form-group clearfix">
                                <label>
                                  Available<br></br>
                                  {state.coinCode}
                                </label>
                                <input
                                  type="number"
                                  placeholder="0"
                                  className="form-control"
                                  readOnly
                                  value={Number(state.userBalanceCoin)
                                    .toFixed(10)
                                    .slice(0, -4)}
                                />
                              </div>

                              <div className="form-group clearfix">
                                <label> Price </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="0"
                                  name="sellPrice"
                                  autoComplete="off"
                                  value={state.sellPrice}
                                  onChange={handleChange}
                                  {...(state.sellPrice === "MARKET"
                                    ? { disabled: "disabled" }
                                    : {})}
                                />
                              </div>
                              <div className="form-group clearfix">
                                <label>Amount</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="0"
                                  name="sellVolume"
                                  value={state.sellVolume}
                                  onChange={handleChange}
                                />
                                <div className="amount_opt">
                                  <ul>
                                    <li onClick={() => sellBalancePer(25)}>
                                      25%
                                    </li>
                                    <li onClick={() => sellBalancePer(50)}>
                                      50%
                                    </li>
                                    <li onClick={() => sellBalancePer(75)}>
                                      75%
                                    </li>
                                    <li
                                      onClick={() => sellBalancePer(100)}
                                    >
                                      100%
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Total</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="0"
                                  name="sellTotal"
                                  value={state.sellTotal}
                                />
                              </div>
                              <div className="" onClick={handleSubmitSell}>
                                {" "}
                                <a href="#" className="blue_btn">
                                  Place Sell Order
                                </a>{" "}
                                <small>
                                  Fee: Maker fee: 0.2% | Taker fee: 0.2%
                                </small>{" "}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div> */}
    </div>
  )
}

const LimitTab =({state,handleChange,buySellBalancePer,handleSubmitBuy})=>{

  const [percentage, setPercentage] = useState(0);
  const [bboActive, setBboActive] = useState(false);

  return(
    <div id="buy_trade">
    <div className="trade_buy_form">
      <form>
        <div className="form-group clearfix">
          <div className='d-flex items-end'>
            <div style={{width: "100%"}}>
            {bboActive ?
            <>
            <select name="bbo" id="bbo"  className="form-control" >
              <option value="BBO">BBO</option>
              <option value="Best 5">Best 5</option>
              <option value="Best 10">Best 10</option>
              <option value="Best 20">Best 20</option>
            </select>
            </>
             :
             <>
             <label> Price </label>
              <input
                type="text"
                className="form-control"
                placeholder="0"
                name="buySellPrice"
                autoComplete="off"
                value={state.buySellPrice}
                onChange={handleChange}
                {...(state.buySellPrice === "MARKET"
                  ? { disabled: "disabled" }
                  : {})}
              />
             </>}
            </div>
            <div>
              <button type='button' onClick={()=> setBboActive(!bboActive)} className={bboActive? 'btn btn-primary': 'btn btn-secondery'}>BBO</button>
            </div>
          </div>
        </div>

        <div className="form-group clearfix">
          <label>Amount</label>
          <input
            type="text"
            className="form-control"
            placeholder="0"
            name="buySellVolume"
            value={state.buySellVolume}
            onChange={handleChange}
          />
          {/* <div className="amount_opt">
            <ul>
              <li onClick={() =>buySellBalancePer(25)}>
                25%
              </li>
              <li onClick={() =>buySellBalancePer(50)}>
                50%
              </li>
              <li onClick={() =>buySellBalancePer(75)}>
                75%
              </li>
              <li onClick={() =>buySellBalancePer(100)}>
                100%
              </li>
            </ul>
          </div> */}
            <div style={{paddingTop: 20}}>
            <input type="range" style={{cursor:"pointer"}} value={percentage} name="points" min="0" max="100" step="25" onChange={(e)=>{setPercentage(e.target.value);buySellBalancePer(e.target.value)}}/>
            </div>
        </div>
        
        <div className="form-group">
          <label>Total</label>
          <input
            type="text"
            className="form-control"
            placeholder="0"
            name="buySellTotal"
            value={state.buySellTotal}
          />
        </div>
        <div className="form-group">
        <div className='d-flex items-end justify-between'>
          <label> Available ({state.pairCode}) </label>
          <label >{Number(state.userBalancePair).toFixed(10).slice(0, -4)}</label>
          </div>
        </div>
        <div style={{display: "flex"}}>
        <button className="green_btn" style={{width: "50%"}} onClick={(e)=>handleSubmitBuy(e, "Buy")}>Buy</button>
        <button className="blue_btn"  style={{width: "50%"}} onClick={(e)=>handleSubmitBuy(e, "Sell")}>Sell</button>
        
        </div>
        {/* <div><small>
            Fee: Maker fee: 0.2% | Taker fee: 0.2%
          </small>{" "}</div> */}
      </form>
    </div>
  </div>
  )
}
const MarketTab =({state,handleChange,buySellBalancePer,handleSubmitBuy})=>{

  const [percentage, setPercentage] = useState(0);

  return(
    <div id="buy_trade">
    <div className="trade_buy_form">
      <form>
        <div className="form-group clearfix">
          <label>
            Available ({state.pairCode})
            
          </label>
          <input
            type="number"
            placeholder="0"
            className="form-control"
            readOnly
            value={Number(state.userBalancePair)
              .toFixed(10)
              .slice(0, -4)}
          />
        </div>

        <div className="form-group clearfix">
          <label> Price </label>
          <input
            type="text"
            className="form-control"
            placeholder="0"
            name="buySellPrice"
            autoComplete="off"
            value={state.buySellPrice}
            onChange={handleChange}
            {...(state.buySellPrice === "MARKET"
              ? { disabled: "disabled" }
              : {})}
          />
        </div>
        <div className="form-group clearfix">
          <label>Amount</label>
          <input
            type="text"
            className="form-control"
            placeholder="0"
            name="buySellVolume"
            value={state.buySellVolume}
            onChange={handleChange}
          />
         
            <div style={{paddingTop:10}}>
            <input type="range" style={{cursor:"pointer"}} value={percentage} name="points" min="0" max="100" step="25" onChange={(e)=>{setPercentage(e.target.value);buySellBalancePer(e.target.value)}}/>
            </div>
        </div>
        <div className="form-group">
          <label>Total</label>
          <input
            type="text"
            className="form-control"
            placeholder="0"
            name="buySellTotal"
            value={state.buySellTotal}
          />
        </div>
        
        <div style={{display: "flex"}}>
        <button className="green_btn" style={{width: "50%"}} onClick={(e)=>handleSubmitBuy(e, "Buy")}>Buy</button>
        <button className="blue_btn"  style={{width: "50%"}} onClick={(e)=>handleSubmitBuy(e, "Sell")}>Sell</button>
        
        </div>
        {/* <div><small>
            Fee: Maker fee: 0.2% | Taker fee: 0.2%
          </small>{" "}</div> */}
      </form>
    </div>
  </div>
  )
}
const StopTab =({state,handleChange,buySellBalancePer,handleSubmitBuy})=>{

  const [percentage, setPercentage] = useState(0);

  return(
    <div id="buy_trade">
    <div className="trade_buy_form">
      <form>
        <div className="form-group clearfix">
          <label>
            Available ({state.pairCode})
            
          </label>
          <input
            type="number"
            placeholder="0"
            className="form-control"
            readOnly
            value={Number(state.userBalancePair)
              .toFixed(10)
              .slice(0, -4)}
          />
        </div>

        <div className="form-group clearfix">
          <label> Price </label>
          <input
            type="text"
            className="form-control"
            placeholder="0"
            name="buySellPrice"
            autoComplete="off"
            value={state.buySellPrice}
            onChange={handleChange}
            {...(state.buySellPrice === "MARKET"
              ? { disabled: "disabled" }
              : {})}
          />
        </div>
        <div className="form-group clearfix">
          <label>Amount</label>
          <input
            type="text"
            className="form-control"
            placeholder="0"
            name="buySellVolume"
            value={state.buySellVolume}
            onChange={handleChange}
          />
         
            <div style={{paddingTop:10}}>
            <input type="range" style={{cursor:"pointer"}}  name="points" min="0" max="100" step="25" onChange={(e)=>{buySellBalancePer(e.target.value)}}/>
            </div>
        </div>
        <div className="form-group">
          <label>Total</label>
          <input
            type="text"
            className="form-control"
            placeholder="0"
            name="buySellTotal"
            value={state.buySellTotal}
          />
        </div>
        
        <div style={{display: "flex"}}>
        <button className="green_btn" style={{width: "50%"}} onClick={(e)=>handleSubmitBuy(e, "Buy")}>Buy</button>
        <button className="blue_btn"  style={{width: "50%"}} onClick={(e)=>handleSubmitBuy(e, "Sell")}>Sell</button>
        
        </div>
        {/* <div><small>
            Fee: Maker fee: 0.2% | Taker fee: 0.2%
          </small>{" "}</div> */}
      </form>
    </div>
  </div>
  )
}
export default BuySell