import React from 'react';

const introductionSection=()=>{
    return(
        <section id="introduction">
  <div className="container">
    <div className="row">
      <div className="col-sm-7 col-xs-6 full intro_text pull-right">
        <h2 className="heading">Introduction</h2>
		<span className="sub_head"><p>
Just like Bitcoin and other cryptocurrencies you can trade other top cryptocurrencies on most secure exchange Bitallx. </p>
		</span>
		<p>We’ve left no stone unturned to make Bitallx India’s most secure exchange. We’re investing in regular security audits to ensure a highly secured trading platform for India.</p>
		<div className="intro_link">
		<a href="#">View Whitepaper</a>
		<a href="#">View Market Pairs</a>
      </div>
      </div>
	  <div className="col-sm-5 col-xs-6 full intro_img text-center">
	  <img src="images/coins.png" alt="img" />
	  </div>
     
    </div>
  </div>
</section>
    )
}

export default introductionSection