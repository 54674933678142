import React, { Component } from 'react';
import { Button } from 'reactstrap';

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import {BASE_URL} from "../../config/constant";
import speakeasy from "@levminer/speakeasy";
import QRCode from "qrcode.react";
import moment from "moment"; 
import TextEllipsis from 'react-text-ellipsis';


var id ;
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  }
class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
        loading: true,
        notFound: false,
        data: ""
    };
  }





    async componentDidMount() {
        await axios.post(BASE_URL + `announcement/getid`, {
            "id": this.props.match.params.blogid
        })
        .then(res => {
            // console.log("user id",this.state.userId)
            // console.log(res.data); 
            if (res.data.message == "success") {
                
                this.setState({
                    data : res.data.data,
                    loading: false,
                })
                
            } else {
                this.setState({
                    notFound: true,
                    loading: false,
                })
            }
        })
    }




  render() {
    document.title ="Announcements"; 
    document.body.classList.add('faded_bg'); 

    return (
        <div> 
            <Header loadFees={this.state.loadFeeSubs}/>
            <section id="middel_area">
                <div className="container">
                <div className="row">
                    
                    
                    <div className="col-sm-12 area_right">
                        <h3>Announcements</h3>
                        <hr></hr>



                        {this.state.notFound == true &&
                            <div className="col-md-12 white_bx text-center"><h3>Not Found</h3></div>
                        }
                        {this.state.loading == true ?
                        <div style={{height:"250px"}}>
                            <div className="loader">Loading...</div>
                        </div>

                        :
                        
                        <>
                        
                        
                        {this.state.notFound == false &&

<div class="card col-md-12" style={{border:"1px #eee solid",padding:"20px",borderRadius:"5px",background:"#fff"}}>
                            <div>

    <h5 class="card-title" style={{marginBottom:"10px"}}>{this.state.data.title}</h5>
                            </div>
                            <hr></hr>
  <img class="card-img-top" src={this.state.data.imageurl} style={{maxWidth:"100%"}}/>
<hr></hr>
  <div class="card-body" style={{}}>
    <p style={{color:"#999",fontSize:"12px",marginBottom:"10px"}}><i class="fa fa-clock-o"></i> {this.state.data.date}</p>
    <p class="card-text"></p> 

<div>

{this.state.data.body}
</div>

  </div>
</div>

                        }
                        

                       
                    
                        </>
                        }
                      
                    </div>
                    

                </div>
                </div>
            </section>

            <Footer/>

        </div>
    );
  }
}

export default Setting;