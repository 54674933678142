import React, { Component, useEffect, useState,useRef } from 'react';
import Sidebar from '../../Components/Dashboard/sidebar';
import Header from '../../Components/Header/header';
import axios from 'axios';
import { BASE_URL } from "../../config/constant";
import Notifications, { notify } from 'react-notify-toast';
import '../../index.css'
import ForecastingTree from './forecastingTree';
import { Spinner } from '../../Components/Spinner/Spinner';
import Pagination from 'react-js-pagination';
import ForecastingTreeForLavel from './stakingTreeForLabel';

function StakingLevels() {
    const [showModal, setShowModal] = useState(false);
    const [forecastingDetails, setForecastingDetails] = useState()
    const [activePage, setActivePage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [totalRow, setTotalRow] = useState(0)
    useEffect(() => {
        document.title = 'Staking Level'
        const userId = localStorage.getItem('userDetails')
        setLoading(true)
        axios.post(BASE_URL+'userStaking/StakinglevelDetail',{userId:userId,pageSize:activePage}).then(res=>{
          if (res.data && res.data.data && res.data.data.data) {
            let data=[];
            // res.data.data.forEach(e0 => {
            // //   if (e0.length > 0) {
                
            // //     data.push({childId: e0[0].childId,expand:e0,expanded:false})
            // //   } else {
            // //     data.push({childId: e0[0].childId,expanded:false})
            // //   }
            // data.push({...e0,expanded:false})
            // });
              setRefList(res.data.data.data)
              setTotalRow(res.data.data.totalRows)
              setLoading(false)
          }
      }).catch(e=>{
        setLoading(false)
      })
    
     
    }, [activePage])
    
    const [refList, setRefList] = useState([]);
    const toggleRow = (d)=>{
        console.log(d)
        d.expanded = !d.expanded;
        setRefList([...refList])
    }
    const onView = (data)=>{
        setShowModal(true);
        setForecastingDetails(data)
    }
  const  handlePageChange = (page)=>{
        // currentPage = page;
        setLoading(true)
        setActivePage(page)
    }
    const Table = ({data}) => {
        return (
            <>
            <div className="table-responsive" style={{position:'relative', minHeight:200}} >
                    {loading && <div className='loader-overlay'>
                        <div className='loader-text'>Loading... </div>
                    </div>}
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>Sr. No.</th>
                        <th>User Name</th>
                        <th>Package ID(Staking)</th>
                        {/* <th>Email Id</th> */}
                        <th>Coin/Token Name</th>
                        <th>Qty</th>
                        <th>Bonus Price(Monthly)</th>
                        <th>Level</th>
                        <th>Percentage</th>
                        <th>Months</th>
                        {/* <th>Amount</th> */}
                        {/* <th>Duration (Months)</th>
                        <th>USD Price</th> */}
                        <th>Action</th>

                    </tr>
                </thead>
                <>
                    {data.map((d,i) => (
                        <>
                        <tr key={'parent'+i} style={{fontSize:12, fontWeight:500}} >
                            <td style={{paddingLeft:'15px'}}>{i+1}</td>
                            <td>{d.username}</td>
                            <td>{d.stakingPackageId || '-'}</td>
                            {/* <td>{d.email}</td> */}
                            <td>{d.coinName}</td>
                            <td>{d.amount}</td>
                            <td>${(+d.ForecastAmount).toFixed(4)}</td>
                            <td>{d.level}</td>
                            <td>{d.percentage}%</td>
                            {/* <td>$ {(d.amount * d.usdPrice).toFixed(4)}</td> */}
                            {/* <td>{d.duration}</td> */}
                            <td>{d.duration}</td>
                            <td> <span className='btn btn-info btn-sm' onClick={()=>{onView({_id:d.id,userId:d.userId,percentage:d.percentage})}}>View</span></td>
                        {/* {d.stakingArray ?<td> <span className='toggle-row' onClick={()=>{toggleRow(d)}}>{d.expanded ? '-':'+'}</span></td>:<td></td>} */}
                        
                            
                        </tr>
                            {/* {d.stakingArray && d.stakingArray.length && d.expanded ?
                                <tr>
                                    <td colSpan={3}>
                                        <Table data={d.stakingArray} /> 
                                    </td>
                                </tr>:null
                                            } */}
                        </>
                    ))}
                    {/* <tr>
                    {loading  ?<td colSpan={9} height='130px' align="center" className="no_data"> <Spinner /></td>:null}
                            {!loading  && refList.length ==0 ?<td colSpan={9} align="center" className="no_data"><i className="fa fa-file-o" /> No record found</td>:null}
                    </tr> */}
                </>
             </table>
            </div> 
            <div style={{ marginTop: '20px', float: 'right' }}>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalRow}
                                            pageRangeDisplayed={5}
                                            onChange={(e)=>handlePageChange(e)}
                                        />
                                    </div>
            </>
        )
    }
    return (
        <div>
            <Notifications />
            <Header />
            <ForecastingTreeForLavel key={Date.now().toString()+'tls'} data={forecastingDetails} show={showModal} setShow={setShowModal} />
            
            <section id="middel_area" className="ref_mid">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-12 area_left">
                                <Sidebar  />
                            </div>
                        <div className="col-sm-12 area_right" style={{position:'relative'}}>
                        
                            <div className="white_bx clearfix">
                                <div className="side_txt">
                                    <h6>Staking Levels </h6>
                                </div>
                                <Table data={refList} />
                                {/* <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Level</th>
                                                <th>Total Forecast</th>
                                                <th style={{width:'20px'}}></th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {refList.map((d,i) => (
                                                <>
                                                <tr key={'parent'+i}>
                                                    <td><strong>Level {d.level}</strong></td>
                                                    <td>{d.totalForecast}</td>
                                                {d.stakingArray ?<td> <span className='toggle-row' onClick={()=>{toggleRow(d)}}>{d.expanded ? '-':'+'}</span></td>:<td>.</td>}
                                                </tr>
                                                    {d.stakingArray && d.stakingArray.length && d.expanded ?
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <Table data={d.stakingArray} />
                                                            </td>
                                                        </tr> : null
                                                    }
                                                </>
                                            ))}
                                          {!refList || refList.length == 0 ?<tr>
                                            <td colSpan={5} align="center" className="no_data"><i className="fa fa-file-o" /> No record found</td>
                                            </tr>:null}
                                        </tbody>
                                    </table>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default StakingLevels