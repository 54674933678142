import React, { useEffect, useState } from 'react';

function HomeBannerSection (){
  const [topPairDetails, setTopPairDetails] = useState([
    {
      pairWithSeparator:'BTC',
      pair: 'BTCUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'ETH',
      pair: 'ETHUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'XRP',
      pair: 'XRPUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'XLM',
      pair: 'XLMUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
    {
      pairWithSeparator:'TRX',
      pair: 'TRXUSDT',
      price:0.00,
      percentageChange: 0.00,
      priceChange: 0.00
    },
  ]);

  const updatePairPrice = (data) =>{
    setTopPairDetails(existing => existing.map(pr => pr.pair == data.s ? 
      {
        ...pr,
        price: (+data.c).toFixed(4),
        percentageChange:data.P,
        priceChange: (+data.p).toFixed(4)
      } : pr))
  }
  useEffect(() => {
    const conn = new WebSocket("wss://stream.binance.com:9443/ws/!ticker@arr");
    conn.onopen = function(evt) {
      // console.log(evt);
        // conn.send(JSON.stringify({ method: "subscribe", topic: "allMiniTickers", symbols: ["$all"] }));
    }
    conn.onmessage = function(evt) {
      try {
        let msgs = JSON.parse(evt.data);
        if (Array.isArray(msgs)) {
          for (let msg of msgs) {
            updatePairPrice(msg);
          }
        } else {
          // updatePairPrice(msgs)
        }
      } catch (e) {
        console.log('Unknown message: ' + evt.data, e);
      }
    }
    return () => {
      conn.close()
    };
  }, []);
  
    return(
      <>
        <section id="home_banner">
<img src="images/bg_shape1.png" alt="img" className="shape1" />
<img src="images/bg_shape2.png" alt="img" className="shape2" />
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-6 col-sm-6 col-xs-12 banner_text">
	  <span>NOW OPEN FOR ALL COUNTRIES</span>
        <h1>Welcome to the INDIA'S MOST TRUSTED BITCOIN & CRYPTOCURRENCY EXCHANGE</h1>
		<p>Buy, Sell & Trade Bitcoin, Ethereum, Ripple, Litecoin and more <br />cryptocurrencies in India. Start trading now!</p>
		<a href="/register" className="orange_btn">Sign Up Now</a>
		
		<div className="download_link">
		<a href="https://play.google.com/store/apps/details?id=com.bitallx.mobileapp" target='_blank'> <img src="/images/google_play_icon.svg" style={{height:"39px"}} alt="img" /></a>
		{/* <a href="#"> <img src="images/ios_store.png" style={{height:"39px"}} alt="img" /></a> */}
		</div>
		
      </div>
	  <div className="col-md-6 col-sm-6 col-xs-12 banner_img text-center">
	  <img src="images/crypto.png" alt="img" />
	  </div>
     
    </div>
  </div>
</section>
<section id="market_part">
  <div className="container-fluid">
    <div className="row">
      <div className="col-xs-12">
      <ul>
      {topPairDetails.map((d,i) => {
        return (
        <li key={d.pairWithSeparator+''+i}>
          <div class="info"> 
          <a href="#">
          <h6> {d.pairWithSeparator} </h6>
          <span>$ {d.price}</span>
          <span class={d.percentageChange<0?"red_clr":"green_clr"}>{d.percentageChange>0?"+":''}{d.percentageChange}</span>
          </a> 
          </div>
          </li>
        )})}
      </ul>
      </div>
    </div>
  </div>
</section>

</>
    )
}

export default HomeBannerSection