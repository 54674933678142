import React, { Component } from "react";
import { Button } from "reactstrap";

import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Dashboard/sidebar";
import { BrowserRouter, Link, Redirect } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../config/constant";
import speakeasy from "@levminer/speakeasy";
import QRCode from "qrcode.react";
import moment from "moment";
import TextEllipsis from "react-text-ellipsis";

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
  }

  render() {
    document.title = "Privacy Policy";
    document.body.classList.add("faded_bg");

    return (
      <>
        <Header loadFees={this.state.loadFeeSubs} />
        <section id="inner_top_part" class="fee_head">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 text-center">
                <h2> PRIVACY POLICY</h2>
              </div>
            </div>
          </div>
        </section>
        <section id="middle_content">
    <div class="container">
	<div class="row">
	<div class="col-12">
	<div class="white_bx clearfix">
                <h3>Privacy Policy</h3>
                <hr></hr>

                <div style={{ textAlign: "justify" }}>
                  <p>
                    <strong>1.INTRODUCTION</strong>
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    We at Bitallx(the "Service&rdquo;, &ldquo;us", "we", or
                    "our") deeply care about your (The client who is using
                    Bitallx service) privacy. This page informs you of our
                    policies regarding the collection, use and disclosure of
                    Personal Information when you use this platform or the
                    Service. We will not use or share your information with
                    anyone except as described in this Privacy Policy. We use
                    your Personal Information for providing and improving the
                    Service. By using the Service, you agree to the collection
                    and use of information in accordance with this policy.
                    Unless otherwise defined in this Privacy Policy, terms used
                    in this Privacy Policy have the same meanings as in our
                    Terms and Conditions, accessible at https://bitallx.com.
                  </p>
                  <p>&nbsp;</p>
                  <ol start="2">
                    <li>
                      <strong>
                        2. PERSONAL DATA WE COLLECT ABOUT YOU AND HOW WE COLLECT
                        IT
                      </strong>
                    </li>
                  </ol>
                  <p>
                    There are three general categories of personal data we
                    collect.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>2.1 INFORMATION YOU GIVE TO US.</strong>
                  </p>
                  <p>
                    (a) We collect your account data, which may include personal
                    identification information such as your name, date of birth,
                    age, nationality, gender, signature, utility bills,
                    photographs, phone number, home address, and/or email
                    address, and formal identification information such as Tax
                    ID number, passport number, driver&rsquo;s license details,
                    national identity card details, photograph identification
                    cards, and/or visa information (collectively, Account Data).
                  </p>
                  <p>
                    The Account Data may be processed for the purposes of
                    providing to you our Services, satisfying anti-money
                    laundering and know-your-customer obligations, and ensuring
                    the security of the Services, maintaining back-ups of our
                    databases and communicating with you. This information is
                    necessary for the adequate performance of the contract
                    between you and us and to allow us to comply with our legal
                    obligations. Without it, we may not be able to provide you
                    with all the requested Services.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    (b) We process financial information such as bank account,
                    credit card or PayPal information when you use our services
                    in order to facilitate the processing of payments (Payment
                    Information). The legal basis for this processing is the
                    performance of a contract between you and us and/or taking
                    steps, at your request, to enter into such a contract and
                    our legitimate interests, namely our interest in the proper
                    administration of our website and business.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    (c) We may process information contained in or relating to
                    any communication that you send to us (Correspondence Data).
                    The Correspondence Data may include the communication
                    content and metadata associated with the communication. The
                    correspondence data may be processed for the purposes of
                    communicating with you and record-keeping. The legal basis
                    for this processing is our legitimate interests, namely the
                    proper administration of our website and business and
                    communications with users.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    (d) We may process information included in your personal
                    profile, which may include your location, time zone and
                    website (Profile Data). The Profile Data may be processed
                    for the purposes of providing you a better user experience
                    when using the Services. The legal basis for this processing
                    is your consent.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>
                      2.2 INFORMATION WE AUTOMATICALLY COLLECT FROM YOUR USE OF
                      THE SERVICES.{" "}
                    </strong>
                  </p>
                  <p>
                    When you use the Services, we may automatically process
                    information about your computer and internet connection
                    (including your IP address, operating system and browser
                    type), your mobile carrier, device information (including
                    device and application IDs), search terms, cookie
                    information, as well as information about the timing,
                    frequency and pattern of your service use, and information
                    about to the transactions you make on our Services, such as
                    the name of the recipient, your name, the amount and type of
                    cryptocurrency and timestamp (Service Data). The Service
                    Data is processed for the purpose of providing our Services.
                    The legal basis for this processing is the adequate
                    performance of the contract between you and us, to enable us
                    to comply with legal obligations and our legitimate interest
                    in being able to provide and improve the functionalities of
                    the Services.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>
                      2.3 INFORMATION WE COLLECT FROM THIRD PARTIES.{" "}
                    </strong>
                  </p>
                  <p>
                    From time to time, we may obtain information about you from
                    third party sources as required or permitted by applicable
                    law, such as public databases, credit bureaus, ID
                    verification partners, resellers and channel partners, joint
                    marketing partners, and social media platforms. ID
                    verification partners use a combination of government
                    records and publicly available information about you to
                    verify your identity. Such information includes your name,
                    address, job role, public employment profile, credit
                    history, status on any sanctions lists maintained by public
                    authorities, and other relevant data. We obtain such
                    information to comply with our legal obligations, such as
                    anti-money laundering laws. The legal basis for the
                    processing such data is compliance with legal obligations.
                  </p>
                  <p>&nbsp;</p>
                  <ol start="3">
                    <li>
                      <strong>3. DISCLOSURE OF PERSONAL DATA</strong>
                    </li>
                  </ol>
                  <p>
                    3.1 We may disclose your personal data to any member of our
                    group of companies (this means our subsidiaries, our
                    ultimate holding company and all its subsidiaries) insofar
                    as reasonably necessary for the purposes, and on the legal
                    bases, set out in this policy.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    3.2 We may disclose Service Data to a variety of third-party
                    service providers insofar as reasonably necessary to improve
                    the functionalities of the Services. For example, we may
                    disclose Service Data to obtain useful analytics, provide
                    in-app support to mobile app users, determine location data
                    and provide search engine functionality to our users.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    3.3 We may share aggregated data (information about our
                    users that we combine together so that it no longer
                    identifies or references an individual user) and other
                    anonymized information for regulatory compliance, industry
                    and market analysis, demographic profiling, marketing and
                    advertising, and other business purposes.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    3.4 In addition to the specific disclosures of personal data
                    set out in this Section 3, we may disclose your personal
                    data if we believe that it is reasonably necessary to comply
                    with a law, regulation, legal process, or governmental
                    request; or in order to protect your vital interests or the
                    vital interests of another natural person; to protect the
                    safety or integrity of the Services, or to explain why we
                    have removed content or accounts from the Services; or to
                    address fraud, security, or technical issues; or to protect
                    our rights or property or the rights or property of those
                    who use the Services.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    3.5 We offer individuals the opportunity to choose (opt out)
                    whether their personal information is (i) to be disclosed to
                    a third party or (ii) to be used for a purpose that is
                    materially different from the purpose(s) for which it was
                    originally collected or subsequently authorized by the
                    individuals. In order to opt out from disclosure of your
                    personal information, please write to us at{" "}
                    <a href="mailto:help@bitallx.com">
                      <u>help@bitallx.com</u>
                    </a>
                    &nbsp;
                  </p>
                  <p>&nbsp;</p>
                  <ol start="4">
                    <li>
                      <strong>4. LINKS TO OTHER SITES</strong>&nbsp;
                    </li>
                  </ol>
                  <p>
                    Our Service may contain links to other sites that are not
                    operated by us. If you click on a third-party link, you will
                    be directed to that third party's site. We strongly advise
                    you to review the Privacy Policy of every site you visit. We
                    have no control over, and assume no responsibility for the
                    content, privacy policies or practices of any third-party
                    sites or services.
                  </p>
                  <p>&nbsp;</p>
                  <ol start="5">
                    <li>
                      <strong>
                        5. INTERNATIONAL TRANSFER OF YOUR PERSONAL DATA
                      </strong>
                    </li>
                  </ol>
                  <p>
                    Your information, including Personal Information, may be
                    transferred to &mdash; and maintained on &mdash; computers
                    located outside of your state, province, country or other
                    governmental jurisdiction where the data protection laws may
                    differ than those from your jurisdiction. If you are located
                    outside India and choose to provide information to us,
                    please note that we transfer the information, including
                    Personal Information, to India and process it there. Your
                    consent to this Privacy Policy followed by your submission
                    of such information represents your agreement to that
                    transfer.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>6. RETAINING AND DELETING PERSONAL DATA</strong>
                  </p>
                  <p>
                    6.1 This Section 6 sets out our data retention policies and
                    procedure, which are designed to help ensure that we comply
                    with our legal obligations in relation to the retention and
                    deletion of personal data.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    6.2 Personal data that we process for any purpose or
                    purposes shall not be kept for longer than is necessary for
                    that purpose or those purposes.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    6.3 We generally retain your personal information for as
                    long as is necessary for the performance of the contract
                    between you and us and to comply with our legal obligations.
                    If you no longer want us to use your information to provide
                    the Services to you, you can request that we erase your
                    personal information and close your account.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    6.4 Notwithstanding the other provisions of this Section 6,
                    we may retain your personal data where such retention is
                    necessary for compliance with a legal obligation to which we
                    are subject, or in order to protect your vital interests or
                    the vital interests of another natural person.
                  </p>
                  <p>&nbsp;</p>
                  <ol start="7">
                    <li>
                      <strong>7. CHILDREN'S PRIVACY</strong>
                    </li>
                  </ol>
                  <p>
                    Our Service does not address anyone under the age of 18
                    ("Children"). We do not knowingly collect personally
                    identifiable information from children under 18. If you are
                    a parent or guardian and you are aware that your Children
                    has provided us with Personal Information, please contact
                    us. If we become aware that we have collected Personal
                    Information from a child under age 18 without verification
                    of parental consent, we take steps to remove that
                    information from our servers.
                  </p>
                  <p>&nbsp;</p>
                  <ol start="8">
                    <li>
                      <strong>8. COOKIES</strong>
                    </li>
                  </ol>
                  <p>
                    Cookies are files with small amount of data, which may
                    include an anonymous unique identifier. Cookies are sent to
                    your browser from a web site and stored on your computer's
                    hard drive. We use "cookies" to collect information. You can
                    instruct your browser to refuse all cookies or to indicate
                    when a cookie is being sent. However, if you do not accept
                    cookies, you may not be able to use some portions of our
                    Service.
                  </p>
                  <p>&nbsp;</p>
                  <ol start="9">
                    <li>
                      <strong>9. SECURITY</strong>
                    </li>
                  </ol>
                  <p>
                    The security of your Personal Information is important to
                    us, but remember that no method of transmission over the
                    Internet, or method of electronic storage is 100% secure.
                    While we strive to use commercially acceptable means to
                    protect your Personal Information, we cannot guarantee its
                    absolute security.
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>10. COMMUNICATIONS</strong>
                  </p>
                  <p>
                    We may use your Personal Information to contact you with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to you. You may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or instructions provided in
                    any email we send.
                  </p>
                  <p>&nbsp;</p>
                  <ol start="11">
                    <li>
                      <strong>11. CHANGES TO OUR PRIVACY POLICY</strong>
                    </li>
                  </ol>
                  <p>
                    It is our policy to post any changes we make to our Policy
                    on this page. If we make material changes to how we treat
                    our users' personal data, we will notify you by e-mail to
                    the primary e-mail address specified in your account. You
                    are responsible for ensuring we have an up-to-date active
                    and deliverable e-mail address for you, and for periodically
                    visiting our Website and this Policy to check for any
                    changes.
                  </p>
                  <p>&nbsp;</p>
                  <ol start="12">
                    <li>
                      <strong>12. CONTACT US</strong>
                    </li>
                  </ol>
                  <p>
                    If you have any questions about this Privacy Policy, please
                    contact us at help@bitallx.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>

        <Footer />
      </>
    );
  }
}

export default Setting;
