import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

import { BASE_URL } from "../../config/constant";
// import logo from './../../images/logo.png'

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisable: false,
    };
  }

  componentDidMount() {
    let id = localStorage.getItem("userDetails", "");
    console.log("User: ", id);

    if (localStorage.getItem("userDetails")) {
      axios
        .post(BASE_URL + `userInformation/get`, {
          id: id,
        })
        .then((res) => {
          if (res.data.message === "success") {
            this.setState({
              uniqueId: res.data.data.uniqueId,
            });
          }
        });
    }
  }

  render() {
    return localStorage.getItem("userDetails") == null ? (
      <header className={this.props.blueBg ? "new_nav" : null}>
        <nav className="navbar">
          <div className="container-fluid right_padding">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
              <a className="navbar-brand" href="/">
                <img
                  src={`/images/${
                    this.props.blueBg ? "logo.png" : "logo.png"
                  }`}
                  alt="Company Logo"
                />
              </a>
            </div>
            <div
              id="navbar"
              className="navbar-collapse collapse"
              aria-expanded="false"
            >
              <ul className="nav navbar-nav navbar-right nav_btn">
              <li className="dropdown">
                  {" "}
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Exchange
                    <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                  <li className="">
                  <a href="/trade/BTC/USDT">Spot Trade</a>
                </li>
                <li className="">
                  <a href="/futures/trade/BTC/USDT">Futures Trade</a>
                </li>
                  </ul>
                </li>
                
                {/* <li>
                  <a href="/news">News</a>
                </li> */}
                <li>
                  <a href="/login" className="">
                    Login
                  </a>
                </li>
                <li>
                  <a href="/register" className="btn blue_btn">
                    Sign Up
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    ) : (
      <header className="new_nav white_bg">
        <nav className="navbar">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
              <a className="navbar-brand" href="/dashboard">
                <img src="/images/logo.png" alt="Company Logo" />
              </a>{" "}
            </div>
            <div
              id="navbar"
              className="navbar-collapse collapse"
              aria-expanded="false"
              style={{ height: "1px" }}
            >
              <ul className="nav navbar-nav main_nav">
                
                <li className="dropdown">
                  {" "}
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Exchange
                    <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                  <li className="">
                  <a href="/trade/BTC/USDT">Spot Trade</a>
                </li>
                <li className="">
                  <a href="/futures/trade/BTC/USDT">Futures Trade</a>
                </li>
                  </ul>
                </li>
                {/* <li>
                  <a href="/news">News</a>
                </li>
                <li>
                  <a href="/support">Support</a>
                </li> */}
              </ul>
              <ul className="nav navbar-nav navbar-right nav_btn">
                <li>
                  <a href="/dashboard">Funds</a>
                </li>
                <li className="dropdown">
                  {" "}
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-user"></i>{" "}
                    {localStorage.getItem("userName")}{" "}
                    <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu">
                    {/* <li><a href="#">User ID : {this.state.uniqueId}</a></li> */}
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/referral">Referral</a>
                    </li>                   
                    
                    <li><a href="/personaldetails">Personal Details</a></li>
                    <li><a href="/setting">Settings</a></li>
                   
                    {/* <li>
                      <a href="#">Security</a>
                    </li>
                    <li>
                      <a href="#">API</a>
                    </li> */}

                    {/* <li>
                      <a href="#">Add Nominee</a>
                    </li>
                    <li>
                      <a href="#">About Bitallx</a>
                    </li> */}
                    <li>
                      <a href="/logout">Logout</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
