import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { BASE_URL } from '../../config/constant';
import axios from 'axios';
import '../../index.css';
import moment from 'moment';

function ForecastingTree({ data, show, setShow }) {
  const [forecastingList, setForecastingList] = useState([]);
  const handleClose = () => {
    setForecastingList({})
    setShow(false)
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    // document.title = 'Stake History'
    if (show) {

      // const userId = localStorage.getItem('userDetails')
      axios.post(BASE_URL + 'userStaking/forecastTreeById', { userId: data.userId, stakingId: data._id }).then(res => {
        if (res.data && res.data.data) {
          setForecastingList(res.data.data);

        }
      }).catch(e => {

      })
    }


  }, [show])
  return (
    <>
      
          <Modal animation={false} show={show} onHide={handleClose} size='lg'>
            
              {/* <div className='forcasting-heading'> Reward Tree</div>
          <hr /> */}
          <section id="middel_area" className="ref_mid" style={{padding:0,position:'initial'}}>
        <img src="images/bg_shape1.png" alt="img" className="shape1" />
        <img src="images/bg_shape2.png" alt="img" className="shape2" />
        {/* <div className="container"> */}
        <div className='white_bx ' style={{padding:0,margin:0}}>
        <Modal.Header closeButton>
              <Modal.Title><h3>Rewards & Forecasting</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {forecastingList && forecastingList.length ? <div className="table-responsive" style={{ height: 300,overflowY:'scroll' }}>
                <table className="table table-hover">
                  <thead className='sticky-on-top' style={{ background: '#fef5f8' }}>
                    <tr >
                      <td>Sr. No.</td>
                      <td>UserId</td>
                      <td>Staking Id</td>
                      <td>Debit Amount</td>
                      <td>WalletCode</td>
                      <td>Amount</td>
                      <td>Created Date</td>
                      <td>USD Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    {forecastingList?.rewardStock?.map((d, i) => (
                      <>
                        <tr key={'parents' + i}>
                          <td className='p-10'>{i+1}</td>
                          <td className='p-10'>{d.userId}</td>
                          <td className='p-10'>{d.stakingId}</td>
                          <td className='p-10'>$ {(+d.debitAmount).toFixed(2)}</td>
                          <td className='p-10'>{d.walletCode}</td>
                          <td className='p-10'>$ {(+d.amount).toFixed(2)}</td>
                          <td className='p-10'>{moment(d.createdAt).format('DD MMM YYYY, HH:SS ')}</td>
                          <td className='p-10' >{(+d.usdPrice).toFixed(2)}</td>

                        </tr>
                      </>
                    ))}
                    {!forecastingList || !forecastingList?.rewardStock || forecastingList?.rewardStock?.length == 0 ? <tr>
                      <td colSpan={8} align="center" className="no_data"><i className="fa fa-file-o" /> No record found for rewards</td>
                    </tr> : null}
                  </tbody>
                </table>
              </div>: null}

              {/* <div className='forcasting-heading'> Forcasting Tree</div> */}
              <div className="table-responsive" style={{ height: 450,overflowY:'scroll' }}>
                <table className="table table-hover">
                  <thead className='sticky-on-top' style={{ background: '#fef5f8' }}>
                    <tr >
                      <td>Sr. No.</td>
                      <td>Coin Name</td>
                      <td>Qty</td>
                      <td>Staked Amount</td>
                      <td>Forecast amount</td>
                      <td>Percentage</td>
                      <td>Date</td>
                      <td>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {forecastingList?.treeDataBinding?.map((d, i) => (
                      <>
                        <tr key={'forcating' + i}>
                          <td className='p-10'>{i+1}</td>
                          <td className='p-10'>{d.coinName}</td>
                          <td className='p-10'>{d.stakedAmount}</td>
                          <td className='p-10'> ${(d.stakedAmount * d.usdPrice).toFixed(2)}</td>
                          <td className='p-10'>$ {(d.amountReceived * d.usdPrice).toFixed(2)}</td>
                          <td className='p-10'>{d.percentage}</td>
                          <td className='p-10'>{moment(d.date).format('DD MMM YYYY, HH:SS ')}</td>
                          <td className='p-10'>{moment(d.date).isSameOrBefore(Date.now(), 'days')? 
                          <span className='text-success'>Successful </span>
                           : <span className='text-warn'>Pending</span>
                        }</td>


                        </tr>
                      </>
                    ))}
                    {!forecastingList || !forecastingList?.treeDataBinding || forecastingList?.treeDataBinding?.length == 0 ? <tr>
                      <td colSpan={7} align="center" className="no_data"><i className="fa fa-file-o" /> No record found for level forecasting</td>
                    </tr> : null}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
              {/* </div> */}
        </div>
      </section>
            {/* <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer> */}
          </Modal>
        
    </>
  );
}

export default ForecastingTree