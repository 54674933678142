import React from 'react';

const customerServiceSection=()=>{
    return(
        <section id="service">
          <img src="images/bg_shape1.png" alt="img" className="shape1" />
<img src="images/bg_shape2.png" alt="img" className="shape2" />
  <div className="container">
    <div className="row">
      <div className="col-md-4 col-sm-6 col-xs-6 full service_info text-center">
        <div className="white_bg clearfix"> 
        <div class="icon_bx text-center">
          <img src="images/security.png" alt="img" />
          </div>
          <h3>BEST IN CLASS SECURITY</h3>
          <p>We’ve left no stone unturned to make Bitallx India’s most secure exchange. We’re investing in regular security audits to ensure a highly secured trading platform for India.</p>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-xs-6 full service_info text-center">
        <div className="white_bg clearfix"> 
        <div class="icon_bx text-center">
          <img src="images/kyc.png" alt="img" />
          </div>
          <h3>SUPER FAST KYC</h3>
          <p>Top of the line identity verification systems process your KYC within a few hours of signing up, while ensuring the right KYC protocol. We’re building in robustness to sharply reduce verification times.</p>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-xs-6 full service_info text-center">
        <div className="white_bg clearfix"> 
        <div class="icon_bx text-center">
          <img src="images/speed.png" alt="img" />
          </div>
          <h3>LIGHTNING SPEED TRANSACTIONS</h3>
          <p>Bitallx can handle Millions of transactions. Our system infrastructure can scale up in a few seconds to match surging demand.</p>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-xs-6 full service_info text-center">
        <div className="white_bg clearfix"> 
        <div class="icon_bx text-center">
          <img src="images/platform_icon.png" alt="img" />
          </div>
          <h3>ACROSS 5 PLATFORMS</h3>
          <p>Bitallx offers a seamless and powerful trading experience across all platforms - on Web, Android & iOS mobile, Windows, and Mac apps.</p>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-xs-6 full service_info text-center">
        <div className="white_bg clearfix"> 
        <div class="icon_bx text-center">
          <img src="images/design_icon.png" alt="img" />
          </div>
          <h3>SIMPLE & EFFICIENT DESIGN</h3>
          <p>Trading on the Bitallx platform is a super fast experience you’ll fall in love with! We’ve built technology used by Millions, and that experience has helped us build this powerful exchange.</p>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 col-xs-6 full service_info text-center">
        <div className="white_bg clearfix"> 
        <div class="icon_bx text-center">
          <img src="images/block_del.png" alt="img" />
          </div>
          <h3>BUILT BY BLOCKCHAIN BELIEVERS</h3>
          <p>We’re a team of hardcore traders & passionate blockchain believers. We understand exactly what you need. Powerful graphs, mobile apps, etc are features being built into Bitallx from day 1.</p>
        </div>
      </div>
    </div>
  </div>
</section>
    )
}

export default customerServiceSection