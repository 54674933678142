import React, { useEffect, useState } from "react";
import "../styles.scss";
import { BASE_URL, TOKEN_ICON_URL } from "../../../../config/constant";
// import Loader from "../../../React-Loader/loader";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const TransferModal =({ show, handleClose,coin,sourceEx, targetEx }) =>{
  const [loading, setLoading] = useState(false);
  const [transferFrom, setTransferFrom] = useState("Spot Wallet");
  const [transferTo, setTransferTo] = useState("Future Wallet");
  const [showFromCoinDropdown, setShowFromCoinDropdown] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [balances, setBalances] = useState({});
  const [selectedToken, setSelectedToken] = useState({});
  const [amount, setAmount] = useState("");

  // useEffect(() => {
  //  if (sourceEx && targetEx) {
  //   setTransferFrom(sourceEx)
  //   setTransferTo(targetEx)
  //  }
  // }, [sourceEx, targetEx])
  
  useEffect(() => {
    console.log(coin);
    const userId = localStorage.getItem("userDetails");
    if (userId && coin) {
      setLoading(true);
      axios
        .get(BASE_URL + `ercToken/get/all`)
        .then((res) => {
          setTokens(res.data?.data || []);
          const sel = (res.data?.data || []).find((e) => e.tokenSymbol === coin)
          setSelectedToken(
            sel
          );
          console.log(coin,sel,res.data?.data);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
        if (sourceEx && targetEx) {
          setTransferFrom(sourceEx)
          setTransferTo(targetEx)
         }
      getBalances(userId);
    }
  }, [coin]);
  // useEffect(() => {
  //   if (coin) {
  //     setSelectedToken(
  //       (tokens || []).find((e) => e.tokenSymbol === coin)
  //     );
  //     }
  // }, [coin])
  
  const getBalances = (userId) => {
    setLoading(true);
    axios
      .get(BASE_URL + `get/futureAndSpotBalances?userId=${userId}`)
      .then((res) => {
        setBalances(res.data?.data || []);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const onTokenSelect = (data) => {
    setLoading(true);
    setSelectedToken(data);
    setShowFromCoinDropdown(false);
    setLoading(false);
  };
  const swap = () => {
    setLoading(true);
    const from = transferFrom;
    const to = transferTo;
    setTransferFrom(to);
    setTransferTo(from);
    setLoading(false);
  };
  const getAvailbleBalance = () => {
    if (transferFrom === "Spot Wallet") {
      return balances?.spotBalances
        ? Number(balances?.spotBalances[selectedToken?.tokenSymbol])
        : 0;
    } else {
      return balances?.FutureWalletBalances
        ? Number(balances?.FutureWalletBalances[selectedToken?.tokenSymbol])
        : 0;
    }
  };

  const onSubmit = () => {
    if (amount <= getAvailbleBalance() && amount>0) {
      setLoading(true);
      const userId = localStorage.getItem("userDetails");
      const payload = {
        userId: userId,
        coinName: selectedToken.tokenSymbol,
        amount: amount,
      };
      if (transferFrom === "Spot Wallet") {
        axios
          .post(BASE_URL + `transfer/spotToFutureWallet`, payload)
          .then((res) => {
            getBalances(userId);
            setLoading(false);
            toast.success(amount+" "+selectedToken.tokenSymbol+" transferred to future wallet!")
            setAmount("");
            handleClose()
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      } else {
        axios
          .post(BASE_URL + `transfer/futureToSpot`, payload)
          .then((res) => {
            getBalances(userId);
            setLoading(false);
            setAmount("");
            handleClose()
            toast.success(amount+" "+selectedToken.tokenSymbol+" transferred to spot wallet!")
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      }
    }
  };
  return (
    <div>
      {loading && <h6>Loading...</h6>}
      <ToastContainer/>
      <div
        className={`modal fade in popup_form ${show && "show"}`}
        id="transfer_modal"
        data-backdrop="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content rounded-md">
            <div className="d-flex flex-between px-2 py-2">
              <h5 className="modal-title" id="exampleModalLabel">
                Transfer From
              </h5>
              <button
                type="button"
                className="close font-16"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleClose();
                }}
              >
                {" "}
                <span aria-hidden="true">&times;</span>{" "}
              </button>
            </div>
            <div className="modal-body">
              <div
                className="trade_buy_form p-2"
                style={{ maxHeight: "75vh", overflow: "auto" }}
              >
                <div className="font-16 txt-black font-w-500">
                  Internal transfer are free on Bitallx
                </div>
                <form>
                  <div className="bg-off-white p-4 rounded-sm mt-4">
                    <div className="transfer-from d-flex flex-start">
                      <div className="from-to">From</div>
                      <div className="font-w-600">{transferFrom}</div>
                    </div>
                    <div className="d-flex flex-end">
                      <img
                        src="/images/sort.png"
                        alt=""
                        height={30}
                        width={30}
                        className="cursor-pointer"
                        onClick={() => swap()}
                      />
                    </div>
                    <div className="transfer-from d-flex flex-start">
                      <div className="from-to">To</div>
                      <div className="font-w-600">{transferTo}</div>
                    </div>
                  </div>
                  <hr />

                  <div>
                    <div>
                      <label htmlFor="coinName-from">Coin</label>
                      <div
                        className="d-flex flex-between bg-off-white p-4 rounded-sm cursor-pointer"
                        onClick={() =>
                          setShowFromCoinDropdown(!showFromCoinDropdown)
                        }
                      >
                        <div className="d-flex flex-start">
                          <div className="mr-2">
                            <img
                              src={TOKEN_ICON_URL + selectedToken?.tokenSymbol + ".png"}
                              width="20px"
                              height="20px"
                              alt=""
                            />
                          </div>
                          <div className="font-12 font-w-500">
                            {selectedToken?.tokenSymbol}
                            <small className="ml-2">
                              {selectedToken?.tokenName}
                            </small>
                          </div>
                        </div>
                        <div>
                          <i className="fa fa-chevron-down txt-black"></i>
                        </div>
                      </div>
                      {showFromCoinDropdown && (
                        <div className="bg-light-gray rounded-sm mt-1">
                          <ul style={{ maxHeight: 200, overflow: "auto" }}>
                            {(tokens || [])
                              .filter((e) => !e.isFiat)
                              .map((data) => (
                                <li
                                  className="divider ml-4 mr-4 py-2 cursor-pinter"
                                  onClick={() => onTokenSelect(data)}
                                >
                                  <div className="d-flex flex-start ">
                                    <div className="mr-2">
                                      <img
                                        src={TOKEN_ICON_URL + data?.tokenSymbol + ".png"}
                                        width="20px"
                                        height="20px"
                                        alt=""
                                      />
                                    </div>
                                    <div className="font-12 font-w-500">
                                      {data.tokenSymbol}
                                      <small className="ml-2">
                                        {data.tokenName}
                                      </small>
                                    </div>
                                  </div>
                                  {/* <div className="divider"></div> */}
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}

                      <div className="from-group mt-4 position-relative">
                        <div className="d-flex flex-between">
                          <label htmlFor="amount">Amount</label>
                          <div className="font-12">
                            {getAvailbleBalance().toFixed(4)}
                            <small> available</small> / {(+amount).toFixed(4)}{" "}
                            <small>in order</small>
                          </div>
                        </div>
                        <input
                          type="number"
                          placeholder="0.0000"
                          name="amount"
                          id="amount"
                          className="form-control bg-off-white "
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        /><span className="btn btn-info max-button" onClick={()=> setAmount(getAvailbleBalance())}>Max</span>
                        {amount > getAvailbleBalance() && (
                          <label className="error-text">Insufficient Fund</label>
                        )}
                      </div>
                    </div>

                    <div className="d-flex flex-end mt-4">
                      <button type="button" className="btn" onClick={()=> handleClose()}> Cancel</button>
                      <button type="button" disabled={amount <=0 || amount>getAvailbleBalance() || loading}
                        className="bg-yellow-5 btn txt-white font-w-600 px-4"
                        onClick={() => onSubmit()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransferModal;
