import React, { Component, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { BASE_URL, TOKEN_ICON_URL } from "../../config/constant";
import '../../index.css'
import BigNumber from 'bignumber.js';
import { uniqBy } from 'lodash';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Card, CardBody, CardHeader, Col, Row, Input, Button } from 'reactstrap';
import 'react-tabs/style/react-tabs.css'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
} 

function BuySellTransactionStatements() {
    const [adminTransactions, setAdminTransactions] = useState([])
    const loading = useRef(true);
    const [filters, setFilters] = useState([])
    const [pageIndex, setPageIndex] = useState(1);
    const [totalRow, setTotalRow] = useState(0)
    
    
    const [tokenList, setTokenList] = useState([])
    const [selectDropdownCoin, setSelectDropdownCoin] = useState('Select Coin');
    const [selectDropdownPair, setSelectDropdownPair] = useState('Select Pair');
    const [coinName , setCoinName] = useState("")
    const [pairName , setPairName] = useState("")
    const [openOrders , setOpenOrders] = useState([])
    const [completedOrders , setCompletedOrders] = useState([])

    useEffect(() => {
        document.title = "Transactions";
        axios.get(BASE_URL + `ercToken/get/all`)
            .then(res => {
                if (res.data.message == "success") {
                    setTokenList(res.data.data)
                }
            })
    }, [])
    useEffect(() => {
        getTableData()
    }, [filters,pageIndex])
    
    const getTableData = () => {
        let userId = localStorage.getItem('userDetails')
        const filterList = uniqBy(filters, 'name')
        axios.post(BASE_URL + `transactionStatement/get/user`, { userId: userId, pageIndex: pageIndex-1, filters: filterList })
            .then(res => {
                if (res.data && res.data.data) {
                    setAdminTransactions(res.data.data);
                    setTotalRow(res.data.totalRow)
                }
                loading.current = false;
            }).catch((e) => {
                loading.current = false;
            })
    }
    const onSelectCoin = (coin) => {

        setCoinName(coin.tokenSymbol)
        // const uniq = filters.filter(e => e.name != "coinName")
        // setFilters([...uniq, { name: "coinName", value: coin.tokenSymbol }]);

        setSelectDropdownCoin(<><img src={TOKEN_ICON_URL + coin.tokenSymbol + ".png"} width="20px" height="20px" style={{ marginLeft: "10px", marginRight: "20px", marginTop: "-4px" }} /> {coin.tokenSymbol} <span style={{ fontSize: "12px", color: "#888" }}>({coin.tokenName})</span></>)
    }

    const onSelectPair = (pair) => {

        setPairName(pair.tokenSymbol)
        // const uniq = filters.filter(e => e.name != "coinName")
        // setFilters([...uniq, { name: "coinName", value: coin.tokenSymbol }]);

        setSelectDropdownPair(<><img src={TOKEN_ICON_URL + pair.tokenSymbol + ".png"} width="20px" height="20px" style={{ marginLeft: "10px", marginRight: "20px", marginTop: "-4px" }} /> {pair.tokenSymbol} <span style={{ fontSize: "12px", color: "#888" }}>({pair.tokenName})</span></>)
    }

    const search = async()=>{
        let sendKey = {
            "pair": pairName,
            "coinName": coinName,
            "status": 0,
            "userId": localStorage.getItem('userDetails'),
        }
        console.log(sendKey)
       await axios.post(BASE_URL+ `order/get/id` , sendKey).then((res)=>{
            console.log(res)
            if (res.data.message == "success") {
                    setOpenOrders(res.data.data)
            }else{
                setOpenOrders([])
            }
        }).catch((err)=>{
            console.log(err)
            setOpenOrders([])
        })


        let sendKey1 = {
            "pair": pairName,
            "coinName": coinName,
            "status": 1,
            "userId": localStorage.getItem('userDetails'),
        }
        console.log(sendKey1)
       await axios.post(BASE_URL+ `order/get/id` , sendKey1).then((res)=>{
            console.log(res)
            if (res.data.message == "success") {
                    setCompletedOrders(res.data.data)
            }else{
                setCompletedOrders([])
            }
        }).catch((err)=>{
            console.log(err)
            setCompletedOrders([])
        })
    }


    const getFormatedTime = (time)=>{
        let T = time.split(" ")
        let T1 = T[1];
        let T3 = T1.replaceAll("-",":")
        let T4 = new Date(T[0] + " " + T3 ).getTime()+(19800000)
        return T4;
  }


   const dateFormatter = (cell, row, enumObject, rowIndex)=> {
        console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12">
                        <a href="#"  >
                        { moment(getFormatedTime(row.dateTime)).format('DD MM YYYY hh:mm:ss a')}
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    
 

    const onFilterChange = (filertType,value)=>{
        const uniq = filters.filter(e => e.name != filertType)
        setFilters([...uniq, { name: filertType, value: value }]);
    }
   const handlePageChange = (page)=>{
     setPageIndex(page)
    }
    return (
        <div>
            <div className="row">
                <div className="col-sm-4 col-md-4">
                    <div className="dropdown withdarw_drop">
                        <button id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {/* <i className="fa fa-search"></i>  */}
                            {selectDropdownCoin} <span className="caret"></span> </button>
                        <ul className="dropdown-menu" aria-labelledby="dLabel">
                            {tokenList.map((d, xid) => (
                                <li key={xid} onClick={() => { onSelectCoin(d) }}>
                                    <img src={TOKEN_ICON_URL + d.tokenSymbol + ".png"} width="20px" height="20px" style={{ marginLeft: "10px", marginRight: "20px" }} />
                                    {d.tokenSymbol} <span style={{ fontSize: "12px", color: "#888" }}>({d.tokenName})</span>
                                </li>
                            ))}
                        </ul>

                    </div>
                </div>
                <div className="col-sm-4 col-md-4">
                    <div className="dropdown withdarw_drop">
                        <button id="dLabel" className="dropdown-select" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {/* <i className="fa fa-search"></i>  */}
                            {selectDropdownPair} <span className="caret"></span> </button>
                        <ul className="dropdown-menu" aria-labelledby="dLabel">
                            {tokenList.map((d, xid) => (
                                <li key={xid} onClick={() => { onSelectPair(d) }}>
                                    <img src={TOKEN_ICON_URL + d.tokenSymbol + ".png"} width="20px" height="20px" style={{ marginLeft: "10px", marginRight: "20px" }} />
                                    {d.tokenSymbol} <span style={{ fontSize: "12px", color: "#888" }}>({d.tokenName})</span>
                                </li>
                            ))}
                        </ul>

                    </div>
                </div>
               
              
                <div className="col-sm-4 col-md-4 ">
                    <button className='btn btn-primary' onClick={()=>{search()}} style={{margin:'2px 5px'}} disabled={coinName == '' || pairName == ''}>Search</button>
                </div>
            </div>
         



            <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader style={{ fontSize: '22px' }}>
                                                    <p style={{
                                                        marginRight: 10, fontSize: '18px'
                                                    }}></p>  
                                                </CardHeader>
                                                <CardBody >
                                                <Tabs>
                                                    <TabList style={{
                                                        backgroundColor: '#D1DADE',
                                                        borderBottom: 0, color: '#777', height: '38px'
                                                    }}>
                                                        <Tab>OPEN ORDERS</Tab>
                                                        <Tab>COMPLETED ORDERS</Tab>
                                                    </TabList>
                                                    <TabPanel style={{ padding: '20px' }}>
                                                        {/* {this.state.loader === 1 ? 
                                                        <div style={{textAlign: 'center', justifyContent: 'center'}}>
                                                             <Spinner animation="border" variant="primary" />
                                                        </div>
                                                        : */}
                                                        <BootstrapTable data={openOrders} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                  <TableHeaderColumn isKey dataField='_id' width={'16%'} thStyle={headerStyle}>TRANSACTION ID</TableHeaderColumn>
                                                            <TableHeaderColumn  dataField='coinName' width={'5%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='pair' width={'5%'} thStyle={headerStyle}>PAIR</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='orderType' width={'5%'} thStyle={headerStyle}>TYPE</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='price' width={'8%'} thStyle={headerStyle}>PRICE</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amount' width={'8%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='total' width={'8%'} thStyle={headerStyle}>TOTAL</TableHeaderColumn>
                                                            {/* <TableHeaderColumn dataField='tredingFee' width={'10%'} thStyle={headerStyle}>TRADING FEE</TableHeaderColumn> */}
                                                            <TableHeaderColumn dataField='binanceorderId' width={'10%'} thStyle={headerStyle}>BINANCE ORDERID</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={dateFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'13%'}
                                                            >DATE</TableHeaderColumn>
                                                          </BootstrapTable>
                                                        {/* } */}
                                                    </TabPanel>

                                                    <TabPanel style={{ padding: '20px' }}>
                                                    {/* {this.state.loader === 1 ? 
                                                        <div style={{textAlign: 'center', justifyContent: 'center'}}>
                                                             <Spinner animation="border" variant="primary" />
                                                        </div>
                                                        : */}
                                                        <BootstrapTable data={completedOrders} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn isKey dataField='_id' width={'16%'} thStyle={headerStyle}>TRANSACTION ID</TableHeaderColumn>
                                                            <TableHeaderColumn  dataField='coinName' width={'5%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='pair' width={'5%'} thStyle={headerStyle}>PAIR</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='orderType' width={'5%'} thStyle={headerStyle}>TYPE</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='price' width={'8%'} thStyle={headerStyle}>PRICE</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amount' width={'8%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='total' width={'8%'} thStyle={headerStyle}>TOTAL</TableHeaderColumn>
                                                            {/* <TableHeaderColumn dataField='tredingFee' width={'10%'} thStyle={headerStyle}>TRADING FEE</TableHeaderColumn> */}
                                                            <TableHeaderColumn dataField='binanceorderId' width={'10%'} thStyle={headerStyle}>BINANCE ORDERID</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={dateFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'13%'}
                                                            >DATE</TableHeaderColumn>
                                                        </BootstrapTable>
                                                        {/* } */}
                                                    </TabPanel>
                                                </Tabs>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>













            <div style={{ marginTop: '20px', float: 'right' }}>
            {/* <Pagination
                activePage={pageIndex}
                itemsCountPerPage={10}
                totalItemsCount={totalRow}
                pageRangeDisplayed={5}
                onChange={(e)=>handlePageChange(e)}
            /> */}
            </div>
        </div>
    )
}

export default BuySellTransactionStatements