import {
	makeApiRequest,
	generateSymbol,
	parseFullSymbol,
} from './helpers.js';
import {
	subscribeOnStream,
	unsubscribeFromStream,
} from './streaming.js';

const ecnaSymbols =[
	{
		"symbol": "BTXT/USDT",
		"full_name": "Bitallx:BTXT/USDT",
		"description": "BTXT/USDT",
		"exchange": "Bitallx",
		"type": "crypto"
	},
	{
		"symbol": "BTXT/ETH",
		"full_name": "Bitallx:BTXT/ETH",
		"description": "BTXT/ETH",
		"exchange": "Bitallx",
		"type": "crypto"
	},
	{
		"symbol": "BTXT/BTC",
		"full_name": "Bitallx:BTXT/BTC",
		"description": "BTXT/BTC",
		"exchange": "Bitallx",
		"type": "crypto"
	}
];
const ecnaExchangePairs={
	"Bitallx":{
		isActive: true,
		isTopTier:true,
		pairs:{
			BTXT:['USDT','ETH','BTC']
		}
	}
}
const lastBarsCache = new Map();
// const granularityMap = {
//     '1': 60,
//     '3': 180,
//     '5': 300,
//     '15': 900,
//     '30': 30 * 60, // 1800
//     '60': 60 * 60, // 3600
//     '120': 60 * 60 * 2, // 7200
//     '240': 60 * 60 * 4, // 14400
//     '360': 60 * 60 * 6, // 21600
//     '720': 60 * 60 * 12, // 43200
//     'D': 1440,
//     'W': 10080,
//     'M': 43800,
//     '3M': 131400,
//   };
const supportedResolutions = ["1", "3", "5", "15", "30", "60", "120", "240", "D", "W","M"]
let passPairData = {
	coinName : " ",
	pair : ""
}
const config = {
  supported_resolutions: supportedResolutions
};
const configurationData = {
	//supported_resolutions: ['1','3','5','15','30','60','120','240','360','720','D',"W","M"],
	exchanges: [
		{
			value: 'Binance',
			name: 'Binance',
			desc: 'Binance',
		},
	
	// {
	// 	value: 'CCCAGG',
	// 	name: 'CCCAGG',
	// 	desc: 'CCCAGG',
	// },
	{
		value: 'Bitallx',
		name: 'Bitallx',
		desc: 'Bitallx',
	},
	// {
	// 	// `exchange` argument for the `searchSymbols` method, if a user selects this exchange
	// 	value: 'Kraken',

	// 	// filter name
	// 	name: 'Kraken',

	// 	// full exchange name displayed in the filter popup
	// 	desc: 'Kraken bitcoin exchange',
	// },
	],
	symbols_types: [{
		name: 'crypto',

		// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
		value: 'crypto',
	},
		// ...
	],
};

async function getAllSymbols() {
	const data = await makeApiRequest('data/v3/all/exchanges');
	if(data.Data){
		data.Data['Bitallx'] = ecnaExchangePairs['Bitallx']
	}
	let allSymbols = [];

	for (const exchange of configurationData.exchanges) {
		const pairs = data.Data[exchange.value].pairs;

		for (const leftPairPart of Object.keys(pairs)) {
			const symbols = pairs[leftPairPart].map(rightPairPart => {
				const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
				return {
					symbol: symbol.short,
					full_name: symbol.full,
					description: symbol.short,
					exchange: exchange.value,
					// exchange: 'CCCAGG',
					type: 'crypto',
				};
			});
			allSymbols = [...allSymbols, ...symbols];
		}
	}
	return allSymbols;
}

export default {
	onReady: (callback) => {
		console.log('[onReady]: Method call');
		setTimeout(() => callback(config),0);
	},

	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback,
	) => {
		console.log('[searchSymbols]: Method call');
		const symbols = await getAllSymbols();
		const newSymbols = symbols.filter(symbol => {
			const isExchangeValid = exchange === '' || symbol.exchange === exchange;
			const isFullSymbolContainsInput = symbol.full_name
				.toLowerCase()
				.indexOf(userInput.toLowerCase()) !== -1;
			return isExchangeValid && isFullSymbolContainsInput;
		});
		onResultReadyCallback(newSymbols);
	},

	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
	) => {
		console.log('[resolveSymbol]: Method call', symbolName);
		const symbols = await getAllSymbols();
		const symbolItem = symbols.find(({
			full_name,
		}) => full_name === symbolName);
		if (!symbolItem) {
			console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
			onResolveErrorCallback('cannot resolve symbol');
			return;
		}
		const symbolInfo = {
			ticker: symbolItem.full_name,
			name: symbolItem.symbol,
			description: symbolItem.description,
			type: symbolItem.type,
			session: '24x7',
			timezone: 'Etc/UTC',
			exchange: symbolItem.exchange,
			minmov: 1,
			pricescale: 100000,
			has_intraday: true,
			has_no_volume: false,
			has_weekly_and_monthly: false,
			intraday_multipliers: ['1', '60'],
			supported_resolutions: supportedResolutions,
			volume_precision: 2,
			data_status: 'streaming',
		};

		console.log('[resolveSymbol]: Symbol resolved', symbolName);
		onSymbolResolvedCallback(symbolInfo);
	},

	getBars: async (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) => {
		passPairData = {
			coinName : await symbolInfo?.name?.split('/')[0],
			pair :  await symbolInfo?.name?.split('/')[1]
		} 
		console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
		// const 
		const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
		const urlParameters = {
			e: parsedSymbol.toSymbol.toLowerCase()=== 'inr'? 'CCCAGG': parsedSymbol.exchange,
			fsym: parsedSymbol.fromSymbol,
			tsym: parsedSymbol.toSymbol,
			toTs: to,
			limit: 1000,
		};
		const query = Object.keys(urlParameters)
			.map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
			.join('&');
		try {
			let data;
			console.log('passPairData    >>>>>>>>  ',passPairData)
			if(passPairData && passPairData?.coinName && passPairData?.coinName === 'BTXT'){
				data = await makeApiRequest(passPairData);
			}else{
				data = await makeApiRequest(`data/histoday?${query}`);
			}
			if ((data?.Response && data?.Response === 'Error') || data.Data.length === 0) {
				// "noData" should be set if there is no data in the requested period.
				onHistoryCallback([], {
					noData: true,
				});
				return;
			}
			let bars = [];
			data.Data.forEach(bar => {
				if (bar.time >= from && bar.time < to) {
					bars = [...bars, {
						time: bar.time * 1000,
						low: bar.low,
						high: bar.high,
						open: bar.open,
						close: bar.close,
						volume: bar.volumefrom
					}];
				}
			});
			if (firstDataRequest) {
				lastBarsCache.set(symbolInfo.full_name, {
					...bars[bars.length - 1],
				});
			}
			console.log(`[getBars]: returned ${bars.length} bar(s)`);
			onHistoryCallback(bars, {
				noData: false,
			});
		} catch (error) {
			console.log('[getBars]: Get error', error);
			onErrorCallback(error);
		}
	},

	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscribeUID,
		onResetCacheNeededCallback,
	) => {
		console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscribeUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
	},

	unsubscribeBars: (subscriberUID) => {
		console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		unsubscribeFromStream(subscriberUID);
	},
};